import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Libs from '../../../../../utils/Libs';
import RowItemProduct from './RowItemProduct';

class RowItem extends Component {
    onItemClick = () => {
        if (!this.props.onItemClick || typeof this.props.onItemClick !== 'function') return;
        this.props.onItemClick(this.props.index);
    }

    onItemClickDelete = () => {
        if (!this.props.onItemClickDelete || typeof this.props.onItemClickDelete !== 'function') return;
        this.props.onItemClickDelete(this.props.index);
    }

    render() {
        var item = this.props.dataItem;
        const { t } = this.props;
        var RowItems = null;
        if(Libs.isArrayData(item.dataProducts)){
            var dataProducts = item.dataProducts;
            RowItems = dataProducts.map((item, index) => {
                return <RowItemProduct dataItem = {item}  key={'row_item_' + index} index={index} />
            })
        }
        return (
            <div className="body-row" key={item.id}>
                <ReactTooltip />
                <div className="body-col width15">
                    <p className="id">{item.id} </p>
                    <p><span className="receive">Tiếp nhận</span></p>
                    <p>KH: {item.customer_name} </p>
                    <p>ĐT: {item.customer_phone} </p>
                </div>
                <div className="body-col width15">
                    <p>{item.username} </p>
                    <p>{item.date} </p>
                    <p>{item.store_name} </p>
                </div>
                <div className="body-col width35">
                    {!Libs.isBlank(RowItems) ?
                    <div className="sub-table">
                    <div className="thead">
                        <div className="th-row">
                            <div className="th-col width60">Tên</div>
                            <div className="th-col width20 text-end">Giá</div>
                            <div className="th-col width20 text-end">SL</div>
                        </div>
                    </div>
                    <div className="tbody">
                        {RowItems}
                        
                    </div>
                </div>
                    : ""}
                    

                </div>
                
                <div className="body-col width10 text-end"><p>{item.tax}</p></div>
                <div className="body-col width10 text-end">
                    <p>{item.saleoff}</p>
                    <p className="percent">({item.saleoff_percent}%)</p>
                </div>

                <div className="body-col width10 text-end">
                    <p><strong>{item.total}</strong></p>
                    <p><span className="success">Đã thanh toán</span></p>
                    <p><span className="payment">Tiền mặt</span></p>
                    </div>
                <div className="body-col width5">
                    <div className="functions">
                        <ul>
                            <li onClick={this.onItemClick.bind(this)} data-tip={t('common.edit')} data-type="warning" data-class="tooltip-action"><var className="icon-pencil-square-o" aria-hidden="true"></var></li>
                            <li onClick={this.onItemClickDelete.bind(this)} data-tip={t('common.delete')} data-type="warning" data-class="tooltip-action"><var className="icon-trash-o" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDelete.bind(this)} data-tip= {t('common.print')} data-type="warning" data-class="tooltip-action"><var className="icon-print" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDelete.bind(this)} data-tip= {t('common.view')} data-type="warning" data-class="tooltip-action"><var className="icon-eye" aria-hidden="true"></var></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default RowItem;
