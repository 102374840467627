import BaseComponent from '../../BaseComponent';
import LoginJsx from './Login.jsx';
import { withTranslation } from 'react-i18next';
import LoginValidate from './LoginValidate';
import Libs from '../../../utils/Libs';
import Constants from '../../../utils/Constants';
import EmployeeService from '../../../services/EmployeeService';

class Login extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = LoginJsx;
        this.state = {
            curItem: {},
            lang: "vi"
        };
    }

    async componentWillMount() {
        if (!Libs.isBlank(localStorage.getItem(Constants.COMMON.TOKEN))) {
            window.location.href = Constants.SITE_URL.DEFAULT_PAGE;
            return;
        }
        else await localStorage.clear()
    }


    async onSaveAction() {
        let curItem = this.state.curItem, self = this;
        let user = {
            email: curItem.email,
            password: Libs.SHA3(curItem.password),
            lang: this.state.lang,
            iso_code: curItem.iso_code
        };

        let v = new LoginValidate(this.props);
        let errors = await v.FLValidationAll(curItem);
        if (errors) {
            this.setValidateMessage(errors);
            return;
        }
        var { t } = this.props;
        EmployeeService.instance.getLogin(user, function (data) {
            if (!Libs.isObjectEmpty(data)) {
                self.setAccessParam(data);
                window.location.href = Constants.SITE_URL.DEFAULT_PAGE;
                return;
            }
            else {
                self.toast(t('login.error'), "error");
            }
        }, false);
    }


    /**
   * set user login parameters to localstore
   * @param {object} param 
   * @param {string} lang 
   */
    setAccessParam(param) {
        let token = param.token;
        let permissions = param.permissions;
        localStorage.setItem(Constants.COMMON.TOKEN, token);
        let jsonPermissions = JSON.stringify(permissions);
        localStorage.setItem(Constants.COMMON.ACCESS_PARAM, Libs.base64Encrypt(jsonPermissions));
        var lang = 'vi';
        if (Libs.isArrayData(param.languages)) {
            var findItemLang = Libs.find(param.languages, 'is_default', 1);
            if (!Libs.isObjectEmpty(findItemLang)) {
                lang = findItemLang.iso_code;
            }
        }

        let info = {
            lang: lang,
            id_language: param.id_language,
            id_company: param.id_company,
            iso_code: param.iso_code,
            email: param.email,
            first_name: param.first_name,
            last_name: param.last_name,
            full_name: param.full_name,
            avatar: param.avatar,
            id_employee: param.id_employee,
            timeout: param.timeout,
            roles: param.roles,
            languages: param.languages,
            config_cdn: param.config_cdn,
            config_thumb_folder_new: param.config_thumb_folder_new,
            config_thumb_new_w: param.config_thumb_new_w,
            config_thumb_new_h: param.config_thumb_new_h,
            config_thumb_folder_pro: param.config_thumb_folder_pro,
            config_thumb_pro_w: param.config_thumb_pro_w,
            config_thumb_pro_h: param.config_thumb_pro_h,
            menu_collapse: true,
            business_type: param.business_type
        }
        let jsonUser = JSON.stringify(info);
        localStorage.setItem(Constants.COMMON.EMPLOYEE_INFO, Libs.base64Encrypt(jsonUser));
    }


    /**
     * @description validate a field input
     * @author Long.Pham 13/06/2021
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new LoginValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    render() {
        if (!localStorage.getItem(Constants.COMMON.TOKEN)) {
            return this.jsxTemplate.call(this);
        }
        return null;
    }

}

const HighOrderComponentTranslated = withTranslation('common')(Login)
export default HighOrderComponentTranslated;