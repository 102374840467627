import React from 'react';
import { RText, RTextArea, RSwitch } from '../../../../../components/Controls';
import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import CMSOutsideClickHandler from "../../../../../components/CMSOutsideClickHandler";
import Libs from '../../../../../utils/Libs';
import RowItemProduct from './RowItemProduct';

export default function AddPopup() {

    var { curItem, allLanguage, dataProductSearchAuto, searchProductParam } = this.state;
    const { t } = this.props;

    var dataProducts = curItem.dataProducts;
    var RowsItemProducts = null;
    if (Libs.isArrayData(dataProducts)) {
        RowsItemProducts = dataProducts.map((item, index) => {
            return <RowItemProduct
                key={'row_item_' + index}
                index={index}
                dataItem={item}
                onItemProductClickDelete={this.onItemProductClickDelete}
                handleInputProductChange={this.handleInputProductChange}
                t={t}
            />
        });
    };

    var tabNavLanguage, tabContent = null;

    if (allLanguage) {
        tabNavLanguage = allLanguage.map((language, index) => {
            return (<li key={index} className="nav-item">
                <span onClick={(e) => { this.onClickShowTab(e, language.iso_code) }} className={curItem.tabActive === language.iso_code ? "nav-link active" : "nav-link"} id={"name-tab-" + language.iso_code} >{language.name}</span>
            </li>);
        });

        tabContent = allLanguage.map((language, index) => {
            return (
                <div key={index} className={curItem.tabActive === language.iso_code ? "tab-pane fade show active" : "tab-pane fade"} id={"name-" + language.iso_code} role="tabpanel" aria-labelledby="home-tab">
                    <div className="info">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('promotion.name')}
                                        inputClass="form-control"
                                        required={language.is_default === 1 ? "required" : ""}
                                        inputId={"name_" + language.iso_code}
                                        inputName={"name_" + language.iso_code}
                                        value={curItem.data[index].name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                <div className="mb-3">
                                    <RTextArea label={t('promotion.description')}
                                        inputClass="form-control"
                                        inputId={"description_" + language.iso_code}
                                        inputName={"description_" + language.iso_code}
                                        value={curItem.data[index].description}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={400} />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            );
        });
    }


    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-xl modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('promotion.edit_title') : t('promotion.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">
                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <p className="control-label">{t('promotion.status')}</p>
                                    <div className="checkmark">
                                        <RSwitch
                                            inputId="status"
                                            inputName="status"
                                            checked={curItem.status}
                                            onChange={(e) => { this.handleInputChange(e) }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12"> 
                                <div className="mb-3">
                                    <CMSDatePicker
                                        label={t("promotion.from_date")}
                                        inputId="from_date"
                                        inputClass="form-control"
                                        inputName="from_date"
                                        required="required"
                                        maxLength={20}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        format="DD/MM/YYYY HH:mm"
                                        timeFormat="HH:mm"
                                        showTimeSelect={true}
                                        timeIntervals={60}
                                        value={curItem.from_date}
                                        handleChange={(e) => { this.handleInputDateChange(e); this.validateOne(e); }}
                                        showIconCalendar={true}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <CMSDatePicker
                                        label={t("promotion.to_date")}
                                        inputId="to_date"
                                        inputClass="form-control"
                                        inputName="to_date"
                                        required="required"
                                        maxLength={20}
                                        showTimeSelect={true}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        format="DD/MM/YYYY HH:mm"
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        value={curItem.to_date}
                                        minDate={curItem.to_min_date}
                                        handleChange={(e) => { this.handleInputDateChange(e); this.validateOne(e); }}
                                        showIconCalendar={true}
                                    />
                                </div>
                            </div>

                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('promotion.total')}
                                        inputClass="form-control"
                                        inputId={"total"}
                                        required="required"
                                        inputName={"total"}
                                        value={curItem.total}
                                        pattern="^[0-9]*$"
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={10} />
                                </div>
                            </div> */}

                        </div>
                    </div>

                </div>

                <div className="box-translate">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-main">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {tabNavLanguage}
                                </ul>
                                <div className="tab-content" id="tabContent">
                                    {tabContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="box-info box-promotion-add-product">
                    <h2>{t('promotion.add_product')}</h2>
                    <div className="box-info-content">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <CMSOutsideClickHandler
                                    onOutsideClick={this.onCloseSearchProductAuto.bind(this)}
                                >
                                    <div className="order-search-product-auto">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <RText placeholder={t('sell.search_keyword')}
                                                    inputClass="form-control"
                                                    inputId="name"
                                                    inputName="name"
                                                    disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                    value={searchProductParam.name}
                                                    onChange={(e) => { this.handleInputSerachProductAutoChange(e); this.validateOne(e); }}
                                                    maxLength={100} />
                                            </div>
                                        </div>

                                        {Libs.isArrayData(dataProductSearchAuto) ?
                                            <div className="main-auto">
                                                <div className="header-auto">
                                                    <div className="row">
                                                        <div className="col-md-8"><p>{t('sell.product_name')}</p></div>
                                                        <div className="col-md-4 text-end"><p>{t('sell.price')}</p></div>
                                                    </div>
                                                </div>

                                                <div className="body-auto">
                                                    {dataProductSearchAuto.map((item, index) => {
                                                        return (
                                                            <div onClick={this.getChooseProduct.bind(this, index)} className="item" key={index}>
                                                                <div className="row">
                                                                    <div className="col-md-8"><p>{item.product_name}</p>
                                                                    </div>
                                                                    <div className="col-md-4 text-end">
                                                                        {item.regular_price === item.saleoff_price ?
                                                                            <p>
                                                                                <span className="discount-price">{Libs.formatNum(item.regular_price, '#.###')} vnđ</span>
                                                                            </p>
                                                                            :
                                                                            <p>
                                                                                <span className="price">{Libs.formatNum(item.regular_price, '#.###')} vnđ</span>
                                                                                <span className="discount-price">{Libs.formatNum(item.saleoff_price, '#.###')} vnđ</span>
                                                                            </p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}

                                                </div>
                                            </div>
                                            : ""}
                                    </div>
                                </CMSOutsideClickHandler>


                            </div>


                            <div className="col-md-12">
                            <div className="data-product">
                                <div className="header-row">
                                    <div className="header-col width10">{t('promotion.stt')}</div>
                                    <div className="header-col width35">{t('promotion.product_name')}</div>
                                    <div className="header-col width15">{t('promotion.regular_price')}</div>
                                    <div className="header-col width15 text-end">{t('promotion.price')}</div>
                                    <div className="header-col width15 text-end">{t('promotion.sl')}</div>
                                    <div className="header-col width10 text-end">{t('common.action')}</div>
                                </div>

                                <div className="body-product">
                                    {RowsItemProducts ? RowsItemProducts : <div className="data-empty">{t('common.data_empty')}</div>}
                                </div>
                            </div>
                            </div>

                        </div>

                    </div>

                </div>


            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>

    )
}