import React, { Component } from 'react';
import { RText } from '../../../../../components/Controls';
// import FLSelect from '../../../../component/FLSelect';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
class AddRow extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * @description remove item row
     * @author long.pham 27-07-2019
     */

    removeItemRow = () => {
        if (!this.props.removeItemRow || typeof this.props.removeItemRow !== 'function') return;
        this.props.removeItemRow(this.props.index);
        return;
    }

    render() {
        var item = this.props.dataItem;
        var curItem = this.props.curItem;
        return (
            <div className="body-row">
                <div className="body-col width25">
                    {item.product_name}
                </div>


                <div className="body-col width20">
                    {item.unit_name}
                </div>

                <div className="body-col width10">
                    <RText
                        inputClass={(item.messages.quantity != "") ? "form-control input-error" : "form-control"}
                        inputId={"quantity-" + this.props.index} inputName="quantity"
                        value={item.quantity}
                        onChange={(e) => { this.props.handleRowInputChange(e, this.props.index); }}
                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                        pattern="^[0-9]*$"
                        maxLength={100} />
                </div>
                <div className="body-col width20">
                    <RText
                        inputClass={(item.messages.price != "") ? "form-control input-error" : "form-control"}
                        inputId={"price-" + this.props.index} inputName="price"
                        value={item.price}
                        onChange={(e) => { this.props.handleRowInputChange(e, this.props.index) }}
                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                        maxLength={10} />
                </div>


                <div className="body-col width20 text-end">
                    {Libs.formatNum(item.quantity * item.price, "#,###.##", 1)}
                </div>

                <div className="body-col text-end width5">
                    <div className="remove-item" onClick={curItem.screen_mode != Constants.SCREEN_MODE.VIEW ? this.removeItemRow.bind(this) : {}}>
                        <i className="icon-trash-o"></i>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddRow;
