import React from 'react';
import { RText, RTextArea, RSwitch, RCheckbox } from '../../../../../components/Controls';
import Modal from 'react-bootstrap4-modal';
import FormReactSelect from '../../../../../components/FormReactSelect';
import Libs from '../../../../../utils/Libs';
import CMSEditor from '../../../../../components/CMSEditor';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import ReactTooltip from 'react-tooltip';
import CMSOutsideClickHandler from "../../../../../components/CMSOutsideClickHandler";
import AddGroupAttrPopup from './AddGroupAttrPopup';
import AddAttrPopup from './AddAttrPopup';
import TimeRange from './TimeRange';
import Constants from '../../../../../utils/Constants';


export default function AddPopup() {

    var { curItem, dataLensType, dataHorizontally, dataGender, dataFace, dataShape, dataMaterial, dataFrameColor, msgErrorGallery, dataSearchAutoTags, curItemGroupAttr, curItemAttr, dataGroupAttr, searchParam, dataUpsellSearchAuto, dataCrossSellingSearchAuto, dataGiftProductSearchAuto, dataType, allLanguage, dataGroupAttribute, dataCategory, dataBrand, dataUnit, dataProductType } = this.state;
    var dataCustomFields = curItem.dataCustomFields;

    const { t } = this.props;
    var dataGroup = curItem.dataGroup;

    var listAttributesHeader = null;
    var listAttributeRow = null;
    var dataGroupAttributes = curItem.dataGroupAttributes;
    var dataAttributes = curItem.dataVariants;

    if (Libs.isArrayData(dataGroupAttributes) && Libs.isArrayData(dataAttributes)) {
        var countItem = dataGroupAttributes.length;
        var classHeader = '';
        var classRow = '';
        if (countItem === 3) {
            classHeader = "header-col dt8";
            classRow = "body-col dt7";
        } else if (countItem === 2) {
            classHeader = "header-col dt7";
            classRow = "body-col dt6";
        } else if (countItem === 1) {
            classHeader = "header-col dt6";
            classRow = "body-col dt6";
        }

        listAttributesHeader = dataGroupAttributes.map((item, index) => {
            return (<div key={index} className={classHeader}>{item.name}</div>);
        });


        listAttributeRow = dataAttributes.map((v, k) => {
            var fastListTimeRange = null;
            if (!Libs.isBlank(v.saleoff_from) && !Libs.isBlank(v.saleoff_to)) {
                fastListTimeRange = v.saleoff_from + " - " + v.saleoff_to;
            } else if (!Libs.isBlank(v.saleoff_from) && Libs.isBlank(v.saleoff_to)) {
                fastListTimeRange = v.saleoff_from;
            }
            return (
                <div key={k} className="body-row">
                    {countItem == 3 ? <div className={classRow}>{v.name}</div> : ""}
                    {countItem == 3 ? <div className={classRow}>{v.name_1}</div> : ""}
                    {countItem == 3 ? <div className={classRow}>{v.name_2}</div> : ""}
                    {countItem == 2 ? <div className={classRow}>{v.name}</div> : ""}
                    {countItem == 2 ? <div className={classRow}>{v.name_1}</div> : ""}
                    {countItem == 1 ? <div className={classRow}>{v.name}</div> : ""}
                    <div className={classRow}>
                        <RText
                            placeholder={t('products.regular_price')}
                            inputClass={!Libs.isBlank(v.errors) && v.errors.regular_price ? "form-control input-error" : "form-control"}
                            inputId={"regular_price_" + k}
                            inputName={"regular_price_" + k}
                            value={v.regular_price}
                            onChange={(e) => { this.handleItemInputChange(e, k); }}
                            pattern="[0-9.,]*"
                            maxLength={16} />
                    </div>
                    <div className={classRow}>
                        <RText
                            placeholder={t('products.promotion_price')}
                            inputClass={!Libs.isBlank(v.errors) && v.errors.saleoff_price ? "form-control input-error" : "form-control"}
                            inputId={"saleoff_price_" + k}
                            inputName={"saleoff_price_" + k}
                            value={v.saleoff_price}
                            onChange={(e) => { this.handleItemInputChange(e, k); }}
                            pattern="[0-9.,]*"
                            maxLength={16} />
                    </div>
                    <div className={classRow}>
                        <div className={!Libs.isBlank(v.errors) && v.errors.time_range ? "range-time-sale input-error" : "range-time-sale"}>
                            <RText
                                placeholder={t('products.time_range')}
                                inputClass="form-control"
                                inputId={"time-range-item-" + k}
                                inputName={"time-range-item-" + k}
                                value={fastListTimeRange}
                                readOnly={true}
                                onChange={(e) => { this.handleInputChange(e); }}
                                onClick={this.onClickItemFastRangeDate.bind(this, k)}
                                maxLength={100} />
                            {v.show_range_time ?
                                <TimeRange
                                    curItem={v}
                                    k={k}
                                    t={t}
                                    auth={this.props.auth}
                                    actions={this.actions}
                                    callBackCloseTimeRange={this.callBackCloseTimeRange}
                                />
                                : ""}

                        </div>

                    </div>

                    <div className={classRow}>
                        <RText
                            placeholder={t('products.amount')}
                            inputClass={!Libs.isBlank(v.errors) && v.errors.quantity ? "form-control input-error" : "form-control"}
                            inputId={"quantity_" + k}
                            inputName={"quantity_" + k}
                            value={v.quantity}
                            onChange={(e) => { this.handleItemInputChange(e, k); }}
                            pattern="[0-9]*"
                            maxLength={20} />
                    </div>

                    <div className={classRow}>
                        <RText
                            placeholder={t('products.sku')}
                            inputClass={!Libs.isBlank(v.errors) && v.errors.sku ? "form-control input-error" : "form-control"}
                            inputId={"sku_" + k}
                            inputName={"sku_" + k}
                            value={v.sku}
                            onChange={(e) => { this.handleItemInputChange(e, k); }}
                            maxLength={60} />
                    </div>

                </div>
            );
        });
    }

    var layoutAddGroupAttr = this.state.showAddGroupAttr ?
        <AddGroupAttrPopup
            t={t}
            allLanguage={allLanguage}
            curItem={curItemGroupAttr}
            onClickCloseAddGroupAttr={this.onClickCloseAddGroupAttr}
            auth={this.props.auth}
            actions={this.actions}
            iso_code_language={this.employee.lang}
        />
        : "";

    var layoutAddAttr = this.state.showAddAttr ?
        <AddAttrPopup
            t={t}
            allLanguage={allLanguage}
            curItem={curItemAttr}
            onClickCloseAddAttr={this.onClickCloseAddAttr}
            auth={this.props.auth}
            actions={this.actions}
            iso_code_language={this.employee.lang}
        />
        : "";

    var timeRange = null;
    if (!Libs.isBlank(curItem.saleoff_from) && !Libs.isBlank(curItem.saleoff_to)) {
        timeRange = curItem.saleoff_from + " - " + curItem.saleoff_to;
    } else if (!Libs.isBlank(curItem.saleoff_from) && Libs.isBlank(curItem.saleoff_to)) {
        timeRange = curItem.saleoff_from;
    }


    var fastTimeRange = null;
    if (!Libs.isBlank(curItem.fast_saleoff_from) && !Libs.isBlank(curItem.fast_saleoff_to)) {
        fastTimeRange = curItem.fast_saleoff_from + " - " + curItem.fast_saleoff_to;
    } else if (!Libs.isBlank(curItem.fast_saleoff_from) && Libs.isBlank(curItem.fast_saleoff_to)) {
        fastTimeRange = curItem.fast_saleoff_from;
    }


    // --------------- upsells ----------------
    var RowUpsellSearch = null;
    if (Libs.isArrayData(dataUpsellSearchAuto)) {
        RowUpsellSearch = dataUpsellSearchAuto.map((item, index) => {
            return (
                <li className="col-md-12" key={index} onClick={this.addUpsell.bind(this, index)}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-1">
                                <div className="image">
                                    <img src={item.thumbnail_medium} alt={item.name} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }

                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <div className="add-upsell btn-save"> <var className="icon-plus"></var> </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        })
    }


    var uplsells = curItem.uplsells;
    var RowItemUpsells = null;
    if (Libs.isArrayData(uplsells)) {
        RowItemUpsells = uplsells.map((item, index) => {
            return (
                <li className="col-md-12" key={index}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-1">
                                {!Libs.isBlank(item.thumbnail_medium) ?
                                    <div className="image">
                                        <img src={item.thumbnail_medium} alt={item.name} />
                                    </div>
                                    : ""}

                            </div>
                            <div className="col-md-9">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <button type="button" className="btn btn-cancel" onClick={this.onClearUpsellItem.bind(this, index)}> <var className="icon-trash-o"></var> </button>
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
    }

    // --------------- cross selling --------------
    var RowCrossSellingSearch = null;
    if (Libs.isArrayData(dataCrossSellingSearchAuto)) {
        RowCrossSellingSearch = dataCrossSellingSearchAuto.map((item, index) => {
            return (
                <li className="col-md-12" key={index} onClick={this.addCrossSelling.bind(this, index)}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-1">
                                <div className="image">
                                    <img src={item.thumbnail_medium} alt={item.name} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }

                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <div className="add-upsell btn-save"> <var className="icon-plus"></var> </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        })
    }


    var crossSelling = curItem.crossSelling;
    var RowItemCrossSelling = null;
    if (Libs.isArrayData(crossSelling)) {
        RowItemCrossSelling = crossSelling.map((item, index) => {
            return (
                <li className="col-md-12" key={index}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-1">
                                <div className="image">
                                    <img src={item.thumbnail_medium} alt={item.name} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <button type="button" className="btn btn-cancel" onClick={this.onClearCrossSellingItem.bind(this, index)}> <var className="icon-trash-o"></var> </button>
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
    }


    // --------------- gift product --------------
    var RowGiftProductSearch = null;
    if (Libs.isArrayData(dataGiftProductSearchAuto)) {
        RowGiftProductSearch = dataGiftProductSearchAuto.map((item, index) => {
            return (
                <li className="col-md-12" key={index} onClick={this.addGiftProduct.bind(this, index)}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-1">
                                <div className="image">
                                    <img src={item.thumbnail_medium} alt={item.name} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }

                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <div className="add-upsell btn-save"> <var className="icon-plus"></var> </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        })
    }


    var giftProduct = curItem.giftProduct;
    var RowItemGiftProduct = null;
    if (Libs.isArrayData(giftProduct)) {
        RowItemGiftProduct = giftProduct.map((item, index) => {
            return (
                <li className="col-md-12" key={index}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-1">
                                <div className="image">
                                    <img src={item.thumbnail_medium} alt={item.name} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <button type="button" className="btn btn-cancel" onClick={this.onClearGiftProductItem.bind(this, index)}> <var className="icon-trash-o"></var> </button>
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
    }


    var tabNavLanguage, tabContent = null;

    var RowDataGroup = null;
    if (Libs.isArrayData(dataGroup)) {
        RowDataGroup = dataGroup.map((v, index) => {
            return (
                <div className="col-md-12" key={"group_" + index}>
                    <div className="main-attr-group">
                        <div className="attr-group">
                            <h4>{v.name}</h4>
                            <div className="attr-content">
                                <div className="row">
                                    <div className="col-11">
                                        <FormReactSelect
                                            instanceId={"attribute_group_" + v.id}
                                            className={"attribute_group_" + v.id}
                                            name={"attribute_group_" + v.id}
                                            isMulti={true}
                                            isSearchable={true}
                                            key={v.id + "-" + Math.random()}
                                            value={Libs.isArrayData(v.dataListAttr) ? v.dataListAttr : []}
                                            onChange={(e) => { this.handleDropdownChangeAttr(e, index, v.id) }}
                                            optionList={v.attrs}
                                            placeHolder={t('products.title_attribute')}
                                        />
                                    </div>


                                    <div className="col-1 text-end">
                                        <button type="button" className="btn btn-save" onClick={this.onClickShowAddAttr.bind(this, v)}> <var className="icon-plus"></var></button>
                                    </div>
                                    {!Libs.isBlank(v.is_default) && v.is_default === 1 ?
                                        <div className="col-12">
                                            <div className="list-add-image">
                                                <ul>
                                                    {
                                                        Libs.isArrayData(v.dataListAttr) ?
                                                            v.dataListAttr.map((subItem, subIndex) => (
                                                                <li key={subIndex + 1}>
                                                                    <input key={Libs.generateStrRandom(2, 2, 2, 8)} onChange={(event) => this.onUploadImageColorChange(event, index, subIndex)} type="file" id={"upload_image_color_" + subIndex} name={"upload_image_color_" + subIndex} />
                                                                    {!Libs.isBlank(subItem.file_upload) && Libs.isBlank(subItem.thumbnail_medium) ?
                                                                        <div className="item-add-attr-image">
                                                                            <div className="view-image">
                                                                                <div className="attr-action">
                                                                                    <label htmlFor={"upload_image_color_" + subIndex} className="edit"><var className="icon-pencil-square-o"></var></label>
                                                                                    <span className="delete" onClick={this.deleteAttributeImage.bind(this, index, subIndex)}><var className="icon-trash-o"></var></span>
                                                                                </div>
                                                                                <img src={subItem.file_upload} alt={subItem.name} />
                                                                            </div>
                                                                            <div className="content-attr">
                                                                                <p>{subItem.name}</p>
                                                                            </div>
                                                                        </div>

                                                                        : (!Libs.isBlank(subItem.thumbnail_medium) ?

                                                                            <div className="item-add-attr-image">
                                                                                <div className="view-image">
                                                                                    <div className="attr-action">
                                                                                        <label htmlFor={"upload_image_color_" + subIndex} className="edit"><var className="icon-pencil-square-o"></var></label>
                                                                                        <span className="delete" onClick={this.deleteAttributeImage.bind(this, index, subIndex)}><var className="icon-trash-o"></var></span>
                                                                                    </div>
                                                                                    <img src={subItem.thumbnail_medium} alt={subItem.name} />
                                                                                </div>
                                                                                <div className="content-attr">
                                                                                    <p>{subItem.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            :

                                                                            <div className="item-add-attr-image">
                                                                                <div className="add-image">
                                                                                    <label htmlFor={"upload_image_color_" + subIndex}>
                                                                                        <i className="icon-picture-o"></i>
                                                                                    </label>

                                                                                </div>
                                                                                <div className="content-attr">
                                                                                    <p>{subItem.name}</p>
                                                                                </div>
                                                                            </div>

                                                                        )}
                                                                    <div className="field-tryon">
                                                                        <RText
                                                                            placeholder={t('products.id_glass_tryon')}
                                                                            inputClass="form-control"
                                                                            inputId={"id_glass_tryon_" + subIndex}
                                                                            inputName={"id_glass_tryon_" + subIndex}
                                                                            value={subItem.id_glass_tryon}
                                                                            onChange={(e) => { this.handleInputGlassTryOnChange(e, index, subIndex); }}
                                                                            maxLength={100} />
                                                                    </div>
                                                                </li>

                                                            )) : ""
                                                    }


                                                </ul>
                                            </div>

                                        </div>
                                        : ""}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }
    var ConfigTabAcive = null;
    if (!Libs.isBlank(this.state.showConfigTab)) {
        switch (this.state.showConfigTab) {
            case 'shipping':
                ConfigTabAcive = <div className="shipping">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-9">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.item_weight')}
                                            inputClass="form-control"
                                            inputId="item_weight"
                                            inputName="item_weight"
                                            value={curItem.item_weight}
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            note={"Gram"}
                                            pattern="[0-9.]*"
                                            maxLength={100} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <h3>{t('products.title_length')}</h3>
                            <div className="row">
                                <div className="col-3">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.item_length')}
                                            inputClass="form-control"
                                            inputId="item_length"
                                            inputName="item_length"
                                            value={curItem.item_length}
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            note="mm"
                                            pattern="[0-9.]*"
                                            maxLength={100} />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.item_width')}
                                            inputClass="form-control"
                                            inputId="item_width"
                                            inputName="item_width"
                                            value={curItem.item_width}
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            note="mm"
                                            pattern="[0-9.]*"
                                            maxLength={100} />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.item_height')}
                                            inputClass="form-control"
                                            inputId="item_height"
                                            inputName="item_height"
                                            value={curItem.item_height}
                                            pattern="[0-9.]*"
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            note="mm"
                                            maxLength={100} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.item_width_2')}
                                            inputClass="form-control"
                                            inputId="item_width_2"
                                            inputName="item_width_2"
                                            value={curItem.item_width_2}
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            note="mm"
                                            pattern="[0-9.]*"
                                            maxLength={100} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.item_width_3')}
                                            inputClass="form-control"
                                            inputId="item_width_3"
                                            inputName="item_width_3"
                                            value={curItem.item_width_3}
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            note="mm"
                                            pattern="[0-9.]*"
                                            maxLength={100} />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                break;
            case 'stores':
                ConfigTabAcive = <div className="stores">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <div className="gen-sku">
                                            <RText label={t('products.sku')}
                                                inputClass="form-control"
                                                inputId={"sku"}
                                                inputName={"sku"}
                                                value={curItem.sku}
                                                onChange={(e) => { this.handleInputChange(e); }}
                                                maxLength={100} />

                                            <div className="random-sku" onClick={this.randomSKU.bind(this)}>{t('products.random_sku')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <h3>{t('products.title_stores_tab')}</h3>
                            <div className="row">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <RText label={t('products.quantity')}
                                            inputClass="form-control"
                                            inputId={"quantity"}
                                            inputName={"quantity"}
                                            value={curItem.quantity}
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            pattern="[0-9]*"
                                            maxLength={20} />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-3">
                                        <RText label={t('products.out_of_stock')}
                                            inputClass="form-control"
                                            inputId={"out_of_stock"}
                                            inputName={"out_of_stock"}
                                            value={curItem.out_of_stock}
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            pattern="[0-9]*"
                                            maxLength={4} />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-3">
                                        <FormReactSelect
                                            label={t('products.id_type')}
                                            instanceId="id_type"
                                            className="id_type"
                                            name="id_type"
                                            value={dataType.filter(function (item) { return item.id === curItem.id_type })}
                                            onChange={(e) => { this.handleDropdownChange(e, 'id_type') }}
                                            optionList={dataType}
                                            placeHolder={t('common.choose')}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
                break;
            case 'attribute':
                ConfigTabAcive = <div className="attribute">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-11">
                                    <div className="mb-3">
                                        <FormReactSelect
                                            instanceId="id_group_attr"
                                            className="id_group_attr"
                                            name="id_group_attr"
                                            isMulti={true}
                                            key={Math.random()}
                                            value={Libs.isArrayData(curItem.dataGroup) ? curItem.dataGroup : []}
                                            onChange={(e) => { this.handleDropdownChangeMulti(e) }}
                                            optionList={dataGroupAttr}
                                            placeHolder={t('products.id_group_attr')}
                                        />


                                    </div>
                                </div>
                                <div className="col-1 text-end">
                                    <button type="button" className="btn btn-save" onClick={this.onClickShowAddGroupAttr.bind(this)}> <var className="icon-plus"></var> </button>
                                </div>
                            </div>
                        </div>

                        {RowDataGroup}






                        {!Libs.isBlank(listAttributesHeader) && !Libs.isBlank(listAttributeRow) ?
                            <div className="col-md-12">
                                <div className="list-attr">
                                    <div className="fast-setup">
                                        <h2>{t('products.fast_setup_title')}</h2>
                                        <div className="form-fast-setup">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <div className="mb-3">
                                                                <RText
                                                                    label={t('products.fast_setup_current_price')}
                                                                    inputClass="form-control"
                                                                    inputId="fast_setup_current_price"
                                                                    inputName="fast_setup_current_price"
                                                                    value={curItem.fast_setup_current_price}
                                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                                    pattern="[0-9.,]*"
                                                                    maxLength={100} />
                                                            </div>
                                                        </div>

                                                        <div className="col-3">
                                                            <div className="mb-3">
                                                                <RText
                                                                    label={t('products.special_price')}
                                                                    inputClass="form-control"
                                                                    inputId="fast_setup_saleoff_price"
                                                                    inputName="fast_setup_saleoff_price"
                                                                    value={curItem.fast_setup_saleoff_price}
                                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                                    pattern="[0-9.,]*"
                                                                    maxLength={100} />
                                                            </div>
                                                        </div>



                                                        <div className="col-4">
                                                            <div className="mb-3">
                                                                <label>{t("products.special_date")}</label>
                                                                <div onClick={this.onClickShowFastRangeDate.bind(this)} className="range-time-sale">
                                                                    {fastTimeRange}
                                                                    <CMSOutsideClickHandler
                                                                        onOutsideClick={this.onCloseShowFastRangeDate.bind(this)}
                                                                    >
                                                                        <div className={this.state.showFastRangeDate ? "date-range on" : "date-range"}>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <CMSDatePicker
                                                                                        value={curItem.fast_saleoff_from}
                                                                                        inputId="fast_saleoff_from"
                                                                                        format="DD/MM/YYYY HH:mm"
                                                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                                                        inputClass="form-control"
                                                                                        inputName="fast_saleoff_from"
                                                                                        minDate={curItem.min_date}
                                                                                        handleChange={this.handleInputDateChange.bind(this)}
                                                                                        maxLength={20}
                                                                                        placeholder={t('products.placeholder_start_date')}
                                                                                        showIconCalendar={false}
                                                                                        timeFormat="HH:mm"
                                                                                        timeIntervals={60}
                                                                                        showTimeSelect={true}
                                                                                    />
                                                                                </div>

                                                                                <div className="col-md-2 text-center">-</div>

                                                                                <div className="col-md-4">
                                                                                    <CMSDatePicker
                                                                                        value={curItem.fast_saleoff_to}
                                                                                        inputId="fast_saleoff_to"
                                                                                        format="DD/MM/YYYY HH:mm"
                                                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                                                        inputClass="form-control"
                                                                                        inputName="fast_saleoff_to"
                                                                                        minDate={curItem.min_date}
                                                                                        handleChange={this.handleInputDateChange.bind(this)}
                                                                                        maxLength={20}
                                                                                        placeholder={t('products.placeholder_end_date')}
                                                                                        showIconCalendar={false}
                                                                                        timeFormat="HH:mm"
                                                                                        timeIntervals={60}
                                                                                        showTimeSelect={true}
                                                                                    />
                                                                                </div>

                                                                                <div className="col-md-2 text-end">
                                                                                    <ReactTooltip />
                                                                                    <div onClick={this.clearFastSetupSaleOff.bind(this)} className="close-calendar" data-tip={t('products.remove_time_sale')} data-type="warning" data-class="tooltip-action" ><var className="icon-cancel-music"></var></div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </CMSOutsideClickHandler>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-2">
                                                            <div className="mb-3">
                                                                <RText
                                                                    label={t('products.special_qty')}
                                                                    inputClass="form-control"
                                                                    inputId="fast_setup_quantity"
                                                                    inputName="fast_setup_quantity"
                                                                    value={curItem.fast_setup_quantity}
                                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                                    pattern="[0-9]*"
                                                                    maxLength={100} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="mb-3">
                                                        <button type="button" className="btn btn-save" onClick={this.onClickApplySetup.bind(this)}> {t('products.apply_all')} </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="main-table-list-attr">
                                        <div className="row">
                                            <div className="col-md-6"><h2>{t('products.list_attr_title')}</h2></div>
                                            <div className="col-md-6 text-end">
                                                <button type="button" className="btn btn-cancel cancel-promotion" onClick={this.deleteSaleOff.bind(this)}> {t('products.del_all_promotion')} </button>
                                            </div>
                                        </div>

                                        <div className="main-table-attr">
                                            <div className="header-table-attr">
                                                <div className="header-row">
                                                    {listAttributesHeader}
                                                    <div className={classHeader}>Giá gốc</div>
                                                    <div className={classHeader}>Giá KM</div>
                                                    <div className={classHeader}>Thời gian KM</div>
                                                    <div className={classHeader}>Số lượng</div>
                                                    <div className={classHeader}>SKU</div>
                                                </div>
                                            </div>
                                            <div className="body-table-attr">
                                                <div className="main-body-attr">
                                                    {listAttributeRow}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            : ""}



                    </div>
                </div>
                break;
            case 'upsells':
                ConfigTabAcive = <div className="upsells">
                    <div className="upsells-main">
                        <div className="upsells-search">
                            <div className="upsells-search-auto">
                                <RText
                                    placeholder={t('products.search_product')}
                                    inputClass="form-control"
                                    inputId="upsells_name"
                                    inputName="upsells_name"
                                    value={searchParam.upsells_name}
                                    onChange={(e) => { this.handleInputSearchAutoChange(e); }}
                                    maxLength={100} />
                            </div>
                            {!Libs.isBlank(RowUpsellSearch) ?
                                <div className="view-search-data">
                                    <ul className="row">
                                        {RowUpsellSearch}
                                    </ul>
                                </div>
                                : ""}

                        </div>

                        {!Libs.isBlank(RowItemUpsells) ?
                            <div className="upsells-data">
                                <ul className="row">
                                    {RowItemUpsells}
                                </ul>
                            </div>
                            : ""}

                    </div>
                </div>
                break;
            case 'cross-selling':
                ConfigTabAcive = <div className="cross-selling">
                    <div className="upsells-main">
                        <div className="upsells-search">
                            <div className="upsells-search-auto">
                                <RText
                                    placeholder={t('products.search_product')}
                                    inputClass="form-control"
                                    inputId="cross_selling"
                                    inputName="cross_selling"
                                    value={searchParam.cross_selling}
                                    onChange={(e) => { this.handleInputSearchAutoChange(e); }}
                                    maxLength={100} />
                            </div>


                            {!Libs.isBlank(RowCrossSellingSearch) ?
                                <div className="view-search-data">
                                    <ul className="row">
                                        {RowCrossSellingSearch}
                                    </ul>
                                </div>
                                : ""}
                        </div>

                        {!Libs.isBlank(RowItemCrossSelling) ?
                            <div className="upsells-data">
                                <ul className="row">
                                    {RowItemCrossSelling}
                                </ul>
                            </div>
                            : ""}
                    </div>
                </div>
                break;
            case 'gift-products':
                ConfigTabAcive = <div className="gift-products">
                    <div className="upsells-main">
                        <div className="upsells-search">
                            <div className="upsells-search-auto">
                                <RText
                                    placeholder={t('products.search_product')}
                                    inputClass="form-control"
                                    inputId="gift_product"
                                    inputName="gift_product"
                                    value={searchParam.gift_product}
                                    onChange={(e) => { this.handleInputSearchAutoChange(e); }}
                                    maxLength={100} />
                            </div>

                            {!Libs.isBlank(RowGiftProductSearch) ?
                                <div className="view-search-data">
                                    <ul className="row">
                                        {RowGiftProductSearch}
                                    </ul>
                                </div>
                                : ""}
                        </div>

                        {!Libs.isBlank(RowItemGiftProduct) ?
                            <div className="upsells-data">
                                <ul className="row">
                                    {RowItemGiftProduct}
                                </ul>
                            </div>
                            : ""}

                    </div>
                </div>
                break;
            case 'advanced':
                ConfigTabAcive = <div className="advanced">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                    <div className="mb-3">
                                        <RText
                                            label={t('products.id_glass_tryon')}
                                            inputClass="form-control"
                                            inputId="id_glass_tryon"
                                            inputName="id_glass_tryon"
                                            value={curItem.id_glass_tryon}
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            maxLength={100} />
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                    <div className="mb-3">
                                        <RText
                                            label={t('products.menu_order')}
                                            inputClass="form-control"
                                            inputId="menu_order"
                                            inputName="menu_order"
                                            value={curItem.menu_order}
                                            pattern="[0-9]*"
                                            onChange={(e) => { this.handleInputChange(e); }}
                                            maxLength={10} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                            <div className="mb-3">
                                <p className="control-label">{t('products.sticky')}</p>
                                <div className="checkmark">
                                    <RSwitch
                                        inputId="sticky"
                                        inputName="sticky"
                                        checked={curItem.sticky}
                                        onChange={(e) => { this.handleInputChange(e) }}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                            <div className="mb-3">
                                <p className="control-label">{t('products.hot')}</p>
                                <div className="checkmark">
                                    <RSwitch
                                        inputId="hot"
                                        inputName="hot"
                                        checked={curItem.hot}
                                        onChange={(e) => { this.handleInputChange(e) }}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                            <div className="mb-3">
                                <p className="control-label">{t('products.new')}</p>
                                <div className="checkmark">
                                    <RSwitch
                                        inputId="new"
                                        inputName="new"
                                        checked={curItem.new}
                                        onChange={(e) => { this.handleInputChange(e) }}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                            <div className="mb-3">
                                <p className="control-label">{t('products.sell_a_lot')}</p>
                                <div className="checkmark">
                                    <RSwitch
                                        inputId="sell_a_lot"
                                        inputName="sell_a_lot"
                                        checked={curItem.sell_a_lot}
                                        onChange={(e) => { this.handleInputChange(e) }}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                            <div className="mb-3">
                                <p className="control-label">{t('products.is_comment')}</p>
                                <div className="checkmark">
                                    <RSwitch
                                        inputId="is_comment"
                                        inputName="is_comment"
                                        checked={curItem.is_comment}
                                        onChange={(e) => { this.handleInputChange(e) }}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                            <fieldset className="super_combo">
                                <legend>{t('products.title_super_combo')}</legend>
                                <div className="super-combo">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <div className="checkmark">
                                                    <RSwitch
                                                        inputId="is_super_combo"
                                                        inputName="is_super_combo"
                                                        checked={curItem.is_super_combo}
                                                        onChange={(e) => { this.handleInputChange(e) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <RText
                                                    label={t('products.pd_eye')}
                                                    inputClass="form-control"
                                                    inputId="pd_eye"
                                                    inputName="pd_eye"
                                                    value={curItem.pd_eye}
                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                    pattern="[0-9.]*"
                                                    disabled={curItem.is_super_combo == 1 ? false : true}
                                                    maxLength={6} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <strong>{t('products.left_eye_title')}</strong>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <RText
                                                    label={t('products.left_eye')}
                                                    inputClass="form-control"
                                                    inputId="left_eye"
                                                    inputName="left_eye"
                                                    value={curItem.left_eye}
                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                    pattern="[0-9.-]*"
                                                    disabled={curItem.is_super_combo == 1 ? false : true}
                                                    maxLength={6} />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <RText
                                                    label={t('products.left_eye_disorder')}
                                                    inputClass="form-control"
                                                    inputId="left_eye_disorder"
                                                    inputName="left_eye_disorder"
                                                    value={curItem.left_eye_disorder}
                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                    pattern="[0-9.-]*"
                                                    disabled={curItem.is_super_combo == 1 ? false : true}
                                                    maxLength={6} />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <RText
                                                    label={t('products.left_eye_axis')}
                                                    inputClass="form-control"
                                                    inputId="left_eye_axis"
                                                    inputName="left_eye_axis"
                                                    value={curItem.left_eye_axis}
                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                    pattern="[0-9.]*"
                                                    disabled={curItem.is_super_combo == 1 ? false : true}
                                                    maxLength={3} />
                                            </div>
                                        </div>

                                    </div>


                                    <div className="row">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <strong>{t('products.right_eye_title')}</strong>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <RText
                                                    label={t('products.right_eye')}
                                                    inputClass="form-control"
                                                    inputId="right_eye"
                                                    inputName="right_eye"
                                                    value={curItem.right_eye}
                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                    pattern="[0-9.-]*"
                                                    disabled={curItem.is_super_combo == 1 ? false : true}
                                                    maxLength={6} />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <RText
                                                    label={t('products.right_eye_disorder')}
                                                    inputClass="form-control"
                                                    inputId="right_eye_disorder"
                                                    inputName="right_eye_disorder"
                                                    value={curItem.right_eye_disorder}
                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                    pattern="[0-9.-]*"
                                                    disabled={curItem.is_super_combo == 1 ? false : true}
                                                    maxLength={6} />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="mb-3">
                                                <RText
                                                    label={t('products.right_eye_axis')}
                                                    inputClass="form-control"
                                                    inputId="right_eye_axis"
                                                    inputName="right_eye_axis"
                                                    value={curItem.right_eye_axis}
                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                    pattern="[0-9.]*"
                                                    disabled={curItem.is_super_combo == 1 ? false : true}
                                                    maxLength={3} />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </fieldset>
                        </div>

                    </div>
                </div>
                break;


            default:
                ConfigTabAcive = <div className="basic">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-8">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.original_price')}
                                            inputClass="form-control"
                                            inputId={"original_price"}
                                            inputName={"original_price"}
                                            value={curItem.original_price}
                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                            pattern="[0-9.,]*"
                                            note="vnđ"
                                            maxLength={16} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-8">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.wholesale_price')}
                                            inputClass="form-control"
                                            inputId={"wholesale_price"}
                                            inputName={"wholesale_price"}
                                            value={curItem.wholesale_price}
                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                            note="vnđ"
                                            pattern="[0-9.,]*"
                                            maxLength={16} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-8">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.regular_price')}
                                            inputClass="form-control"
                                            inputId={"regular_price"}
                                            inputName={"regular_price"}
                                            required="required"
                                            value={curItem.regular_price}
                                            onChange={(e) => { this.handleSaleOffInputChange(e); this.validateOne(e); }}
                                            note="vnđ"
                                            pattern="[0-9.,]*"
                                            maxLength={16} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-8">
                                    <div className="input-group mb-3">
                                        <RText label={t('products.saleoff_price')}
                                            inputClass="form-control"
                                            inputId={"saleoff_price"}
                                            inputName={"saleoff_price"}
                                            disabled={Libs.isBlank(curItem.regular_price) ? true : false}
                                            value={curItem.saleoff_price}
                                            onChange={(e) => { this.handleSaleOffInputChange(e); this.validateOne(e); }}
                                            note="vnđ"
                                            pattern="[0-9.,]*"
                                            maxLength={16} />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-8">
                                    <div className="input-group mb-3">
                                        <label className="control-label">{t('products.time_range_title')}</label>
                                        <CMSOutsideClickHandler
                                            onOutsideClick={this.onCloseShowRangeDate.bind(this)}
                                        >

                                            <div onClick={this.onClickShowPriceRangeDate.bind(this)} className="range-time-sale">
                                                <RText
                                                    inputClass="form-control"
                                                    inputId={"time_range"}
                                                    inputName={"time_range"}
                                                    value={timeRange}
                                                    disabled={true}
                                                    onChange={(e) => { this.handleInputChange(e); }}
                                                    maxLength={100} />

                                                <var className="select-time-range icon-calendar-1"></var>
                                                <div className={this.state.showBasicRangeDate ? "date-range on" : "date-range"}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <CMSDatePicker
                                                                value={curItem.saleoff_from}
                                                                inputId="saleoff_from"
                                                                format="DD/MM/YYYY HH:mm"
                                                                dateFormat="dd/MM/yyyy HH:mm"
                                                                inputClass="form-control"
                                                                inputName="saleoff_from"
                                                                minDate={curItem.min_date}
                                                                handleChange={this.handleInputDateChange.bind(this)}
                                                                maxLength={20}
                                                                placeholder={t('products.placeholder_start_date')}
                                                                showIconCalendar={false}
                                                                timeFormat="HH:mm"
                                                                timeIntervals={60}
                                                                showTimeSelect={true}
                                                            />
                                                        </div>

                                                        <div className="col-md-2 text-center">-</div>

                                                        <div className="col-md-4">
                                                            <CMSDatePicker
                                                                value={curItem.saleoff_to}
                                                                inputId="saleoff_to"
                                                                format="DD/MM/YYYY HH:mm"
                                                                dateFormat="dd/MM/yyyy HH:mm"
                                                                inputClass="form-control"
                                                                inputName="saleoff_to"
                                                                minDate={curItem.min_date}
                                                                handleChange={this.handleInputDateChange.bind(this)}
                                                                maxLength={20}
                                                                placeholder={t('products.placeholder_end_date')}
                                                                showIconCalendar={false}
                                                                timeFormat="HH:mm"
                                                                timeIntervals={60}
                                                                showTimeSelect={true}
                                                            />
                                                        </div>

                                                        <div className="col-md-1 text-end">
                                                            <ReactTooltip />
                                                            <div onClick={this.clearSaleOff.bind(this)} className="close-calendar" data-tip={t('products.remove_time_sale')} data-type="warning" data-class="tooltip-action" ><var className="icon-cancel-music"></var></div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </CMSOutsideClickHandler>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                break;
        }
    }


    if (Libs.isArrayData(allLanguage)) {
        tabNavLanguage = allLanguage.map((language, index) => {
            return (<li key={index} className="nav-item">
                <span onClick={(e) => { this.onClickShowTab(e, language.iso_code) }} className={curItem.tabActive === language.iso_code ? "nav-link active" : "nav-link"} id={"name-tab-" + language.iso_code} >{language.name}</span>
            </li>);
        });

        tabContent = allLanguage.map((language, index) => {
            return (
                <div key={index} className={curItem.tabActive === language.iso_code ? "tab-pane fade show active" : "tab-pane fade"} id={"name-" + language.iso_code} role="tabpanel" aria-labelledby="home-tab">
                    <div className="info">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('products.name')}
                                        inputClass="form-control"
                                        required={language.is_default === 1 ? "required" : ""}
                                        inputId={"name_" + language.iso_code}
                                        inputName={"name_" + language.iso_code}
                                        value={curItem.data[index].name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('products.slug')}
                                        inputClass="form-control"
                                        inputId={"slug_" + language.iso_code}
                                        inputName={"slug_" + language.iso_code}
                                        value={curItem.data[index].slug}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className={"content_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('products.content')}
                                            id={"content_" + language.iso_code}
                                            name={"content_" + language.iso_code}
                                            height={400}
                                            content={curItem.data[index].content}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this, 'content_' + language.iso_code, language.iso_code)}
                                            mode="full"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className={"description_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('products.description')}
                                            id={"description_" + language.iso_code}
                                            name={"description_" + language.iso_code}
                                            height={200}
                                            content={curItem.data[index].description}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this, 'description_' + language.iso_code, language.iso_code)}
                                            mode="full"
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className={"des_size_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('products.des_size')}
                                            id={"des_size_" + language.iso_code}
                                            name={"des_size_" + language.iso_code}
                                            height={200}
                                            content={curItem.data[index].des_size}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this, 'des_size_' + language.iso_code, language.iso_code)}
                                            mode="full"
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className={"privilege_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('products.privilege')}
                                            id={"privilege_" + language.iso_code}
                                            name={"privilege_" + language.iso_code}
                                            height={200}
                                            content={curItem.data[index].privilege}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this, 'privilege_' + language.iso_code, language.iso_code)}
                                            mode="full"
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>

                    {Libs.isArrayData(dataCustomFields) ?
                        <div className="custom-fields">
                            {dataCustomFields.map((item, n) => {
                                return (
                                    <div className="meta" key={n}>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="main-meta">
                                                    <h2>{item.name}</h2>
                                                    <div className="meta-content">


                                                        <div className="row">
                                                            {Libs.isArrayData(item.fields) ?

                                                                item.fields.map((v, k) => {
                                                                    switch (v.field_type) {
                                                                        case "text":
                                                                            return (
                                                                                <div key={v.field_key + "_" + k} className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <RText label={v.field_label}
                                                                                            inputClass="form-control"
                                                                                            inputId={"field_value_" + v.field_key + "_" + language.iso_code}
                                                                                            inputName={"field_value_" + v.field_key + "_" + language.iso_code}
                                                                                            value={v.dataTrans[index].field_value}
                                                                                            onChange={(e) => { this.handleInputCustomFieldChange(e, n, k, language.iso_code); }}
                                                                                            maxLength={200} />
                                                                                    </div>
                                                                                </div>);
                                                                        case "textarea":
                                                                            return (
                                                                                <div key={v.field_key + "_" + k} className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <RTextArea label={v.field_label}
                                                                                            inputClass="form-control"
                                                                                            inputId={"field_value_" + v.field_key + "_" + language.iso_code}
                                                                                            inputName={"field_value_" + v.field_key + "_" + language.iso_code}
                                                                                            value={v.dataTrans[index].field_value}
                                                                                            onChange={(e) => { this.handleInputCustomFieldChange(e, n, k, language.iso_code); }}
                                                                                            maxLength={200} />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        case "wysiwyg":
                                                                            return (
                                                                                <div key={v.field_key + "_" + k} className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                                    <div className={v.field_key + "_field_" + language.iso_code}>
                                                                                        <CMSEditor
                                                                                            key={v.field_key + "_group_" + language.iso_code}
                                                                                            label={v.field_label}
                                                                                            id={v.field_key + "_group_" + language.iso_code}
                                                                                            name={v.field_key + "_group_" + language.iso_code}
                                                                                            height={200}
                                                                                            content={v.dataTrans[index].field_value}
                                                                                            readonly={false}
                                                                                            onEditorChange={this.handleCustomFieldEditorChange.bind(this, n, k, language.iso_code)}
                                                                                            mode="full"
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            );
                                                                    }

                                                                })

                                                                : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                        : ""}

                    <div className="meta">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="main-meta">
                                    <h2>SEO</h2>
                                    <div className="meta-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_title')}
                                                        inputClass="form-control"
                                                        inputId={"meta_title_" + language.iso_code}
                                                        inputName={"meta_title_" + language.iso_code}
                                                        value={curItem.data[index].meta_title}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_keyword')}
                                                        inputClass="form-control"
                                                        inputId={"meta_keyword_" + language.iso_code}
                                                        inputName={"meta_keyword_" + language.iso_code}
                                                        value={curItem.data[index].meta_keyword}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RTextArea label={t('common.meta_description')}
                                                        rows="3"
                                                        inputClass="form-control"
                                                        inputId={"meta_description_" + language.iso_code}
                                                        inputName={"meta_description_" + language.iso_code}
                                                        value={curItem.data[index].meta_description}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            );
        });
    } else if (Libs.isArrayData(allLanguage) && !Libs.isArrayData(dataCustomFields) && this.state.loading) {
        tabNavLanguage = allLanguage.map((language, index) => {
            return (<li key={index} className="nav-item">
                <span onClick={(e) => { this.onClickShowTab(e, language.iso_code) }} className={curItem.tabActive === language.iso_code ? "nav-link active" : "nav-link"} id={"name-tab-" + language.iso_code} >{language.name}</span>
            </li>);
        });

        tabContent = allLanguage.map((language, index) => {
            return (
                <div key={index} className={curItem.tabActive === language.iso_code ? "tab-pane fade show active" : "tab-pane fade"} id={"name-" + language.iso_code} role="tabpanel" aria-labelledby="home-tab">
                    <div className="info">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('attributes.name')}
                                        inputClass="form-control"
                                        required={language.is_default === 1 ? "required" : ""}
                                        inputId={"name_" + language.iso_code}
                                        inputName={"name_" + language.iso_code}
                                        value={curItem.data[index].name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className={"description_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('products.content')}
                                            id={"content_" + language.iso_code}
                                            name={"content_" + language.iso_code}
                                            height={400}
                                            content={curItem.data[index].content}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this)}
                                            mode="full"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className={"description_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('products.description')}
                                            id={"description_" + language.iso_code}
                                            name={"description_" + language.iso_code}
                                            height={200}
                                            content={curItem.data[index].description}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this)}
                                            mode="full"
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className={"des_size_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('products.des_size')}
                                            id={"des_size_" + language.iso_code}
                                            name={"des_size_" + language.iso_code}
                                            height={200}
                                            content={curItem.data[index].des_size}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this)}
                                            mode="full"
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className={"privilege_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('products.privilege')}
                                            id={"privilege_" + language.iso_code}
                                            name={"privilege_" + language.iso_code}
                                            height={200}
                                            content={curItem.data[index].privilege}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this)}
                                            mode="full"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="meta">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="main-meta">
                                    <h2>SEO</h2>
                                    <div className="meta-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_title')}
                                                        inputClass="form-control"
                                                        inputId={"meta_title_" + language.iso_code}
                                                        inputName={"meta_title_" + language.iso_code}
                                                        value={curItem.data[index].meta_title}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_keyword')}
                                                        inputClass="form-control"
                                                        inputId={"meta_keyword_" + language.iso_code}
                                                        inputName={"meta_keyword_" + language.iso_code}
                                                        value={curItem.data[index].meta_keyword}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RTextArea label={t('common.meta_description')}
                                                        rows="3"
                                                        inputClass="form-control"
                                                        inputId={"meta_description_" + language.iso_code}
                                                        inputName={"meta_description_" + language.iso_code}
                                                        value={curItem.data[index].meta_description}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            );
        });
    }


    return (
        <div className="wp">
            <div className="add-attr">
                {layoutAddGroupAttr}
                {layoutAddAttr}
            </div>

            <div className="add-product">
                <Modal visible={true} className="modal-add-product" dialogClassName="modal-xl modal-dialog-scrollable" >
                    <div className="modal-header">
                        <h5 className="modal-title">{(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('products.edit_product_title') : t('products.add_product_title')}</h5>


                        <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
                    </div>
                    <div className="modal-body">

                        <div className="box-info">
                            <h2>{t('common.info')}</h2>
                            <div className="box-info-content">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_category')}
                                                instanceId="id_category"
                                                className="id_category"
                                                name="id_category"
                                                required="required"
                                                value={dataCategory.filter(function (item) { return item.id === parseInt(curItem.id_category) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_category'); }}
                                                optionList={dataCategory}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_brand')}
                                                instanceId="id_brand"
                                                className="id_brand"
                                                name="id_brand"
                                                required="required"
                                                value={dataBrand.filter(function (item) { return item.id === parseInt(curItem.id_brand) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_brand'); }}
                                                optionList={dataBrand}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_unit')}
                                                instanceId="id_unit"
                                                className="id_unit"
                                                name="id_unit"
                                                required="required"
                                                value={dataUnit.filter(function (item) { return item.id === parseInt(curItem.id_unit) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_unit'); }}
                                                optionList={dataUnit}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_product_type')}
                                                instanceId="id_product_type"
                                                className="id_product_type"
                                                name="id_product_type"
                                                required="required"
                                                value={dataProductType.filter(function (item) { return item.id === parseInt(curItem.id_product_type) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_product_type'); }}
                                                optionList={dataProductType}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_type_horizontally')}
                                                instanceId="id_type_horizontally"
                                                className="id_type_horizontally"
                                                name="id_type_horizontally"
                                                value={dataHorizontally.filter(function (item) { return item.id === parseInt(curItem.id_type_horizontally) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_type_horizontally'); }}
                                                optionList={dataHorizontally}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_type_shape')}
                                                instanceId="id_type_shape"
                                                className="id_type_shape"
                                                name="id_type_shape"
                                                value={dataShape.filter(function (item) { return item.id === parseInt(curItem.id_type_shape) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_type_shape'); }}
                                                optionList={dataShape}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_type_material')}
                                                instanceId="id_type_material"
                                                className="id_type_material"
                                                name="id_type_material"
                                                value={dataMaterial.filter(function (item) { return item.id === parseInt(curItem.id_type_material) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_type_material'); }}
                                                optionList={dataMaterial}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_type_frame_color')}
                                                instanceId="id_type_frame_color"
                                                className="id_type_frame_color"
                                                name="id_type_frame_color"
                                                value={curItem.dataFrameColor}
                                                // value={dataFrameColor.filter(function (item) { return item.id === parseInt(curItem.id_type_frame_color) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_type_frame_color'); }}
                                                optionList={dataFrameColor}
                                                isMulti={true}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_type_gender')}
                                                instanceId="id_type_gender"
                                                className="id_type_gender"
                                                name="id_type_gender"
                                                value={curItem.dataGender}
                                                // value={dataGender.filter(function (item) { return item.id === parseInt(curItem.id_type_gender) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_type_gender'); }}
                                                optionList={dataGender}
                                                isMulti={true}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                label={t('products.id_type_face')}
                                                instanceId="id_type_face"
                                                className="id_type_face"
                                                name="id_type_face"
                                                isMulti={true}
                                                value={curItem.dataFace}
                                                // value={dataFace.filter(function (item) { return item.id === parseInt(curItem.id_type_face) })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_type_face'); }}
                                                optionList={dataFace}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>



                                </div>
                            </div>

                        </div>


                        {Libs.isArrayData(dataLensType) ?
                            <div className="box-info">
                                <h2>{t('products.glass_lens')}</h2>
                                <div className="box-info-content">
                                    <div className="row">
                                        {dataLensType.map((m, n) => {
                                            var childs = m.childs;
                                            return (
                                                <div className="col-md-4" key={"glass_len_" + n}>
                                                    <div className="lens-group">
                                                        <RCheckbox
                                                            inputId={"role_" + m.id}
                                                            inputName="glass_group"
                                                            labelClass="no-label"
                                                            checked={m.is_checked}
                                                            onChange={(e) => { this.handleGlassLensInputChange(e, n, null); }}
                                                            label={m.name}
                                                            disabled={Libs.isArrayData(childs) ? true : false}
                                                        />
                                                        {Libs.isArrayData(childs) ?
                                                            <ul>
                                                                {childs.map((k, h) => {
                                                                    return (
                                                                        <li key={h}>
                                                                            <RCheckbox
                                                                                inputId={"role_" + k.id}
                                                                                inputName="glass_group"
                                                                                labelClass="no-label"
                                                                                checked={k.is_checked}
                                                                                onChange={(e) => { this.handleGlassLensInputChange(e, n, h); }}
                                                                                label={k.name}
                                                                            />
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                            : ""}
                                                    </div>


                                                </div>

                                            );
                                        })}
                                    </div>

                                </div>
                            </div>

                            : ""}





                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="box-info">
                                    <h2>{t('products.thumbnail')} <span className="required">*</span></h2>
                                    <div className="box-info-content">
                                        <div className="section-thumbnail">
                                            <ul className="row">
                                                <li className="col-md-12">
                                                    <input key={Libs.generateStrRandom(2, 2, 2, 8)} onChange={(event) => this.onUploadThumbnailChange(event)} type="file" id="upload_thumbnail" name="upload_thumbnail" />
                                                    {Libs.isBlank(curItem.upload_thumbnail) && Libs.isBlank(curItem.thumbnail) ?
                                                        <div className={!Libs.isBlank(curItem.thumbnail_mess) ? "add-thumbnail error-thumbnail" : "add-thumbnail"}>
                                                            <label htmlFor="upload_thumbnail" className="upload_thumbnail">
                                                                <i className="icon-picture-o"></i>
                                                            </label>
                                                        </div>
                                                        : (!Libs.isBlank(curItem.upload_thumbnail) ?
                                                            <div className="item-view">
                                                                <div className="action">
                                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                                    <span onClick={this.deleteThumbnail.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                                </div>
                                                                <img src={curItem.upload_thumbnail} />
                                                            </div>
                                                            :
                                                            <div className="item-view">
                                                                <div className="action">
                                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                                </div>
                                                                <img src={curItem.thumbnail_medium} />
                                                            </div>
                                                        )}

                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="box-info">
                                    <h2>{t('products.thumbnail_hover')}</h2>
                                    <div className="box-info-content">
                                        <div className="section-thumbnail">
                                            <ul className="row">

                                                <li className="col-md-12">
                                                    <input key={Libs.generateStrRandom(2, 2, 2, 8)} onChange={(event) => this.onUploadThumbnailHoverChange(event)} type="file" id="upload_thumbnail_hover" name="upload_thumbnail_hover" />
                                                    {Libs.isBlank(curItem.upload_thumbnail_hover) && Libs.isBlank(curItem.thumbnail_hover) ?
                                                        <div className={!Libs.isBlank(curItem.thumbnail_hover_mess) ? "add-thumbnail error-thumbnail" : "add-thumbnail"}>
                                                            <label htmlFor="upload_thumbnail_hover" className="upload_thumbnail">
                                                                <i className="icon-picture-o"></i>
                                                            </label>
                                                        </div>
                                                        : (!Libs.isBlank(curItem.upload_thumbnail_hover) ?
                                                            <div className="item-view">
                                                                <div className="action">
                                                                    <label htmlFor="upload_thumbnail_hover" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                                    <span onClick={this.deleteThumbnailHover.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                                </div>
                                                                <img src={curItem.upload_thumbnail_hover} />
                                                            </div>
                                                            :
                                                            <div className="item-view">
                                                                <div className="action">
                                                                    <label htmlFor="upload_thumbnail_hover" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                                </div>
                                                                <img src={curItem.thumbnail_hover_medium} />
                                                            </div>
                                                        )}

                                                </li>



                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>


                        <div className="box-info">
                            <div className="config">
                                <h2>Cấu hình sản phẩm</h2>

                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="item-config-menu">
                                                <ul>
                                                    <li onClick={this.onClickTabConfig.bind(this, 'basic')} className={this.state.showConfigTab === 'basic' ? "active" : ""}><span><var className="icon-icon-mysql"></var> Giá</span></li>
                                                    <li onClick={this.onClickTabConfig.bind(this, 'shipping')} className={this.state.showConfigTab === 'shipping' ? "active" : ""}><span><var className="icon-icon-mysql"></var> Kích thước</span></li>
                                                    <li onClick={this.onClickTabConfig.bind(this, 'stores')} className={this.state.showConfigTab === 'stores' ? "active" : ""}><span><var className="icon-icon-mysql"></var> Kiểm kê kho hàng</span></li>
                                                    <li onClick={this.onClickTabConfig.bind(this, 'attribute')} className={this.state.showConfigTab === 'attribute' ? "active" : ""}><span><var className="icon-icon-mysql"></var> Các thuộc tính</span></li>
                                                    <li onClick={this.onClickTabConfig.bind(this, 'upsells')} className={this.state.showConfigTab === 'upsells' ? "active" : ""}><span><var className="icon-icon-mysql"></var> Upsells</span></li>
                                                    <li onClick={this.onClickTabConfig.bind(this, 'cross-selling')} className={this.state.showConfigTab === 'cross-selling' ? "active" : ""}><span><var className="icon-icon-mysql"></var> Sản phẩm bán chéo</span></li>
                                                    <li onClick={this.onClickTabConfig.bind(this, 'gift-products')} className={this.state.showConfigTab === 'gift-products' ? "active" : ""}><span><var className="icon-icon-mysql"></var> Sản phẩm tặng kèm</span></li>
                                                    <li onClick={this.onClickTabConfig.bind(this, 'advanced')} className={this.state.showConfigTab === 'advanced' ? "active" : ""}><span><var className="icon-icon-mysql"></var> Nâng cao</span></li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="config-tab">
                                                {ConfigTabAcive}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12">
                                <div className="tab-main">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        {tabNavLanguage}
                                    </ul>
                                    <div className="tab-content" id="tabContent">
                                        {tabContent}
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="box-info">
                            <h2>{t('products.gallery')}</h2>
                            <div className="box-info-content">
                                <div className="list-gallery">
                                    {Libs.isArrayData(msgErrorGallery) ?
                                        <div className="alert alert-danger">
                                            {msgErrorGallery.map((item, index) => {
                                                return <p key={index}>{item}</p>
                                            })}
                                        </div>
                                        : ""}

                                    <ul className="row">
                                        <li className="col-md-2">
                                            <div className="add-gallery">
                                                <input onChange={(event) => this.onUploadGalleryChange(event)} multiple type="file" id="upload_gallery" name="upload_gallery" />
                                                <label htmlFor="upload_gallery" className="upload_gallery">
                                                    <i className="icon-picture-o"></i>
                                                </label>
                                            </div>
                                        </li>
                                        {Libs.isArrayData(curItem.galleries) ?
                                            curItem.galleries.map((item, index) => {
                                                console.log(item);
                                                return (
                                                    <li className="col-md-2" key={index}>
                                                        <div className="item-view">
                                                            <div className="action">
                                                                <span onClick={this.deleteItemGallery.bind(this, index)} className="delete"><var className="icon-trash-o"></var></span>
                                                            </div>
                                                            {!Libs.isBlank(item.thumbnail_medium) ?
                                                                <img src={item.thumbnail_medium} alt={item.file_name} />
                                                                :
                                                                <img src={item.file_upload} alt={item.file_name} />
                                                            }

                                                        </div>
                                                    </li>
                                                );
                                            })
                                            : ""}
                                    </ul>
                                </div>
                            </div>

                        </div>


                        <div className="box-info">
                            <h2>{t('products.video')}</h2>
                            <div className="box-info-content">
                                <div className="section-video">
                                    <ul className="row">
                                        <li className="col-md-12">
                                            <input key={curItem.selectedVideoFile} onChange={(event) => this.onUploadVideoChange(event)} type="file" id="upload_video" name="upload_video" />
                                            {Libs.isBlank(curItem.file_video_upload) && Libs.isBlank(curItem.video) ?
                                                <div className="add-video">
                                                    <label htmlFor="upload_video" className="upload_video">
                                                        <i className="icon-video-camera"></i>
                                                    </label>
                                                </div>
                                                : (!Libs.isBlank(curItem.file_video_upload) ?
                                                    <div className="item-view">
                                                        <div className="action">
                                                            <label htmlFor="upload_video" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                            <span onClick={this.deleteVideo.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                        </div>
                                                        <video width="320" height="240" controls>
                                                            <source src={curItem.file_video_upload} type="video/mp4" />
                                                            <source src={curItem.file_video_upload} type="video/avi" />
                                                            <source src={curItem.file_video_upload} type="video/ogg" />
                                                            <source src={curItem.file_video_upload} type="video/mov" />
                                                        </video>
                                                    </div>
                                                    :
                                                    <div className="item-view">
                                                        <div className="action">
                                                            <label htmlFor="upload_video" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                            <span className="delete"><var className="icon-trash-o"></var></span>
                                                        </div>
                                                        <video width="320" height="240" controls>
                                                            <source src={curItem.video_url} type="video/mp4" />
                                                            <source src={curItem.video_url} type="video/avi" />
                                                            <source src={curItem.video_url} type="video/ogg" />
                                                            <source src={curItem.video_url} type="video/mov" />
                                                        </video>
                                                    </div>
                                                )}

                                        </li>



                                    </ul>
                                </div>
                            </div>

                        </div>

                        <div className="box-info">
                            <h2>{t('products.hashtag')}</h2>
                            <div className="box-info-content">
                                <div className="tags">
                                    <div className="row">
                                        <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                            <div className="mb-3">
                                                <div className="search-tags">
                                                    <RText
                                                        placeholder={t('products.tag_suggest')}
                                                        inputClass="form-control"
                                                        inputId="tag_suggest"
                                                        inputName="tag_suggest"
                                                        value={searchParam.tag_suggest}
                                                        onChange={(e) => { this.handleInputTagsSearchChange(e); }}
                                                        maxLength={100} />
                                                    {Libs.isArrayData(dataSearchAutoTags) ?
                                                        <div className="list-tag-suggest">
                                                            {dataSearchAutoTags.map((item, index) => {
                                                                return <span onClick={this.addItemSearchTag.bind(this, index)} key={index}>{item.name}</span>
                                                            })}
                                                        </div>
                                                        : ""}

                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                            <div className="mb-3">
                                                <RText
                                                    placeholder={t('products.tag_name')}
                                                    inputClass="form-control"
                                                    inputId="tag_name"
                                                    inputName="tag_name"
                                                    value={searchParam.tag_name}
                                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                                    maxLength={100} />
                                            </div>
                                        </div>
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                            <div className="product-add-tag">
                                                <button type="button" className="btn btn-save" onClick={this.onClickAddTag.bind(this)}> {t('products.product_add_tag')} </button>
                                            </div>
                                        </div>


                                    </div>
                                    {Libs.isArrayData(curItem.dataTags) ?
                                        <div className="row">
                                            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                                                <div className="view-tags">
                                                    {curItem.dataTags.map((item, index) => {
                                                        return <span onClick={this.addItemSearchTag.bind(this, index)} key={index}>{item.name} <var onClick={this.deleteItemTags.bind(this, index)} ><i className="icon-cancel-music"></i></var> </span>
                                                    })}
                                                </div>
                                            </div>
                                        </div>


                                        : ""}

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>{t('common.cancel')}</button>
                        <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                            {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                        </button>
                    </div>
                </Modal>
            </div>

        </div>
    )
}