
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
// import ExportStockService from '../../../../services/ExportStockService';
// import BranchService from '../../../../services/BranchService';
// import StoresService from '../../../../services/StoresService';
// import ProductService from '../../../../services/ProductService';
// import TypeService from '../../../../services/TypeService';
import AddRowValidate from './AddRowValidate';
// import cloneDeep from 'lodash-es/cloneDeep';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: {},
            listType: [],
            dataListAddRow: [
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
            ],
            allStores: [],
            allProducts: [],
            dataType: [
                {id: 1, value: 1, label: "Xuất kho bán hàng"},
                {id: 1, value: 1, label: "Xuất kho huỷ"},
                {id: 1, value: 1, label: "Xuất kho khác"},
            ],
            dataStores: [
                {id: 1, value: 1, label: "kho 1"},
                {id: 1, value: 1, label: "kho 2"},
                {id: 1, value: 1, label: "kho 3"},
            ],
            dataPaymentMethod: [
                {id: 1, value: 1, label: "Tiền mặt"},
                {id: 1, value: 1, label: "Chuyển khoản"},
                {id: 1, value: 1, label: "Internet banking"},
                {id: 1, value: 1, label: "Visa, Master"},
            ],
            dataCategories: [
                {id: 1, value: 1, label: "Danh mục 1"},
                {id: 2, value: 2, label: "Danh mục 2"}
            ],
        }
        this.jsxTemplate = AddPopupJsx;
        // Sự kiện lắng nghe input text thay đổi giá trị
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author long.pham 28/07/2019
     */
    componentDidMount() {
        // let self = this;
        // let curItem = cloneDeep(this.props.curItem);
        // curItem.output_dates = Libs.convertDateToStr(new Date(), Constants.COMMON.DATE_FORMAT);
        // let curD = new Date();
        // curItem.iso_code = Date.parse(curD);
        // this.setState({
        //     curItem: curItem
        // });
 
        // self.listType();
        // self.getAllStores();
        // self.getAllProducts();
    }


    /**
     * @description get all type 
     * @author long.pham 26/07/2019
     */

    listType() {
        let self = this;
        // let params = {
        //     'type': 'export-stores',
        //     'iso_code_language': 'vi'
        // };
        // TypeService.instance.getAll(params, function (data, totalRow) {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             listType: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             listType: []
        //         });
        //     }
        // }, false);
    }

    /**
    * @description get all store
    * @author long.pham 26/07/2019
    */

    getAllStores() {
        // let self = this;
        // let params = {
        //     id_company: this.staff.id_company
        // };
        // StoresService.instance.getAll(params, function (data, totalRow) {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allStores: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allStores: []
        //         });
        //     }
        // }, false);
    }

    /**
     * @description get all store
     * @author long.pham 26/07/2019
     */

    getAllProducts() {
        let self = this;
        // let params = {
        //     id_company: this.staff.id_company,
        //     'iso_code': 'vi'
        // };
        // ProductService.instance.getAllProduct(params, function (data, totalRow) {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allProducts: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allProducts: []
        //         });
        //     }
        // }, false);
    }

    /**
     * @description add row 
     * @author long.pham 26/07/2019
     */

    onClickAddRow() {
        let params = this.state.dataListAddRow;
        let item = {
            index: params.length + 1,
            id_export_stores: "",
            id_product: "",
            id_type: "",
            type_name: "",
            quantity: 1,
            price: 0,
            messages: {
                id_product: "",
                quantity: "",
                price: ""
            }
        }
        params.push(item);
        this.setState({
            dataListAddRow: params
        });
    }

    /**
     * @description Remove row
     * @author long.pham 26/07/2019
     */
    removeItemRow = (index) => {
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;
        let dataListAddRow = this.state.dataListAddRow;
        dataListAddRow.splice(index, 1);
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }

    /**
     * @description Select change
     * @author long.pham 26/07/2019
     */
    onRowSelectChange = (event, index) => {
        let self = this;
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;
        let dataListAddRow = this.state.dataListAddRow;
        let item = dataListAddRow[index];
        if (name == 'id_product') {
            let currentProductChoose = Libs.find(dataListAddRow, 'id_product', value);
            if (currentProductChoose) {
                value = "";
                item.id_product = "";
                item.id_type = "";
                item.type_name = "";
                item.quantity = 1;
                item.price = 0;
                // item.messages.id_product = trans.translate('EXPORT_STOCK.exits_product_choose');
                // self.toast(trans.translate('EXPORT_STOCK.exits_product_choose'), "error");
                // $('#id_product_' + index).val('').trigger('change');
            } else {
                item.messages.id_product = "";
            }

            if (value != "") {
                let products = this.state.allProducts;
                let productObjAdd = Libs.find(products, 'id', value);
                if (productObjAdd) {
                    item.type_name = productObjAdd.unit_name;
                    item.id_type = productObjAdd.id_type;
                    item.price = productObjAdd.price;
                }
            }
        }

        item[name] = value;
        dataListAddRow[index] = item;
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }

    /**
     * @description validate a field input
     * @author long.pham 28/07/2019
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description select change
     * @author long.pham 28/07/2019
     * @param {*} event 
     */
    handleInputChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            item[name] = value;
            this.setState({ curItem: item });
        }

    }


    /** 
     * @description Save info ExportStock
     * @author long.pham 28/07/2019
    */
    async onSaveAction() {
        // let params = this.state.curItem, self = this;
        // var screenMode = (!Libs.isBlank(self.state.curItem.screen_mode)) ? self.state.curItem.screen_mode : Constants.SCREEN_MODE.ADD;
        // let v = new AddExportStockPopupValidate();
        // let errors = await v.FLValidationAll(params);
        // if (errors) {
        //     setValidateMessage(errors);
        //     return;
        // }
        // params.screen_mode = screenMode;
        // let dataListAddRow = this.state.dataListAddRow;
        // if (Libs.isObjectEmpty(dataListAddRow)) {
        //     self.toast(trans.translate('EXPORT_STOCK.list_product_is_not_null'), "error");
        //     return;
        // }
        // var mess = false;
        // dataListAddRow.map((item, index) => {
        //     let itemRow = dataListAddRow[index];
        //     if (Libs.isBlank(item.id_product)) {
        //         itemRow.messages.id_product = "error";
        //         mess = true;
        //     } else {
        //         itemRow.messages.id_product = "";
        //     }

        //     if (!Libs.isNumber(item.quantity) || item.quantity * 1 <= 0) {
        //         itemRow.messages.quantity = "error";
        //         mess = true;
        //     } else {
        //         itemRow.messages.quantity = "";
        //     }

        //     if (!Libs.isNumber(item.price) || item.price * 1 <= 0) {
        //         itemRow.messages.price = "error";
        //         mess = true;
        //     } else {
        //         itemRow.messages.price = "";
        //     }
        //     dataListAddRow[index] = itemRow;

        // });

        // this.setState({
        //     dataListAddRow : dataListAddRow
        // });

        // if (mess == true) {
        //     self.toast(trans.translate('WAREHOUSING.list_product_is_not_null'), "error");
        //     return;
        // }

        // //remove message validation
        // removeAllValidateMessage();
        // params.id_staff = this.staff.id_staff;
        // params.created_by = this.staff.first_name + " " + this.staff.last_name;
        // params.updated_by = this.staff.first_name + " " + this.staff.last_name;
        // params.id_company = this.staff.id_company;
        // params.output_date = Libs.convertStr2DateV01 (params.output_dates, "dd/mm/yyyy", "/");
        // params.is_delete = 0;
        // params.status = 1;
        // params.dataDetail = dataListAddRow;
        // ExportStockService.instance.save(params, function (status, data, msg) {
        //     // console.log('data: ', data, status, msg);
        //     if (status) {
        //         self.toast(msg, "info");
        //         self.props.onCloseAddExportStockPopup(true, data);
        //     }
        //     else if (data) {
        //         setValidateMessage(data);
        //     }
        //     else if (!Libs.isBlank(msg)) {
        //         self.toast(msg, "error");
        //     }
        // }, true);
    }

    /**
     * @description Row input on change
     * @author long.pham 26/07/2019
     */
    handleRowInputChange = (event, index) => {
        let self = this;
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (name == 'price' && value != null) {
            if (value != 0) {
                if (!Libs.isNumber(value)) {
                    return;
                }
            }

        }

        if (name == 'quantity' && value != null) {
            if (value != 0) {
                if (!Libs.isNumber(value)) {
                    return;
                }
            }

        }
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;
        let dataListAddRow = this.state.dataListAddRow;
        let item = this.state.dataListAddRow[index];
        item[name] = value;

        dataListAddRow[index] = item;
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }


    /**
     * @description close popup
     * @author long.pham 28/07/2019
     */
    onCloseAddPopup() {
        if (!this.props.onCloseAddPopup || typeof this.props.onCloseAddPopup !== 'function') return;
        this.props.onCloseAddPopup(false, null);
    }




















































    


    /**
     * @description open add supplier popup
     * @author long.pham 28/07/2019
     */
    // onClickAddSupplier() {
    //     if (!this.props.onClickAddSupplier || typeof this.props.onClickAddSupplier !== 'function') return;
    //     this.props.onClickAddSupplier(false, null);
    // }



    /**
     * @description Get object select change
     * @author long.pham 28/07/2019
     */
    onSelectChange = (event, data) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        var { curItem } = this.state;
        curItem[name] = value;
        this.setState({
            curItem
        });
        this.validateOne(event);
    }



    

    async validateOneRow(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddRowValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

}
export default AddPopup;