import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Libs from '../../../../../utils/Libs';
import { RSwitch } from '../../../../../components/Controls';
import Auth from '../../../../../utils/Auth';
import Constants from '../../../../../utils/Constants';

class RowItem extends Component {
    onItemClick = () => {
        if (!this.props.onItemClick || typeof this.props.onItemClick !== 'function') return;
        this.props.onItemClick(this.props.index);
    }

    onItemClickDelete = () => {
        if (!this.props.onItemClickDelete || typeof this.props.onItemClickDelete !== 'function') return;
        this.props.onItemClickDelete(this.props.index);
    }

    /**
     * @description Status change event
     * @author long.pham 27-07-2019
     */
    onStatusChange = () => {
        if (!this.props.onStatusChange || typeof this.props.onStatusChange !== 'function') return;
        this.props.onStatusChange(this.props.index);
        return;
    }

    render() {
        var item = this.props.dataItem;
        const { t } = this.props;
        return (
            <div className="body-row" key={item.id}>
                <ReactTooltip />
                <div className="body-col width5"><p><img src={item.thumbnail} alt={item.name} /></p></div>
                <div className="body-col width15">
                    <p className="id"><strong>ID: </strong>{item.id}</p>
                    <p className="sku"><strong>SKU: </strong>{item.sku}</p>
                </div>
                <div className="body-col width30">
                    <p><strong>{item.name}</strong> </p>
                    {!Libs.isBlank(item.created_date) ? <p>{t('products.created_date')}: {item.created_date}</p> : ""}

                </div>

                <div className="body-col width10">
                    {(!Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 && item.saleoff_price < item.regular_price) ?
                        <div className="price-product">
                            <p className="price">
                                <label className="current_price">{Libs.formatNum(item.regular_price, "#,###.##")} <var>đ</var></label>
                            </p>
                            <p className="price">
                                <label className="discount_price">{Libs.formatNum(item.saleoff_price, "#,###.##")} <var>đ</var></label>
                            </p>
                        </div>
                        :
                        <p className="price">
                            <label className="discount_price">{item.is_single == 2 ? "Từ: ": ""} {Libs.formatNum(item.regular_price, "#,###.##")} <var>đ</var></label>
                        </p>
                    }

                </div>
                <div className="body-col width15"><p>{item.category_name}</p></div>
                <div className="body-col width10 text-center">
                    <div className="checkmark">
                        <RSwitch
                            inputId={"status_" + item.id}
                            inputName="status"
                            checked={(item.status * 1 === 1) ? 1 : 0}
                            disabled={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.APPROVAL) ? false : true}
                            onChange={this.onStatusChange.bind(this)}
                        />
                    </div>

                </div>
                <div className="body-col width10">
                    <div className="functions">
                        <ul>
                            <li className={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.DEL) ? '' : 'disabled'} onClick={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.EDIT) ? this.onItemClick.bind(this) : ''} data-tip={t('common.edit')} data-type="warning" data-class="tooltip-action"><var className="icon-pencil-square-o" aria-hidden="true"></var></li>
                            <li className={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.DEL) ? '' : 'disabled'} onClick={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.DEL) ? this.onItemClickDelete.bind(this) : ''} data-tip={t('common.delete')} data-type="warning" data-class="tooltip-action"><var className="icon-trash-o" aria-hidden="true"></var></li>
                        </ul>

                        {/* <ul>
                            <li onClick={this.onItemClick.bind(this)} data-tip={t('common.edit')} data-type="warning" data-class="tooltip-action"><var className="icon-pencil-square-o" aria-hidden="true"></var></li>
                            <li onClick={this.onItemClickDelete.bind(this)} data-tip={t('common.delete')} data-type="warning" data-class="tooltip-action"><var className="icon-trash-o" aria-hidden="true"></var></li>
                            <li data-tip={t('common.view')} data-type="warning" data-class="tooltip-action"><var className="icon-eye" aria-hidden="true"></var></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        );
    }
}
export default RowItem;
