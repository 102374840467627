import React from 'react';
import { RText, RTextArea } from '../../../../../components/Controls';
import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import Modal from 'react-bootstrap4-modal';
import RowItemProductAdd from './RowItemProductAdd';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import CMSOutsideClickHandler from "../../../../../components/CMSOutsideClickHandler";
import { parseFloat } from '../../../../../validator/libs/number';

export default function AddPopup() {

    var { curItem, dataBilling, dataOrderStatus, dataPaymentStatus, searchProductParam, dataProductSearchAuto, dataSearchPhoneAuto, dataSearchEmailAuto, dataCustomerGroup, dataCustomerType, dataProvince, dataDistrict, dataWard, dataProvinceShip, dataDistrictShip, dataWardShip, manualTypeDiscount, dataPaymentMethod, dataGender } = this.state;
    const { t } = this.props;
    var RowsItemProductAdds = null;
    var dataProducts = curItem.dataProducts;
    var Subtotal = 0;
    var businessType = this.employee.business_type;

    if (Libs.isArrayData(dataProducts)) {
        RowsItemProductAdds = dataProducts.map((item, index) => {
            Subtotal = Subtotal + (parseInt(Libs.convertStringToNumber(item.saleoff_price)) * parseInt(Libs.convertStringToNumber(item.quantity)));
            return <RowItemProductAdd
                key={'row_item_' + index}
                index={index}
                dataItem={item}
                onItemProductClickDelete={this.onItemProductClickDelete}
                handleInputProductChange={this.handleInputProductChange}
                screen_mode={curItem.screen_mode}
                t={t}
            />
        });
    };


    //Chiet khau
    var manualDiscount = 0;
    if (!Libs.isBlank(curItem.id_type_discount) && curItem.id_type_discount === 5 && !Libs.isBlank(curItem.manual_discount)) {
        manualDiscount = Subtotal * (parseFloat(curItem.manual_discount) / 100);
    } else if (!Libs.isBlank(curItem.id_type_discount) && curItem.id_type_discount === 4 && !Libs.isBlank(curItem.manual_discount)) {
        manualDiscount = Libs.convertStringToNumber(curItem.manual_discount);
    }

    // Coupon
    var couponTotal = 0;
    var coupon = curItem.coupon;

    if (!Libs.isObjectEmpty(coupon)) {
        if (Subtotal >= coupon.min_order && coupon.id_type === 5) {
            couponTotal = (Subtotal * (parseFloat(coupon.coupon_value) / 100)) > coupon.max_value ? coupon.max_value : (Subtotal * (parseFloat(coupon.coupon_value) / 100));
        } else if (Subtotal >= coupon.min_order && coupon.id_type === 4) {
            couponTotal = coupon.coupon_value;
        }
    };


    // vat
    var vat = (Subtotal - couponTotal - manualDiscount) * (parseFloat(curItem.vat) / 100);

    // Total
    var totalOrder = 0;
    totalOrder = Subtotal - couponTotal - manualDiscount + vat;
    if (!Libs.isBlank(curItem.amount_ship)) {
        totalOrder = totalOrder + parseInt(Libs.convertStringToNumber(curItem.amount_ship));
    }

    var TotalAmountReceived = 0;
    if (!Libs.isBlank(curItem.amount_received)) {
        TotalAmountReceived = parseInt(Libs.convertStringToNumber(curItem.amount_received)) - totalOrder
    }

    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-dialog-scrollable modal-xl add-order full-screen" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? (t('sell.edit_title') + ": " + curItem.id) : (curItem.screen_mode === Constants.SCREEN_MODE.ADD ? t('sell.add_title') : t('sell.view_title') + ": " + curItem.id)}</h5>

                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">
                {curItem.screen_mode !== Constants.SCREEN_MODE.VIEW ?
                    <div className="box-search">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                                        <CMSOutsideClickHandler
                                            onOutsideClick={this.onCloseSearchProductAuto.bind(this)}
                                        >
                                            <div className="order-search-product-auto">
                                                <div className="row">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                        <RText placeholder={t('sell.search_keyword')}
                                                            inputClass="form-control"
                                                            inputId="name"
                                                            inputName="name"
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={searchProductParam.name}
                                                            onChange={(e) => { this.handleInputSerachProductAutoChange(e); this.validateOne(e); }}
                                                            maxLength={100} />
                                                    </div>
                                                </div>

                                                {Libs.isArrayData(dataProductSearchAuto) ?
                                                    <div className="main-auto">
                                                        <div className="header-auto">
                                                            <div className="row">
                                                                <div className="col-md-8"><p>{t('sell.product_name')}</p></div>
                                                                <div className="col-md-4 text-end"><p>{t('sell.price')}</p></div>
                                                            </div>
                                                        </div>

                                                        <div className="body-auto">
                                                            {dataProductSearchAuto.map((item, index) => {
                                                                return (
                                                                    <div onClick={this.getChooseProduct.bind(this, index)} className="item" key={index}>
                                                                        <div className="row">
                                                                            <div className="col-md-8"><p>{item.product_name}</p>
                                                                            </div>
                                                                            <div className="col-md-4 text-end">
                                                                                {item.regular_price === item.saleoff_price ?
                                                                                    <p>
                                                                                        <span className="discount-price">{Libs.formatNum(item.regular_price, '#.###')} vnđ</span>
                                                                                    </p>
                                                                                    :
                                                                                    <p>
                                                                                        <span className="price">{Libs.formatNum(item.regular_price, '#.###')} vnđ</span>
                                                                                        <span className="discount-price">{Libs.formatNum(item.saleoff_price, '#.###')} vnđ</span>
                                                                                    </p>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}

                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </CMSOutsideClickHandler>


                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row">
                                    <div className="col-xl-10 col-lg-10 col-md-10 col-12">
                                        {Libs.isArrayData(dataBilling) ?
                                            <ul className="list-tab">
                                                {dataBilling.map((item, index) => {
                                                    return (
                                                        <li onClick={this.onClickSetActiveOrder.bind(this, index)} key={index}><span className={item.active ? "tab-bill active" : "tab-bill"}>{item.name}</span></li>
                                                    );
                                                })}
                                            </ul>
                                            : ""}

                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-12 text-end">
                                        <div onClick={this.addNewBilling.bind(this)} className="add-tab-order">
                                            <var className="icon-plus"></var>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}

                <div className="main-order">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                            <div className="data-product">
                                <div className="header-row">
                                    <div className="header-col width5">{t('sell.stt')}</div>
                                    <div className="header-col width35">{t('sell.product_name')}</div>
                                    <div className="header-col width20 text-end">{t('sell.price')}</div>
                                    <div className="header-col width10 text-end">{t('sell.sl')}</div>
                                    <div className="header-col width20 text-end">{t('sell.product_sub_total')}</div>
                                    <div className="header-col width10 text-end">{t('common.action')}</div>
                                </div>

                                <div className="body-product">
                                    {RowsItemProductAdds ? RowsItemProductAdds : <div className="data-empty">{t('sell.cart_empty')}</div>}
                                </div>
                            </div>
                            <div className="main-box-info box-customer">
                                <div className="box-info">
                                    <h2>{t('sell.customer_title')}</h2>
                                    <div className="box-info-content">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <CMSOutsideClickHandler
                                                    onOutsideClick={this.onCloseSearchPhoneAuto.bind(this)}
                                                >
                                                    <div className="search-auto">
                                                        <div className="input-group mb-3">

                                                            <span className="input-group-text"><var className="icon-phone"></var></span>
                                                            <RText placeholder={t('sell.customer_phone')}
                                                                inputClass="form-control"
                                                                inputId="phone"
                                                                inputName="phone"
                                                                disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                                value={curItem.phone}
                                                                onChange={(e) => { this.handleInputSearchAutoChange(e); this.validateOne(e); }}
                                                                pattern="^[0-9-+()._ ]*$"
                                                                maxLength={20} />
                                                        </div>
                                                        {Libs.isArrayData(dataSearchPhoneAuto) ?
                                                            <div className="main-auto">
                                                                <div className="header-auto">
                                                                    <div className="row">
                                                                        <div className="col-md-4"><p>{t('customer.customer_name')}</p></div>
                                                                        <div className="col-md-4"><p>{t('customer.phone')}</p></div>
                                                                        <div className="col-md-4"><p>{t('customer.email')}</p></div>
                                                                    </div>
                                                                </div>

                                                                <div className="body-auto">
                                                                    {dataSearchPhoneAuto.map((item, index) => {
                                                                        return (
                                                                            <div onClick={this.setCurrentCustomerPhone.bind(this, index)} className="item" key={index}>
                                                                                <div className="row">
                                                                                    <div className="col-md-4"><p>{item.fullname}</p></div>
                                                                                    <div className="col-md-4"><p>{item.phone}</p></div>
                                                                                    <div className="col-md-4"><p>{item.email}</p></div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}

                                                                </div>
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </CMSOutsideClickHandler>


                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <CMSOutsideClickHandler
                                                    onOutsideClick={this.onCloseSearchEmailAuto.bind(this)}
                                                >
                                                    <div className="search-auto">
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text"><var className="icon-email-mail-streamline"></var></span>
                                                            <RText placeholder={t('sell.email')}
                                                                inputClass="form-control"
                                                                inputId="email"
                                                                inputName="email"
                                                                value={curItem.email}
                                                                disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                                onChange={(e) => { this.handleInputSearchAutoChange(e); this.validateOne(e); }}
                                                                maxLength={100} />
                                                        </div>
                                                        {Libs.isArrayData(dataSearchEmailAuto) ?
                                                            <div className="main-auto">
                                                                <div className="header-auto">
                                                                    <div className="row">
                                                                        <div className="col-md-4"><p>{t('customer.customer_name')}</p></div>
                                                                        <div className="col-md-4"><p>{t('customer.phone')}</p></div>
                                                                        <div className="col-md-4"><p>{t('customer.email')}</p></div>
                                                                    </div>
                                                                </div>

                                                                <div className="body-auto">
                                                                    {dataSearchEmailAuto.map((item, index) => {
                                                                        return (
                                                                            <div onClick={this.setCurrentCustomerEmail.bind(this, index)} className="item" key={index}>
                                                                                <div className="row">
                                                                                    <div className="col-md-4"><p>{item.fullname}</p></div>
                                                                                    <div className="col-md-4"><p>{item.phone}</p></div>
                                                                                    <div className="col-md-4"><p>{item.email}</p></div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}

                                                                </div>
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </CMSOutsideClickHandler>

                                            </div>



                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-man-people-streamline-user"></var></span>
                                                    <RText placeholder={t('customer.first_name')}
                                                        inputClass="form-control"
                                                        inputId={"first_name"}
                                                        inputName={"first_name"}
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        value={curItem.first_name}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-man-people-streamline-user"></var></span>
                                                    <RText placeholder={t('customer.last_name')}
                                                        inputClass="form-control"
                                                        inputId="last_name"
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        inputName="last_name"
                                                        value={curItem.last_name}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>





                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-credit-card"></var></span>
                                                    <RText placeholder={t('sell.card_number')}
                                                        inputClass="form-control"
                                                        inputId={"card_number"}
                                                        inputName={"card_number"}
                                                        value={curItem.card_number}
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-location"></var></span>
                                                    <div className="dropdown-group">
                                                        <FormReactSelect
                                                            instanceId="id_province"
                                                            className="id_province"
                                                            name="id_province"
                                                            isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={dataProvince.filter(function (item) { return item.id === curItem.id_province })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_province') }}
                                                            optionList={dataProvince}
                                                            placeHolder={t('sell.id_province')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-location"></var></span>
                                                    <div className="dropdown-group">
                                                        <FormReactSelect
                                                            instanceId="id_district"
                                                            className="id_district"
                                                            name="id_district"
                                                            isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={dataDistrict.filter(function (item) { return item.id === curItem.id_district })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_district') }}
                                                            optionList={dataDistrict}
                                                            placeHolder={t('sell.id_district')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-location"></var></span>
                                                    <div className="dropdown-group">
                                                        <FormReactSelect
                                                            instanceId="id_ward"
                                                            className="id_ward"
                                                            name="id_ward"
                                                            isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={dataWard.filter(function (item) { return item.id === curItem.id_ward })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_ward') }}
                                                            optionList={dataWard}
                                                            placeHolder={t('sell.id_ward')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-home-house-streamline"></var></span>
                                                    <RText placeholder={t('sell.address')}
                                                        inputClass="form-control"
                                                        inputId={"address"}
                                                        inputName={"address"}
                                                        value={curItem.address}
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-facebook-square"></var></span>
                                                    <RText placeholder={t('sell.facebook')}
                                                        inputClass="form-control"
                                                        inputId={"facebook"}
                                                        inputName={"facebook"}
                                                        value={curItem.facebook}
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <FormReactSelect
                                                    instanceId="gender"
                                                    className="gender"
                                                    name="gender"
                                                    isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                    value={dataGender.filter(function (item) { return item.id === curItem.gender })}
                                                    onChange={(e) => { this.handleDropdownChange(e, 'gender') }}
                                                    optionList={dataGender}
                                                    placeHolder={t('sell.gender')}
                                                />

                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <CMSDatePicker
                                                    placeholder={t("sell.birthday")}
                                                    inputId="birthday"
                                                    dateFormat="dd/MM/yyyy"
                                                    inputClass="form-control"
                                                    inputName="birthday"
                                                    maxLength={20}
                                                    disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                    value={curItem.birthday}
                                                    handleChange={(e) => { this.handleInputDateChange(e); }}
                                                    showIconCalendar={false}
                                                />
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <FormReactSelect
                                                    instanceId="id_customer_type"
                                                    className="id_customer_type"
                                                    name="id_customer_type"
                                                    isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                    value={dataCustomerType.filter(function (item) { return item.id === curItem.id_customer_type })}
                                                    onChange={(e) => { this.handleDropdownChange(e, 'id_customer_type') }}
                                                    optionList={dataCustomerType}
                                                    placeHolder={t('sell.id_customer_type')}
                                                />
                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <FormReactSelect
                                                    instanceId="id_customer_group"
                                                    className="id_customer_group"
                                                    name="id_customer_group"
                                                    isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                    value={dataCustomerGroup.filter(function (item) { return item.id === curItem.id_customer_group })}
                                                    onChange={(e) => { this.handleDropdownChange(e, 'id_customer_group') }}
                                                    optionList={dataCustomerGroup}
                                                    placeHolder={t('sell.id_customer_group')}
                                                />
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="mb-3">
                                                    <RText placeholder={t('sell.note')}
                                                        inputClass="form-control"
                                                        inputId="note"
                                                        inputName="note"
                                                        value={curItem.note}
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={200} />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                {curItem.screen_mode !== Constants.SCREEN_MODE.VIEW ?
                                                    <div className="mb-3">
                                                        {Libs.isBlank(curItem.id_customer) ?
                                                            <div onClick={this.onSaveCustomer.bind(this)} className="add-customer">{t('sell.save_customer')}</div>
                                                            : ""}
                                                        <div onClick={this.resetCustomer.bind(this)} className="add-reset">Reset</div>
                                                    </div>
                                                    : ""}

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div className="main-box-info">
                                <div className="box-info">
                                    <h2>{t('sell.ship_title')}
                                        <span onClick={this.copyFromCustomer.bind(this)} className="copy-customer-info">{t('sell.copy_from_customer')}</span>
                                    </h2>
                                    <div className="box-info-content">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-man-people-streamline-user"></var></span>
                                                    <RText placeholder={t('sell.ship_first_name')}
                                                        inputClass="form-control"
                                                        inputId="ship_first_name"
                                                        inputName="ship_first_name"
                                                        value={curItem.ship_first_name}
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-man-people-streamline-user"></var></span>
                                                    <RText placeholder={t('sell.ship_last_name')}
                                                        inputClass="form-control"
                                                        inputId="ship_last_name"
                                                        inputName="ship_last_name"
                                                        value={curItem.ship_last_name}
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-phone"></var></span>
                                                    <RText placeholder={t('sell.ship_phone')}
                                                        inputClass="form-control"
                                                        inputId={"ship_phone"}
                                                        inputName={"ship_phone"}
                                                        value={curItem.ship_phone}
                                                        pattern="^[0-9-+()._ ]*$"
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>



                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-location"></var></span>
                                                    <div className="dropdown-group">
                                                        <FormReactSelect
                                                            instanceId="id_province_ship"
                                                            className="id_province_ship"
                                                            name="id_province_ship"
                                                            isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={dataProvince.filter(function (item) { return item.id === curItem.id_province_ship })}
                                                            onChange={(e) => { this.handleDropdownShipChange(e, 'id_province_ship') }}
                                                            optionList={dataProvinceShip}
                                                            placeHolder={t('sell.id_province_ship')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-location"></var></span>
                                                    <div className="dropdown-group">
                                                        <FormReactSelect
                                                            instanceId="id_district_ship"
                                                            className="id_district_ship"
                                                            name="id_district_ship"
                                                            isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={dataDistrictShip.filter(function (item) { return item.id === curItem.id_district_ship })}
                                                            onChange={(e) => { this.handleDropdownShipChange(e, 'id_district_ship') }}
                                                            optionList={dataDistrictShip}
                                                            placeHolder={t('sell.id_district_ship')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-location"></var></span>
                                                    <div className="dropdown-group">
                                                        <FormReactSelect
                                                            instanceId="id_ward_ship"
                                                            className="id_ward_ship"
                                                            name="id_ward_ship"
                                                            isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={dataWardShip.filter(function (item) { return item.id === curItem.id_ward_ship })}
                                                            onChange={(e) => { this.handleDropdownShipChange(e, 'id_ward_ship') }}
                                                            optionList={dataWardShip}
                                                            placeHolder={t('sell.id_ward_ship')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-home-house-streamline"></var></span>
                                                    <RText placeholder={t('sell.ship_address')}
                                                        inputClass="form-control"
                                                        inputId={"ship_address"}
                                                        inputName={"ship_address"}
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        value={curItem.ship_address}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="main-box-info">
                                <div className="box-info">
                                    <h2>{t('sell.note_order')}</h2>
                                    <div className="box-info-content">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <div className="mb-3">
                                                    <RTextArea placeholder={t('sell.note_order')}
                                                        inputClass="form-control"
                                                        inputId="note_order"
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        inputName="note_order"
                                                        value={curItem.note_order}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {businessType == 0 ?
                                <div className="main-box-info">
                                    <div className="box-info">
                                        <h2>{t('sell.pd_title')}</h2>
                                        <div className="box-info-content">
                                            <div className="row">
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                    <div className="mb-3">
                                                        <RText label={t('sell.pd')}
                                                            inputClass="form-control"
                                                            inputId="pd"
                                                            inputName="pd"
                                                            value={curItem.pd}
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            pattern="^[0-9.]*$"
                                                            maxLength={6} />
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                    <div className="mb-3">
                                                        <RText label={t('sell.lens_width')}
                                                            inputClass="form-control"
                                                            inputId="lens_width"
                                                            inputName="lens_width"
                                                            value={curItem.lens_width}
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            pattern="^[0-9.]*$"
                                                            maxLength={6} />
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                    <div className="mb-3">
                                                        <RText label={t('sell.bridge_width')}
                                                            inputClass="form-control"
                                                            inputId="bridge_width"
                                                            inputName="bridge_width"
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={curItem.bridge_width}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            pattern="^[0-9.]*$"
                                                            maxLength={6} />
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                    <div className="mb-3">
                                                        <RText label={t('sell.frame_width')}
                                                            inputClass="form-control"
                                                            inputId="frame_width"
                                                            inputName="frame_width"
                                                            value={curItem.frame_width}
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            pattern="^[0-9.]*$"
                                                            maxLength={6} />
                                                    </div>
                                                </div>

                                            </div>
                                            {Libs.isArrayData(curItem.dataPD) ?
                                                <div className="data-pd">
                                                    <div className="header-row">
                                                        <div className="header-col width10">{t('sell.stt')}</div>
                                                        <div className="header-col width30">{t('sell.pd')}</div>
                                                        <div className="header-col width20">{t('sell.lens_width')}</div>
                                                        <div className="header-col width20">{t('sell.bridge_width')}</div>
                                                        <div className="header-col width20">{t('sell.frame_width')}</div>
                                                    </div>

                                                    <div className="body-pd">
                                                        {curItem.dataPD.map((item, index) => {
                                                            return (
                                                                <div className="body-row" key={index}>
                                                                    <div className="body-col width10"><p>{index + 1}</p></div>
                                                                    <div className="body-col width30">
                                                                        <p>{item.pd}</p>
                                                                    </div>
                                                                    <div className="body-col width20">
                                                                        <p>{item.lens_width}</p>
                                                                    </div>
                                                                    <div className="body-col width20">
                                                                        <p>{item.bridge_width}</p>
                                                                    </div>
                                                                    <div className="body-col width20"><p>{item.frame_width}</p></div>

                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                : ""}


                                        </div>
                                    </div>
                                </div>

                                : ""}


                            {!Libs.isBlank(curItem.thumbnail_full) && curItem.screen_mode === Constants.SCREEN_MODE.VIEW ?
                                <div className="main-box-info">
                                    <div className="box-info">
                                        <h2>{t('sell.result_hospital')}</h2>
                                        <div className="box-info-content">
                                            <img src={curItem.thumbnail_full} />
                                        </div>
                                    </div>
                                </div>

                                : ""}




                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <div className="main-box-info box-info-order">
                                <div className="box-info">
                                    <div className="box-info-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="input-group mb-3">
                                                    <RText placeholder={t('sell.coupon')}
                                                        inputClass="form-control"
                                                        disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                        inputId="coupon_code" inputName="coupon_code"
                                                        value={curItem.coupon_code}
                                                        disabled={(curItem.screen_mode === Constants.SCREEN_MODE.VIEW || !Libs.isArrayData(dataProducts)) ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                        maxLength={40} />

                                                    <span onClick={Libs.isArrayData(dataProducts) ? this.appleCoupon.bind(this) : null} className={Libs.isArrayData(dataProducts) ? "input-group-text apply-coupon" : "input-group-text apply-coupon disabled"} >
                                                        {t('sell.apply')}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-md-12">

                                                <div className="tax-code-info">
                                                    <h3>{t('sell.manual_discount')}</h3>
                                                    <div className="row">
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                            <FormReactSelect
                                                                instanceId="id_type_discount"
                                                                className="id_type_discount"
                                                                name="id_type_discount"
                                                                value={manualTypeDiscount.filter(function (item) { return item.id === curItem.id_type_discount })}
                                                                onChange={(e) => { this.handleDropdownChange(e, 'id_type_discount') }}
                                                                optionList={manualTypeDiscount}
                                                                isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                                placeHolder={t('common.choose')}
                                                            />
                                                        </div>

                                                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                                            <div className="mb-3">
                                                                <RText
                                                                    inputClass="form-control"
                                                                    disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                                    inputId="manual_discount" inputName="manual_discount"
                                                                    value={Libs.formatNum(Libs.convertStringToNumber(curItem.manual_discount), '#.###.##')}
                                                                    pattern={curItem.id_type_discount === 5 ? "^[0-9]*$" : "^[0-9.]*$"}
                                                                    disabled={Libs.isBlank(curItem.id_type_discount) ? true : false}
                                                                    onChange={(e) => { this.handleInputManualDiscountChange(e); this.validateOne(e) }}
                                                                    maxLength={curItem.id_type_discount === 5 ? 2 : 10} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="tax-code-info">
                                            <h3>{t('sell.status')}</h3>
                                            <div className="row">

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <FormReactSelect
                                                            label={t('sell.id_order_status')}
                                                            instanceId="id_order_status"
                                                            required="required"
                                                            className="id_order_status"
                                                            name="id_order_status"
                                                            isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={dataOrderStatus.filter(function (item) { return item.id == curItem.id_order_status })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_order_status') }}
                                                            optionList={dataOrderStatus}
                                                            placeHolder={t('common.choose')}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <FormReactSelect
                                                            label={t('sell.id_payment_status')}
                                                            instanceId="id_payment_status"
                                                            required="required"
                                                            className="id_payment_status"
                                                            name="id_payment_status"
                                                            isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={dataPaymentStatus.filter(function (item) { return item.id == parseInt(curItem.id_payment_status) })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_payment_status') }}
                                                            optionList={dataPaymentStatus}
                                                            placeHolder={t('common.choose')}
                                                        />

                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div className="tax-code-info">
                                            <h3>{t('sell.payment')}</h3>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <RText label={t('sell.vat')}
                                                            inputClass="form-control"
                                                            inputId="vat" inputName="vat"
                                                            value={curItem.vat}
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            pattern="^[0-9]*$"
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            maxLength={2} />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <FormReactSelect
                                                            label={t('sell.id_payment_method')}
                                                            instanceId="id_payment_method"
                                                            required="required"
                                                            className="id_payment_method"
                                                            name="id_payment_method"
                                                            isDisabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={dataPaymentMethod.filter(function (item) { return item.id === curItem.id_payment_method })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_payment_method') }}
                                                            optionList={dataPaymentMethod}
                                                            placeHolder={t('common.choose')}
                                                        />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="summary-order">
                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>{t('sell.sub_total')}</p></div>
                                                    <div className="col-md-6 col-6 text-end"><p><span>{Libs.formatNum(Subtotal, '#.###')} vnđ</span></p></div>
                                                </div>
                                            </div>

                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>{t('sell.saleoff')}
                                                        {!Libs.isObjectEmpty(curItem.coupon) ? <var onClick={this.deleteCoupon.bind(this)} className="icon-trash-o"></var> : ""}
                                                    </p></div>
                                                    <div className="col-md-6 col-6 text-end"><p><span>{Libs.formatNum(couponTotal, '#.###')} vnđ</span> </p></div>
                                                </div>
                                            </div>


                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>{t('sell.manual_discount')}</p></div>
                                                    <div className="col-md-6 col-6 text-end"><p><span>{Libs.formatNum(manualDiscount, '#.###')} vnđ</span></p></div>
                                                </div>
                                            </div>

                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>{t('sell.vat')}</p></div>
                                                    <div className="col-md-6 col-6 text-end"><p><span>{Libs.formatNum(vat, '#.###')} vnđ</span></p></div>
                                                </div>
                                            </div>

                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>{t('sell.amount_ship')}</p></div>
                                                    <div className="col-md-6 col-6 text-end">
                                                        <RText placeholder={t('sell.amount_ship')}
                                                            inputClass="form-control  text-end"
                                                            inputId="amount_ship" inputName="amount_ship"
                                                            value={Libs.formatNum(Libs.convertStringToNumber(curItem.amount_ship), '#.###.##')}
                                                            pattern="^[0-9.]*$"
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={10} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sum">


                                                <div className="item-line">
                                                    <div className="row">
                                                        <div className="col-md-6 col-6"><p>{t('sell.total_order')}</p></div>
                                                        <div className="col-md-6 col-6 text-end"><p><strong>{Libs.formatNum(totalOrder, '#.###')} vnđ</strong></p></div>
                                                    </div>
                                                </div>

                                                <div className="item-line">
                                                    <div className="row">
                                                        <div className="col-md-6 col-6"><p>{t('sell.amount_received')}</p></div>
                                                        <div className="col-md-6 col-6">
                                                            <p>
                                                                <RText placeholder={t('sell.amount_received')}
                                                                    inputClass="form-control  text-end"
                                                                    inputId="amount_received" inputName="amount_received"
                                                                    value={Libs.formatNum(Libs.convertStringToNumber(curItem.amount_received), '#.###.##')}
                                                                    onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                                    pattern="^[0-9.]*$"
                                                                    disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                                    maxLength={20} />

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="item-line">
                                                    <div className="row">
                                                        <div className="col-md-6 col-6"><p>{t('sell.amount_received_title')}</p></div>
                                                        <div className="col-md-6 col-6 text-end"><p><strong>{Libs.formatNum(TotalAmountReceived, '#.###')} vnđ</strong></p></div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>


                                        <div className="tax-code-info">
                                            <h3>{t('sell.export_vat')}</h3>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <RText label={t('sell.company_name')}
                                                            inputClass="form-control"
                                                            inputId="billing_company_name"
                                                            inputName="billing_company_name"
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={curItem.billing_company_name}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={200} />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <RText label={t('sell.tax_code')}
                                                            inputClass="form-control"
                                                            inputId="billing_tax_code"
                                                            inputName="billing_tax_code"
                                                            value={curItem.billing_tax_code}
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={200} />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <RText label={t('sell.address')}
                                                            inputClass="form-control"
                                                            inputId="billing_address"
                                                            inputName="billing_address"
                                                            value={curItem.billing_address}
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={200} />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <RText label={t('sell.phone')}
                                                            inputClass="form-control"
                                                            inputId="billing_phone"
                                                            inputName="billing_phone"
                                                            pattern="^[0-9-+()._ ]*$"
                                                            disabled={curItem.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                                                            value={curItem.billing_phone}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={20} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>






            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>{t('common.cancel')}</button>
                {(curItem.screen_mode !== Constants.SCREEN_MODE.VIEW) ?
                    <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                        {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                    </button>
                    : ""}
            </div>
        </Modal>

    )
}