import BaseValidate from '../../../../../validator/BaseValidate';
class AddRowValidate extends BaseValidate{
    constructor(props){
        super();
        var {t} = props;
        this.trans = t;
    }
    setRule(){
        this.addRuleForField('quantity_check', 'trim', true);
        this.addRuleForField('quantity_check', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('quantity_check', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));
    }
    setAlias(){
        this.v.setAlias({
            quantity_check: this.trans('INVENTORY_STOCK.quantity_check')
        });
        
    }
}
export default AddRowValidate;