import React from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer';
import SidebarProduct from '../common/SidebarProduct/SidebarProduct';

const MainModuleProduct = ({ children }) => {
  return <div>
    <Header />
    <SidebarProduct />
    <div id="main">
      {children}
    </div>
    <Footer />
  </div>
};

export default MainModuleProduct;