import PopupJsx from './Popup.jsx';
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import { withTranslation } from 'react-i18next';
import Constants from '../../../../../utils/Constants';
import PopupService from '../../../../../services/PopupService';

class Popup extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = PopupJsx;
        this.state = {
            curItem: {},
            dataList: [],
            searchParam: {
                limit: Constants.COMMON.LIMIT,
                offset: 0,
                index: 1,
            },
            add: false,
            allLanguage: Libs.isBlank(this.employee) ? [] : this.employee.languages
        };
    }

    componentDidMount() {
        this.getList();
    }

    /**
     * get list
     * @author Long.Pham 2019-06-03
     */
    getList() {
        let self = this;
        let params = this.state.searchParam;
        params.id_language = this.employee.id_language;
        params.id_company = this.employee.id_company;
        PopupService.instance.getList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataList: data
                });
            } else {
                self.setState({
                    dataList: [],
                    total_row: 0
                });
            }
            self.forceUpdate();
        });
    }


    onClickAdd = () => {
        let curItem = {};
        let data = [];
        var allLanguage = this.state.allLanguage;
        curItem.screen_mode = Constants.SCREEN_MODE.ADD;
        curItem.tabActive = '';
        allLanguage.map((language, index) => {
            if (language.is_default === 1) { curItem.tabActive = language.is_default === 1 ? language.iso_code : ''; }
            let item = {
                is_default: language.is_default,
                iso_code: language.iso_code,
                id_language: language.id,
                name: "",
                description: ""
            };
            return data.push(item);
        });

        curItem.data = data;
        this.setState({
            curItem: curItem,
            add: true
        });

    };

    onClickCloseAdd = (status) => {
        if (status) {
            this.getList();
        }
        this.setState({
            add: false
        })
    }

    /**
    * Func filter table
    * @author Long.Pham 12-05-2021
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sort_column = sortKey;
        this.state.searchParam.order_by = (this.state.searchParam.order_by === '' || this.state.searchParam.order_by === 'asc') ? 'desc' : 'asc';
        this.getList();
    }

    /**
     * @description Item click event
     * @author Long.Pham 12-05-2021
     * @param index element in the list
     */
    onItemClick = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index], self = this;

        var params = this.state.curItem;
        params.id_company = item.id_company;
        params.id = item.id;

        PopupService.instance.getDetail(params, data => {
            if (data) {
                item.data = data.data;
                item.screen_mode = Constants.SCREEN_MODE.EDIT;
                self.setState({
                    curItem: item,
                    add: true
                });
            }
        }, false);
    }    

    render() {
        return this.jsxTemplate.call(this)
    }
}

const HighOrderComponentTranslated = withTranslation('common')(Popup)
export default HighOrderComponentTranslated;