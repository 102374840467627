import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Libs from '../../../../../utils/Libs';
import RowItemProduct from './RowItemProduct';
import Auth from '../../../../../utils/Auth';
import Constants from '../../../../../utils/Constants';

class RowItem extends Component {
    onItemClick = () => {
        if (!this.props.onItemClick || typeof this.props.onItemClick !== 'function') return;
        this.props.onItemClick(this.props.index);
    }

    onItemClickDelete = () => {
        if (!this.props.onItemClickDelete || typeof this.props.onItemClickDelete !== 'function') return;
        this.props.onItemClickDelete(this.props.index);
    }

    onItemClickView = () => {
        if (!this.props.onItemClickView || typeof this.props.onItemClickView !== 'function') return;
        this.props.onItemClickView(this.props.index);
    }

    /**
     * @description Item click event delete
     * @author long.pham 27-07-2019
     */
     onItemClickPrint = () => {
        if (!this.props.onItemClickPrint || typeof this.props.onItemClickPrint !== 'function') return;
        this.props.onItemClickPrint(this.props.index);
    }
    

    render() {
        var item = this.props.dataItem;
        var saleoff = 0;
        if(!Libs.isBlank(item.manual_discount_value)){
            saleoff = saleoff + item.manual_discount_value;
        }

        if(!Libs.isBlank(item.coupon_value)){
            saleoff = saleoff + item.coupon_value;
        }

        const { t } = this.props;
        var RowItems = null;
        if (Libs.isArrayData(item.dataProducts)) {
            var dataProducts = item.dataProducts;
            RowItems = dataProducts.map((item, index) => {
                return <RowItemProduct dataItem={item} key={'row_item_' + index} index={index} />
            })
        }
        return (
            <div className="body-row" key={item.id}>
                <ReactTooltip />
                <div className="body-col width15">
                    <p className="id">{item.id} </p>
                    <p><span className="receive">{item.order_status_name}</span></p>
                    {!Libs.isBlank(item.customer_name) ? <p>KH: {item.customer_name} </p> : ""}

                    {!Libs.isBlank(item.phone) ? <p>ĐT: {item.phone} </p> : ""}



                </div>
                <div className="body-col width15">
                    <p>{item.employee_name} </p>
                    <p>{item.created_date} </p>
                </div>
                <div className="body-col width35">
                    {!Libs.isBlank(RowItems) ?
                        <div className="sub-table">
                            <div className="thead">
                                <div className="th-row">
                                    <div className="th-col width50">Tên</div>
                                    <div className="th-col width30 text-end">Giá</div>
                                    <div className="th-col width20 text-end">SL</div>
                                </div>
                            </div>
                            <div className="tbody">
                                {RowItems}

                            </div>
                        </div>
                        : ""}


                </div>

                <div className="body-col width10 text-end">
                    {saleoff > 0 ? 
                    <p>{Libs.formatNum(saleoff, '#.###.##')} vnđ</p> : ""}

                    {/* <p className="percent">({item.saleoff_percent}%)</p> */}
                </div>

                <div className="body-col width15 text-end">
                    <p><strong>{Libs.formatNum(item.total, '#.###.##')} vnđ</strong></p>
                    {!Libs.isBlank(item.payment_status_name) ? <p><span className="success">{item.payment_status_name}</span></p> : ""}
                    {!Libs.isBlank(item.payment_method_name) ? <p><span className="payment">{item.payment_method_name}</span></p> : ""}

                </div>
                <div className="body-col width10">
                    <div className="functions">
                        <ul>
                            <li className={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.EDIT) ? '' : 'disabled'} onClick={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.EDIT) ? this.onItemClick.bind(this) : ''} data-tip={t('common.edit')} data-type="warning" data-class="tooltip-action"><var className="icon-pencil-square-o"></var></li>
                            <li className={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.PRINT) ? '' : 'disabled'} onClick={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.PRINT) ? this.onItemClickPrint.bind(this) : ''} data-tip={t('common.print')} data-type="warning" data-class="tooltip-action"><var className="icon-print"></var></li>
                            <li className={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.VIEW) ? '' : 'disabled'} onClick={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.VIEW) ? this.onItemClickView.bind(this) : ''} data-tip={t('common.view')} data-type="warning" data-class="tooltip-action"><var className="icon-eye"></var></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default RowItem;
