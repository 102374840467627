import React from 'react';
import { RText, RTextArea } from '../../../../../components/Controls';
import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import Modal from 'react-bootstrap4-modal';
import RowItemProductAdd from './RowItemProductAdd';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';

export default function AddPopup() {

    var { curItem, dataCategories, dataStores, dataTypeOrders, manualDiscountType, dataPaymentMethod, dataProvince, dataDistrict, dataWard, dataGender, dataLevel, dataGroup, dataProducts } = this.state;
    const { t } = this.props;
    var RowsItemProductAdds = null;
    if (Libs.isArrayData(dataProducts)) {
        RowsItemProductAdds = dataProducts.map((item, index) => {
            return <RowItemProductAdd
                key={'row_item_' + index}
                index={index}
                dataItem={item}
                onItemClickDelete={this.onItemClickDelete}
                t={t}
            />
        })
    }
    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-dialog-scrollable modal-xl add-order full-screen" >
            <div className="modal-header">
                <h5 className="modal-title">{t('return_order.add_title')}</h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">


                <div className="main-order">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-12">

                            <div className="main-box-info">
                                <div className="box-info">
                                    <h2>{t('common.info')}</h2>
                                    <div className="box-info-content">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                                <div className="mb-3">
                                                    <FormReactSelect
                                                        label={t('return_order.id_store')}
                                                        instanceId="id_store"
                                                        className="id_store"
                                                        name="id_store"
                                                        value={dataStores.filter(function (item) { return item.id === curItem.id_store })}
                                                        onChange={(e) => { this.handleDropdownChange(e, 'id_store') }}
                                                        optionList={dataStores}
                                                        placeHolder={t('common.choose')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                                <div className="mb-3">
                                                    <FormReactSelect
                                                        label={t('return_order.id_type_order')}
                                                        instanceId="id_type_order"
                                                        className="id_type_order"
                                                        name="id_type_order"
                                                        value={dataTypeOrders.filter(function (item) { return item.id === curItem.id_type_order })}
                                                        onChange={(e) => { this.handleDropdownChange(e, 'id_type_order') }}
                                                        optionList={dataTypeOrders}
                                                        placeHolder={t('common.choose')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                                <div className="mb-3">
                                                    <RText label={t('return_order.staff_sale')}
                                                        inputClass="form-control"
                                                        inputId={"staff_sale"}
                                                        inputName={"staff_sale"}
                                                        value={curItem.staff_sale}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <div className="mb-3">
                                                    <RTextArea label={t('return_order.note')}
                                                        inputClass="form-control"
                                                        inputId={"note_order"}
                                                        inputName={"note_order"}
                                                        value={curItem.note_order}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="main-box-info">
                                <div className="box-info">
                                    <h2>{t('return_order.customer_title')}</h2>
                                    <div className="box-info-content">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-phone"></var></span>
                                                    <RText placeholder={t('return_order.customer_phone')}
                                                        inputClass="form-control"
                                                        inputId={"customer_phone"}
                                                        inputName={"customer_phone"}
                                                        value={curItem.customer_phone}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-man-people-streamline-user"></var></span>
                                                    <RText placeholder={t('return_order.customer_name')}
                                                        inputClass="form-control"
                                                        inputId={"customer_name"}
                                                        inputName={"customer_name"}
                                                        value={curItem.customer_name}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-email-mail-streamline"></var></span>
                                                    <RText placeholder={t('return_order.email')}
                                                        inputClass="form-control"
                                                        inputId={"email"}
                                                        inputName={"email"}
                                                        value={curItem.email}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-credit-card"></var></span>
                                                    <RText placeholder={t('return_order.card_number')}
                                                        inputClass="form-control"
                                                        inputId={"card_number"}
                                                        inputName={"card_number"}
                                                        value={curItem.card_number}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-location"></var></span>
                                                    <div className="dropdown-group">
                                                        <FormReactSelect
                                                            instanceId="id_province"
                                                            className="id_province"
                                                            name="id_province"
                                                            value={dataProvince.filter(function (item) { return item.id === curItem.id_province })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_province') }}
                                                            optionList={dataProvince}
                                                            placeHolder={t('return_order.id_province')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-location"></var></span>
                                                    <div className="dropdown-group">
                                                        <FormReactSelect
                                                            instanceId="id_district"
                                                            className="id_district"
                                                            name="id_district"
                                                            value={dataDistrict.filter(function (item) { return item.id === curItem.id_district })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_district') }}
                                                            optionList={dataDistrict}
                                                            placeHolder={t('return_order.id_district')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-location"></var></span>
                                                    <div className="dropdown-group">
                                                        <FormReactSelect
                                                            instanceId="id_ward"
                                                            className="id_ward"
                                                            name="id_ward"
                                                            value={dataWard.filter(function (item) { return item.id === curItem.id_ward })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_ward') }}
                                                            optionList={dataWard}
                                                            placeHolder={t('return_order.id_ward')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-home-house-streamline"></var></span>
                                                    <RText placeholder={t('return_order.address')}
                                                        inputClass="form-control"
                                                        inputId={"address"}
                                                        inputName={"address"}
                                                        value={curItem.address}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text"><var className="icon-facebook-square"></var></span>
                                                    <RText placeholder={t('return_order.facebook')}
                                                        inputClass="form-control"
                                                        inputId={"facebook"}
                                                        inputName={"facebook"}
                                                        value={curItem.facebook}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <FormReactSelect
                                                    instanceId="gender"
                                                    className="gender"
                                                    name="gender"
                                                    value={dataGender.filter(function (item) { return item.id === curItem.gender })}
                                                    onChange={(e) => { this.handleDropdownChange(e, 'gender') }}
                                                    optionList={dataGender}
                                                    placeHolder={t('return_order.gender')}
                                                />

                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <CMSDatePicker
                                                    placeholder={t("sell.birthday")}
                                                    inputId="birthday"
                                                    inputClass="form-control"
                                                    inputName="birthday"
                                                    maxLength={20}
                                                    value={curItem.import_date}
                                                    handleChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                    showIconCalendar={false}
                                                />
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <FormReactSelect
                                                    instanceId="customer_group"
                                                    className="customer_group"
                                                    name="customer_group"
                                                    value={dataGroup.filter(function (item) { return item.id === curItem.customer_group })}
                                                    onChange={(e) => { this.handleDropdownChange(e, 'customer_group') }}
                                                    optionList={dataGroup}
                                                    placeHolder={t('return_order.customer_group')}
                                                />
                                            </div>

                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <FormReactSelect
                                                    instanceId="customer_level"
                                                    className="customer_level"
                                                    name="customer_level"
                                                    value={dataLevel.filter(function (item) { return item.id === curItem.customer_level })}
                                                    onChange={(e) => { this.handleDropdownChange(e, 'customer_level') }}
                                                    optionList={dataLevel}
                                                    placeHolder={t('return_order.customer_level')}
                                                />
                                            </div>


                                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                <div className="mb-3">
                                                    <RText placeholder={t('return_order.note')}
                                                        inputClass="form-control"
                                                        inputId="note"
                                                        inputName="note"
                                                        value={curItem.note}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={200} />
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div className="box-search">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <FormReactSelect
                                            instanceId="id_category"
                                            className="id_category"
                                            name="id_category"
                                            value={dataCategories.filter(function (item) { return item.id === curItem.id_category })}
                                            onChange={(e) => { this.handleDropdownChange(e, 'id_category') }}
                                            optionList={dataCategories}
                                            placeHolder={t('return_order.categories')}
                                        />
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <RText placeholder={t('return_order.search_keyword')}
                                            inputClass="form-control"
                                            inputId={"name"}
                                            inputName={"name"}
                                            value={curItem.name}
                                            onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                            maxLength={100} />
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <RText placeholder={t('return_order.id_order')}
                                            inputClass="form-control"
                                            inputId={"id_order"}
                                            inputName={"id_order"}
                                            value={curItem.id_order}
                                            onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                            maxLength={100} />
                                    </div>

                                </div>
                            </div>

                            <div className="data-product">
                                <div className="header-row">
                                    <div className="header-col width5">STT</div>
                                    <div className="header-col width35">Sản phẩm</div>
                                    <div className="header-col width20 text-end">Giá</div>
                                    <div className="header-col width10 text-end">SL</div>
                                    <div className="header-col width20 text-end">Thành tiền</div>
                                    <div className="header-col width10 text-end">Thao tác</div>
                                </div>

                                <div className="body-product">
                                    {RowsItemProductAdds ? RowsItemProductAdds : <div className="data-empty">Chưa có sản phẩm trong đơn hàng</div>}
                                </div>
                            </div>
                            


                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <div className="main-box-info box-info-order">
                                <div className="box-info">
                                    <div className="box-info-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="input-group mb-3">
                                                    <RText placeholder={t('return_order.coupon')}
                                                        inputClass="form-control"
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        inputId="coupon" inputName="coupon"
                                                        value={curItem.coupon}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                        maxLength={200} />

                                                    <span className="input-group-text apply-coupon">
                                                        {t('return_order.apply')}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-md-12">

                                                <div className="tax-code-info">
                                                    <h3>{t('return_order.manual_discount')}</h3>
                                                    <div className="row">
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                            <FormReactSelect
                                                                instanceId="manual_discount_type"
                                                                className="manual_discount_type"
                                                                name="manual_discount_type"
                                                                value={manualDiscountType.filter(function (item) { return item.id === curItem.manual_discount_type })}
                                                                onChange={(e) => { this.handleDropdownChange(e, 'manual_discount_type') }}
                                                                optionList={manualDiscountType}
                                                                placeHolder={t('common.choose')}
                                                            />
                                                        </div>

                                                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                                            <div className="mb-3">
                                                                <RText
                                                                    inputClass="form-control"
                                                                    disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                                    inputId="manual_discount" inputName="manual_discount"
                                                                    value={curItem.manual_discount}
                                                                    onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                                    maxLength={500} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="tax-code-info">
                                            <h3>{t('return_order.payment')}</h3>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <RText label={t('return_order.vat')}
                                                            inputClass="form-control"
                                                            inputId="vat" inputName="vat"
                                                            value={curItem.vat}
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            maxLength={100} />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <FormReactSelect
                                                            label={t('return_order.payment_method')}
                                                            instanceId="payment_method"
                                                            required="required"
                                                            className="payment_method"
                                                            name="payment_method"
                                                            value={dataPaymentMethod.filter(function (item) { return item.id === curItem.payment_method })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'payment_method') }}
                                                            optionList={dataPaymentMethod}
                                                            placeHolder={t('common.choose')}
                                                        />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="summary-order">
                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>Tiền hàng</p></div>
                                                    <div className="col-md-6 col-6 text-end"><p><span>500,000 vnđ</span></p></div>
                                                </div>
                                            </div>

                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>Giảm giá</p></div>
                                                    <div className="col-md-6 col-6 text-end"><p><span>500,000 vnđ</span></p></div>
                                                </div>
                                            </div>


                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>Chiết khấu</p></div>
                                                    <div className="col-md-6 col-6 text-end"><p><span>500,000 vnđ</span></p></div>
                                                </div>
                                            </div>

                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>VAT</p></div>
                                                    <div className="col-md-6 col-6 text-end"><p><span>500,000 vnđ</span></p></div>
                                                </div>
                                            </div>

                                            <div className="summary-item">
                                                <div className="row">
                                                    <div className="col-md-6 col-6"><p>Phí ship</p></div>
                                                    <div className="col-md-6 col-6 text-end">
                                                        <RText placeholder={t('return_order.amount_ship')}
                                                            inputClass="form-control  text-end"
                                                            inputId="amount_ship" inputName="amount_ship"
                                                            // value={curItem.amount_ship}
                                                            value="0"
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={200} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sum">
                                                <div className="item-line">
                                                    <div className="row">
                                                        <div className="col-md-6 col-6"><p>Số tiền trả khách</p></div>
                                                        <div className="col-md-6 col-6">
                                                            <p>
                                                                <RText placeholder={t('return_order.amount_received')}
                                                                    inputClass="form-control  text-end"
                                                                    inputId="amount_received" inputName="amount_received"
                                                                    // value={curItem.amount_received}
                                                                    value="0"
                                                                    onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                                    maxLength={200} />

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                

                                            </div>

                                        </div>


                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>






            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this)}>{t('common.cancel')}</button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}> {t('common.create')} </button>
            </div>
        </Modal>

    )
}