import BaseValidate from '../../../../../validator/BaseValidate';
class AddRowValidate extends BaseValidate{
    constructor(props){
        super();
        var  {t} = props;
        this.trans = t;
    }
    setRule(){
        this.addRuleForField('price', 'trim', true);
        this.addRuleForField('price', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('price', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));
    }
    setAlias(){
        this.v.setAlias({
            price: this.trans('EXPORT_STOCK.price')
        });
        
    }
}
export default AddRowValidate;