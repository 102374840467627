
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import ListFieldPopupJsx from './ListFieldPopup.jsx';
import ListFieldPopupValidate from './ListFieldPopupValidate';
import SpecificationService from '../../../../../services/SpecificationService';

class ListFieldPopup extends BaseComponent {
    constructor(props) {
        super(props);
        var { t } = props;
        this.state = {
            curItem: this.props.curItem,
            curItemAddField: {},
            allLanguage: this.props.allLanguage,
            addField: false,
            dataList: [],
            searchParam: {
                limit: 100,
                offset: 0,
                index: 1,
            },
            
            dataType: [
                {
                    id: "product",
                    value: "product",
                    label: t('custom_field.product')
                },
                {
                    id: "page",
                    value: "page",
                    label: t('custom_field.page')
                },
                {
                    id: "new",
                    value: "new",
                    label: t('custom_field.new')
                }
            ]
        }

        this.jsxTemplate = ListFieldPopupJsx;
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
        this.getList();
    }

    /**
     * get list
     * @author Long.Pham 2019-06-03
     */
     getList() {
        let self = this;
        let params = this.state.searchParam, curItem = this.props.curItem;
        params.id_language = this.employee.id_language;
        params.id_company = this.employee.id_company;
        params.id = curItem.id;

        SpecificationService.instance.getList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataList: data
                });

                var total = parseInt(total_row / self.state.searchParam.limit);
                if (total_row % self.state.searchParam.limit > 0) {
                    total = total + 1;
                }

            } else {
                self.setState({
                    dataList: [],
                    total_row: 0
                });
            }
            self.forceUpdate();
        });
    }

    onClickAddField = () => {
        let curItemAddField = {};
        let data = [];
        var allLanguage = this.state.allLanguage;
        curItemAddField.screen_mode = Constants.SCREEN_MODE.ADD;
        curItemAddField.tabActive = '';
        allLanguage.map((language, index) => {
            if (language.is_default === 1) { curItemAddField.tabActive = language.is_default === 1 ? language.iso_code : ''; }
            let item = {
                is_default: language.is_default,
                iso_code: language.iso_code,
                id_language: language.id,
                field_label: ""
            };
            return data.push(item);
        });

        curItemAddField.data = data;
        this.setState({
            curItemAddField: curItemAddField,
            addField: true
        },() => {
            document.body.classList.add('modal-open-field');
        });

    };


    onClickCloseAddField = (status) => {
        if (status) {
            this.getList();
        }
        this.setState({
            addField: false
        }, () => {
            document.body.classList.remove('modal-open-field');
        })
    }


    /**
   * @description Item click event delete
   * @author Long.Pham 12-05-2021
   * @param index Order element in the list
   */
     onItemClickDelete = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var params = this.state.dataList[index], self = this;
        params.status = -1;
        params.updated_by = this.employee.first_name + ' ' + this.employee.last_name;
        SpecificationService.instance.delete(params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.getList();
            }
            else if (data) {
                self.setValidateMessage(data);
            }
            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }


    /**
     * @description Item click event
     * @author Long.Pham 12-05-2021
     * @param index element in the list
     */
     onItemClick = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index], self = this;

        var params = this.state.curItemAddField;
        params.id_company = item.id_company;
        params.id = item.id;

        SpecificationService.instance.getDetail(params, data => {
            if (data) {
                item.data = data.data;
                item.screen_mode = Constants.SCREEN_MODE.EDIT;
                let curTransItem = Libs.find(data.data,'is_default', 1);
                if(Libs.isObjectEmpty(curTransItem)){ return false; }
                item["field_label_"+curTransItem.iso_code] = curTransItem.field_label;

                if(Libs.isArrayData(data.data)){
                    var dataLang = data.data;
                    dataLang.map((row, index) => {
                        if(row.is_default === 1){
                            item.tabActive = row.iso_code;
                        }
                        return 1;
                    });
                }

                self.setState({
                    curItemAddField: item,
                    addField: true
                }, () => {
                    document.body.classList.add('modal-open-field');
                });
            }
        }, false);
    }
}
export default ListFieldPopup;