import React from 'react';
import { RText, RTextArea, RSwitch } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSEditor from '../../../../../components/CMSEditor';

import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';


export default function AddPopup() {

    var { curItem, allLanguage, datacategory } = this.state;
    const { t } = this.props;
    var tabNavLanguage, tabContent = null;

    console.log(curItem);
    if (allLanguage) {
        tabNavLanguage = allLanguage.map((language, index) => {
            return (<li key={index} className="nav-item">
                <span onClick={(e) => { this.onClickShowTab(e, language.iso_code) }} className={curItem.tabActive === language.iso_code ? "nav-link active" : "nav-link"} id={"name-tab-" + language.iso_code} >{language.name}</span>
            </li>);
        });

        tabContent = allLanguage.map((language, index) => {
            return (
                <div key={index} className={curItem.tabActive === language.iso_code ? "tab-pane fade show active" : "tab-pane fade"} id={"name-" + language.iso_code} role="tabpanel" aria-labelledby="home-tab">
                    <div className="info">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('category.name')}
                                        inputClass="form-control"
                                        required={language.is_default === 1 ? "required" : ""}
                                        inputId={"name_" + language.iso_code}
                                        inputName={"name_" + language.iso_code}
                                        value={curItem.data[index].name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('category.slug')}
                                        inputClass="form-control"
                                        inputId={"slug_" + language.iso_code}
                                        inputName={"slug_" + language.iso_code}
                                        value={curItem.data[index].slug}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                <div className="mb-3">
                                    <div className={"description_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('category.description')}
                                            id={"description_" + language.iso_code}
                                            name={"description_" + language.iso_code}
                                            height={250}
                                            content={curItem.data[index].description}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this, 'description_' + language.iso_code, language.iso_code)}
                                            mode="basic"
                                        />

                                    </div>
                                </div>

                            </div>


                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className="upload-icon">
                                        <label>{t('category.icon_title')}</label>
                                        <p className="alert alert-warning alert-gallery">{t('category.icon_note')}</p>
                                        <ul className="row">
                                            <li className="col-md-12 col-12">
                                                <div className="item-add-icon">
                                                    <input onChange={(event) => this.onFileIconChange(event, language.iso_code)} type="file" id={"upload_icon_" + language.iso_code} name={"upload_icon_" + language.iso_code} />
                                                    {!curItem.data[index].icon_upload && Libs.isBlank(curItem.data[index].icon) ?
                                                        <div className="add-icon">
                                                            <label htmlFor={"upload_icon_" + language.iso_code} className="upload-icon">
                                                                <i className="icon-picture-o"></i> <span>{t('common.upload_image')}</span>
                                                            </label>
                                                        </div>
                                                        : ""}

                                                    {!Libs.isBlank(curItem.data[index].icon_upload) ?
                                                        <div className="view-icon">
                                                            <img alt="Upload" src={curItem.data[index].icon_upload} />
                                                            <div className="icon-action">
                                                                <label className="edit" htmlFor={"upload_icon_" + language.iso_code}><var className="icon-pencil-square-o"></var></label>
                                                                <span className="delete" onClick={(e) => this.onClickDeleteIcon(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                            </div>
                                                        </div>
                                                        : 
                                                        (!Libs.isBlank(curItem.data[index].icon_full) ?
                                                            <div className="view-icon">
                                                                <img alt="Upload" src={curItem.data[index].icon_full} />
                                                                <div className="icon-action">
                                                                    <label className="edit" htmlFor= {"upload_icon_" + language.iso_code} ><var className="icon-pencil-square-o"></var></label>
                                                                    <span className="delete" onClick={(e) => this.onClickDeleteIcon(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                                </div>
                                                            </div>
                                                            : "")
                                                            }

                                                </div>
                                            </li>
                                        </ul>
                                        {!Libs.isBlank(curItem.data[index].icon_message) ?
                                            <div className="alert alert-danger">
                                                {curItem.data[index].icon_message}
                                            </div>
                                            : ''}

                                    </div>
                                </div>
                            </div>


                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className="upload-banner-desktop">
                                        <label>{t('category.banner_desktop_title')}</label>
                                        <p className="alert alert-warning alert-gallery">{t('category.banner_desktop_note')}</p>
                                        <ul className="row">
                                            <li className="col-md-12 col-12">
                                                <div className="item-add-banner-desktop">
                                                    <input onChange={(event) => this.onFileBannerDesktopChange(event, language.iso_code)} type="file" id={"upload_banner_desktop_" + language.iso_code} name={"upload_banner_" + language.iso_code} />
                                                    {!curItem.data[index].banner_desktop_upload && Libs.isBlank(curItem.data[index].banner_desktop) ?
                                                        <div className="add-banner-desktop">
                                                            <label htmlFor={"upload_banner_desktop_" + language.iso_code} className="upload-banner-desktop">
                                                                <i className="icon-picture-o"></i> <span>{t('common.upload_image')}</span>
                                                            </label>
                                                        </div>
                                                        : ""}

                                                    {!Libs.isBlank(curItem.data[index].banner_desktop_upload) ?
                                                        <div className="view-banner-desktop">
                                                            <img alt="Upload" src={curItem.data[index].banner_desktop_upload} />
                                                            <div className="banner-desktop-action">
                                                                <label className="edit" htmlFor={"upload_banner_desktop_" + language.iso_code}><var className="icon-pencil-square-o"></var></label>
                                                                <span className="delete" onClick={(e) => this.onClickDeleteBannerDesktop(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                            </div>
                                                        </div>
                                                        : 
                                                        (!Libs.isBlank(curItem.data[index].banner_desktop_full) ?
                                                            <div className="view-banner-desktop">
                                                                <img alt="Upload" src={curItem.data[index].banner_desktop_full} />
                                                                <div className="banner-desktop-action">
                                                                    <label className="edit" htmlFor= {"upload_banner_desktop_" + language.iso_code} ><var className="icon-pencil-square-o"></var></label>
                                                                    <span className="delete" onClick={(e) => this.onClickDeleteBannerDesktop(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                                </div>
                                                            </div>
                                                            : "")

                                                        }

                                                </div>
                                            </li>
                                        </ul>
                                        {!Libs.isBlank(curItem.data[index].banner_desktop_message) ?
                                            <div className="alert alert-danger">
                                                {curItem.data[index].banner_desktop_message}
                                            </div>
                                            : ''}

                                    </div>
                                </div>
                            </div>


                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <div className="upload-banner-mobile">
                                        <label>{t('category.banner_mobile_title')}</label>
                                        <p className="alert alert-warning alert-gallery">{t('category.banner_mobile_note')}</p>
                                        <ul className="row">
                                            <li className="col-md-12 col-12">
                                                <div className="item-add-banner-mobile">
                                                    <input onChange={(event) => this.onFileBannerMobileChange(event, language.iso_code)} type="file" id={"upload_banner_mobile_" + language.iso_code} name={"upload_banner_mobile_" + language.iso_code} />
                                                    {!curItem.data[index].banner_mobile_upload  && Libs.isBlank(curItem.data[index].banner_mobile) ?
                                                        <div className="add-banner-mobile">
                                                            <label htmlFor={"upload_banner_mobile_" + language.iso_code} className="upload-banner-mobile">
                                                                <i className="icon-picture-o"></i> <span>{t('common.upload_image')}</span>
                                                            </label>
                                                        </div>
                                                        : ""}

                                                    {!Libs.isBlank(curItem.data[index].banner_mobile_upload) ?
                                                        <div className="view-banner-mobile">
                                                            <img alt="Upload" src={curItem.data[index].banner_mobile_upload} />
                                                            <div className="banner-mobile-action">
                                                                <label className="edit" htmlFor={"upload_banner_mobile_" + language.iso_code}><var className="icon-pencil-square-o"></var></label>
                                                                <span className="delete" onClick={(e) => this.onClickDeleteBannerMobile(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                            </div>
                                                        </div>
                                                        : 
                                                        (!Libs.isBlank(curItem.data[index].banner_mobile_full) ?
                                                            <div className="view-banner-mobile">
                                                                <img alt="Upload" src={curItem.data[index].banner_mobile_full} />
                                                                <div className="banner-mobile-action">
                                                                    <label className="edit" htmlFor= {"upload_banner_mobile_" + language.iso_code} ><var className="icon-pencil-square-o"></var></label>
                                                                    <span className="delete" onClick={(e) => this.onClickDeleteBannerMobile(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                                </div>
                                                            </div>
                                                            : "")
                                                        }

                                                </div>
                                            </li>
                                        </ul>
                                        {!Libs.isBlank(curItem.data[index].banner_mobile_message) ?
                                            <div className="alert alert-danger">
                                                {curItem.data[index].banner_mobile_message}
                                            </div>
                                            : ''}

                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                    <div className="meta">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="main-meta">
                                    <h2>SEO</h2>
                                    <div className="meta-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_title')}
                                                        inputClass="form-control"
                                                        inputId={"meta_title_" + language.iso_code}
                                                        inputName={"meta_title_" + language.iso_code}
                                                        value={curItem.data[index].meta_title}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_keyword')}
                                                        inputClass="form-control"
                                                        inputId={"meta_keyword_" + language.iso_code}
                                                        inputName={"meta_keyword_" + language.iso_code}
                                                        value={curItem.data[index].meta_keyword}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RTextArea label={t('common.meta_description')}
                                                        rows="3"
                                                        inputClass="form-control"
                                                        inputId={"meta_description_" + language.iso_code}
                                                        inputName={"meta_description_" + language.iso_code}
                                                        value={curItem.data[index].meta_description}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        });
    }


    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('category.edit_title') : t('category.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">
                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        label={t('category.parent')}
                                        instanceId="parent"
                                        className="parent"
                                        name="parent"
                                        isClearable = {true}
                                        value={datacategory.filter(function (item) { return item.id === curItem.parent })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'parent') }}
                                        optionList={datacategory}
                                        placeHolder={t('category.category_choose')}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                <div className="mb-3">
                                    <RText label={t('category.menu_order')}
                                        inputClass="form-control"
                                        inputId= "menu_order"
                                        inputName= "menu_order"
                                        value={curItem.menu_order}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        pattern="^[0-9]*$"
                                        maxLength={4} />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                <div className="mb-3">
                                    <p className="control-label">{t('category.is_online')}</p>
                                    <div className="checkmark">
                                        <RSwitch
                                            inputId="is_online"
                                            inputName="is_online"
                                            checked={curItem.is_online}
                                            onChange={(e) => { this.handleInputChange(e) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="box-translate">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-main">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {tabNavLanguage}
                                </ul>
                                <div className="tab-content" id="tabContent">
                                    {tabContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>

    )
}