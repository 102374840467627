import React, { Component } from 'react';
// import Libs from '../../../utils/Libs';
import { RSwitch } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import ReactTooltip from 'react-tooltip';
class RowItem extends Component {
    constructor(props) {
        super(props);
    }
    /**
     * @description Status change event
     * @author long.pham 27-07-2019
     */
    onStatusChange = () => {
        if (!this.props.onStatusChange || typeof this.props.onStatusChange !== 'function') return;
        this.props.onStatusChange(this.props.index);
        return;
    }

    showActionEvent = () => {
        if (!this.props.showActionEvent || typeof this.props.showActionEvent !== 'function') return;
        this.props.showActionEvent(this.props.index);
        return;
    }

    /**
     * @description Item click event
     * @author long.pham 27-07-2019
     */
    onItemClick = () => {
        if (!this.props.onItemClick || typeof this.props.onItemClick !== 'function') return;
        this.props.onItemClick(this.props.index);
    }

    /**
     * @description Item click event delete
     * @author long.pham 27-07-2019
     */
    onItemClickDelete = () => {
        if (!this.props.onItemClickDelete || typeof this.props.onItemClickDelete !== 'function') return;
        this.props.onItemClickDelete(this.props.index);
    }


    /**
     * @description Item click event delete
     * @author long.pham 27-07-2019
     */
    onItemClickDetail = () => {
        if (!this.props.onItemClickDetail || typeof this.props.onItemClickDetail !== 'function') return;
        this.props.onItemClickDetail(this.props.index);
    }



    render() {
        var item = this.props.dataItem;
        var {t} = this.props;
        return (
            <div className="body-row" key = {item.id}>
                <ReactTooltip />
                <div className="body-col width10"><p>{item.date} </p></div>
                <div className="body-col width15"><p>{item.id_code} </p></div>
                <div className="body-col width15"><p>{item.total}</p></div>
                <div className="body-col width20"><p>{item.transfer_person} </p></div>
                <div className="body-col width25"><p>{item.description}</p></div>
                <div className="body-col width15">
                    <div className="functions">
                        <ul>
                            <li onClick = {this.onItemClick.bind(this)} data-tip= {t('common.edit')} data-type="warning" data-class="tooltip-action"><var className="icon-pencil-square-o" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDelete.bind(this)} data-tip= {t('common.delete')} data-type="warning" data-class="tooltip-action"><var className="icon-trash-o" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDelete.bind(this)} data-tip= {t('common.view')} data-type="warning" data-class="tooltip-action"><var className="icon-eye" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDelete.bind(this)} data-tip= {t('common.excel')} data-type="warning" data-class="tooltip-action"><var className="icon-page-export-csv" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDelete.bind(this)} data-tip= {t('common.pdf')} data-type="warning" data-class="tooltip-action"><var className="icon-file-text-o" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDelete.bind(this)} data-tip= {t('common.print')} data-type="warning" data-class="tooltip-action"><var className="icon-print" aria-hidden="true"></var></li>
                        </ul>
                    </div>
                </div>
            </div>

            // <div className={item.is_delete == 1 ? "body-item is_delete" : "body-item"} key={item.id}>
            //     <div className="body-col width10"><p>{Libs.dateFormat(item.date, "DD-MM-YYYY")}</p></div>
            //     <div className="body-col width10"><p>{item.id_code}</p></div>
            //     <div className="body-col width15"><p>{item.total}</p></div>
               
                
            //     <div className="body-col width15"><p>{item.transfer_person}</p></div>
            //     <div className="body-col width40"><p>{item.description}</p></div>
            //     <div className="body-col width10">
            //         <div className="function">
            //             <p onClick={() => this.props.setActiveElement(this.props.index)}><i className="fa fa-ellipsis-h" aria-hidden="true"></i></p>
            //             <ul className={this.props.index === this.props.activeId ? "on" : ""}>
            //                 {item.is_delete != 1 ?
            //                     <li><a onClick={this.onItemClick.bind(this)} ><var className="fa fa-pencil" aria-hidden="true"></var> Sửa</a></li>
            //                     : ""}
            //                 {item.is_delete != 1 ?
            //                     <li><a onClick={this.onItemClickDelete.bind(this)} ><var className="fa fa-trash-o" aria-hidden="true"></var> Xoá</a></li>
            //                     : ""}

            //                 <li><a onClick={this.onItemClickDetail.bind(this)}><var className="fa fa-eye" aria-hidden="true"></var> Chi tiết</a></li>
            //                 <li><a onClick={this.onItemClickDetail.bind(this)}><var className="fa fa-file-pdf-o" aria-hidden="true"></var> Xuất PDF</a></li>
            //                 <li><a onClick={this.onItemClickDetail.bind(this)}><var className="fa fa-file-excel-o" aria-hidden="true"></var> Xuất Excel</a></li>
            //             </ul>
            //         </div>

            //     </div>
            // </div>
        );
    }
}
export default RowItem;
