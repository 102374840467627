import React from 'react';
import { RText, RButton } from '../../../../../components/Controls';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import FormReactSelect from '../../../../../components/FormReactSelect';
// import FLSelect from '../../../../component/FLSelect';
import Libs from '../../../../../utils/Libs';
import { Paging } from '../../../../../components/Paging';
import RowItem from './RowItem';
import SummaryStorePopup from './SummaryStorePopup';

export default function SummaryStore() {
  var { t } = this.props;
  var { formSearch, dataList, dataStores, searchParam, curItem, allStores, allCategory, activeId } = this.state;

  // add list row 
  var RowItems = null;
  if (Libs.isArrayData(dataList)) {
    RowItems = dataList.map((item, index) => {
      return <RowItem
        key={'row_item_' + index}
        index={index}
        dataItem={item}
        activeId={activeId}
        showActionEvent={this.showActionEvent}
        setActiveElement={this.setActiveElement}
        onItemClickDetail={this.onItemClickDetail}
        t={t}
      />
    });
  }

  var summaryStorePopupLayout = this.state.showSummaryStorePopup
    ? <SummaryStorePopup
      onCloseSummaryStorePopup={this.onCloseSummaryStorePopup.bind(this)}
      auth={this.props.auth}
      curItem={curItem}
      searchParam={searchParam}
      t={t}
    /> : '';

  return (
    <React.Fragment>
      {summaryStorePopupLayout}
      <div className="summary" >
        <div className="page-breadcrumb">
          <div className="row">
            <div className="col-md-6"><h2>{t('summary_store.title')}</h2>
            </div>
            <div className="col-md-6">
              <ul>
                <li><div className="search" onClick={this.onSearch.bind(this)}><span className="icon icon-search"></span> {t('common.search')}</div></li>
              </ul>
            </div>
          </div>
        </div>


        {this.state.formSearch ?
          <div className="form-search">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="mb-3">
                  <FormReactSelect
                    label={t('summary_store.id_store')}
                    instanceId="id_store"
                    className="id_store"
                    name="id_store"
                    value={dataStores.filter(function (item) { return item.id === curItem.id_store })}
                    onChange={(e) => { this.handleDropdownChange(e, 'id_store') }}
                    optionList={dataStores}
                    placeHolder={t('common.choose')}
                  />
                </div>
              </div>


              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="mb-3">
                  <CMSDatePicker
                    label={t("summary_store.from_date")}
                    inputId="from_date"
                    inputClass="form-control"
                    inputName="from_date"
                    maxLength={10}
                    value={searchParam.from_date}
                    handleChange={this.handleSearchDateChange.bind(this)}
                  />
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="mb-3">
                  <CMSDatePicker
                    label={t("summary_store.to_date")}
                    inputId="to_date"
                    inputClass="form-control"
                    inputName="to_date"
                    maxLength={10}
                    value={searchParam.to_date}
                    maxDate={searchParam.max_date}
                    handleChange={this.handleSearchDateChange.bind(this)}
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                <div className="mb-3">
                  <button type="button" className="btn btn-search" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                </div>
              </div>

            </div>

          </div>
          : ""}




        <div className="data-view">
          <div className="main-header">
            <div className="header-row">
              <div onClick={e => this.onSort(e, 'export_date')} className={searchParam.sort_column === "export_date" ? "header-col width20 sorting " + searchParam.order_by : "header-col width20 sorting"}><p>{t('summary_store.product_name')}</p></div>
              <div onClick={e => this.onSort(e, 'total')} className={searchParam.sort_column === "partner_name" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('summary_store.quantity_store_first')}</p></div>
              <div onClick={e => this.onSort(e, 'total')} className={searchParam.sort_column === "total" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('summary_store.quantity_store_import')}</p></div>
              <div className="header-col width15"><p>{t('summary_store.quantity_store_export')}</p></div>
              <div className="header-col width15"><p>{t('summary_store.quantity_store_last')}</p></div>
              <div className="header-col width10 text-end"><p>{t('common.action')}</p></div>
            </div>
          </div>
          <div className="main-body">
            <div className="body">
              {RowItems ? RowItems : <div className="data-empty">{t('common.data_empty')}</div>}
            </div>
          </div>
        </div>

        <div className="main-paging">
          <Paging
            total={this.paging.total}
            current={this.paging.current}
            inputChangedHandler={this.inputChangedHandler}
            onBrandKeyPress={this.onBrandKeyPress}
            onInputPage={this.onInputPage}
            onClickReload={this.onClickReload}
            onSelectPage={(index) => this.onSelectPage.bind(this, index)}>
          </Paging>
        </div>
      </div>
    </React.Fragment>

  )
}