import React from 'react';
import { RText, RTextArea, RSwitch } from '../../../../../components/Controls';
import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';
import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import Libs from '../../../../../utils/Libs';

export default function AddPopup() {

    var { curItem, dataProvince, dataDistrict, dataWard, dataGender, dataCustomerType, dataCustomerGroup } = this.state;
    const { t } = this.props;

    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('customer.edit_title') : t('customer.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">

                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">

                        <div className="row">


                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        instanceId="id_customer_group"
                                        className="id_customer_group"
                                        name="id_customer_group"
                                        required="required"
                                        value={dataCustomerGroup.filter(function (item) { return item.id === curItem.id_customer_group })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'id_customer_group') }}
                                        optionList={dataCustomerGroup}
                                        label={t('customer.id_customer_group')}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        instanceId="id_customer_type"
                                        className="id_customer_type"
                                        name="id_customer_type"
                                        required="required"
                                        value={dataCustomerType.filter(function (item) { return item.id === curItem.id_customer_type })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'id_customer_type') }}
                                        optionList={dataCustomerType}
                                        label={t('customer.id_customer_type')}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <p className="control-label">{t('customer.status')}</p>
                                    <div className="checkmark">
                                        <RSwitch
                                            inputId="status"
                                            inputName="status"
                                            checked={curItem.status}
                                            onChange={(e) => { this.handleInputChange(e) }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('customer.first_name')}
                                        inputClass="form-control"
                                        required="required"
                                        inputId={"first_name"}
                                        inputName={"first_name"}
                                        value={curItem.first_name}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('customer.last_name')}
                                        inputClass="form-control"
                                        required="required"
                                        inputId={"last_name"}
                                        inputName={"last_name"}
                                        value={curItem.last_name}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('customer.phone')}
                                        inputClass="form-control"
                                        inputId={"phone"}
                                        inputName={"phone"}
                                        value={curItem.phone}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        pattern="^[0-9-+()._ ]*$"
                                        maxLength={20} />
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('customer.card_number')}
                                        inputClass="form-control"
                                        inputId={"card_number"}
                                        inputName={"card_number"}
                                        value={curItem.card_number}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <FormReactSelect
                                    instanceId="gender"
                                    className="gender"
                                    name="gender"
                                    value={dataGender.filter(function (item) { return item.id === curItem.gender })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'gender') }}
                                    optionList={dataGender}
                                    label={t('customer.gender')}
                                />

                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <CMSDatePicker
                                    label={t("customer.birthday")}
                                    inputId="birthday"
                                    inputClass="form-control"
                                    inputName="birthday"
                                    maxLength={20}
                                    value={curItem.birthday}
                                    handleChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                    showIconCalendar={false}
                                />
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        instanceId="id_province"
                                        className="id_province"
                                        name="id_province"
                                        value={dataProvince.filter(function (item) { return item.id === curItem.id_province })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'id_province') }}
                                        optionList={dataProvince}
                                        label={t('common.id_province')}
                                    />
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        instanceId="id_district"
                                        className="id_district"
                                        name="id_district"
                                        value={dataDistrict.filter(function (item) { return item.id === curItem.id_district })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'id_district') }}
                                        optionList={dataDistrict}
                                        label={t('common.id_district')}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        instanceId="id_ward"
                                        className="id_ward"
                                        name="id_ward"
                                        value={dataWard.filter(function (item) { return item.id === curItem.id_ward })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'id_ward') }}
                                        optionList={dataWard}
                                        label={t('common.id_ward')}
                                    />
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('common.address')}
                                        inputClass="form-control"
                                        inputId={"address"}
                                        inputName={"address"}
                                        value={curItem.address}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('common.facebook')}
                                        inputClass="form-control"
                                        inputId={"facebook"}
                                        inputName={"facebook"}
                                        value={curItem.facebook}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('customer.tax_code')}
                                        inputClass="form-control"
                                        inputId="tax_code"
                                        inputName="tax_code"
                                        value={curItem.tax_code}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>

                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('customer.company_name')}
                                        inputClass="form-control"
                                        inputId="company_name"
                                        inputName="company_name"
                                        value={curItem.company_name}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('customer.passport')}
                                        inputClass="form-control"
                                        inputId="passport"
                                        inputName="passport"
                                        value={curItem.passport}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="mb-3">
                                    <RTextArea label={t('common.note')}
                                        inputClass="form-control"
                                        inputId="note"
                                        inputName="note"
                                        value={curItem.note}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="box-info">
                    <h2>{t('customer.account_info')}</h2>
                    <div className="box-info-content">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('customer.email')}
                                        inputClass="form-control"
                                        inputId={"email"}
                                        required="required"
                                        inputName={"email"}
                                        value={curItem.email}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <div className="group-password">
                                        <RText label={t('customer.new_password')}
                                            inputClass="form-control"
                                            required="required"
                                            inputId="password"
                                            inputName="password"
                                            value={curItem.password}
                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                            maxLength={20} />
                                        <span onClick={this.generatePassword.bind(this)} className="generate-pass"> <var className="icon-refresh"></var></span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="mb-3">
                                    <RText label={t('customer.password_confirm')}
                                        inputClass="form-control"
                                        required="required"
                                        inputId="password_confirm"
                                        inputName="password_confirm"
                                        value={curItem.password_confirm}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>


                        </div>
                    </div>

                </div>


                <div className="box-info">
                    <h2>{t('products.thumbnail')}</h2>
                    <div className="box-info-content">
                        <div className="section-thumbnail">
                            <ul className="row">
                                <li className="col-md-12">
                                    <input key={Libs.generateStrRandom(2, 2, 2, 8)} onChange={(event) => this.onUploadThumbnailChange(event)} type="file" id="upload_thumbnail" name="upload_thumbnail" />
                                    {Libs.isBlank(curItem.upload_thumbnail) && Libs.isBlank(curItem.thumbnail) ?
                                        <div className={!Libs.isBlank(curItem.thumbnail_mess) ? "add-thumbnail error-thumbnail" : "add-thumbnail"}>
                                            <label htmlFor="upload_thumbnail" className="upload_thumbnail">
                                                <i className="icon-picture-o"></i>
                                            </label>
                                        </div>
                                        : (!Libs.isBlank(curItem.upload_thumbnail) ?
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                    <span onClick={this.deleteThumbnail.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                </div>
                                                <img src={curItem.upload_thumbnail} />
                                            </div>
                                            :
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                </div>
                                                <img src={curItem.thumbnail_full} />
                                            </div>
                                        )}

                                </li>



                            </ul>
                        </div>
                    </div>

                </div>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>

    )
}