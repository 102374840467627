
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import TimeRangeJsx from './TimeRange.jsx';

class TimeRange extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
            dataAttributes: this.props.dataAttributes
        }

        this.jsxTemplate = TimeRangeJsx;
    }

    onCloseItemFastRangeDate = (index) => {
        var { curItem } = this.state, self = this;
        curItem.show_range_time = false;
        this.props.callBackCloseTimeRange(curItem, index);
    }


    clearItemSaleOff = (index) => {
        var { curItem } = this.state, self = this;
        curItem.saleoff_from = null;
        curItem.saleoff_to = null;
        curItem.saleoff_price = null;
        this.setState({
            curItem: curItem
        }, () => {
            self.props.callBackCloseTimeRange(curItem, index);
        });
    }

    handleInputItemDateChange(index, event) {
        let target = event.target, self = this;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem } = this.state;
            var nameFromDate = "saleoff_from_" + index;
            var nameToDate = "saleoff_to_" + index;

            if (name === nameFromDate) {
                if (!Libs.isBlank(curItem.saleoff_to) && Libs.compareDate(value, 'DD/MM/YYYY', curItem.saleoff_to) > 0) {
                    curItem.saleoff_from = curItem.saleoff_to;
                } else {
                    curItem.saleoff_from = value;
                }
            }


            if (name === nameToDate) {
                if (!Libs.isBlank(curItem.saleoff_from) && Libs.compareDate(curItem.saleoff_from, 'DD/MM/YYYY', value) > 0) {
                    curItem.saleoff_to = curItem.saleoff_from;
                } else {
                    curItem.saleoff_to = value;
                }
            }
            if(!Libs.isBlank(curItem.saleoff_from) && !Libs.isBlank(curItem.saleoff_to)){
                curItem.errors.time_range = false;
            }
            
            this.setState({ curItem: curItem }, () => {
                self.props.callBackCloseTimeRange(curItem, index);
            });
        }
    }
}
export default TimeRange;