import React from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer';
import SidebarSell from '../common/SidebarSell/SidebarSell';

const MainModuleSell = ({ children }) => {
  return <div>
    <Header />
    <SidebarSell />
    <div id="main">
      {children}
    </div>
    <Footer />
  </div>
};

export default MainModuleSell;
