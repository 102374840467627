import React from 'react';
import { RText, RTextArea } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import FormReactSelect from '../../../../../components/FormReactSelect';
import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';


export default function AddPopup() {

    var { curItem, allLanguage, dataBannerPosition, dataType } = this.state;
    const { t } = this.props;
    var tabNavLanguage, tabContent = null;

    if (allLanguage) {
        tabNavLanguage = allLanguage.map((language, index) => {
            return (<li key={index} className="nav-item">
                <span onClick={(e) => { this.onClickShowTab(e, language.iso_code) }} className={curItem.tabActive === language.iso_code ? "nav-link active" : "nav-link"} id={"name-tab-" + language.iso_code} >{language.name}</span>
            </li>);
        });

        tabContent = allLanguage.map((language, index) => {
            return (
                <div key={index} className={curItem.tabActive === language.iso_code ? "tab-pane fade show active" : "tab-pane fade"} id={"name-" + language.iso_code} role="tabpanel" aria-labelledby="home-tab">
                    <div className="info">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('banner.name')}
                                        inputClass="form-control"
                                        required={language.is_default === 1 ? "required" : ""}
                                        inputId={"name_" + language.iso_code}
                                        inputName={"name_" + language.iso_code}
                                        value={curItem.data[index].name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('banner.link')}
                                        inputClass="form-control"
                                        inputId={"link_" + language.iso_code}
                                        inputName={"link_" + language.iso_code}
                                        value={curItem.data[index].link}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                <div className="mb-3">
                                    <RTextArea label={t('banner.description')}
                                        inputClass="form-control"
                                        inputId={"description_" + language.iso_code}
                                        inputName={"description_" + language.iso_code}
                                        value={curItem.data[index].description}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={500} />


                                </div>
                            </div>

                            {curItem.type == 1 ?
                                <div className="section-upload-image">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="mb-3">
                                            <div className="upload-banner-desktop">
                                                <label>{t('banner.banner_desktop_title')}</label>
                                                <p className="alert alert-warning alert-gallery">{t('banner.banner_desktop_note')}</p>
                                                <ul className="row">
                                                    <li className="col-md-12 col-12">
                                                        <div className="item-add-banner-desktop">
                                                            <input onChange={(event) => this.onFileBannerDesktopChange(event, language.iso_code)} type="file" id={"upload_banner_desktop_" + language.iso_code} name={"upload_banner_" + language.iso_code} />
                                                            {!curItem.data[index].banner_desktop_upload && Libs.isBlank(curItem.data[index].banner_desktop) ?
                                                                <div className="add-banner-desktop">
                                                                    <label htmlFor={"upload_banner_desktop_" + language.iso_code} className="upload-banner-desktop">
                                                                        <i className="icon-picture-o"></i> <span>{t('common.upload_image')}</span>
                                                                    </label>
                                                                </div>
                                                                : ""}

                                                            {!Libs.isBlank(curItem.data[index].banner_desktop_upload) ?
                                                                <div className="view-banner-desktop">
                                                                    <img alt="Upload" src={curItem.data[index].banner_desktop_upload} />
                                                                    <div className="banner-desktop-action">
                                                                        <label className="edit" htmlFor={"upload_banner_desktop_" + language.iso_code}><var className="icon-pencil-square-o"></var></label>
                                                                        <span className="delete" onClick={(e) => this.onClickDeleteBannerDesktop(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                (!Libs.isBlank(curItem.data[index].banner_desktop_full) ?
                                                                    <div className="view-banner-desktop">
                                                                        <img alt="Upload" src={curItem.data[index].banner_desktop_full} />
                                                                        <div className="banner-desktop-action">
                                                                            <label className="edit" htmlFor={"upload_banner_desktop_" + language.iso_code} ><var className="icon-pencil-square-o"></var></label>
                                                                            <span className="delete" onClick={(e) => this.onClickDeleteBannerDesktop(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                                        </div>
                                                                    </div>
                                                                    : "")

                                                            }

                                                        </div>
                                                    </li>
                                                </ul>
                                                {!Libs.isBlank(curItem.data[index].banner_desktop_message) ?
                                                    <div className="alert alert-danger">
                                                        {curItem.data[index].banner_desktop_message}
                                                    </div>
                                                    : ''}

                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="mb-3">
                                            <div className="upload-banner-mobile">
                                                <label>{t('banner.banner_mobile_title')}</label>
                                                <p className="alert alert-warning alert-gallery">{t('banner.banner_mobile_note')}</p>
                                                <ul className="row">
                                                    <li className="col-md-12 col-12">
                                                        <div className="item-add-banner-mobile">
                                                            <input onChange={(event) => this.onFileBannerMobileChange(event, language.iso_code)} type="file" id={"upload_banner_mobile_" + language.iso_code} name={"upload_banner_mobile_" + language.iso_code} />
                                                            {!curItem.data[index].banner_mobile_upload && Libs.isBlank(curItem.data[index].banner_mobile) ?
                                                                <div className="add-banner-mobile">
                                                                    <label htmlFor={"upload_banner_mobile_" + language.iso_code} className="upload-banner-mobile">
                                                                        <i className="icon-picture-o"></i> <span>{t('common.upload_image')}</span>
                                                                    </label>
                                                                </div>
                                                                : ""}

                                                            {!Libs.isBlank(curItem.data[index].banner_mobile_upload) ?
                                                                <div className="view-banner-mobile">
                                                                    <img alt="Upload" src={curItem.data[index].banner_mobile_upload} />
                                                                    <div className="banner-mobile-action">
                                                                        <label className="edit" htmlFor={"upload_banner_mobile_" + language.iso_code}><var className="icon-pencil-square-o"></var></label>
                                                                        <span className="delete" onClick={(e) => this.onClickDeleteBannerMobile(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                (!Libs.isBlank(curItem.data[index].banner_mobile_full) ?
                                                                    <div className="view-banner-mobile">
                                                                        <img alt="Upload" src={curItem.data[index].banner_mobile_full} />
                                                                        <div className="banner-mobile-action">
                                                                            <label className="edit" htmlFor={"upload_banner_mobile_" + language.iso_code} ><var className="icon-pencil-square-o"></var></label>
                                                                            <span className="delete" onClick={(e) => this.onClickDeleteBannerMobile(e, language.iso_code)} ><var className="icon-trash-o"></var></span>
                                                                        </div>
                                                                    </div>
                                                                    : "")
                                                            }

                                                        </div>
                                                    </li>
                                                </ul>
                                                {!Libs.isBlank(curItem.data[index].banner_mobile_message) ?
                                                    <div className="alert alert-danger">
                                                        {curItem.data[index].banner_mobile_message}
                                                    </div>
                                                    : ''}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}





                        </div>
                    </div>

                </div>
            );
        });
    }


    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('banner.edit_title') : t('banner.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">
                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        label={t('banner.id_banner_position')}
                                        instanceId="id_banner_position"
                                        className="id_banner_position"
                                        name="id_banner_position"
                                        required="required"
                                        isClearable={true}
                                        value={dataBannerPosition.filter(function (item) { return item.id === curItem.id_banner_position })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'id_banner_position') }}
                                        optionList={dataBannerPosition}
                                        placeHolder={t('banner.banner_choose')}
                                    />
                                </div>
                            </div>


                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        label={t('banner.type')}
                                        instanceId="type"
                                        className="type"
                                        name="type"
                                        required="required"
                                        value={dataType.filter(function (item) { return item.id === curItem.type })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'type') }}
                                        optionList={dataType}
                                        placeHolder={t('banner.banner_choose')}
                                    />
                                </div>
                            </div>


                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                <div className="mb-3">
                                    <RText label={t('banner.menu_order')}
                                        inputClass="form-control"
                                        inputId="menu_order"
                                        inputName="menu_order"
                                        value={curItem.menu_order}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        pattern="^[0-9]*$"
                                        maxLength={4} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="box-translate">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-main">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {tabNavLanguage}
                                </ul>
                                <div className="tab-content" id="tabContent">
                                    {tabContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {curItem.type == 2 ?
                    <div className="box-info">
                        <h2>{t('products.video')}</h2>
                        <div className="box-info-content">
                            <div className="section-video">
                                <ul className="row">
                                    <li className="col-md-12">
                                        <input key={curItem.selectedVideoFile} onChange={(event) => this.onUploadVideoChange(event)} type="file" id="upload_video" name="upload_video" />
                                        {Libs.isBlank(curItem.file_video_upload) && Libs.isBlank(curItem.video) ?
                                            <div className="add-video">
                                                <label htmlFor="upload_video" className="upload_video">
                                                    <i className="icon-video-camera"></i>
                                                </label>
                                            </div>
                                            : (!Libs.isBlank(curItem.file_video_upload) ?
                                                <div className="item-view">
                                                    <div className="action">
                                                        <label htmlFor="upload_video" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                        <span onClick={this.deleteVideo.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                    </div>
                                                    <video width="320" height="240" controls>
                                                        <source src={curItem.file_video_upload} type="video/mp4" />
                                                        <source src={curItem.file_video_upload} type="video/avi" />
                                                        <source src={curItem.file_video_upload} type="video/ogg" />
                                                        <source src={curItem.file_video_upload} type="video/mov" />
                                                    </video>
                                                </div>
                                                :
                                                <div className="item-view">
                                                    <div className="action">
                                                        <label htmlFor="upload_video" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                        <span className="delete" onClick={this.deleteVideo.bind(this)}><var className="icon-trash-o"></var></span>
                                                    </div>
                                                    <video width="320" height="240" controls>
                                                        <source src={curItem.video_full} type="video/mp4" />
                                                        <source src={curItem.video_full} type="video/avi" />
                                                        <source src={curItem.video_full} type="video/ogg" />
                                                        <source src={curItem.video_full} type="video/mov" />
                                                    </video>
                                                </div>
                                            )}

                                    </li>



                                </ul>
                            </div>
                        </div>

                    </div>
                    : ""}


            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>

    )
}