import React from 'react';
import NavLink from '../../../components/Link';
import './Profile.scss';

class SidebarSales extends React.Component {
    render() {
        return (
            <div className="sidebar-profile">
                <div className="title">
                    <div className="avatar">
                        <span><img src="/static/media/avatar.02edd327.jpg" alt="Avatar" /></span>
                    </div>
                    <h2>Pham Van Long</h2>
                    <p>Quản lý tài khoản</p>
                </div>
                <div className="content">
                    <ul>
                        <li><NavLink to="/user/categories"><var className="icon icon-alert"></var>Thông báo</NavLink></li>
                        <li><NavLink to="/user/document"><var className="icon icon-book"></var>Tài liệu hướng dẫn</NavLink></li>
                        <li><NavLink to="/user/plicy"><var className="icon-security"></var>Chính sách quy định</NavLink></li>
                        <li><NavLink to="/user/service"><var className="icon-list-unordered"></var>Dịch vụ</NavLink></li>
                        <li><NavLink to="/user/products"><var className="icon icon-logout"></var>Thoát</NavLink></li>

                    </ul>
                </div>

            </div>
        )
    }
}

export default SidebarSales;