import React, { Component } from 'react';
class AddRowLeft extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var item = this.props.dataItem;
        return (
            <div className="body-row">
                <div className="body-col width10">
                    <div className="body-product">
                        {item.id_code}
                    </div>
                </div>

                <div className="body-col width20">
                    {item.type_code}
                </div>
                <div className="body-col width30">
                    {item.id_code_date}
                </div>
                <div className="body-col width30">
                    {item.note}
                </div>
                <div className="body-col width10">
                    {/* {this.props.curItem.data.unit} */}
                </div>
            </div>
        );
    }
}
export default AddRowLeft;
