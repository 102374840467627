import React from 'react';
import Header from '../common/Header/Header';
// import Footer from '../common/Footer';
// import SidebarStores from '../common/SidebarStores';

const MainDashboard = ({ children }) => {
  return <div>
    <Header />
    {/* <SidebarStores /> */}
    <div id="main-dashboard">
      {children}
    </div>
    {/* <Footer /> */}
  </div>
};

export default MainDashboard;
