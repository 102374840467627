
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
import CategoryService from '../../../../../services/CategoryService';
import BrandService from '../../../../../services/BrandService';
import UnitService from '../../../../../services/UnitService';
import GalleryTypeService from '../../../../../services/GalleryTypeService';
import ProductTypeService from '../../../../../services/ProductTypeService';
import TypeService from '../../../../../services/TypeService';
import ProductService from '../../../../../services/ProductService';
import GroupAttributesService from '../../../../../services/GroupAttributesService';
import TagsService from '../../../../../services/TagsService';
import CustomFieldService from '../../../../../services/CustomFieldService';
import GlassLensTypeService from '../../../../../services/GlassLensTypeService';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            curItem: this.props.curItem,
            msgErrorGallery: [],
            curItemGroupAttr: {},
            curItemAttr: {},
            searchParam: {},
            dataCategory: [],
            dataBrand: [],
            dataUnit: [],
            dataGalleryType: [],
            dataProductType: [],
            dataType: [],
            dataGroupAttr: [],
            dataUpsellSearchAuto: [],
            dataCrossSellingSearchAuto: [],
            dataGiftProductSearchAuto: [],
            dataSearchAutoTags: [],
            allLanguage: this.props.allLanguage,
            showConfigTab: 'basic',
            showBasicRangeDate: false,
            showFastRangeDate: false,
            showAddGroupAttr: false,
            showAddAttr: false,
            dataHorizontally: [],
            dataShape: [],
            dataMaterial: [],
            dataFrameColor: [],
            dataFace: [],
            dataGender: [],
            dataLensType: []
        };

        this.jsxTemplate = AddPopupJsx;
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleInputDateChange = this.handleInputDateChange.bind(this);
        this.handleInputSearchAutoChange = this.handleInputSearchAutoChange.bind(this);
        this.handleInputItemDateChange = this.handleInputItemDateChange.bind(this);
        this.handleItemInputChange = this.handleItemInputChange.bind(this);
        this.handleInputTagsSearchChange = this.handleInputTagsSearchChange.bind(this);
        this.handleInputGlassTryOnChange = this.handleInputGlassTryOnChange.bind(this);
        this.handleSaleOffInputChange = this.handleSaleOffInputChange.bind(this);
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
        this.getListFilterType();
        this.getDropdownListCategory();
        this.getDropdownListBrand();
        this.getDropdownListUnit();
        this.getDropdownListProductType();
        this.getDropdownListType();
        this.getDropdownListAttr();
        this.getLensTypeList();

        this.getDropdownListGalleryType();
        var curItem = this.state.curItem;
        if (curItem.screen_mode === Constants.SCREEN_MODE.ADD) {
            this.getListGroupCustomField();
            curItem.id_type = 3;
            curItem.uplsells = [];
            curItem.crossSelling = [];
            curItem.giftProduct = [];
            curItem.dataGroup = [];
            curItem.galleries = [];
            curItem.dataTags = [];
            curItem.dataCustomFields = [];
            curItem.quantity = 0;
            curItem.dataVariants = [];
            curItem.dataGroupAttributes = [];
            curItem.sku = Libs.generateSKU(this.employee.id_company, 4);
        }
    }

    handleSaleOffInputChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value

        if (name) {
            var numericExpression = /^[0-9\b]+$/;
            if (value == "" || numericExpression.test(value)) {
                let item = this.state.curItem;
                if (name == 'saleoff_price' && parseInt(value) > parseInt(item.regular_price)) {
                    value = item.regular_price;
                }

                if (name == 'regular_price' && parseInt(value) < parseInt(item.saleoff_price)) {
                    item.saleoff_price = null;
                }
                item[name] = value;
                this.setState({ curItem: item });
            }
        }
    }


    /**
    * setValue method to Input
    * @author Long.Pham 2019-01-17
    */
    handleGlassLensInputChange(event, groupIndex, childIndex) {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            var { dataLensType } = this.state;

            if (Libs.isArrayData(dataLensType)) {
                var group = dataLensType[groupIndex];
                if (Libs.isObjectEmpty(group)) return;
                var childs = group.childs;
                if (Libs.isArrayData(childs)) {
                    if (!Libs.isBlank(childIndex)) {
                        childs[childIndex].is_checked = childs[childIndex].is_checked ? 0 : 1;
                    }
                    dataLensType[groupIndex].childs = childs;
                } else {
                    dataLensType[groupIndex].is_checked = dataLensType[groupIndex].is_checked ? 0 : 1;
                }
            }
            this.setState({
                dataLensType: dataLensType
            });
        }
    }


    getLensTypeList() {
        let self = this;
        var { curItem } = this.state;
        var dataGlassLens = Libs.isArrayData(curItem.dataGlassLens) ? curItem.dataGlassLens : [];
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        GlassLensTypeService.instance.getLensTypeList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                for (var i = 0, len = data.length; i < len; i++) {

                    var findItemGroup = Libs.find(dataGlassLens, 'id_glass_lens_type', data[i].id);
                    if (!Libs.isObjectEmpty(findItemGroup)) {
                        data[i].is_checked = 1;
                    }

                    var childs = data[i].childs;
                    if (Libs.isArrayData(childs)) {
                        for (var j = 0, leng = childs.length; j < leng; j++) {
                            var findItemChild = Libs.find(dataGlassLens, 'id_glass_lens_type', childs[j].id);
                            if (!Libs.isObjectEmpty(findItemChild)) {
                                data[i].childs[j].is_checked = 1;
                            }
                        }
                    }
                }

                self.setState({
                    dataLensType: data
                });
            } else {
                self.setState({
                    dataLensType: []
                })
            }

        }, false);
    }

    /**
     * ge list type
     * @author Long.Pham 2019-06-03
     */
    getListFilterType() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,

            type_strs: [
                { type: "frame_color" },
                { type: "material" },
                { type: "shape" },
                { type: "horizontally" },
                { type: "gender" },
                { type: "face" },
                { type: "lily_material" },
            ]
        };

        TypeService.instance.getListTypeKeyArr(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataHorizontally: data.filter((v, k) => v.type === 'horizontally'),
                    dataShape: data.filter((v, k) => v.type === 'shape'),
                    dataMaterial: data.filter((v, k) => v.type === 'material' || v.type === 'lily_material'),
                    dataFrameColor: data.filter((v, k) => v.type === 'frame_color'),
                    dataGender: data.filter((v, k) => v.type === 'gender'),
                    dataFace: data.filter((v, k) => v.type === 'face')
                });
            } else {
                self.setState({
                    dataHorizontally: [],
                    dataShape: [],
                    dataMaterial: [],
                    dataFrameColor: [],
                    dataGender: [],
                    dataFace: []
                })
            }

        }, false);

    }


    randomSKU = () => {
        var curItem = this.state.curItem;
        curItem.sku = Libs.generateSKU(this.employee.id_company, 4);
        this.setState({
            curItem: curItem
        })
    }

    handleDropdownChange = (item, name) => {
        var self = this;
        var { t } = this.props;
        let curItem = self.state.curItem;

        if (Libs.isObjectEmpty(item)) {
            curItem.parent = '';
            self.setValidateMessage({ name: t('products.id_category') });
        } else {
            var value = item.value;
            curItem[name] = value;
            if (name === 'id_category') {
                self.setValidateMessage({ id_category: '' }, true);
            } else if (name === 'id_brand') {
                self.setValidateMessage({ id_brand: '' }, true);
            } else if (name === 'id_unit') {
                self.setValidateMessage({ id_unit: '' }, true);
            } else if (name === 'id_product_type') {
                self.setValidateMessage({ id_product_type: '' }, true);
            }
        }

        if (name == 'id_type_gender') {
            curItem.dataGender = item;
        } else if (name == 'id_type_face') {
            curItem.dataFace = item;
        } else if (name == 'id_type_frame_color') {
            curItem.dataFrameColor = item;
        }

        self.setState({
            curItem: curItem
        });
    }

    deleteThumbnail() {
        var { curItem } = this.state;
        curItem.upload_thumbnail = '';
        curItem.upload_thumbnail_name = '';
        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload thumbnail
     * @author Long.Pham 12/05/2021
     */
    onUploadThumbnailChange = (event) => {

        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgError = null;
        if (!checkExtFile) {
            msgError = t('message.msg_err_ext_image_file');
        }
        else if (fileSize <= 0) {
            msgError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgError = t('message.msg_err_max_size_image_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgError)) {
            self.toast(msgError, 'error');
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            curItem.upload_thumbnail = e.target.result;
            curItem.upload_thumbnail_name = fileName;
            self.setValidateMessage({ upload_thumbnail: '' }, true);
            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }

    deleteThumbnailHover() {
        var { curItem } = this.state;
        curItem.upload_thumbnail_hover = '';
        curItem.upload_thumbnail_hover_name = '';
        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload thumbnail
     * @author Long.Pham 12/05/2021
     */
    onUploadThumbnailHoverChange = (event) => {

        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgError = null;
        if (!checkExtFile) {
            msgError = t('message.msg_err_ext_image_file');
        }
        else if (fileSize <= 0) {
            msgError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgError = t('message.msg_err_max_size_image_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgError)) {
            self.toast(msgError, 'error');
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            curItem.upload_thumbnail_hover = e.target.result;
            curItem.upload_thumbnail_hover_name = fileName;
            self.setValidateMessage({ upload_thumbnail_hover: '' }, true);
            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }


    /**
     * handle data of editor change with key
     * @param {*} content 
     * @param {*} props 
     */
    handleCustomFieldEditorChange(groupIndex, fieldIndex, lang, e, editor) {
        var { curItem } = this.state;
        var dataCustomFields = curItem.dataCustomFields;
        if (!Libs.isArrayData(dataCustomFields)) return;

        let allLanguage = this.state.allLanguage;
        allLanguage.map((language, index) => {
            if (lang === language.iso_code) {
                dataCustomFields[groupIndex].fields[fieldIndex].dataTrans[index].field_value = e;
            }
        });

        curItem.dataCustomFields = dataCustomFields;
    }


    /**
    * @description custom field change
    * @author Long.Pham 12/05/2021
    * @param {*} event 
    */

    handleInputCustomFieldChange = (event, groupIndex, fieldIndex, lang) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            var { curItem } = this.state;
            var dataCustomFields = curItem.dataCustomFields;
            if (!Libs.isArrayData(dataCustomFields)) return;

            let allLanguage = this.state.allLanguage;


            allLanguage.map((language, index) => {
                if (lang === language.iso_code) {
                    dataCustomFields[groupIndex].fields[fieldIndex].dataTrans[index].field_value = value;
                }
            });

            curItem.dataCustomFields = dataCustomFields;

            this.setState({
                curItem: curItem
            })
        }
    }
    /**
     * ge list type
     * @author Long.Pham 2019-06-03
     */
    getListGroupCustomField() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        CustomFieldService.instance.getListGroupCustomField(params, (data, total_row) => {
            var { curItem } = this.state;
            var allLanguage = this.state.allLanguage;
            var dataCustomFields = [];
            if (Libs.isArrayData(data)) {
                if (curItem.screen_mode === Constants.SCREEN_MODE.ADD) {
                    for (var i = 0, len = data.length; i < len; i++) {
                        var itemGroup = {
                            id: data[i].id,
                            name: data[i].name,
                            fields: []
                        };
                        var fields = data[i].fields;
                        var dataGroupFields = [];
                        for (var j = 0, leng = fields.length; j < leng; j++) {
                            var itemField = {
                                id: fields[j].id,
                                id_product: '',
                                id_specification: fields[j].id,
                                field_label: fields[j].field_label,
                                field_type: fields[j].field_type,
                                field_key: fields[j].field_key,
                                dataTrans: []
                            };

                            if (Libs.isArrayData(allLanguage)) {
                                for (var v = 0, l = allLanguage.length; v < l; v++) {
                                    itemField.dataTrans.push(
                                        {
                                            id_product_specification: '',
                                            id_language: allLanguage[v].id,
                                            field_value: ''
                                        }
                                    );
                                }
                            }

                            dataGroupFields.push(itemField);
                        }

                        itemGroup.fields = dataGroupFields;
                        dataCustomFields.push(itemGroup);
                    }
                }

                curItem.dataCustomFields = dataCustomFields;

                self.setState({
                    curItem: curItem,
                    loading: true
                });
            } else {
                self.setState({
                    curItem: curItem
                })
            }

        }, false);

    }

    onClickAddTag() {
        var { searchParam } = this.state, self = this;
        if (Libs.isBlank(searchParam.tag_name)) return;

        var params = {
            screen_mode: Constants.SCREEN_MODE.ADD,
            id_company: this.employee.id_company,
            iso_code_lang: this.employee.lang,
            id_language: this.employee.id_language,
            iso_code: this.employee.iso_code,
            name: searchParam.tag_name
        }

        TagsService.instance.productSaveTags(params, function (status, data, msg) {
            var { curItem, searchParam } = self.state;
            var dataTags = curItem.dataTags;
            if (status) {
                searchParam.tag_name = null;
                dataTags.push({
                    id: data.id,
                    id_tags: data.id_tags,
                    id_company: data.id_company,
                    name: data.name
                });

                curItem.dataTags = dataTags;

                self.setState({
                    curItem: curItem,
                    searchParam: searchParam
                });

            } else if (data) {
                self.toast(data.name, "error");
            }

            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);

    }

    handleInputTagsSearchChange(event) {
        let target = event.target, self = this;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { searchParam } = this.state;
            searchParam[name] = value;
            if (name == 'tag_suggest') {
                this.setState({ searchParam }, () => {
                    self.getListTagsSearchAuto();
                });
            }
        }
    }


    deleteItemTags(index) {
        var { curItem } = this.state;
        var dataTags = curItem.dataTags;
        if (!Libs.isArrayData(dataTags)) return;

        var item = dataTags[index];
        if (Libs.isObjectEmpty(item)) return;

        dataTags.splice(index, 1);

        curItem.dataTags = dataTags;

        this.setState({
            curItem: curItem
        });
    }
    /**
     * get list tag
     * @author Long.Pham 2019-06-03
     */
    getListTagsSearchAuto() {
        let self = this, searchParam = this.state.searchParam;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            name: searchParam.tag_suggest
        };
        TagsService.instance.getListTagsSearchAuto(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataSearchAutoTags: data
                });
            } else {
                self.setState({
                    dataSearchAutoTags: []
                });
            }
        })
    }


    addItemSearchTag(index) {
        var { curItem, dataSearchAutoTags, searchParam } = this.state;
        if (!Libs.isArrayData(dataSearchAutoTags)) return;
        var item = dataSearchAutoTags[index];
        if (Libs.isObjectEmpty(item)) return;

        var dataTags = curItem.dataTags;
        var findItem = Libs.find(dataTags, 'id_tags', item.id);
        if (Libs.isObjectEmpty(findItem)) {
            dataTags.push(item);
        }
        curItem.dataTags = dataTags;
        searchParam.tag_suggest = null;
        this.setState({
            curItem: curItem,
            dataSearchAutoTags: [],
            searchParam: searchParam

        });

    }

    deleteItemGallery(index) {
        var { curItem } = this.state;
        var galleries = curItem.galleries;
        if (!Libs.isArrayData(galleries)) return;
        galleries.splice(index, 1);

        curItem.galleries = galleries;
        this.setState({
            curItem: curItem
        });
    }
    /**
     * @description Upload galleries
     * @author Long.Pham 12/05/2021
     */
    onUploadGalleryChange = (event) => {
        var { t } = this.props;
        var target = event.target;
        var self = this, { curItem, msgErrorGallery } = this.state;
        var files = target.files;
        if (files.length <= 0) return;
        var dataGalleryType = this.state.dataGalleryType;
        if (!Libs.isArrayData(dataGalleryType)) return;
        var itemGalleryType = Libs.find(dataGalleryType, 'is_default', 1);

        if (Libs.isObjectEmpty(itemGalleryType)) return;

        if (files.length > Constants.COMMON.LIMIT_IMG) {
            self.toast(t('msg_err_limit_image'), "error");
            return;
        } else {
            for (const item of files) {
                if (!item || item === undefined || item === null) return;
                var fileName = item.name, fileSize = item.size;
                var checkExtFile = Libs.checkExtensionFile(fileName, 1);
                var msgFileError = null;
                if (!checkExtFile) {
                    msgFileError = t('message.msg_err_ext_image_file')
                }
                else if (fileSize <= 0) {
                    msgFileError = t('message.msg_err_file_size');
                }
                else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
                    msgFileError = t('message.msg_err_max_size_upload');
                }
                if (!Libs.isBlank(msgFileError)) {
                    msgErrorGallery.push(fileName + ": " + msgFileError);
                } else {

                    let imageParam = {
                        name: '',
                        file_name: fileName,
                        size: fileSize,
                        type: item.type,
                        file_upload: '',
                        id_gallery_type: itemGalleryType.id,
                        description: ''
                    };

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        imageParam.file_upload = e.target.result;
                        if (curItem.galleries.length <= 15) {
                            curItem.galleries.push(imageParam);
                        }

                        self.setState({
                            curItem: curItem
                        });

                    };

                    reader.readAsDataURL(item);
                    self.setState({
                        curItem: curItem
                    });
                }
            }
        }




        self.setState({
            msgErrorGallery: msgErrorGallery
        }, () => {
            setTimeout(function () {
                this.setState({ msgErrorGallery: [] });
            }.bind(this), 10000);
        });
    }


    deleteVideo() {
        var curItem = this.state.curItem;
        curItem.file_video_upload = null;
        curItem.file_video_name = null;
        curItem.selectedVideoFile = null;
        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload video
     * @author Long.Pham 12/05/2021
     */
    onUploadVideoChange = (event) => {

        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 3);
        var msgError = null;
        if (!checkExtFile) {
            msgError = t('message.msg_err_ext_video_file');
        }
        else if (fileSize <= 0) {
            msgError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_VIDEO_SIZE) {
            msgError = t('message.msg_err_max_size_video_upload');
        }

        var self = this;
        var { curItem } = this.state;
        curItem.selectedVideoFile = target.files;
        if (!Libs.isBlank(msgError)) {
            self.toast(msgError, 'error');
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            curItem.file_video_upload = e.target.result;
            curItem.file_video_name = fileName;
            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }




    deleteSaleOff = () => {
        var { curItem } = this.state;
        var dataVariants = curItem.dataVariants;
        if (!Libs.isArrayData(dataVariants)) return;
        for (var i = 0, len = dataVariants.length; i < len; i++) {
            dataVariants[i].saleoff_price = null;
            dataVariants[i].saleoff_from = null;
            dataVariants[i].saleoff_to = null;
        }
        this.setState({
            curItem: curItem
        });
    }


    clearItemSaleOff = (index) => {
        var { curItem } = this.state;
        var dataVariants = curItem.dataVariants;

        if (!Libs.isArrayData(dataVariants)) return;
        var item = dataVariants[index];
        if (Libs.isObjectEmpty(item)) return;
        dataVariants[index].saleoff_from = null;
        dataVariants[index].saleoff_to = null;
        dataVariants[index].saleoff_price = null;
        this.setState({
            curItem: curItem
        });
    }

    handleItemInputChange(event, index) {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            var regular_price_name = 'regular_price_' + index;
            var saleoff_price_name = 'saleoff_price_' + index;
            var quantity_name = 'quantity_' + index;
            var sku_name = 'sku_' + index;

            var numericExpression = /^[0-9.,\b]+$/;
            if (name === quantity_name) {
                numericExpression = /^[0-9\b]+$/;
            }
            let { curItem } = this.state;
            var dataVariants = curItem.dataVariants;
            if (!Libs.isArrayData(dataVariants)) return;
            var item = dataVariants[index];
            if (Libs.isObjectEmpty(item)) return;

            if (value === "" || numericExpression.test(value)) {
                if (name === regular_price_name) {
                    if (!Libs.isBlank(dataVariants[index].saleoff_price) && value < dataVariants[index].saleoff_price) {
                        dataVariants[index].saleoff_price = null;
                    }
                    dataVariants[index].regular_price = value;
                    dataVariants[index].errors.regular_price = false;
                }

                if (name === saleoff_price_name) {
                    if (parseInt(value) > parseInt(dataVariants[index].regular_price)) {
                        dataVariants[index].saleoff_price = dataVariants[index].regular_price;
                    } else {
                        dataVariants[index].saleoff_price = value;
                    }

                    dataVariants[index].errors.saleoff_price = false;
                }

                if (name === quantity_name) {
                    dataVariants[index].quantity = value;
                    dataVariants[index].errors.quantity = false;
                }
            }
            if (name === sku_name) {
                dataVariants[index].sku = value;
                dataVariants[index].errors.sku = false;
            }

            curItem.dataVariants = dataVariants;
            this.setState({
                curItem: curItem
            });
        }
    }

    callBackCloseTimeRange = (obj, index) => {
        var { curItem } = this.state, self = this;
        var dataVariants = curItem.dataVariants;
        if (Libs.isArrayData(dataVariants)) {
            var item = dataVariants[index];
            if (Libs.isObjectEmpty(item)) return;
            dataVariants[index] = obj;

            curItem.dataVariants = dataVariants;
            self.setState({
                curItem: curItem
            });
        }
    }

    onClickItemFastRangeDate = (index) => {
        var { curItem } = this.state;
        var dataVariants = curItem.dataVariants;
        if (Libs.isArrayData(dataVariants)) {
            var item = dataVariants[index];
            if (Libs.isObjectEmpty(item)) return;
            dataVariants[index].show_range_time = 1;
        }

        curItem.dataVariants = dataVariants;
        this.setState({
            curItem: curItem
        })
    }

    onClickApplySetup() {
        var { curItem } = this.state;
        var dataVariants = curItem.dataVariants;
        if (Libs.isArrayData(dataVariants)) {
            for (var i = 0, len = dataVariants.length; i < len; i++) {
                dataVariants[i].quantity = !Libs.isBlank(curItem.fast_setup_quantity) ? curItem.fast_setup_quantity : null;
                dataVariants[i].regular_price = !Libs.isBlank(curItem.fast_setup_current_price) ? curItem.fast_setup_current_price : null;
                dataVariants[i].saleoff_price = !Libs.isBlank(curItem.fast_setup_saleoff_price) ? curItem.fast_setup_saleoff_price : null;
                dataVariants[i].saleoff_from = !Libs.isBlank(curItem.fast_saleoff_from) ? curItem.fast_saleoff_from : null;
                dataVariants[i].saleoff_to = !Libs.isBlank(curItem.fast_saleoff_to) ? curItem.fast_saleoff_to : null;
                dataVariants[i].errors = {
                    quantity: false,
                    regular_price: false,
                    saleoff_price: false,
                    time_range: false
                };
            }
        }

        curItem.dataVariants = dataVariants;
        this.setState({
            curItem: curItem
        })
    }

    loadListAttribute() {
        var { curItem } = this.state;
        var dataVariants = curItem.dataVariants;
        var dataGroup = curItem.dataGroup;
        var dataGroupAttributes = [];

        if (!Libs.isArrayData(dataGroup)) return;
        for (var i = 0, len = dataGroup.length; i < len; i++) {
            if (Libs.isArrayData(dataGroup[i].dataListAttr)) {
                dataGroupAttributes.push(dataGroup[i]);
            }
        }

        var dataAttr = [];
        if (Libs.isArrayData(dataGroupAttributes)) {
            switch (dataGroupAttributes.length) {
                case 1:
                    let firstItem = dataGroupAttributes[0];
                    let firstDataListAttr = firstItem.dataListAttr;
                    for (let i = 0, len = firstDataListAttr.length; i < len; i++) {
                        var checkExits = Libs.find(dataVariants, 'unique_string_id', (firstDataListAttr[i].id).toString());
                        if (!Libs.isObjectEmpty(checkExits)) {
                            dataAttr.push(checkExits);
                        } else {
                            dataAttr.push({
                                unique_string_id: (firstDataListAttr[i].id).toString(),
                                sku: Libs.generateSKU(this.employee.id_company, 4),
                                id_product: '',
                                name: firstDataListAttr[i].name,
                                id_attribute1: firstDataListAttr[i].id,
                                id_attribute2: null,
                                id_attribute3: null,

                                name: firstDataListAttr[i].name,
                                name_1: null,
                                name_2: null,

                                quantity: null,
                                regular_price: null,
                                saleoff_price: null,
                                saleoff_from: null,
                                saleoff_to: null,
                                show_range_time: 0,
                                is_default: 0,
                                file_upload: '',
                                file_upload_name: '',
                                thumbnail_url: '',
                                errors: {
                                    regular_price: false,
                                    saleoff_price: false,
                                    quantity: false,
                                    time_range: false,
                                    sku: false
                                }
                            });
                        }
                    }

                    break;
                case 2:
                    let firstItem2 = dataGroupAttributes[0];
                    let firstDataListAttr2 = firstItem2.dataListAttr;

                    let secondItem = dataGroupAttributes[1];
                    let secondDataListAttr = secondItem.dataListAttr;

                    for (let i = 0, len = firstDataListAttr2.length; i < len; i++) {
                        var firstRow = firstDataListAttr2[i];
                        for (let j = 0, leng = secondDataListAttr.length; j < leng; j++) {
                            var secondRow = secondDataListAttr[j];

                            var checkExits2 = Libs.find(dataVariants, 'unique_string_id', firstRow.id + "-" + secondRow.id);
                            if (!Libs.isObjectEmpty(checkExits2)) {
                                dataAttr.push(checkExits2);
                            } else {
                                dataAttr.push({
                                    unique_string_id: firstRow.id + "-" + secondRow.id,
                                    sku: Libs.generateSKU(this.employee.id_company, 4),
                                    id_product: '',
                                    id_attribute1: firstRow.id,
                                    id_attribute2: secondRow.id,
                                    id_attribute3: null,
                                    name: firstRow.name,
                                    name_1: secondRow.name,
                                    name_2: null,
                                    quantity: null,
                                    regular_price: null,
                                    saleoff_price: null,
                                    saleoff_from: null,
                                    saleoff_to: null,
                                    show_range_time: 0,
                                    is_default: 0,
                                    file_upload: '',
                                    file_upload_name: '',
                                    thumbnail_url: '',
                                    errors: {
                                        regular_price: false,
                                        saleoff_price: false,
                                        quantity: false,
                                        time_range: false,
                                        sku: false
                                    }
                                });
                            }

                        }
                    }
                    break;
                case 3:
                    let firstItem3 = dataGroupAttributes[0];
                    let firstDataListAttr3 = firstItem3.dataListAttr;

                    let secondItem3 = dataGroupAttributes[1];
                    let secondDataListAttr3 = secondItem3.dataListAttr;

                    let threeItem = dataGroupAttributes[2];
                    let threeDataListAttr = threeItem.dataListAttr;

                    for (let i = 0, len = firstDataListAttr3.length; i < len; i++) {
                        var firstRow3 = firstDataListAttr3[i];
                        for (let j = 0, leng = secondDataListAttr3.length; j < leng; j++) {
                            var secondRow3 = secondDataListAttr3[j];

                            for (let v = 0, lengt = threeDataListAttr.length; v < lengt; v++) {
                                var threeRow = threeDataListAttr[v];

                                var checkExits3 = Libs.find(dataVariants, 'unique_string_id', firstRow3.id + "-" + secondRow3.id + "-" + threeRow.id);
                                if (!Libs.isObjectEmpty(checkExits3)) {
                                    dataAttr.push(checkExits3);
                                } else {
                                    dataAttr.push({
                                        unique_string_id: firstRow3.id + "-" + secondRow3.id + "-" + threeRow.id,
                                        sku: firstRow3.id + "-" + secondRow3.id + "-" + threeRow.id,
                                        id_product: Libs.generateSKU(this.employee.id_company, 4),
                                        id_attribute1: firstRow3.id,
                                        id_attribute2: secondRow3.id,
                                        id_attribute3: threeRow.id,
                                        name: firstRow3.name,
                                        name_1: secondRow3.name,
                                        name_2: threeRow.name,
                                        quantity: null,
                                        regular_price: null,
                                        saleoff_price: null,
                                        saleoff_from: null,
                                        saleoff_to: null,
                                        show_range_time: 0,
                                        is_default: 0,
                                        file_upload: '',
                                        file_upload_name: '',
                                        thumbnail_url: '',

                                        errors: {
                                            regular_price: false,
                                            saleoff_price: false,
                                            quantity: false,
                                            time_range: false,
                                            sku: false
                                        }
                                    });
                                }

                            }

                        }
                    }
                    break;
            }
        }

        curItem.dataVariants = dataAttr;
        curItem.dataGroupAttributes = dataGroupAttributes;

        this.setState({
            curItem: curItem
        })
    }

    /**
     * @description Upload bannner mobile
     * @author Long.Pham 12/05/2021
     */
    onUploadImageColorChange = (event, index, subindex) => {

        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgError = null;
        if (!checkExtFile) {
            msgError = t('message.msg_err_ext_image_file')
        }
        else if (fileSize <= 0) {
            msgError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgError = t('message.msg_err_max_size_upload');
        }

        var self = this;
        var { curItem } = this.state;
        var dataGroup = curItem.dataGroup;
        if (!Libs.isArrayData(dataGroup)) return;
        if (Libs.isObjectEmpty(dataGroup[index])) return;
        if (!Libs.isArrayData(dataGroup[index].dataListAttr)) return;
        if (Libs.isObjectEmpty(dataGroup[index].dataListAttr[subindex])) return;

        if (!Libs.isBlank(msgError)) {
            self.toast(msgError, 'error');
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            var item = dataGroup[index].dataListAttr[subindex];
            item.file_upload = e.target.result;
            item.file_name = fileName;
            dataGroup[index].dataListAttr[subindex] = item;
            curItem.dataGroup = dataGroup;
            self.setState({
                curItem: curItem
            }, () => {
                self.loadListAttribute();
            });
        };
        reader.readAsDataURL(file);
    }

    handleInputGlassTryOnChange = (event, index, subindex) => {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;

        if (name) {
            var { curItem } = this.state;
            var dataGroup = curItem.dataGroup;
            if (!Libs.isArrayData(dataGroup)) return;
            if (Libs.isObjectEmpty(dataGroup[index])) return;
            if (!Libs.isArrayData(dataGroup[index].dataListAttr)) return;
            if (Libs.isObjectEmpty(dataGroup[index].dataListAttr[subindex])) return;

            var item = dataGroup[index].dataListAttr[subindex];
            item.id_glass_tryon = value;
            dataGroup[index].dataListAttr[subindex] = item;
            curItem.dataGroup = dataGroup;
            self.setState({
                curItem: curItem
            });
        }
    }

    deleteAttributeImage = (index, subindex) => {
        var { curItem } = this.state;
        var dataGroup = curItem.dataGroup;
        var item = dataGroup[index].dataListAttr[subindex];
        if (Libs.isObjectEmpty(item)) return;
        item.file_upload = null;
        item.file_name = null;
        item.thumbnail = null;
        item.thumbnail_medium = null;
        this.setState({
            curItem: curItem
        });

    }



    onClickShowAddAttr(item) {
        let curItemAttr = this.state.curItemAttr;
        let data = [];
        var allLanguage = this.state.allLanguage;
        curItemAttr.screen_mode = Constants.SCREEN_MODE.ADD;
        curItemAttr.tabActive = '';
        allLanguage.map((language, index) => {
            if (language.is_default === 1) { curItemAttr.tabActive = language.is_default === 1 ? language.iso_code : ''; }
            let item = {
                is_default: language.is_default,
                iso_code: language.iso_code,
                id_language: language.id,
                name: "",
                description: ""
            };
            return data.push(item);
        });
        curItemAttr.id_attribute_group = item.id;
        curItemAttr.data = data;

        this.setState({
            curItemAttr: curItemAttr,
            showAddAttr: true
        }, () => {
            document.body.classList.add('modal-open-attr');
        });
    }

    onClickCloseAddAttr = (status, obj) => {
        var self = this;
        if (!Libs.isObjectEmpty(obj)) {
            var params = {
                id_company: this.employee.id_company,
                id_language: this.employee.id_language
            };

            GroupAttributesService.instance.getDropdownListAttr(params, (data, total_row) => {
                if (Libs.isArrayData(data)) {
                    var curItem = self.state.curItem;
                    var dataGroup = curItem.dataGroup;
                    var findGroup = Libs.find(data, "id", parseInt(obj.id_attribute_group));
                    if (Libs.isObjectEmpty(findGroup)) return;


                    if (Libs.isArrayData(dataGroup)) {
                        dataGroup.map((v, i) => {
                            if (dataGroup[i].id === findGroup.id) {
                                dataGroup[i].attrs = findGroup.attrs;
                                var findAttr = Libs.find(findGroup.attrs, 'id', parseInt(obj.id));
                                if (!Libs.isObjectEmpty(findAttr)) {
                                    var dataListAttr = Libs.isArrayData(dataGroup[i].dataListAttr) ? dataGroup[i].dataListAttr : [];
                                    dataListAttr.push(findAttr);
                                    dataGroup[i].dataListAttr = dataListAttr;
                                }

                            }
                        });
                    }

                    curItem.dataGroup = dataGroup;

                    self.setState({
                        curItem: curItem
                    });
                }

            }, true);

        }

        var self = this;
        self.setState({
            showAddAttr: false
        }, () => {
            document.body.classList.remove('modal-open-attr');
        });
    }


    handleDropdownChangeMulti = (item) => {
        var self = this;
        let curItem = self.state.curItem;
        var dataGroup = curItem.dataGroup;
        if (Libs.isArrayData(item)) {
            dataGroup = item;
        } else {
            dataGroup = [];
        }


        dataGroup.sort(function (a, b) {
            let dateA = a.id;
            let dateB = b.id;
            if (dateA < dateB) {
                return -1;
            }
            else if (dateA > dateB) {
                return 1;
            }
            return 0;
        });
        curItem.dataGroup = dataGroup;
        self.setState({
            curItem: curItem
        });
    }




    handleDropdownChangeAttr(item, index, id) {
        var self = this;
        let curItem = this.state.curItem;
        var dataGroup = curItem.dataGroup;
        if (!Libs.isArrayData(dataGroup)) return;

        if (Libs.isArrayData(item)) {
            dataGroup[index].dataListAttr = item;
        } else {
            dataGroup[index].dataListAttr = [];
        }

        curItem.dataGroup = dataGroup;
        self.setState({
            curItem: curItem
        }, () => {
            var params = {
                id_company: this.employee.id_company,
                id_language: this.employee.id_language
            };

            GroupAttributesService.instance.getDropdownListAttr(params, (data, total_row) => {
                if (Libs.isArrayData(data)) {
                    var curItem = self.state.curItem;
                    var findItem = Libs.find(data, 'id', parseInt(id));
                    if (!Libs.isObjectEmpty(findItem)) {
                        dataGroup[index].attrs = Libs.isArrayData(findItem.attrs) ? findItem.attrs : [];
                    }
                    self.setState({
                        curItem: curItem,
                        dataGroupAttr: data

                    });

                } else {
                    self.setState({
                        dataGroupAttr: []
                    })
                }
                self.loadListAttribute();
            }, true);
        });
    }



    /**
     * ge list type
     * @author Long.Pham 2019-06-03
     */
    getDropdownListAttr(id = null) {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        GroupAttributesService.instance.getDropdownListAttr(params, (data, total_row) => {
            var curItem = self.state.curItem;
            var dataGroup = curItem.dataGroup;
            if (!Libs.isBlank(id)) {
                var findItem = Libs.find(data, 'id', id);
                if (!Libs.isObjectEmpty(findItem)) {
                    dataGroup.push(findItem);
                }
            }
            curItem.dataGroup = dataGroup;
            if (Libs.isArrayData(data)) {
                self.setState({
                    curItem: curItem,
                    dataGroupAttr: data

                });
            } else {
                self.setState({
                    dataGroupAttr: []
                })
            }

        }, false);

    }


    onClickShowAddGroupAttr() {
        let curItemGroupAttr = this.state.curItemGroupAttr;
        let data = [];
        var allLanguage = this.state.allLanguage;
        curItemGroupAttr.screen_mode = Constants.SCREEN_MODE.ADD;
        curItemGroupAttr.tabActive = '';
        allLanguage.map((language, index) => {
            if (language.is_default === 1) { curItemGroupAttr.tabActive = language.is_default === 1 ? language.iso_code : ''; }
            let item = {
                is_default: language.is_default,
                iso_code: language.iso_code,
                id_language: language.id,
                name: "",
                description: ""
            };
            return data.push(item);
        });

        curItemGroupAttr.data = data;

        this.setState({
            curItemGroupAttr: curItemGroupAttr,
            showAddGroupAttr: true
        }, () => {
            document.body.classList.add('modal-open-attr');
        });
    }

    onClickCloseAddGroupAttr = (status, obj) => {
        var self = this;
        if (status && !Libs.isObjectEmpty(obj)) {
            var params = {
                id_company: this.employee.id_company,
                id_language: this.employee.id_language
            };
            GroupAttributesService.instance.getDropdownListAttr(params, (data, total_row) => {
                var curItem = self.state.curItem;
                var dataGroup = Libs.isArrayData(curItem.dataGroup) ? curItem.dataGroup : [];
                if (Libs.isArrayData(data)) {
                    var findItem = Libs.find(data, 'id', parseInt(obj.id));
                    if (!Libs.isObjectEmpty(findItem)) {
                        dataGroup.push(findItem);
                    }
                    curItem.dataGroup = dataGroup;
                    self.setState({
                        dataGroupAttr: data,
                        curItem: curItem
                    })

                } else {
                    self.setState({
                        dataGroupAttr: []
                    })
                }
            }, false);

        }

        var self = this;
        self.setState({
            showAddGroupAttr: false
        }, () => {
            document.body.classList.remove('modal-open-attr');
        });
    }

    handleInputSearchAutoChange(event) {
        let target = event.target, self = this;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { searchParam } = this.state;
            searchParam[name] = value;
            if (name == 'upsells_name') {
                this.setState({ searchParam }, () => {
                    self.getUpsellSearchAuto();
                });
            }

            if (name == 'cross_selling') {
                this.setState({ searchParam }, () => {
                    self.getCrossSellingSearchAuto();
                });
            }


            if (name == 'gift_product') {
                this.setState({ searchParam }, () => {
                    self.getGiftProductSearchAuto();
                });
            }
        }
    }



    addUpsell = (index) => {
        var { curItem, dataUpsellSearchAuto } = this.state;
        if (Libs.isArrayData(dataUpsellSearchAuto)) {
            var item = dataUpsellSearchAuto[index];
            if (Libs.isObjectEmpty(item)) return;

            var uplsells = curItem.uplsells;
            var findItemExits = Libs.find(uplsells, 'id', item.id);
            if (Libs.isObjectEmpty(findItemExits)) {
                uplsells.push(item);
            }
            dataUpsellSearchAuto.splice(index, 1);
            curItem.uplsells = uplsells;

        }
        this.setState({
            curItem: curItem
        });
    }

    onClearUpsellItem = (index) => {
        var curItem = this.state.curItem;
        var uplsells = curItem.uplsells;
        if (!Libs.isArrayData(uplsells)) return;
        var item = uplsells[index];
        if (Libs.isObjectEmpty(item)) return;
        uplsells.splice(index, 1);

        curItem.uplsells = uplsells;
        this.setState({
            curItem: curItem
        });

    }

    addCrossSelling = (index) => {
        var { curItem, dataCrossSellingSearchAuto } = this.state;
        if (Libs.isArrayData(dataCrossSellingSearchAuto)) {
            var item = dataCrossSellingSearchAuto[index];
            if (Libs.isObjectEmpty(item)) return;

            var crossSelling = curItem.crossSelling;
            var findItemExits = Libs.find(crossSelling, 'id', item.id);
            if (Libs.isObjectEmpty(findItemExits)) {
                crossSelling.push(item);
            }
            dataCrossSellingSearchAuto.splice(index, 1);
            curItem.crossSelling = crossSelling;

        }
        this.setState({
            curItem: curItem
        });
    }

    onClearCrossSellingItem = (index) => {
        var curItem = this.state.curItem;
        var crossSelling = curItem.crossSelling;
        if (!Libs.isArrayData(crossSelling)) return;
        var item = crossSelling[index];
        if (Libs.isObjectEmpty(item)) return;
        crossSelling.splice(index, 1);

        curItem.crossSelling = crossSelling;
        this.setState({
            curItem: curItem
        });

    }


    addGiftProduct = (index) => {
        var { curItem, dataGiftProductSearchAuto } = this.state;
        if (Libs.isArrayData(dataGiftProductSearchAuto)) {
            var item = dataGiftProductSearchAuto[index];
            if (Libs.isObjectEmpty(item)) return;

            var giftProduct = curItem.giftProduct;
            var findItemExits = Libs.find(giftProduct, 'id', item.id);
            if (Libs.isObjectEmpty(findItemExits)) {
                giftProduct.push(item);
            }
            dataGiftProductSearchAuto.splice(index, 1);
            curItem.giftProduct = giftProduct;

        }
        this.setState({
            curItem: curItem
        });
    }

    onClearGiftProductItem = (index) => {
        var curItem = this.state.curItem;
        var giftProduct = curItem.giftProduct;
        if (!Libs.isArrayData(giftProduct)) return;
        var item = giftProduct[index];
        if (Libs.isObjectEmpty(item)) return;
        giftProduct.splice(index, 1);

        curItem.giftProduct = giftProduct;
        this.setState({
            curItem: curItem
        });

    }



    /**
    * ge list type
    * @author Long.Pham 2019-06-03
    */
    getUpsellSearchAuto() {
        let self = this, searchParam = this.state.searchParam;
        var curItem = this.state.curItem;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            name: searchParam.upsells_name,
            ids: curItem.uplsells
        };
        if (Libs.isBlank(searchParam.upsells_name)) {
            self.setState({
                dataUpsellSearchAuto: []
            });
            return;
        }

        ProductService.instance.getSearchAuto(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataUpsellSearchAuto: data
                });
            } else {
                self.setState({
                    dataUpsellSearchAuto: []
                });
            }
        })
    }

    getCrossSellingSearchAuto() {
        let self = this, searchParam = this.state.searchParam;
        var curItem = this.state.curItem;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            name: searchParam.cross_selling,
            ids: curItem.crossSelling
        };
        if (Libs.isBlank(searchParam.cross_selling)) {
            self.setState({
                dataCrossSellingSearchAuto: []
            });
            return;
        }

        ProductService.instance.getSearchAuto(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataCrossSellingSearchAuto: data
                });
            } else {
                self.setState({
                    dataCrossSellingSearchAuto: []
                });
            }
        })
    }


    getGiftProductSearchAuto() {
        let self = this, searchParam = this.state.searchParam;
        var curItem = this.state.curItem;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            name: searchParam.gift_product,
            ids: curItem.giftProduct
        };
        if (Libs.isBlank(searchParam.gift_product)) {
            self.setState({
                dataGiftProductSearchAuto: []
            });
            return;
        }

        ProductService.instance.getSearchAuto(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataGiftProductSearchAuto: data
                });
            } else {
                self.setState({
                    dataGiftProductSearchAuto: []
                });
            }
        })
    }


    clearSaleOff = () => {
        var curItem = this.state.curItem;
        curItem.saleoff_to = null;
        curItem.saleoff_from = null;
        this.setState({
            curItem: curItem
        })
    }


    clearFastSetupSaleOff = () => {
        var curItem = this.state.curItem;
        curItem.fast_saleoff_from = null;
        curItem.fast_saleoff_to = null;
        this.setState({
            curItem: curItem
        })
    }



    handleInputDateChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem } = this.state;

            switch (name) {
                case 'saleoff_from':
                    if (!Libs.isBlank(curItem.saleoff_to) && Libs.compareDate(value, 'DD/MM/YYYY', curItem.saleoff_to) > 0) {
                        curItem.saleoff_from = curItem.saleoff_to;
                    } else {
                        curItem[name] = value;
                    }
                    break;

                case 'saleoff_to':
                    if (!Libs.isBlank(curItem.saleoff_from) && Libs.compareDate(curItem.saleoff_from, 'DD/MM/YYYY', value) > 0) {
                        curItem.saleoff_to = curItem.saleoff_from;
                    } else {
                        curItem[name] = value;
                    }
                    break;

                case 'fast_saleoff_from':
                    if (!Libs.isBlank(curItem.fast_saleoff_to) && Libs.compareDate(value, 'DD/MM/YYYY', curItem.fast_saleoff_to) > 0) {
                        curItem.fast_saleoff_from = curItem.fast_saleoff_to;
                    } else {
                        curItem[name] = value;
                    }
                    break;

                case 'fast_saleoff_to':
                    if (!Libs.isBlank(curItem.fast_saleoff_from) && Libs.compareDate(curItem.fast_saleoff_from, 'DD/MM/YYYY', value) > 0) {
                        curItem.fast_saleoff_to = curItem.fast_saleoff_from;
                    } else {
                        curItem[name] = value;
                    }
                    break;
            }





            this.setState({ curItem });
        }
    }



    handleInputItemDateChange(index, event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem } = this.state;
            var dataVariants = curItem.dataVariants;
            var nameFromDate = "saleoff_from_" + index;
            var nameToDate = "saleoff_to_" + index;

            var item = dataVariants[index];
            if (Libs.isObjectEmpty(item)) return;

            if (name === nameFromDate) {
                if (!Libs.isBlank(item.saleoff_to) && Libs.compareDate(value, 'DD/MM/YYYY', item.saleoff_to) > 0) {
                    item.saleoff_from = item.saleoff_to;
                } else {
                    item.saleoff_from = value;
                }
            }


            if (name === nameToDate) {
                if (!Libs.isBlank(item.saleoff_from) && Libs.compareDate(item.saleoff_from, 'DD/MM/YYYY', value) > 0) {
                    item.saleoff_to = item.saleoff_from;
                } else {
                    item.saleoff_to = value;
                }
            }

            dataVariants[index] = item;

            curItem.dataVariants = dataVariants;
            this.setState({ curItem: curItem });
        }
    }



    onCloseShowRangeDate = () => {
        if (this.state.showBasicRangeDate) {
            this.setState({
                showBasicRangeDate: false
            })
        }
    }






    /**
     * ge list type
     * @author Long.Pham 2019-06-03
     */
    getDropdownListType() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            type: 'pre_order_allowed'
        };
        TypeService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                var curItem = this.state.curItem;
                if (curItem.screen_mode === Constants.SCREEN_MODE.ADD) {
                    var item = Libs.find(data, 'is_default', 1);
                    if (!Libs.isObjectEmpty(item)) {
                        curItem.id_type = item.id
                    }
                }
                self.setState({
                    dataType: data,
                    curItem: curItem
                });
            } else {
                self.setState({
                    dataType: []
                });
            }
        })
    }






    /**
     * ge list category
     * @author Long.Pham 2019-06-03
     */
    getDropdownListCategory() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        CategoryService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataCategory: data
                });
            } else {
                self.setState({
                    dataCategory: []
                });
            }
        })
    }

    /**
     * ge list brand
     * @author Long.Pham 2019-06-03
     */
    getDropdownListBrand() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        BrandService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataBrand: data
                });
            } else {
                self.setState({
                    dataBrand: []
                });
            }
        })
    }

    /**
     * ge list unit
     * @author Long.Pham 2019-06-03
     */
    getDropdownListUnit() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        UnitService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataUnit: data
                });
            }
        })
    }


    /**
     * ge list gallery type
     * @author Long.Pham 2019-06-03
     */
    getDropdownListGalleryType() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        GalleryTypeService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataGalleryType: data
                });
            }
        })
    }


    /**
     * ge list unit
     * @author Long.Pham 2019-06-03
     */
    getDropdownListProductType() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        ProductTypeService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataProductType: data
                });
            }
        })
    }














    onClickShowPriceRangeDate = () => {
        this.setState({
            showBasicRangeDate: true
        })
    }


    onClickShowFastRangeDate = () => {
        this.setState({
            showFastRangeDate: true
        })
    }




    onCloseShowFastRangeDate = () => {
        if (this.state.showFastRangeDate) {
            this.setState({
                showFastRangeDate: false
            })
        }
    }




    onFileBannerMobileChange() {

    }

    onClickTabConfig = (index) => {
        if (!Libs.isBlank(index)) {
            this.setState({
                showConfigTab: index
            })
        }
    }
    /**
     * handle data of editor change with key
     * @param {*} content 
     * @param {*} props 
     */
    handleEditorChange(name, lang, e, editor) {
        var self = this;
        let curItem = self.state.curItem;
        var allLanguage = this.state.allLanguage;
        let dataContent = name.substr(0, 7),
            dataDescription = name.substr(0, 11),
            dataDesSize = name.substr(0, 8),
            dataPrivilege = name.substr(0, 9);
        allLanguage.map((language, index) => {
            if (lang === language.iso_code && dataContent === 'content') {
                curItem.data[index].content = e;
            }


            if (lang === language.iso_code && dataDescription === 'description') {
                curItem.data[index].description = e;
            }

            if (lang === language.iso_code && dataDesSize === 'des_size') {
                curItem.data[index].des_size = e;
            }

            if (lang === language.iso_code && dataPrivilege === 'privilege') {
                curItem.data[index].privilege = e;
            }

        });
    }

    onClickShowTab = (e, tabActive) => {
        var curItem = this.state.curItem;
        if (Libs.isBlank(tabActive)) return;
        curItem.tabActive = tabActive;

        this.setState({
            curItem: curItem
        });
    }


    /**
     * @description validate a field input
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description select change
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */

    handleInputTranslateChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            let allLanguage = this.state.allLanguage;
            let isoCode = name.substr(-2, 2),
                dataName = name.substr(0, 4),
                dataSlug = name.substr(0, 4),
                dataDescription = name.substr(0, 11),
                dataMetaTitle = name.substr(0, 10),
                dataMetaDescription = name.substr(0, 16),
                dataMetaKeyword = name.substr(0, 12),
                dataDesSize = name.substr(0, 7),
                dataPrivilege = name.substr(0, 9)
                ;

            allLanguage.map((language, index) => {
                if (isoCode === language.iso_code && dataName === 'name') {
                    item.data[index].name = (event.target.validity.valid) ? value : this.state.curItem.data[index].name;
                }

                if (isoCode === language.iso_code && dataSlug === 'slug') {
                    item.data[index].slug = (event.target.validity.valid) ? value : this.state.curItem.data[index].slug;
                }

                if (isoCode === language.iso_code && dataDescription === 'description') {
                    item.data[index].description = (event.target.validity.valid) ? value : this.state.curItem.data[index].description;
                }

                if (isoCode === language.iso_code && dataMetaTitle === 'meta_title') {
                    item.data[index].meta_title = (event.target.validity.valid) ? value : this.state.curItem.data[index].meta_title;
                }
                if (isoCode === language.iso_code && dataMetaKeyword === 'meta_keyword') {
                    item.data[index].meta_keyword = (event.target.validity.valid) ? value : this.state.curItem.data[index].meta_keyword;
                }
                if (isoCode === language.iso_code && dataMetaDescription === 'meta_description') {
                    item.data[index].meta_description = (event.target.validity.valid) ? value : this.state.curItem.data[index].meta_description;
                }

                if (isoCode === language.iso_code && dataDesSize === 'des_size') {
                    item.data[index].des_size = (event.target.validity.valid) ? value : this.state.curItem.data[index].des_size;
                }

                if (isoCode === language.iso_code && dataPrivilege === 'privilege') {
                    item.data[index].privilege = (event.target.validity.valid) ? value : this.state.curItem.data[index].privilege;
                }

                return 1;
            });

            item[name] = (event.target.validity.valid) ? value : this.state.curItem[name];

            this.setState({ curItem: item });
        }
    }




    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSave() {
        var { t } = this.props;
        var { curItem, dataLensType } = this.state, self = this;
        self.loadListAttribute();
        var screenMode = (!Libs.isBlank(curItem.id)) ? Constants.SCREEN_MODE.EDIT : ((!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD);
        curItem.screen_mode = screenMode;

        let v = new AddPopupValidate(this.props);
        let errors = await v.FLValidationAll(curItem);
        if (screenMode == Constants.SCREEN_MODE.ADD) {
            if (Libs.isBlank(curItem.upload_thumbnail) || Libs.isBlank(curItem.upload_thumbnail_name)) {
                errors.upload_thumbnail = t('products.thumbnail_mess');
            }
        }

        if (errors) {
            this.setValidateMessage(errors);
            self.toast(t('common.error'), 'error');
            return;
        }

        //remove message validation
        this.removeAllValidateMessage();

        var dataVariants = curItem.dataVariants;
        var flagError = false;

        if (Libs.isArrayData(dataVariants)) {
            for (var i = 0, len = dataVariants.length; i < len; i++) {
                if (Libs.isBlank(dataVariants[i].quantity)) {
                    dataVariants[i].errors.quantity = true;
                    flagError = true;
                }

                if (Libs.isBlank(dataVariants[i].regular_price)) {
                    dataVariants[i].errors.regular_price = true;
                    flagError = true;
                }

                if (!Libs.isBlank(dataVariants[i].saleoff_price)) {

                    if (Libs.isBlank(dataVariants[i].saleoff_from) || Libs.isBlank(dataVariants[i].saleoff_to)) {
                        dataVariants[i].errors.time_range = true;
                        flagError = true;
                    }
                }

                if (!Libs.isBlank(dataVariants[i].saleoff_from) || !Libs.isBlank(dataVariants[i].saleoff_to)) {
                    if (Libs.isBlank(dataVariants[i].saleoff_price)) {
                        dataVariants[i].errors.saleoff_price = true;
                        flagError = true;
                    }
                }

                var findSku = dataVariants.filter((item) => item.sku == dataVariants[i].sku);
                if(Libs.isBlank(dataVariants[i].sku) || findSku.length > 1){
                    dataVariants[i].errors.sku = true;
                    flagError = true;
                }
            }
        }


        curItem.dataVariants = dataVariants;
        this.setState({
            curItem: curItem
        });
        if (flagError) { self.toast(t('common.error'), 'error'); return; }

        var params = Object.assign({}, curItem);
        params.id_company = this.employee.id_company;
        params.iso_code_lang = this.employee.lang;
        params.id_language = this.employee.id_language;
        params.iso_code = this.employee.iso_code;
        params.created_by = this.employee.first_name + " " + this.employee.last_name;
        params.updated_by = this.employee.first_name + " " + this.employee.last_name;
        params.config_thumb_folder_new = this.employee.config_thumb_folder_new;
        params.config_thumb_folder_pro = this.employee.config_thumb_folder_pro;
        params.config_thumb_new_h = this.employee.config_thumb_new_h;
        params.config_thumb_pro_h = this.employee.config_thumb_pro_h;
        params.config_thumb_pro_w = this.employee.config_thumb_pro_w;
        params.config_cdn = this.employee.config_cdn;

        if (Libs.isBlank(params.sku)) { params.sku = Libs.generateSKU(this.employee.id_company, 4); }


        if (Libs.isArrayData(params.data)) {
            let itemLanguageDefault = Libs.find(params.data, 'is_default', 1);
            if (!itemLanguageDefault) return;
            params.data.map((item, index) => {
                params.data[index].name = (item.name == '') ? itemLanguageDefault.name : item.name;
                params.data[index].slug = (item.slug == '') ? itemLanguageDefault.slug : item.slug;
                params.data[index].content = (item.content == '') ? itemLanguageDefault.content : item.content;
                params.data[index].description = (item.description == '') ? itemLanguageDefault.description : item.description;
                params.data[index].meta_title = (item.meta_title == '') ? itemLanguageDefault.meta_title : item.meta_title;
                params.data[index].meta_keyword = (item.meta_keyword == '') ? itemLanguageDefault.meta_keyword : item.meta_keyword;
                params.data[index].meta_description = (item.meta_description == '') ? itemLanguageDefault.meta_description : item.meta_description;
                params.data[index].des_size = (item.des_size == '') ? itemLanguageDefault.des_size : item.des_size;
                params.data[index].privilege = (item.privilege == '') ? itemLanguageDefault.privilege : item.privilege;

            });
        } else { return false; }

        var dataLens = [];
        if (Libs.isArrayData(dataLensType)) {
            for (var i = 0, len = dataLensType.length; i < len; i++) {
                if (dataLensType[i].is_checked) {
                    dataLens.push(dataLensType[i]);
                }
                var childs = dataLensType[i].childs;

                if (Libs.isArrayData(childs)) {
                    for (var j = 0, leng = childs.length; j < leng; j++) {
                        if (childs[j].is_checked) {
                            dataLens.push(childs[j]);
                        }
                    }
                }
            }
        }

        params.dataLens = dataLens;

        ProductService.instance.save(params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onClickCloseAdd(true);
            }
            else if (data) {
                self.setValidateMessage(data);
            }
            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }
}
export default AddPopup;