
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
import CollectionService from '../../../../../services/CollectionService';
import ProductService from '../../../../../services/ProductService';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
            allLanguage: this.props.allLanguage,
            msgErrorGallery: [],
            dataCollectionProductSearchAuto: [],
            searchParam: {}
        }

        this.jsxTemplate = AddPopupJsx;
        this.handleInputSearchAutoChange = this.handleInputSearchAutoChange.bind(this);
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
        var curItem = this.state.curItem;
        if (curItem.screen_mode === Constants.SCREEN_MODE.ADD) {
            curItem.status = 1;
            curItem.galleries = [];
            curItem.collectionProduct = [];
        }
        this.setState({
            curItem: curItem
        })
    }


    deleteVideo() {
        var curItem = this.state.curItem;
        curItem.file_video_upload = null;
        curItem.file_video_name = null;
        curItem.selectedVideoFile = null;
        curItem.video = null;
        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload video
     * @author Long.Pham 12/05/2021
     */
    onUploadVideoChange = (event) => {

        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 3);
        var msgError = null;
        if (!checkExtFile) {
            msgError = t('message.msg_err_ext_video_file');
        }
        else if (fileSize <= 0) {
            msgError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_VIDEO_SIZE) {
            msgError = t('message.msg_err_max_size_video_upload');
        }

        var self = this;
        var { curItem } = this.state;
        curItem.selectedVideoFile = target.files;
        if (!Libs.isBlank(msgError)) {
            self.toast(msgError, 'error');
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            curItem.file_video_upload = e.target.result;
            curItem.file_video_name = fileName;
            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }


    onCloseShowFastRangeDate = () => {
        if(Libs.isArrayData(this.state.dataCollectionProductSearchAuto)){
            this.setState({
                dataCollectionProductSearchAuto: []
            })
        }
    }

    addCollectionProduct = (index) => {
        var { curItem, dataCollectionProductSearchAuto } = this.state;
        if (Libs.isArrayData(dataCollectionProductSearchAuto)) {
            var item = dataCollectionProductSearchAuto[index];
            if (Libs.isObjectEmpty(item)) return;

            var collectionProduct = curItem.collectionProduct;
            var findItemExits = Libs.find(collectionProduct, 'id', item.id);
            if (Libs.isObjectEmpty(findItemExits)) {
                collectionProduct.push(item);
            }
            dataCollectionProductSearchAuto.splice(index, 1);
            curItem.collectionProduct = collectionProduct;

        }
        this.setState({
            curItem: curItem
        });
    }

    onClearCollectionProductItem = (index) => {
        var curItem = this.state.curItem;
        var collectionProduct = curItem.collectionProduct;
        if (!Libs.isArrayData(collectionProduct)) return;
        var item = collectionProduct[index];
        if (Libs.isObjectEmpty(item)) return;
        collectionProduct.splice(index, 1);

        curItem.collectionProduct = collectionProduct;
        this.setState({
            curItem: curItem
        });

    }

    handleInputSearchAutoChange(event) {
        let target = event.target, self = this;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { searchParam } = this.state;
            searchParam[name] = value;
            if (name == 'name_product') {
                this.setState({ searchParam }, () => {
                    self.getCollectionProductSearchAuto();
                });
            }
        }
    }

    getCollectionProductSearchAuto() {
        let self = this, searchParam = this.state.searchParam;
        var curItem = this.state.curItem;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            name: searchParam.name_product,
            ids: curItem.CollectionProduct
        };
        if (Libs.isBlank(searchParam.name_product)) {
            self.setState({
                dataCollectionProductSearchAuto: []
            });
            return;
        }

        ProductService.instance.getSearchAuto(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataCollectionProductSearchAuto: data
                });
            } else {
                self.setState({
                    dataCollectionProductSearchAuto: []
                });
            }
        })
    }

    /**
     * @description Upload galleries
     * @author Long.Pham 12/05/2021
     */
    onUploadGalleryChange = (event) => {
        var { t } = this.props;
        var target = event.target;
        var self = this, { curItem, msgErrorGallery } = this.state;
        var files = target.files;
        if (files.length <= 0) return;

        if (files.length > Constants.COMMON.LIMIT_IMG) {
            self.toast(t('msg_err_limit_image'), "error");
            return;
        } else {
            for (const item of files) {
                if (!item || item === undefined || item === null) return;
                var fileName = item.name, fileSize = item.size;
                var checkExtFile = Libs.checkExtensionFile(fileName, 1);
                var msgFileError = null;
                if (!checkExtFile) {
                    msgFileError = t('message.msg_err_ext_image_file')
                }
                else if (fileSize <= 0) {
                    msgFileError = t('message.msg_err_file_size');
                }
                else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
                    msgFileError = t('message.msg_err_max_size_upload');
                }
                if (!Libs.isBlank(msgFileError)) {
                    msgErrorGallery.push(fileName + ": " + msgFileError);
                } else {

                    let imageParam = {
                        name: '',
                        file_name: fileName,
                        size: fileSize,
                        type: item.type,
                        file_upload: '',
                        description: ''
                    };

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        imageParam.file_upload = e.target.result;
                        if (curItem.galleries.length <= 15) {
                            curItem.galleries.push(imageParam);
                        }

                        self.setState({
                            curItem: curItem
                        });

                    };

                    reader.readAsDataURL(item);
                    self.setState({
                        curItem: curItem
                    });
                }
            }
        }




        self.setState({
            msgErrorGallery: msgErrorGallery
        }, () => {
            setTimeout(function () {
                this.setState({ msgErrorGallery: [] });
            }.bind(this), 10000);
        });
    }


    deleteItemGallery(index) {
        var { curItem } = this.state;
        var galleries = curItem.galleries;
        if (!Libs.isArrayData(galleries)) return;
        galleries.splice(index, 1);

        curItem.galleries = galleries;
        this.setState({
            curItem: curItem
        });
    }

    deleteThumbnail() {
        var { curItem } = this.state;
        curItem.upload_thumbnail = '';
        curItem.upload_thumbnail_name = '';
        this.setState({
            curItem: curItem
        });
    }
    /**
     * @description Upload thumbnail
     * @author Long.Pham 12/05/2021
     */
    onUploadThumbnailChange = (event) => {

        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgError = null;
        if (!checkExtFile) {
            msgError = t('message.msg_err_ext_image_file');
        }
        else if (fileSize <= 0) {
            msgError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgError = t('message.msg_err_max_size_image_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgError)) {
            self.toast(msgError, 'error');
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            curItem.upload_thumbnail = e.target.result;
            curItem.upload_thumbnail_name = fileName;
            self.setValidateMessage({ upload_thumbnail: '' }, true);
            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }


    deleteThumbnail1() {
        var { curItem } = this.state;
        curItem.upload_thumbnail1 = '';
        curItem.upload_thumbnail1_name = '';
        this.setState({
            curItem: curItem
        });
    }
    /**
     * @description Upload thumbnail
     * @author Long.Pham 12/05/2021
     */
    onUploadThumbnail1Change = (event) => {

        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgError = null;
        if (!checkExtFile) {
            msgError = t('message.msg_err_ext_image_file');
        }
        else if (fileSize <= 0) {
            msgError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgError = t('message.msg_err_max_size_image_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgError)) {
            self.toast(msgError, 'error');
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            curItem.upload_thumbnail1 = e.target.result;
            curItem.upload_thumbnail1_name = fileName;
            self.setValidateMessage({ upload_thumbnail1: '' }, true);
            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }


    onClickShowTab = (e, tabActive) => {
        var curItem = this.state.curItem;
        if (Libs.isBlank(tabActive)) return;
        curItem.tabActive = tabActive;

        this.setState({
            curItem: curItem
        });
    }


    /**
     * @description validate a field input
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description select change
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */

    handleInputTranslateChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            let allLanguage = this.state.allLanguage;
            let isoCode = name.substr(-2, 2),
                dataTitle = name.substr(0, 5),
                dataSlug = name.substr(0, 4),
                dataDescription = name.substr(0, 11),
                dataMetaTitle = name.substr(0, 10),
                dataMetaDescription = name.substr(0, 16),
                dataMetaKeyword = name.substr(0, 12);

            allLanguage.map((language, index) => {
                if (isoCode === language.iso_code && dataTitle === 'title') {
                    item.data[index].title = (event.target.validity.valid) ? value : this.state.curItem.data[index].title;
                }

                if (isoCode === language.iso_code && dataSlug === 'slug') {
                    item.data[index].slug = (event.target.validity.valid) ? value : this.state.curItem.data[index].slug;
                }

                if (isoCode === language.iso_code && dataDescription === 'description') {
                    item.data[index].description = (event.target.validity.valid) ? value : this.state.curItem.data[index].description;
                }

                if (isoCode === language.iso_code && dataMetaTitle === 'meta_title') {
                    item.data[index].meta_title = (event.target.validity.valid) ? value : this.state.curItem.data[index].meta_title;
                }
                if (isoCode === language.iso_code && dataMetaKeyword === 'meta_keyword') {
                    item.data[index].meta_keyword = (event.target.validity.valid) ? value : this.state.curItem.data[index].meta_keyword;
                }
                if (isoCode === language.iso_code && dataMetaDescription === 'meta_description') {
                    item.data[index].meta_description = (event.target.validity.valid) ? value : this.state.curItem.data[index].meta_description;
                }

                return 1;
            });

            item[name] = (event.target.validity.valid) ? value : this.state.curItem[name];

            this.setState({ curItem: item });
        }
    }


    handleEditorChange(name, lang, e, editor) {
        var self = this;
        let curItem = self.state.curItem;
        var allLanguage = this.state.allLanguage;
        let dataDescription = name.substr(0, 11);
        allLanguage.map((language, index) => {
            if (lang === language.iso_code && dataDescription === 'description') {
                curItem.data[index].description = e;
            }
        });
    }

    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSave() {
        var curItem = this.state.curItem, self = this;
        var params = Object.assign({}, this.state.curItem);
        var screenMode = (!Libs.isBlank(curItem.id)) ? Constants.SCREEN_MODE.EDIT : ((!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD);
        let v = new AddPopupValidate(this.props);
        let errors = await v.FLValidationAll(curItem);
        if (errors) {
            this.setValidateMessage(errors);
            return;
        }
        //remove message validation
        this.removeAllValidateMessage();
        params.screen_mode = screenMode;

        params.id_company = this.employee.id_company;
        params.iso_code_lang = this.employee.lang;
        params.id_language = this.employee.id_language;
        params.iso_code = this.employee.iso_code;
        params.created_by = this.employee.first_name + " " + this.employee.last_name;
        params.updated_by = this.employee.first_name + " " + this.employee.last_name;
        params.config_thumb_folder_new = this.employee.config_thumb_folder_new;
        params.config_thumb_folder_pro = this.employee.config_thumb_folder_pro;
        params.config_thumb_new_h = this.employee.config_thumb_new_h;
        params.config_thumb_pro_h = this.employee.config_thumb_pro_h;
        params.config_thumb_pro_w = this.employee.config_thumb_pro_w;
        params.config_cdn = this.employee.config_cdn;

        if (Libs.isArrayData(params.data)) {
            let itemLanguageDefault = Libs.find(params.data, 'is_default', 1);
            if (!itemLanguageDefault) return;

            params.data.map((item, index) => {
                params.data[index].title = (item.title === '') ? itemLanguageDefault.title : item.title;
                params.data[index].slug = (item.slug === '') ? itemLanguageDefault.slug : item.slug;
                params.data[index].description = (item.description === '') ? itemLanguageDefault.description : item.description;
                params.data[index].meta_title = (item.meta_title === '') ? itemLanguageDefault.meta_title : item.meta_title;
                params.data[index].meta_keyword = (item.meta_keyword === '') ? itemLanguageDefault.meta_keyword : item.meta_keyword;
                params.data[index].meta_description = (item.meta_description === '') ? itemLanguageDefault.meta_description : item.meta_description;
                return params;

            });
        } else { return false; }


        CollectionService.instance.save(params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onClickCloseAdd(true);
            }
            else if (data) {
                self.setValidateMessage(data);
            }

            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }

}
export default AddPopup;