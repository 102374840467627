// var baseUrl = window.location.protocol + "//" + window.location.host;
// var url = window.location.hostname;
// var serverAPI = 'crm.kinhmatlily.com';
// switch (url) {
//     case 'www.crm.kinhmatlily.com':
//     case 'crm.kinhmatlily.com':
//         serverAPI = 'https://api.kinhmatlily.com';
//         break;
//     default:
//         serverAPI = 'http://localhost:3009';
//         break;
// }

const Constants = {
    "API_HOST": process.env.REACT_APP_API_HOST,
    "JOB_HOST": process.env.REACT_APP_JOB_HOST,
    "JOB_HOST_AUTH": process.env.REACT_APP_JOB_HOST_AUTH,
    "TINY_API_KEY": "w1ac8su5n6gkls7pzfyeg9b7ovfjb6988fm90c8mtr3zs8wt",
    "STATIC_KEY" : "6UTcKodwS4mSBIZgI9pC11vLfcTobNkz5ivqVVGn0PiC44Hev9w3vGZ4NU1RZqQL",
    "COMMON": {
        "EMPLOYEE_INFO": "employee-info",
        "LIMIT_IMG": 15,
        "MAX_IMAGE_SIZE": 1048576,
        "MAX_VIDEO_SIZE": 62914560,
        "LIMIT": 30,
        "TOKEN": "cms-access-token",
        "ACCESS_PARAM": "access-param"
    },
    "URL": {
        "FILES": {
            "UPLOAD_IMG": "/FileUpload/saveUploadImage"
        },

        "PROVINCE": {
            "LIST": "/Province/getList"
        },
        "DISTRICT": {
            "LIST": "/District/getList"
        },
        "WARD": {
            "LIST": "/Ward/getList"
        },

        "EMPLOYEE": {
            "LOGIN": "/auth/login",
            "FORGOT_PASSWORD": "/employee/forgotPassword",
            "RESET_PASSWORD": "/employee/resetPassword",
            "DETAIL_UPDATE_PROFILE": "/employee/getDetailUpdateProfile",
            "SAVE_UPDATE_PROFILE": "/employee/saveUpdateProfileAction",
            "CHANGE_PASSWORD": "/employee/changePassword",
            "LIST": "/employee/getList",
            "SAVE": "/employee/saveAction",
            "UPDATE_STATUS": "/employee/updateStatus",
            "DELETE": "/employee/deleteAction",
            "DETAIL": "/employee/getDetail",
        },
        "REGENCY": {
            "SAVE": "/regency/saveAction",
            "LIST": "/regency/getList",
            "UPDATE_STATUS": "/regency/updateStatus",
            "DELETE": "/regency/deleteAction",
            "DETAIL": "/regency/getDetail",
            "DROPDOWN_LIST": "/regency/getDropDownList"
        },
        "ROLE": {
            "SAVE": "/role/saveAction",
            "LIST": "/role/getList",
            "UPDATE_STATUS": "/role/updateStatus",
            "DELETE": "/role/deleteAction",
            "DETAIL": "/role/getDetail",
            "DROPDOWN_LIST": "/role/getDropDownList",
            "PERMISSION": "/role/getListScreenPermissions",
            "UPDATE_PERMISSIONS": "/role/updateRolePermissions",
            "UPDATE_ROLE_MAP_SCREEN": "/role/updateRoleMapScreen"
        },
        "CATEGORY": {
            "SAVE": "/category/saveAction",
            "LIST": "/category/getList",
            "UPDATE_STATUS": "/category/updateStatus",
            "DELETE": "/category/deleteAction",
            "DETAIL": "/category/getDetail",
            "DROPDOWN_LIST": "/category/getDropDownList",
            "DROPDOWN_LIST_CATEGORY": "/category/getDropDownListCategory"
        },


        "GLASS_LENS": {
            "SAVE": "/GlassLensType/saveAction",
            "LIST": "/GlassLensType/getList",
            "UPDATE_STATUS": "/GlassLensType/updateStatus",
            "DELETE": "/GlassLensType/deleteAction",
            "DETAIL": "/GlassLensType/getDetail",
            "DROPDOWN_LIST": "/GlassLensType/getDropDownList",
            "LENS_TYPE_LIST": "/GlassLensType/getListGlassLensType"
        },


        "NEWS_CATEGORY": {
            "SAVE": "/NewsCategory/saveAction",
            "LIST": "/NewsCategory/getList",
            "UPDATE_STATUS": "/NewsCategory/updateStatus",
            "DELETE": "/NewsCategory/deleteAction",
            "DETAIL": "/NewsCategory/getDetail",
            "DROPDOWN_LIST": "/NewsCategory/getDropDownList",
            "DROPDOWN_LIST_CATEGORY": "/NewsCategory/getDropDownListCategory"
        },

        "NEWS": {
            "SAVE": "/News/saveAction",
            "LIST": "/News/getList",
            "UPDATE_STATUS": "/News/updateStatus",
            "DELETE": "/News/deleteAction",
            "DETAIL": "/News/getDetail"
        },

        "COLLECTION": {
            "SAVE": "/Collection/saveAction",
            "LIST": "/Collection/getList",
            "UPDATE_STATUS": "/Collection/updateStatus",
            "DELETE": "/Collection/deleteAction",
            "DETAIL": "/Collection/getDetail"
        },

        "PAGES": {
            "SAVE": "/Pages/saveAction",
            "LIST": "/Pages/getList",
            "UPDATE_STATUS": "/Pages/updateStatus",
            "DELETE": "/Pages/deleteAction",
            "DETAIL": "/Pages/getDetail"
        },

        
        "GROUP_ATTRIBUTES": {
            "SAVE": "/GroupAttributes/saveAction",
            "LIST": "/GroupAttributes/getList",
            "UPDATE_STATUS": "/GroupAttributes/updateStatus",
            "DELETE": "/GroupAttributes/deleteAction",
            "DETAIL": "/GroupAttributes/getDetail",
            "DROPDOWN_LIST": "/GroupAttributes/getDropDownList",
            "DROPDOWN_LIST_ATTR": "/GroupAttributes/getDropDownListAllAttr"
        },

        "BANNER_POSITION": {
            "SAVE": "/BannerPosition/saveAction",
            "LIST": "/BannerPosition/getList",
            "UPDATE_STATUS": "/BannerPosition/updateStatus",
            "DELETE": "/BannerPosition/deleteAction",
            "DETAIL": "/BannerPosition/getDetail",
            "DROPDOWN_LIST": "/BannerPosition/getDropDownList"
        },

        "CUSTOMER_GROUP": {
            "SAVE": "/CustomerGroup/saveAction",
            "LIST": "/CustomerGroup/getList",
            "UPDATE_STATUS": "/CustomerGroup/updateStatus",
            "DELETE": "/CustomerGroup/deleteAction",
            "DETAIL": "/CustomerGroup/getDetail",
            "DROPDOWN_LIST": "/CustomerGroup/getDropDownList"
        },

        "CUSTOMER_TYPE": {
            "SAVE": "/CustomerType/saveAction",
            "LIST": "/CustomerType/getList",
            "UPDATE_STATUS": "/CustomerType/updateStatus",
            "DELETE": "/CustomerType/deleteAction",
            "DETAIL": "/CustomerType/getDetail",
            "DROPDOWN_LIST": "/CustomerType/getDropDownList"
        },

        "COUPON": {
            "SAVE": "/Coupon/saveAction",
            "LIST": "/Coupon/getList",
            "UPDATE_STATUS": "/Coupon/updateStatus",
            "DELETE": "/Coupon/deleteAction",
            "DETAIL": "/Coupon/getDetail",
            "APPLY_COUPON":  "/Coupon/getAppleCoupon"
        },

        "PROMOTION": {
            "SAVE": "/Promotion/saveAction",
            "LIST": "/Promotion/getList",
            "UPDATE_STATUS": "/Promotion/updateStatus",
            "DELETE": "/Promotion/deleteAction",
            "DETAIL": "/Promotion/getDetail"
        },


        "CUSTOMER": {
            "SAVE": "/Customer/saveAction",
            "LIST": "/Customer/getList",
            "UPDATE_STATUS": "/Customer/updateStatus",
            "DELETE": "/Customer/deleteAction",
            "DETAIL": "/Customer/getDetail",
            "DROPDOWN_LIST": "/Customer/getDropDownList",
            "SEARCH_AUTO": "/Customer/getSearchAuto",
            "SAVE_CUSTOMER_ORDER": "/Customer/saveCustomerOrder"
        },

        "NEWSLETTER": {
            "SAVE": "/Newsletter/saveAction",
            "LIST": "/Newsletter/getList",
            "UPDATE_STATUS": "/Newsletter/updateStatus",
            "DELETE": "/Newsletter/deleteAction",
            "DETAIL": "/Newsletter/getDetail"
        },


        "BANNER": {
            "SAVE": "/Banner/saveAction",
            "LIST": "/Banner/getList",
            "UPDATE_STATUS": "/Banner/updateStatus",
            "DELETE": "/Banner/deleteAction",
            "DETAIL": "/Banner/getDetail"
        },


        "CUSTOMER_REVIEW": {
            "SAVE": "/CustomerReview/saveAction",
            "LIST": "/CustomerReview/getList",
            "UPDATE_STATUS": "/CustomerReview/updateStatus",
            "DELETE": "/CustomerReview/deleteAction",
            "DETAIL": "/CustomerReview/getDetail"
        },

        "PARTNER_CUSTOMER": {
            "SAVE": "/PartnerCustomer/saveAction",
            "LIST": "/PartnerCustomer/getList",
            "UPDATE_STATUS": "/PartnerCustomer/updateStatus",
            "DELETE": "/PartnerCustomer/deleteAction",
            "DETAIL": "/PartnerCustomer/getDetail"
        },


        "TYPE": {
            "DROPDOWN_LIST": "/Type/getDropdownList",
            "TYPE_KEY_STRS": "/Type/getListTypeKeyArr"
        },

        "PAYMENT_MOTHOD": {
            "DROPDOWN_LIST": "/PaymentMethod/getDropdownList"
        },
        "ORDER_STATUS" : {
            "DROPDOWN_LIST": "/OrderStatus/getDropdownList"
        },

        "PAYMENT_STATUS" : {
            "DROPDOWN_LIST": "/PaymentStatus/getDropdownList"
        },

        "PRODUCT_TYPE": {
            "SAVE": "/ProductType/saveAction",
            "LIST": "/ProductType/getList",
            "UPDATE_STATUS": "/ProductType/updateStatus",
            "DELETE": "/ProductType/deleteAction",
            "DETAIL": "/ProductType/getDetail",
            "DROPDOWN_LIST": "/ProductType/getDropDownList"
        },

        "ATTRIBUTES": {
            "SAVE": "/attributes/saveAction",
            "LIST": "/attributes/getList",
            "UPDATE_STATUS": "/attributes/updateStatus",
            "DELETE": "/attributes/deleteAction",
            "DETAIL": "/attributes/getDetail",
            "DROPDOWN_LIST": "/attributes/getDropDownList"
        },

        "BRAND": {
            "SAVE": "/brand/saveAction",
            "LIST": "/brand/getList",
            "UPDATE_STATUS": "/brand/updateStatus",
            "DELETE": "/brand/deleteAction",
            "DETAIL": "/brand/getDetail",
            "DROPDOWN_LIST": "/brand/getDropDownList"
        },

        "UNIT": {
            "SAVE": "/unit/saveAction",
            "LIST": "/unit/getList",
            "UPDATE_STATUS": "/unit/updateStatus",
            "DELETE": "/unit/deleteAction",
            "DETAIL": "/unit/getDetail",
            "DROPDOWN_LIST": "/unit/getDropDownList"
        },

        "GALLERY_TYPE": {
            "SAVE": "/GalleryType/saveAction",
            "LIST": "/GalleryType/getList",
            "UPDATE_STATUS": "/GalleryType/updateStatus",
            "DELETE": "/GalleryType/deleteAction",
            "DETAIL": "/GalleryType/getDetail",
            "DROPDOWN_LIST": "/GalleryType/getDropDownList"
        },

        "TAGS": {
            "SAVE": "/tags/saveAction",
            "LIST": "/tags/getList",
            "UPDATE_STATUS": "/tags/updateStatus",
            "DELETE": "/tags/deleteAction",
            "DETAIL": "/tags/getDetail",
            "DROPDOWN_LIST": "/tags/getDropDownList",
            "SEARCH_AUTO": "/tags/getSearchAuto",
        },

        "CUSTOM_FIELD": {
            "SAVE": "/CustomField/saveAction",
            "LIST": "/CustomField/getList",
            "UPDATE_STATUS": "/CustomField/updateStatus",
            "DELETE": "/CustomField/deleteAction",
            "DETAIL": "/CustomField/getDetail",
            "DROPDOWN_LIST": "/CustomField/getDropDownList",
            "GROUP_CUSTOM_FIELD": "/CustomField/getListGroupCustomField",
            "SAVE_FIELD": "/CustomField/saveFieldAction",
            
        },

        "SPECIFICATION": {
            "SAVE": "/specification/saveAction",
            "LIST": "/specification/getList",
            "UPDATE_STATUS": "/specification/updateStatus",
            "DELETE": "/specification/deleteAction",
            "DETAIL": "/specification/getDetail",
            "DROPDOWN_LIST": "/specification/getDropDownList"
        },

        "PRODUCT": {
            "SEARCH_AUTO": "/Product/getSearchAuto",
            "SAVE": "/Product/saveAction",
            "LIST": "/Product/getList",
            "ALL_ID": "/Product/getAllID",
            "UPDATE_STATUS": "/Product/updateStatus",
            "DELETE": "/Product/deleteAction",
            "DETAIL": "/Product/getDetail",
            "EXPORT_TO_EXCEL": "/Product/exportToExcel",
            "UPDATE_IMPORT": "/Product/updateImport",
            "IMAGE_BASE64": "/Product/getBase64Image"
        },

        "ORDERS": {
            "SEARCH_PRODUCT_AUTO": "/Orders/getSearchProductAuto",
            "SAVE": "/Orders/saveAction",
            "LIST": "/Orders/getList",
            // "UPDATE_STATUS": "/Orders/updateStatus",
            // "DELETE": "/Orders/deleteAction",
            "DETAIL": "/Orders/getDetail",
            "PRINT": "/Orders/exportPrint"
        },

        "POPUP": {
            "SAVE": "/Popup/saveAction",
            "LIST": "/Popup/getList",
            "UPDATE_STATUS": "/Popup/updateStatus",
            "DELETE": "/Popup/deleteAction",
            "DETAIL": "/Popup/getDetail",
            "DROPDOWN_LIST": "/Popup/getDropDownList"
        },
        "EMAIL_TEMPLATE": {
            "SAVE": "/EmailTemplate/saveAction",
            "LIST": "/EmailTemplate/getList",
            "UPDATE_STATUS": "/EmailTemplate/updateStatus",
            "DELETE": "/EmailTemplate/deleteAction",
            "DETAIL": "/EmailTemplate/getDetail",
            "DROPDOWN_LIST": "/EmailTemplate/getDropDownList"
        },
    },

    "SITE_URL": {
        "PUBLIC_URL": ['/', '/forgot-password', '/reset-password'],
        "LOGIN": "/",
        "DEFAULT_PAGE": "/dashboard"
    },

    "CONTENT_TYPE": {
        json: "application/json",
        stream: "application/octet-stream",
        excel: "application/vnd.ms-excel",
        multipart: "multipart/form-data"
    },

    "METHOD": {
        POST: "post",
        GET: "get",
        DELETE: "delete",
        PUT: "put",
        POSTEXCEL: "postexcel",
        POSTPDF: "postpdf"
    },

    "ERROR_MSG_TIMEOUT": 8000,

    "ERROR_CODE": {
        "CODE_01": 1,
        "CODE_02": 1,
    },

    "SCREEN_MODE": {
        "VIEW": 0,
        "ADD": 1,
        "EDIT": 2,
        "UPDATE_IMPORT": 3
    },
    "AUTH_MODE": {
        "VIEW": 0,
        "NEW": 1,
        "DEL": 2,
        "EDIT": 3,
        "EXCEL": 4,
        "PDF": 5,
        "PRINT": 6,
        "TRANSLATE": 7,
        "APPROVAL": 8,
        "SET": 9,
        "FULL" : 511
    },
    "AUTH_DATA_KEY" : {
        "VIEW": "view",
        "NEW": "new",
        "EDIT": "edit",
        "DEL": "delete",
        "PRINT": "print",
        "PDF": "pdf",
        "EXCEL": "excel",
        "TRANSLATE": "translate",
        "APPROVAL":"approval",
        "FULL": "auths"
    },
};
export default Constants;