import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RText, RButton } from '../../../../../components/Controls';
import FormReactSelect from '../../../../../components/FormReactSelect';
import Libs from '../../../../../utils/Libs';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import Constants from '../../../../../utils/Constants';
import AddRow from './AddRow'
export default function AddPopup() {
    var { t } = this.props;
    var { curItem, dataType, dataStores, dataPaymentMethod, dataCategories, listType, dataListAddRow, allStores, allProducts, allSupplier, paymentMethod, manualDiscountType } = this.state;
    console.log('data row list aa: ', this.props.dataListAddRow);

    // Add row
    var addRows = null;
    var total = 0;
    function compareValues(key, order = 'asc') {
        return function (a, b) {
            if (!a.hasOwnProperty(key) ||
                !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order == 'desc') ?
                    (comparison * -1) : comparison
            );
        };
    }

    if (Libs.isArrayData(dataListAddRow)) {
        addRows = dataListAddRow.sort(compareValues('index', 'desc')).map((item, index) => {
            total = total + (item.quantity * item.price);
            return <AddRow
                key={'row_item_' + index}
                index={index}
                dataItem={item}
                curItem={curItem}
                allStores={allStores}
                allProducts={allProducts}
                removeItemRow={this.removeItemRow}
                onRowSelectChange={this.onRowSelectChange}
                handleRowInputChange={this.handleRowInputChange}
                validateOneRow={this.validateOneRow}
            />
        });
    }

    return (
        <React.Fragment>
            <Modal visible={true} className="modal-add-small" dialogClassName="modal-dialog-scrollable modal-xl form-add-export-stock full-screen">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {curItem.screen_mode == Constants.SCREEN_MODE.EDIT ? t('export_stock.label_edit')
                            : (curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? t('export_stock.label_view') : t('export_stock.label_add'))
                        }</h5>
                    <span className="close" onClick={this.props.onCloseAddPopup.bind(this)}><var className="icon-cancel-music"></var></span>
                </div>

                <div className="modal-body">
                    <div className="export-stock-info">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <div className="info">
                                    <h2>Thông tin</h2>
                                    <div className="info-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <FormReactSelect
                                                        label={t('export_stock.id_type')}
                                                        instanceId="id_type"
                                                        required="required"
                                                        className="id_type"
                                                        name="id_type"
                                                        value={dataType.filter(function (item) { return item.id === curItem.id_type })}
                                                        onChange={(e) => { this.handleDropdownChange(e, 'id_type') }}
                                                        optionList={dataType}
                                                        placeHolder={t('common.choose')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <RText label={t('export_stock.id_code')}
                                                        inputClass="form-control"
                                                        required="required"
                                                        inputId="iso_code" inputName="iso_code"
                                                        value={curItem.iso_code}
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <CMSDatePicker
                                                        label={t("export_stock.date")}
                                                        inputId="import_date"
                                                        required="required"
                                                        inputClass="form-control"
                                                        inputName="import_date"
                                                        maxLength={20}
                                                        value={curItem.import_date}
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        handleChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        showIconCalendar={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <FormReactSelect
                                                        label={t('export_stock.id_stores')}
                                                        instanceId="id_stores"
                                                        required="required"
                                                        className="id_stores"
                                                        name="id_stores"
                                                        value={dataStores.filter(function (item) { return item.id === curItem.id_stores })}
                                                        onChange={(e) => { this.handleDropdownChange(e, 'id_stores') }}
                                                        optionList={dataStores}
                                                        placeHolder={t('common.choose')}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <RText label={t('export_stock.obj')}
                                                        inputClass="form-control"
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        inputId="obj" inputName="obj"
                                                        value={curItem.obj}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                        maxLength={500} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <RText label={t('export_stock.description')}
                                                        inputClass="form-control"
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        inputId="description" inputName="description"
                                                        value={curItem.description}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                        maxLength={500} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                <div className="info">
                                    <h2>Thanh toán</h2>
                                    <div className="info-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <RText label={t('import_stock.vat')}
                                                        inputClass="form-control"
                                                        inputId="vat" inputName="vat"
                                                        value={curItem.vat}
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <div className="mb-3">
                                                    <FormReactSelect
                                                        label={t('export_stock.payment_method')}
                                                        instanceId="payment_method"
                                                        required="required"
                                                        className="payment_method"
                                                        name="payment_method"
                                                        value={dataPaymentMethod.filter(function (item) { return item.id === curItem.payment_method })}
                                                        onChange={(e) => { this.handleDropdownChange(e, 'payment_method') }}
                                                        optionList={dataPaymentMethod}
                                                        placeHolder={t('common.choose')}
                                                    />

                                                </div>
                                            </div>

                                        </div>

                                        <div className="tax-code-info">
                                            <h3>{t('export_stock.tax_code_info')}</h3>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <RText label={t('export_stock.company_name')}
                                                            inputClass="form-control"
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            inputId="company_name" inputName="company_name"
                                                            value={curItem.company_name}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={200} />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <RText label={t('export_stock.tax_code')}
                                                            inputClass="form-control"
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            inputId="tax_code" inputName="tax_code"
                                                            value={curItem.tax_code}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={200} />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <RText label={t('export_stock.address')}
                                                            inputClass="form-control"
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            inputId="address" inputName="address"
                                                            value={curItem.address}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={200} />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <RText label={t('export_stock.phone')}
                                                            inputClass="form-control"
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            inputId="phone" inputName="phone"
                                                            value={curItem.phone}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={200} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>


                    <div className="add-stock-product">
                        <div className="import-stock-search-product">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <FormReactSelect
                                            instanceId="id_categories"
                                            required="required"
                                            className="id_categories"
                                            name="id_categories"
                                            value={dataCategories.filter(function (item) { return item.id === curItem.id_categories })}
                                            onChange={(e) => { this.handleDropdownChange(e, 'id_categories') }}
                                            optionList={dataCategories}
                                            placeHolder={t('common.choose')}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="mb-3">
                                        <div className="data-search">
                                            <RText
                                                placeholder={t('import_stock.keyword_search_product')}
                                                inputClass="form-control"
                                                required="required"
                                                inputId="keyword_search_product" inputName="keyword_search_product"
                                                value={curItem.keyword_search_product}
                                                disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                maxLength={200} />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="header-add-product">
                            <div className="header-col width25">
                                {t('export_stock.product_name')}
                            </div>

                            <div className="header-col width20">
                                {t('export_stock.unit')}
                            </div>

                            <div className="header-col width10">
                                {t('export_stock.quantity')}
                            </div>

                            <div className="header-col width20">
                                {t('export_stock.price')}
                            </div>

                            <div className="header-col width20 text-end">
                                {t('export_stock.total_sub')}
                            </div>

                            <div className="header-col text-end width5">

                            </div>

                        </div>
                        <div className="body">
                            {addRows}

                            <div className="body-row">
                                <div className="body-col width45 text-end"><strong>Tổng</strong></div>
                                <div className="body-col width10">100</div>
                                <div className="body-col width20"></div>
                                <div className="body-col width20 text-end">{Libs.formatNum(total, "#,###.##", 1)} <span>vnđ</span></div>
                                <div className="body-col text-end width5"></div>
                            </div>

                            <div className="body-row">
                                <div className="body-col width75 text-end"><strong>VAT(%)</strong></div>
                                <div className="body-col width20 text-end">10</div>
                                <div className="body-col text-end width5"></div>
                            </div>

                            <div className="body-row">
                                <div className="body-col width75 text-end"><strong>Số tiền cần thanh toán</strong></div>
                                <div className="body-col width20 text-end">{Libs.formatNum(total, "#,###.##", 1)} <span>vnđ</span></div>
                                <div className="body-col text-end width5"></div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="modal-footer">
                    <RButton
                        onClick={this.onCloseAddPopup.bind(this)}
                        className="btn btn-cancel"
                        text={t('common.cancel')}
                        data-dismiss="modal" aria-hidden="true"
                        title={t('common.cancel')} />

                    {curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true :
                        <RButton
                            onClick={this.onSaveAction.bind(this)}
                            className="btn btn-save"
                            text={t('common.save')}
                            title={t('common.save')} />
                    }

                </div>
            </Modal>
        </React.Fragment >
    )
}