
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
import BannerService from '../../../../../services/BannerService';
import BannerPositionService from '../../../../../services/BannerPositionService';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
            allLanguage: this.props.allLanguage,
            dataBannerPosition: [],
            dataType: [
                {
                    id: 1,
                    value: 1,
                    label: "Image"
                },
                {
                    id: 2,
                    value: 2,
                    label: "Video"
                }
            ]
        }

        this.jsxTemplate = AddPopupJsx;
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
        var curItem = this.state.curItem;
        if (curItem.screen_mode === Constants.SCREEN_MODE.ADD) {
            curItem.type = 1;
        }
        this.getDataBannerPosition();
    }


    deleteVideo(){
        var curItem = this.state.curItem;
        curItem.file_video_upload = null;
        curItem.file_video_name = null;
        curItem.video_full = null;
        curItem.video = null;
        curItem.selectedVideoFile = null;
        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload video
     * @author Long.Pham 12/05/2021
     */
    onUploadVideoChange = (event) => {

        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 3);
        var msgError = null;
        if (!checkExtFile) {
            msgError = t('message.msg_err_ext_video_file');
        }
        else if (fileSize <= 0) {
            msgError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_VIDEO_SIZE) {
            msgError = t('message.msg_err_max_size_video_upload');
        }

        var self = this;
        var { curItem } = this.state;
        curItem.selectedVideoFile = target.files;
        if (!Libs.isBlank(msgError)) {
            self.toast(msgError, 'error');
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            curItem.file_video_upload = e.target.result;
            curItem.file_video_name = fileName;
            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }


    /**
         * ge list roles
         * @author Long.Pham 2019-06-03
         */
     getDataBannerPosition =() => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        BannerPositionService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataBannerPosition: data
                });
            }
        })
    }

    onClickShowTab = (e, tabActive) => {
        var curItem = this.state.curItem;
        if (Libs.isBlank(tabActive)) return;
        curItem.tabActive = tabActive;

        this.setState({
            curItem: curItem
        });
    }


    /**
     * @description validate a field input
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description select change
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */

    handleInputTranslateChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            let allLanguage = this.state.allLanguage;
            let isoCode = name.substr(-2, 2),
                dataName = name.substr(0, 4),
                dataLink = name.substr(0, 4),
                dataDescription = name.substr(0, 11);

            allLanguage.map((language, index) => {
                if (isoCode === language.iso_code && dataName === 'name') {
                    item.data[index].name = (event.target.validity.valid) ? value : this.state.curItem.data[index].name;
                }

                if (isoCode === language.iso_code && dataLink === 'link') {
                    item.data[index].link = (event.target.validity.valid) ? value : this.state.curItem.data[index].link;
                }

                if (isoCode === language.iso_code && dataDescription === 'description') {
                    item.data[index].description = (event.target.validity.valid) ? value : this.state.curItem.data[index].description;
                }

                return 1;
            });

            item[name] = (event.target.validity.valid) ? value : this.state.curItem[name];

            this.setState({ curItem: item });
        }
    }


    handleDropdownChange = (item, name) => {
        var self = this;
        let curItem = self.state.curItem;

        if (Libs.isObjectEmpty(item)) {
            curItem.id_banner_position = '';
        } else {
            var value = item.value;
            curItem[name] = value;
        }

        self.setState({
            curItem: curItem
        });
    }



    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSave() {
        var curItem = this.state.curItem, self = this;
        var params = Object.assign({}, this.state.curItem);
        var screenMode = (!Libs.isBlank(curItem.id)) ? Constants.SCREEN_MODE.EDIT : ((!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD);
        let v = new AddPopupValidate(this.props);
        let errors = await v.FLValidationAll(curItem);
        if (errors) {
            this.setValidateMessage(errors);
            return;
        }
        //remove message validation
        this.removeAllValidateMessage();
        params.screen_mode = screenMode;

        params.id_company = this.employee.id_company;
        params.iso_code_lang = this.employee.lang;
        params.id_language = this.employee.id_language;
        params.iso_code = this.employee.iso_code;
        params.created_by = this.employee.first_name + " " + this.employee.last_name;
        params.updated_by = this.employee.first_name + " " + this.employee.last_name;
        params.config_thumb_folder_new = this.employee.config_thumb_folder_new;
        params.config_thumb_folder_pro = this.employee.config_thumb_folder_pro;
        params.config_thumb_new_h = this.employee.config_thumb_new_h;
        params.config_thumb_pro_h = this.employee.config_thumb_pro_h;
        params.config_thumb_pro_w = this.employee.config_thumb_pro_w;
        params.config_cdn = this.employee.config_cdn;

        if (Libs.isArrayData(params.data)) {
            let itemLanguageDefault = Libs.find(params.data, 'is_default', 1);
            if (!itemLanguageDefault) return;

            params.data.map((item, index) => {
                params.data[index].name = (item.name === '') ? itemLanguageDefault.name : item.name;
                params.data[index].link = (item.link === '') ? itemLanguageDefault.link : item.link;
                params.data[index].description = (item.description === '') ? itemLanguageDefault.description : item.description;
                return params;

            });
        } else { return false; }


        BannerService.instance.save(params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onClickCloseAdd(true);
            }
            else if (data) {
                self.setValidateMessage(data);
            }

            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }

    /**
     * @description Delete banner desktop upload 
     * @author Long.Pham 12/05/2021
     */
    onClickDeleteBannerDesktop = (event, language) => {
        var { curItem } = this.state;
        if (Libs.isArrayData(curItem.data)) {
            var data = curItem.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].iso_code === language) {
                    data[i].banner_desktop_message = '';
                    data[i].banner_desktop_upload = '';
                    data[i].banner_desktop = null;
                    data[i].banner_desktop_full = null;
                }
            }
            curItem.data = data;
        }

        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload bannner desktop
     * @author Long.Pham 12/05/2021
     */
    onFileBannerDesktopChange = (event, language) => {
        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgBannerDesktopError = null;
        if (!checkExtFile) {
            msgBannerDesktopError = t('message.msg_err_ext_image_file')
        }
        else if (fileSize <= 0) {
            msgBannerDesktopError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgBannerDesktopError = t('message.msg_err_max_size_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgBannerDesktopError)) {
            curItem.banner_desktop_upload = '';
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_desktop_message = msgBannerDesktopError;
                    }
                }
                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });

            return;
        }

        else {
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_desktop_message = '';
                    }
                }

                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });
        }

        //Read file upload
        var reader = new FileReader();
        reader.onload = function (e) {
            if (Libs.isArrayData(curItem.data)) {
                var data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_desktop_upload = e.target.result;
                        data[i].banner_desktop_name = fileName;
                    }
                }
                curItem.data = data;
            }

            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }



    /**
     * @description Delete banner mobile upload 
     * @author Long.Pham 12/05/2021
     */
    onClickDeleteBannerMobile = (event, language) => {
        var { curItem } = this.state;
        if (Libs.isArrayData(curItem.data)) {
            var data = curItem.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].iso_code === language) {
                    data[i].banner_mobile_message = '';
                    data[i].banner_mobile_upload = '';
                    data[i].banner_mobile = null;
                    data[i].banner_mobile_full = null;
                }
            }
            curItem.data = data;
        }

        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload bannner mobile
     * @author Long.Pham 12/05/2021
     */
    onFileBannerMobileChange = (event, language) => {
        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgBannerMobileError = null;
        if (!checkExtFile) {
            msgBannerMobileError = t('message.msg_err_ext_image_file')
        }
        else if (fileSize <= 0) {
            msgBannerMobileError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgBannerMobileError = t('message.msg_err_max_size_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgBannerMobileError)) {
            curItem.banner_mobile_upload = '';
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_mobile_message = msgBannerMobileError;
                    }
                }
                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });

            return;
        }

        else {
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_mobile_message = '';
                    }
                }

                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });
        }

        //Read file upload
        var reader = new FileReader();
        reader.onload = function (e) {
            if (Libs.isArrayData(curItem.data)) {
                var data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_mobile_upload = e.target.result;
                        data[i].banner_mobile_name = fileName;
                    }
                }
                curItem.data = data;
            }

            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }

}
export default AddPopup;