
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import AddFieldPopupJsx from './AddFieldPopup.jsx';
import AddFieldPopupValidate from './AddFieldPopupValidate';
import SpecificationService from '../../../../../services/SpecificationService';

class AddFieldPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItemAddField,
            allLanguage: this.props.allLanguage,
            fieldType: [
                {
                    id: "text",
                    value: "text",
                    label: "Text"
                },
                {
                    id: "textarea",
                    value: "textarea",
                    label: "Text Area"
                },
                {
                    id: "number",
                    value: "number",
                    label: "Number"
                },

                {
                    id: "wysiwyg",
                    value: "wysiwyg",
                    label: "Wysiwyg Editor"
                },
            ]
        }

        this.paging = {
            total: 0,
            current: 1,
            currentInput: 1
        };

        this.jsxTemplate = AddFieldPopupJsx;
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    handleDropdownChange = (item, name) => {
        var self = this;
        let curItem = self.state.curItem;

        if (Libs.isObjectEmpty(item)) {
            curItem.field_type = '';
        } else {
            var value = item.value;
            curItem[name] = value;
            this.setValidateMessage({field_type: ""}, true);
        }

        self.setState({
            curItem: curItem
        });
    }

    onClickShowTab = (e, tabActive) => {
        var curItem = this.state.curItem;
        if (Libs.isBlank(tabActive)) return;
        curItem.tabActive = tabActive;

        this.setState({
            curItem: curItem
        });
    }


    /**
     * @description validate a field input
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddFieldPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description select change
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */

    handleInputTranslateChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            let allLanguage = this.state.allLanguage;
            let isoCode = name.substr(-2, 2),
                dataName = name.substr(0, 11);

            allLanguage.map((language, index) => {
                if (isoCode === language.iso_code && dataName === 'field_label') {
                    item.data[index].field_label = (event.target.validity.valid) ? value : this.state.curItem.data[index].field_label;
                }
                return 1;
            });

            item[name] = (event.target.validity.valid) ? value : this.state.curItem[name];

            this.setState({ curItem: item });
        }
    }



    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSave() {
        var curItem = this.state.curItem, self = this;
        var params = Object.assign({}, this.state.curItem);
        var screenMode = (!Libs.isBlank(curItem.id)) ? Constants.SCREEN_MODE.EDIT : ((!Libs.isBlank(this.props.curItemAddField.screen_mode)) ? this.props.curItemAddField.screen_mode : Constants.SCREEN_MODE.ADD);
        let v = new AddFieldPopupValidate(this.props);
        let errors = await v.FLValidationAll(curItem);
        if (errors) {
            this.setValidateMessage(errors);
            return;
        }
        //remove message validation
        this.removeAllValidateMessage();
        params.screen_mode = screenMode;

        params.id_company = this.employee.id_company;
        params.iso_code_lang = this.employee.lang;
        params.id_language = this.employee.id_language;
        params.iso_code = this.employee.iso_code;
        params.status = 1;

        var curItemGroup = this.props.curItemGroup;
        if(Libs.isObjectEmpty(curItemGroup)) return; 
        params.id_specification_group = curItemGroup.id;

        if (Libs.isArrayData(params.data)) {
            let itemLanguageDefault = Libs.find(params.data, 'is_default', 1);
            if (!itemLanguageDefault) return;

            params.data.map((item, index) => {
                params.data[index].field_label = (item.field_label === '') ? itemLanguageDefault.field_label : item.field_label;
                return params;

            });
        } else { return false; }

        SpecificationService.instance.save( params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onClickCloseAddField(true);
            }
            else if (data) {
                self.setValidateMessage(data);
            }

            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }
}
export default AddFieldPopup;