import React from 'react';

class Sidebar extends React.Component {
    render() {
        return <div className="sidebar">

        </div>
    }
}

export default Sidebar;