import React from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer';
import SidebarHR from '../common/SidebarHR/SidebarHR';

const MainModuleHr = ({ children }) => {
  return <div>
    <Header />
    <SidebarHR />
    <div id="main">
      {children} 
    </div>
    <Footer />
  </div>
};

export default MainModuleHr;
