
/********************************************************
* Copyright 2020-2021 NEXT WAVE ENERGY MONITORING INC.
* All rights reserved.
* 
*********************************************************/
import BaseComponent from '../../../../BaseComponent';
import DeletePopupJsx from './DeletePopup.jsx';

class DeletePopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem
        }
        this.jsxTemplate = DeletePopupJsx;
    }

    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
    }

    /**
     * @description close popup
     * @author Long.Pham 12/05/2021
     */
     onClickCloseDelete() {
        if (!this.props.onClickCloseDelete || typeof this.props.onClickCloseDelete !== 'function') return;
        this.props.onClickCloseDelete(false, null);
    }


    /** 
     * @description delete product
     * @author Long.Pham 12/05/2021
    */
    async onDeleteAction() {
        let params = this.state.curItem;
        params.is_delete = (params.is_delete === 1? 0 : 1);
        // if(!Libs.isBlank(params.status)){
        //     params.status = parseInt(params.status);
        // }
        // SiteService.instance.delete(params, function (status, data, msg) {
        //     if (status) {
        //         self.toast(msg, "info");
        //         self.props.onCloseDeleteSitePopup(true, data);
        //     }
        //     else if (data) {
        //         setValidateMessage(data);
        //     }
        //     else if (!Libs.isBlank(msg)) {
        //         self.toast(msg, "error");
        //     }
        // }, true);
    }
}
export default DeletePopup;