import React, { Component } from 'react';
import { RText } from '../../../../../components/Controls';
// import FLSelect from '../../../../component/FLSelect';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
class AddRow extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * @description remove item row
     * @author long.pham 27-07-2019
     */

    removeItemRow = () => {
        if (!this.props.removeItemRow || typeof this.props.removeItemRow !== 'function') return;
        this.props.removeItemRow(this.props.index);
        return;
    }

    render() {
        var item = this.props.dataItem;
        var curItem = this.props.curItem;
        return (
            <div className="body-row">
                <div className="body-col width20">
                    {item.product_name}
                </div>

                <div className="body-col width10">
                    {item.unit_name}
                </div>

                <div className="body-col width10">
                    {item.quantity_system}
                </div>
                <div className="body-col width10">
                    <RText
                        inputClass={(item.messages.quantity_check != "") ? "form-control input-error" : "form-control"}
                        inputId={"quantity_check-" + this.props.index} inputName="quantity_check"
                        value={item.quantity_check}
                        onChange={(e) => { this.props.handleRowInputChange(e, this.props.index) }}
                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                        maxLength={10} />
                </div>

                <div className="body-col width10">
                    {(item.quantity_system - item.quantity_check) != undefined ? item.quantity_system - item.quantity_check : ''}
                </div>




                <div className="body-col width35">
                    <RText
                        inputClass="form-control"
                        inputId={"reason-" + this.props.index} inputName="reason"
                        value={item.reason}
                        onChange={(e) => { this.props.handleRowInputChange(e, this.props.index); }}
                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                        maxLength={100} />
                </div>



                <div className="body-col text-end width5">
                    <div className="remove-item" onClick={this.removeItemRow.bind(this)}>
                        <i className="icon-trash-o"></i>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddRow;
