import React, { Component } from 'react';
import Libs from '../../../../../utils/Libs';
class RowItemProduct extends Component {

    render() {
        var item = this.props.dataItem;
        return (
            <div className="tbody-row" key = {"item-product-"+this.props.index}>
                <div className="tbody-col width50">
                    <p>{item.product_name}</p>
                </div>
                <div className="tbody-col width30 text-end"><p>{Libs.formatNum(item.saleoff_price, '#.###.##')} vnđ</p></div>
                <div className="tbody-col width20 text-end"><p>{item.quantity}</p></div>
            </div>
        );
    }
}
export default RowItemProduct;
