import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate{
    constructor(props){
        super();
        var {t} = props;
        this.trans = t;
    }
    setRule(){
        this.addRuleForField('purpose', 'trim', true);
        this.addRuleForField('purpose', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('purpose', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('id_code', 'trim', true);
        this.addRuleForField('id_code', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('id_code', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('date', 'trim', true);
        this.addRuleForField('date', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('date', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('end_date', 'trim', true);
        this.addRuleForField('end_date', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('end_date', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('id_store', 'trim', true);
        this.addRuleForField('id_store', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('id_store', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));
        
    }
    setAlias(){
        this.v.setAlias({
            purpose : this.trans('INVENTORY_STOCK.purpose'), 
            id_code: this.trans('INVENTORY_STOCK.id_code'),
            date: this.trans('INVENTORY_STOCK.date'),
            end_date: this.trans('INVENTORY_STOCK.end_date'),
            id_store: this.trans('INVENTORY_STOCK.id_store')
        });
        
    }
}
export default AddPopupValidate;