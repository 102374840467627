import SearchBillJsx from './SearchBill.jsx';
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import { withTranslation } from 'react-i18next';
import Constants from '../../../../../utils/Constants';
import './Retail.scss';

class SearchBill extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = SearchBillJsx;
        this.state = {
            curItem: {},
            dataStores: [
                {id: 1, value: 1, label: "kho 1"},
                {id: 1, value: 1, label: "kho 2"},
                {id: 1, value: 1, label: "kho 3"},
            ],

            dataStatus: [
                {id: 1, value: 1, label: "Đã bán"},
                {id: 1, value: 1, label: "Huỷ"},
                {id: 1, value: 1, label: "Trả hàng"},
            ],
            dataPaymentMethod: [
                {id: 1, value: 1, label: "Tiền mặt"},
                {id: 1, value: 1, label: "Chuyển khoản"},
                {id: 1, value: 1, label: "Cà thẻ"},
                {id: 1, value: 1, label: "Trả góp"},
            ],

            dataList: [
                { id: 1123123123, 
                    username: "Pham Van Long", 
                    store_name: "kho số 1", 
                    customer_name: "Nguyễn Thanh Tùng",
                    customer_phone: "097 123 345",
                    saleoff: "20000",
                    saleoff_percent: 10,
                    product_name: "Sản phẩm 1 -XL - Đỏ",
                    tax: 10,
                    total: 500000,
                    date: '12/12/2021 12:12',
                    dataProducts: [
                        {
                            id: 1,
                            name: "Sản phẩm mật ong 001 - XL - Đỏ",
                            price: 2000000,
                            quantity: 1
                        },
                        {
                            id: 1,
                            name: "Sản phẩm mật ong 001 - XL - Đỏ",
                            price: 2000000,
                            quantity: 1
                        }
                    ],
                },
                { id: 1123123123, 
                    username: "Pham Van Long", 
                    store_name: "kho số 1", 
                    customer_name: "Nguyễn Thanh Tùng",
                    customer_phone: "097 123 345",
                    saleoff: "20000",
                    saleoff_percent: 10,
                    product_name: "Sản phẩm 1 -XL - Đỏ",
                    tax: 10,
                    total: 500000,
                    date: '12/12/2021 12:12',
                    dataProducts: [
                        {
                            id: 1,
                            name: "Sản phẩm mật ong 001 - XL - Đỏ",
                            price: 2000000,
                            quantity: 1
                        },
                        {
                            id: 1,
                            name: "Sản phẩm mật ong 001 - XL - Đỏ",
                            price: 2000000,
                            quantity: 1
                        }
                    ],
                },
            ],
            searchParam: {
                limit: Constants.COMMON.LIMIT,
                offset: 0,
                index: 1,
            },
            delete: false,
            formSearch: true,
            allLanguage: [
                { id: 1, name: 'Tiếng Việt', iso_code: 'vi', default: 1 },
                { id: 2, name: 'English', iso_code: 'en', default: 0 }
            ]
        };

        this.paging = {
            total: 100,
            current: 1
        };
        this.inputChangedHandler = this.inputChangedHandler.bind(this);

    }

    onClickCloseDelete = () => {
        this.setState({
            delete: false
        })
    }

    /**
     * @description Item click event
     * @author Long.Pham 12-05-2021
     * @param index element in the list
     */
    onItemClick = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        item.screen_mode = Constants.SCREEN_MODE.EDIT;
        this.setState({
            curItem: item,
            showAddSitePopup: true
        });
    }

    /**
   * @description Item click event delete
   * @author Long.Pham 12-05-2021
   * @param index Order element in the list
   */
    onItemClickDelete = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        this.setState({
            curItem: item,
            delete: true
        });
    }


    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name === 'current') {
            if (value !== '') {
                var { t } = this.props;
                if (!Libs.isNumber(value)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (value > this.paging.total) {
                value = self.paging.total;
            }

            self.paging.current = value;
            this.onSelectPage(value);
            self.forceUpdate();
        }

        if (name === 'limit') {
            var { searchParam } = this.state;
            searchParam.limit = value;
            this.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            })
        }
    }


    /**
     * @description Select page in pagging
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;
        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        // self.getList();
    }


    /**
     * @description reload data
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onClickReload() {
        // let self = this;
        // self.getList();
    }


    /**
    * Func filter table
    * @author Long.Pham 12-05-2021
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sort_column = sortKey;
        this.state.searchParam.order_by = (this.state.searchParam.order_by === '' || this.state.searchParam.order_by === 'asc') ? 'desc' : 'asc';
        this.forceUpdate();
        // this.getList();
    }

    /**
     * @description Call form search
     * @author Long.Pham 12/05/2021
     */
    onSearch() {
        let self = this;
        let formSearch = (this.state.formSearch === false) ? true : false;
        if (this.state.formSearch === true) {
            let searchParam = this.state.searchParam;
            searchParam.name = '';
            searchParam.index = 1;
            searchParam.offset = 0;
            self.paging.current = 1;
            self.paging.total = 1;
            self.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            });
        }

        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * Func search
     * @author Long.Pham 12/05/2021
     * @param  {Object} e
     */
     handleSearch() {
        // this.getList();
    }



    render() {
        return this.jsxTemplate.call(this)
    }
}

const HighOrderComponentTranslated = withTranslation('common')(SearchBill)
export default HighOrderComponentTranslated;