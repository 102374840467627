import React from 'react';
import Modal from 'react-bootstrap4-modal';
import Libs from '../../../../../utils/Libs';
import AddFieldPopup from './AddFieldPopup';
import RowItemField from './RowItemField';

export default function ListFieldPopup() {

    var { curItem, curItemAddField, allLanguage, dataList } = this.state;
    const { t } = this.props;
    var layoutAddField = this.state.addField ?
        <AddFieldPopup
            t={t}
            allLanguage={allLanguage}
            curItemAddField={curItemAddField}
            curItem = {curItem}
            curItemGroup = {this.props.curItem}
            onClickCloseAddField={this.onClickCloseAddField}
            auth={this.props.auth}
            actions={this.actions}
            iso_code_language={this.employee.lang}
        />
        : ""

    var RowItemList = null;
    if (Libs.isArrayData(dataList)) {
        RowItemList = dataList.map((item, index) => {
            return (
                <RowItemField
                    key={'row_item_' + index}
                    index={index}
                    dataItem={item}
                    onItemClick={this.onItemClick}
                    onItemClickListField={this.onItemClickListField}
                    onItemClickDelete={this.onItemClickDelete}
                    actions={this.actions}
                    t={t}
                />
            );
        })
    }
    return (
        <div>
            {layoutAddField}
            <div className="model-field">
                <Modal visible={true} className="modal-add" dialogClassName="modal-xl modal-dialog-scrollable" >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {curItem.name}
                        </h5>
                        <span className="close" onClick={this.props.onClickCloseListField.bind(this, false)}><var className="icon-cancel-music"></var></span>
                    </div>
                    <div className="modal-body">
                        <div className="list-field">
                            <div className="field-header">
                                <div className="field-header-row">
                                    <div className="field-header-col width10">Order</div>
                                    <div className="field-header-col width30">Label</div>
                                    <div className="field-header-col width30">Key</div>
                                    <div className="field-header-col width10 text-center">Type</div>
                                    <div className="field-header-col width20 text-end">Thao tác</div>
                                </div>
                            </div>

                            <div className="field-body">
                                {RowItemList}
                            </div>
                            <div className="field-footer">
                                <div className="add-field" onClick={this.onClickAddField.bind(this)}>
                                    <var className="icon-plus"></var> Add field
                        </div>
                            </div>
                        </div>


                    </div>

                </Modal>
            </div>

        </div>


    )
}