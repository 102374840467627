import React, { Component } from 'react';
import { RText } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
class RowItemProduct extends Component {

    onItemProductClickDelete = () => {
        if (!this.props.onItemProductClickDelete || typeof this.props.onItemProductClickDelete !== 'function') return;
        this.props.onItemProductClickDelete(this.props.index);
    }

    render() {
        const { t } = this.props;
        var item = this.props.dataItem;
        var index = this.props.index;
        var id = this.props.index + 1;
        console.log("item: ", item);
        return (
            <div className="body-row" key={index}>
                <div className="body-col width10"><p>{id}</p></div>
                <div className="body-col width35">
                    <p><strong>{item.product_name}</strong></p>
                    <p>SKU: {item.sku}</p>
                    <p>ID: {item.id_product}</p>
                </div>
                <div className="body-col width15">
                    {Libs.formatNum(Libs.convertStringToNumber(item.regular_price), '#.###.##')}
                </div>
                <div className="body-col width15">
                    <p>
                        <RText placeholder={t('sell.price')}
                            inputClass="form-control text-end"
                            inputId={"price_" + index}
                            inputName="saleoff_price"
                            disabled={this.props.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                            value={Libs.formatNum(Libs.convertStringToNumber(item.saleoff_price), '#.###.##')}
                            onChange={(e) => { this.props.handleInputProductChange(e, index); }}
                            maxLength={20} />
                    </p>
                </div>
                <div className="body-col width15">
                    <p>
                        <RText placeholder={t('sell.quantity')}
                            inputClass="form-control text-end"
                            inputId={"quantity_" + index}
                            inputName="quantity"
                            disabled={this.props.screen_mode === Constants.SCREEN_MODE.VIEW ? true : false}
                            value={item.quantity}
                            onChange={(e) => { this.props.handleInputProductChange(e, index); }}
                            maxLength={10} />
                    </p>
                </div>
                <div className="body-col width10  text-end">
                    {this.props.screen_mode != Constants.SCREEN_MODE.VIEW ?
                        <p className="delete">
                            <span onClick={this.onItemProductClickDelete.bind(this)} className="icon-trash-o"></span>
                        </p>
                        : ""}

                </div>
            </div>
        );
    }
}
export default RowItemProduct;
