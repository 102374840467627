import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

class RowItem extends Component {
    onItemClick = () => {
        if (!this.props.onItemClick || typeof this.props.onItemClick !== 'function') return;
        this.props.onItemClick(this.props.index);
    }

    onItemClickDelete = () => {
        if (!this.props.onItemClickDelete || typeof this.props.onItemClickDelete !== 'function') return;
        this.props.onItemClickDelete(this.props.index);
    }

    render() {
        var item = this.props.dataItem;
        const { t } = this.props;
        return (
            <div className="body-row" key = {item.id}>
                <ReactTooltip />
                <div className="body-col width10"><p>{item.id} </p></div>
                <div className="body-col width30"><p>{item.name} </p></div>
                <div className="body-col width35"><p>{item.address}</p></div>
                <div className="body-col width15"><p>{item.phone}</p></div>
                <div className="body-col width10">
                    <div className="functions">
                        <ul>
                            <li onClick = {this.onItemClick.bind(this)} data-tip= {t('common.edit')} data-type="warning" data-class="tooltip-action"><var className="icon-pencil-square-o" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDelete.bind(this)} data-tip= {t('common.delete')} data-type="warning" data-class="tooltip-action"><var className="icon-trash-o" aria-hidden="true"></var></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default RowItem;
