import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate {
    constructor(props) {
        super();
        var { t, iso_code_language } = props;
        this.trans = t;
        this.iso_code_language = iso_code_language;
    }
    setRule() {
        if (this.iso_code_language === 'vi') {
            this.addRuleForField('name_vi', 'trim', true);
            this.addRuleForField('name_vi', 'required', true, this.trans('validate_rule.required'), this.trans('products.name'));
            this.addRuleForField('name_vi', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('products.name'));
        }

        if (this.iso_code_language === 'en') {
            this.addRuleForField('name_en', 'trim', true);
            this.addRuleForField('name_en', 'required', true, this.trans('validate_rule.required'), this.trans('products.name'));
            this.addRuleForField('name_en', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('products.name'));
        }



        this.addRuleForField('id_category', 'trim', true);
        this.addRuleForField('id_category', 'required', true, this.trans('validate_rule.required'), this.trans('products.id_category'));

        this.addRuleForField('id_brand', 'trim', true);
        this.addRuleForField('id_brand', 'required', true, this.trans('validate_rule.required'), this.trans('products.id_brand'));

        this.addRuleForField('id_unit', 'trim', true);
        this.addRuleForField('id_unit', 'required', true, this.trans('validate_rule.required'), this.trans('products.id_unit'));

        this.addRuleForField('id_product_type', 'trim', true);
        this.addRuleForField('id_product_type', 'required', true, this.trans('validate_rule.required'), this.trans('products.id_product_type'));

        this.addRuleForField('regular_price', 'trim', true);
        this.addRuleForField('regular_price', 'required', true, this.trans('validate_rule.required'), this.trans('products.regular_price'));
        this.addRuleForField('regular_price', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('products.regular_price'));

        
        this.addRuleForField('sku', 'trim', true);
        this.addRuleForField('sku', 'required', true, this.trans('validate_rule.required'), this.trans('products.sku'));
        this.addRuleForField('sku', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('products.sku'));
        
    }
    setAlias() {
        if (this.iso_code_language === 'vi') {
            this.v.setAlias({
                name_vi: this.trans('products.name'),
                id_category: this.trans('products.id_category'),
                id_brand: this.trans('products.id_brand'),
                id_unit: this.trans('products.id_unit'),
                id_product_type: this.trans('products.id_product_type'),
                regular_price: this.trans('products.regular_price'),
                sku: this.trans('products.sku')
            });
        }

        if (this.iso_code_language === 'en') {
            this.v.setAlias({
                name_en: this.trans('products.name'),
                id_category: this.trans('products.id_category'),
                id_brand: this.trans('products.id_brand'),
                id_unit: this.trans('products.id_unit'),
                id_product_type: this.trans('products.id_product_type'),
                regular_price: this.trans('products.regular_price'),
                sku: this.trans('products.sku')
            });
        }



    }
}
export default AddPopupValidate;