import React from 'react';
import { RText, RTextArea, RSwitch } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import CMSEditor from '../../../../../components/CMSEditor';
import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';


export default function AddPopup() {

    var { curItem, allLanguage } = this.state;
    const { t } = this.props;
    var tabNavLanguage, tabContent = null;



    if (allLanguage) {
        tabNavLanguage = allLanguage.map((language, index) => {
            return (<li key={index} className="nav-item">
                <span onClick={(e) => { this.onClickShowTab(e, language.iso_code) }} className={curItem.tabActive === language.iso_code ? "nav-link active" : "nav-link"} id={"name-tab-" + language.iso_code} >{language.name}</span>
            </li>);
        });

        tabContent = allLanguage.map((language, index) => {
            return (
                <div key={index} className={curItem.tabActive === language.iso_code ? "tab-pane fade show active" : "tab-pane fade"} id={"name-" + language.iso_code} role="tabpanel" aria-labelledby="home-tab">
                    <div className="info">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('pages.name')}
                                        inputClass="form-control"
                                        required={language.is_default === 1 ? "required" : ""}
                                        inputId={"title_" + language.iso_code}
                                        inputName={"title_" + language.iso_code}
                                        value={curItem.data[index].title}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('pages.slug')}
                                        inputClass="form-control"
                                        inputId={"slug_" + language.iso_code}
                                        inputName={"slug_" + language.iso_code}
                                        value={curItem.data[index].slug}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                <div className="mb-3">
                                    <div className={"content_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('pages.content')}
                                            id={"content_" + language.iso_code}
                                            name={"content_" + language.iso_code}
                                            height={250}
                                            content={curItem.data[index].content}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this, 'content_' + language.iso_code, language.iso_code)}
                                            mode="basic"
                                        />

                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                    <div className="meta">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="main-meta">
                                    <h2>SEO</h2>
                                    <div className="meta-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_title')}
                                                        inputClass="form-control"
                                                        inputId={"meta_title_" + language.iso_code}
                                                        inputName={"meta_title_" + language.iso_code}
                                                        value={curItem.data[index].meta_title}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_keyword')}
                                                        inputClass="form-control"
                                                        inputId={"meta_keyword_" + language.iso_code}
                                                        inputName={"meta_keyword_" + language.iso_code}
                                                        value={curItem.data[index].meta_keyword}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RTextArea label={t('common.meta_description')}
                                                        rows="3"
                                                        inputClass="form-control"
                                                        inputId={"meta_description_" + language.iso_code}
                                                        inputName={"meta_description_" + language.iso_code}
                                                        value={curItem.data[index].meta_description}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        });
    }


    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-xl modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('pages.edit_title') : t('pages.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">
                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">

                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                <div className="mb-3">
                                    <p className="control-label">{t('pages.status')}</p>
                                    <div className="checkmark">
                                        <RSwitch
                                            inputId="status"
                                            inputName="status"
                                            checked={curItem.status}
                                            onChange={(e) => { this.handleInputChange(e) }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                

                <div className="box-info">
                    <h2>{t('products.thumbnail')}</h2>
                    <div className="box-info-content">
                        <div className="section-thumbnail">
                            <ul className="row">
                                <li className="col-md-6 col-12">
                                    <input key={Libs.generateStrRandom(2, 2, 2, 8)} onChange={(event) => this.onUploadThumbnailChange(event)} type="file" id="upload_thumbnail" name="upload_thumbnail" />
                                    {Libs.isBlank(curItem.upload_thumbnail) && Libs.isBlank(curItem.thumbnail) ?
                                        <div className={!Libs.isBlank(curItem.thumbnail_mess) ? "add-thumbnail error-thumbnail" : "add-thumbnail"}>
                                            <label htmlFor="upload_thumbnail" className="upload_thumbnail">
                                                <i className="icon-picture-o"></i>
                                            </label>
                                        </div>
                                        : (!Libs.isBlank(curItem.upload_thumbnail) ?
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                    <span onClick={this.deleteThumbnail.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                </div>
                                                <img src={curItem.upload_thumbnail} />
                                            </div>
                                            :
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                </div>
                                                <img src={curItem.thumbnail_full} />
                                            </div>
                                        )}

                                </li>

                            </ul>
                        </div>
                    </div>

                </div>


                <div className="box-translate">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-main">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {tabNavLanguage}
                                </ul>
                                <div className="tab-content" id="tabContent">
                                    {tabContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>

    )
}