
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
// import TransferStockService from '../../../../services/TransferStockService';
// import StoresService from '../../../../services/StoresService';
// import ProductService from '../../../../services/ProductService';
import AddRowValidate from './AddRowValidate';
// import cloneDeep from 'lodash-es/cloneDeep';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: {},
            dataListAddRow: [],
            allStores: [],
            allProducts: [],
            dataStores: [
                {id: 1, value: 1, label: "kho 1"},
                {id: 1, value: 1, label: "kho 2"},
                {id: 1, value: 1, label: "kho 3"},
            ],
            dataCategories: [
                {id: 1, value: 1, label: "Danh mục 1"},
                {id: 2, value: 2, label: "Danh mục 2"}
            ],
            dataListAddRow: [
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
            ],
        }
        this.jsxTemplate = AddPopupJsx;
        // Sự kiện lắng nghe input text thay đổi giá trị
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author long.pham 28/07/2019
     */
    componentDidMount() {
        // let self = this;
        // let curItem = cloneDeep(this.props.curItem);
        // curItem.date = (!Libs.isBlank(curItem.date)) ? Libs.dateFormat(curItem.date, "DD/MM/YYYY HH:mm:ss") : Libs.getCurrentDDMMYYYYHI();
        // this.setState({
        //     curItem: curItem,
        //     // dataListAddRow: cloneDeep(this.props.dataListAddRow)
        // });
        // self.getAllStores();
        // self.getAllProducts();
    }




    /**
     * @description close popup
     * @author long.pham 28/07/2019
     */
    onCloseAddPopup() {
        if (!this.props.onCloseAddPopup || typeof this.props.onCloseAddPopup !== 'function') return;
        this.props.onCloseAddPopup(false, null);
    }

    /**
     * @description add row 
     * @author long.pham 26/07/2019
     */

    onClickAddRow() {
        let params = this.state.dataListAddRow;
        let item = {
            index: params.length + 1,
            id_product: "",
            obj_product: [],
            name: "",
            id_stores_export_stock: "",
            id_stores_import_stock : "",
            obj_stores: [],
            unit: "",
            quantity: 1,
            price: 0,
            product_date: "",
            expiration_date: "",
            messages: {
                id_product: "",
                id_stores_export_stock: "",
                id_stores_import_stock : "",
                quantity: "",
                price: ""
            }
        }

        params.push(item);
        this.setState({
            dataListAddRow: params
        });
    }





    /**
     * @description open add supplier popup
     * @author long.pham 28/07/2019
     */
    onClickAddSupplier() {
        if (!this.props.onClickAddSupplier || typeof this.props.onClickAddSupplier !== 'function') return;
        this.props.onClickAddSupplier(false, null);
    }


    /** 
     * @description Save info TransferStock
     * @author long.pham 28/07/2019
    */
    async onSaveAction() {
        // let params = this.state.curItem, self = this;
        // var screenMode = (!Libs.isBlank(self.state.curItem.screen_mode)) ? self.state.curItem.screen_mode : Constants.SCREEN_MODE.ADD;
        // let v = new AddPopupValidate(this.props);
        // let errors = await v.FLValidationAll(params);
        // if (errors) {
        //     setValidateMessage(errors);
        //     return;
        // }

        // params.screen_mode = screenMode;
        // let dataListAddRow = this.state.dataListAddRow;
        // if (Libs.isObjectEmpty(dataListAddRow)) {
        //     self.toast(trans.translate('EXPORT_STOCK.list_product_is_not_null'), "error");
        //     return;
        // }
        // var mess = false;
        // dataListAddRow.map((item, index) => {
        //     let itemRow = dataListAddRow[index];
        //     if (Libs.isBlank(item.id_product)) {
        //         itemRow.messages.id_product = "a";
        //         mess = true;
        //     } else {
        //         itemRow.messages.id_product = "";
        //     }

        //     if (Libs.isBlank(item.id_stores_export_stock)) {
        //         itemRow.messages.id_stores_export_stock = "a";
        //         mess = true;
        //     } else {
        //         itemRow.messages.id_stores_export_stock = "";
        //     }

        //     if (Libs.isBlank(item.id_stores_import_stock)) {
        //         itemRow.messages.id_stores_import_stock = "a";
        //         mess = true;
        //     } else {
        //         itemRow.messages.id_stores_import_stock = "";
        //     }

        //     if(item.id_stores_import_stock == item.id_stores_export_stock){
        //         itemRow.messages.id_stores_import_stock = trans.translate('TRANSFER_STOCK.must_be_diffirent_id_import_stock');
        //         itemRow.messages.id_stores_export_stock = trans.translate('TRANSFER_STOCK.must_be_diffirent_id_export_stock');
        //         mess = true;
        //     } else{
        //         itemRow.messages.id_stores_import_stock = "";
        //         itemRow.messages.id_stores_export_stock = ""; 
        //     }

        //     if (!Libs.isNumber(item.quantity)) {
        //         itemRow.messages.quantity = "a";
        //         mess = true;
        //     } else {
        //         itemRow.messages.quantity = "";
        //     }

        //     if (!Libs.isNumber(item.price)) {
        //         itemRow.messages.price = "a";
        //         mess = true;
        //     } else {
        //         itemRow.messages.price = "";
        //     }
        //     dataListAddRow[index] = itemRow;

        // });
        
        // if(mess == true){
        //     self.toast(trans.translate('TRANSFER_STOCK.list_product_is_incorrect'), "error"); 
        //     self.setState({
        //         dataListAddRow: dataListAddRow
        //     });
        //     return;
        // }

        // //remove message validation
        // removeAllValidateMessage();
        // params.dataArr = dataListAddRow;
        // TransferStockService.instance.save(params, function (status, data, msg) {
        //     // console.log('data: ', data, status, msg);
        //     if (status) {
        //         self.toast(msg, "info");
        //         self.props.onCloseAddTransferStockPopup(true, data);
        //     }
        //     else if (data) {
        //         setValidateMessage(data);
        //     }
        //     else if (!Libs.isBlank(msg)) {
        //         self.toast(msg, "error");
        //     }
        // }, true);
    }


    /**
     * @description validate a field input
     * @author long.pham 28/07/2019
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description Get object select change
     * @author long.pham 28/07/2019
     */
    onSelectChange = (event, data) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        var { curItem } = this.state;
        curItem[name] = value;
        this.setState({
            curItem
        });
        this.validateOne(event);
    }


    /**
     * @description select change
     * @author long.pham 28/07/2019
     * @param {*} event 
     */

    handleInputChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            item[name] = (value) ? value : this.state.curItem[name];
            this.setState({ curItem: item });
        }

    }

    /**
    * @description get all store
    * @author long.pham 26/07/2019
    */

    getAllStores() {
        // let self = this;
        // StoresService.instance.getAll(function (data, totalRow) {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allStores: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allStores: []
        //         });
        //     }
        // }, false);
    }

    /**
     * @description get all store
     * @author long.pham 26/07/2019
     */

    getAllProducts() {
        // let self = this;
        // ProductService.instance.getAllProduct(function (data, totalRow) {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allProducts: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allProducts: []
        //         });
        //     }
        // }, false);
    }

    /**
     * @description Remove row
     * @author long.pham 26/07/2019
     */
    removeItemRow = (index) => {
        console.log(index);
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;
        let dataListAddRow = this.state.dataListAddRow;
        dataListAddRow.splice(index, 1);
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }

    /**
     * @description Select change
     * @author long.pham 26/07/2019
     */
    onRowSelectChange = (event, index) => {
        let self = this;
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;


        let dataListAddRow = this.state.dataListAddRow;
        let item = dataListAddRow[index];
        // if (name == 'id_product') {
        //     let currentProductChoose = Libs.find(dataListAddRow, 'id_product', value);
        //     if (currentProductChoose) {
        //         value = "";
        //         item.id_product = "";
        //         item.obj_product = [];
        //         item.name = "";
        //         item.id_stores = "";
        //         item.obj_stores = [];
        //         item.unit = "";
        //         item.quantity = 1;
        //         item.price = 0;
        //         item.product_date = "";
        //         item.expiration_date = "";
        //         // item.messages.id_product = trans.translate('EXPORT_STOCK.exits_product_choose');
        //         // self.toast(trans.translate('EXPORT_STOCK.exits_product_choose'), "error");
        //         // $('#id_product_' + index).val('').trigger('change');
        //     } else {
        //         item.messages.id_product = "";
        //     }

        //     if (value != "") {
        //         let products = this.state.allProducts;
        //         let productObjAdd = Libs.find(products, 'id', value);
        //         if (productObjAdd) {
        //             item.obj_product = productObjAdd;
        //             item.unit = productObjAdd.unit_name;
        //             item.price = productObjAdd.price;
        //         }
        //     }
        // }

        // if(name == 'id_stores_export_stock'){
        //     let stores = this.state.allStores;
        //     let storeObjAdd = Libs.find(stores, 'id', value);
        //     if (storeObjAdd) {
        //         item.obj_stores = storeObjAdd;
        //         item.messages.id_stores_export_stock = "";
        //     }

        //     if(value == item.id_stores_import_stock){
        //         item.messages.id_stores_export_stock = trans.translate('TRANSFER_STOCK.must_be_diffirent_id_export_stock');
        //         self.toast(trans.translate('TRANSFER_STOCK.must_be_diffirent_id_export_stock'), "error");
        //     }else{
        //         item.messages.id_stores_export_stock = ""; 
        //     }
        // }

        // if(name == 'id_stores_import_stock'){
        //     let stores = this.state.allStores;
        //     let storeObjAdd = Libs.find(stores, 'id', value);
        //     if (storeObjAdd) {
        //         item.obj_stores = storeObjAdd;
        //         item.messages.id_stores_import_stock = "";
        //     }

        //     if(value == item.id_stores_export_stock){
        //         item.messages.id_stores_import_stock = trans.translate('TRANSFER_STOCK.must_be_diffirent_id_import_stock');
        //         self.toast(trans.translate('TRANSFER_STOCK.must_be_diffirent_id_import_stock'), "error");
        //     }else{
        //         item.messages.id_stores_import_stock = ""; 
        //     }
        // }

        item[name] = value;

        dataListAddRow[index] = item;
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }

    /**
     * @description Row input on change
     * @author long.pham 26/07/2019
     */
    handleRowInputChange = (event, index) => {
        let self = this;
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (name == 'price' && value != null) {
            if (value != 0) {
                if (!Libs.isNumber(value)) {
                    return;
                }
            }

        }

        if (name == 'quantity' && value != null) {
            if (value != 0) {
                if (!Libs.isNumber(value)) {
                    return;
                }
            }

        }
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;
        let dataListAddRow = this.state.dataListAddRow;
        let item = this.state.dataListAddRow[index];
        item[name] = value;

        dataListAddRow[index] = item;
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }

    async validateOneRow(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddRowValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

}
export default AddPopup;