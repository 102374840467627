import React from 'react';
import { RText, RButton } from '../../../../../components/Controls';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import Libs from '../../../../../utils/Libs';
import { Paging } from '../../../../../components/Paging';
import RowItem from './RowItem';
// import Constant from '../../../../../utils/Constants';
import AddRow from './AddRow';
import AddPopup from './AddPopup';
import DeletePopup from './DeletePopup';
// import AddSupplierPopup from './AddSupplierPopup';
// import AddProductPopup from './AddProductPopup';

export default function ExportStock() {
  const { t } = this.props;
  var { formSearch, dataList, searchParam, curItem, listSupplier, listIdTypeVoucher, dataListAddRow, allStores, allProducts } = this.state;

  // Add row
  var addRows = null;
  function compareValues(key, order = 'asc') {
    return function (a, b) {
      if (!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ?
          (comparison * -1) : comparison
      );
    };
  }

  if (Libs.isArrayData(dataListAddRow)) {
    // console.log(dataListAddRow.sort());
    addRows = dataListAddRow.sort(compareValues('index', 'desc')).map((item, index) => {
      return <AddRow
        key={'row_item_' + index}
        index={index}
        dataItem={item}
        curItem={curItem}
        allStores={allStores}
        allProducts={allProducts}
        removeItemRow={this.removeItemRow}
        onRowSelectChange={this.onRowSelectChange}
        handleRowInputChange={this.handleRowInputChange}
        validateOneRow={this.validateOneRow}
      />
    });
  }

  // add list row 
  var RowItems = null;
  if (Libs.isArrayData(dataList)) {
    RowItems = dataList.map((item, index) => {
      return <RowItem
        key={'row_item_' + index}
        index={index}
        dataItem={item}
        onStatusChange={this.onStatusChange}
        showActionEvent={this.showActionEvent}
        setActiveElement={this.setActiveElement}
        activeId={this.state.activeId}
        onItemClick={this.onItemClick}
        onItemClickDelete={this.onItemClickDelete}
        onItemClickDetail={this.onItemClickDetail}
        clickPrintf={this.clickPrintf}
        clickExcel={this.clickExcel}
        t={t}
      />
    });
  }

  var AddPopupLayout = this.state.add
    ? <AddPopup
      onCloseAddPopup={this.onCloseAddPopup.bind(this)}
      auth={this.props.auth}
      curItem={curItem}
      dataListAddRow={dataListAddRow}
      addRows={addRows}
      t={t}
    /> : '';


  var DeletePopupLayout = this.state.delete
    ? <DeletePopup
      onClickCloseDelete={this.onClickCloseDelete.bind(this)}
      auth={this.props.auth}
      curItem={curItem}
      t={t}
    /> : '';


  // var AddSupplierPopupLayout = this.state.showAddSupplier
  // ? <AddSupplierPopup
  //   onCloseSupplierPopup = {this.onCloseSupplierPopup.bind(this)}
  // 	auth={this.props.auth}
  // 	curItem={curItem}
  // /> : '';


  // var AddProductPopupLayout = this.state.showAddProduct
  // ? <AddProductPopup
  //   onCloseProductPopup = {this.onCloseProductPopup.bind(this)}
  // 	auth={this.props.auth}
  //   curItem={curItem}
  // /> : '';


  return (
    <React.Fragment>
      {AddPopupLayout}
      {DeletePopupLayout}
      {/* {ExportStockPopupLayout}
      {ExportStockDeletePopupLayout}
      {AddSupplierPopupLayout}
      {AddProductPopupLayout} */}
      <div className="export-stock">
        <div className="page-breadcrumb">
          <div className="row">
            <div className="col-md-6"><h2>{t('export_stock.title')}</h2>
            </div>
            <div className="col-md-6">
              <ul>
                <li><div className="search" onClick={this.onSearch.bind(this)}><span className="icon icon-search"></span> {t('common.search')}</div></li>
                <li><div className="add" onClick={this.onClickAdd.bind(this)}><span className="icon icon-add"></span> {t('common.add')}</div></li>
              </ul>
            </div>
          </div>
        </div>


        {this.state.formSearch ?
          <div className="form-search">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="mb-3">
                  <RText label={t('export_stock.id_code')}
                    inputClass="form-control"
                    inputName="iso_code"
                    inputId="iso_code" inputName="iso_code"
                    value={searchParam.iso_code}
                    onChange={(e) => { this.handleInputChange(e); }}
                    maxLength={200} />
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="form-group">
                  <RText label={t('export_stock.id_order')}
                    inputClass="form-control"
                    inputName="id_orders"
                    inputId="id_orders" inputName="id_orders"
                    value={searchParam.id_order}
                    onChange={(e) => { this.handleInputChange(e); }}
                    maxLength={200} />
                </div>
              </div>



              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="mb-3">
                  <CMSDatePicker
                    label={t("export_stock.from_date")}
                    inputId="from_date"
                    inputClass="form-control"
                    inputName="from_date"
                    maxLength={10}
                    value={searchParam.from_date}
                    handleChange={this.handleSearchDateChange.bind(this)}
                  />
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="mb-3">
                  <CMSDatePicker
                    label={t("export_stock.to_date")}
                    inputId="to_date"
                    inputClass="form-control"
                    inputName="to_date"
                    maxLength={10}
                    value={searchParam.to_date}
                    maxDate={searchParam.max_date}
                    handleChange={this.handleSearchDateChange.bind(this)}
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                <div className="mb-3">
                  <button type="button" className="btn btn-search" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                </div>
              </div>

            </div>

          </div>
          : ""}


        <div className="data-view">
          <div className="main-header">
            <div className="header-row">
              <div onClick={e => this.onSort(e, 'export_date')} className={searchParam.sort_column === "export_date" ? "header-col width10 sorting " + searchParam.order_by : "header-col width10 sorting"}><p>{t('export_stock.date')}</p></div>
              <div onClick={e => this.onSort(e, 'id_code')} className={searchParam.sort_column === "iso_code" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('export_stock.id_code')}</p></div>
              <div onClick={e => this.onSort(e, 'total')} className={searchParam.sort_column === "partner_name" ? "header-col width20 sorting " + searchParam.order_by : "header-col width20 sorting"}><p>{t('export_stock.id_order')}</p></div>
              <div onClick={e => this.onSort(e, 'total')} className={searchParam.sort_column === "total" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('export_stock.total')}</p></div>

              <div className="header-col width25"><p>{t('export_stock.type_voucher')}</p></div>
              <div className="header-col width15 text-end"><p>{t('common.action')}</p></div>
            </div>
          </div>
          <div className="main-body">
            <div className="body">
              {RowItems ? RowItems : <div className="data-empty">{t('common.data_empty')}</div>}
            </div>
          </div>
        </div>

        <div className="main-paging">
          <Paging
            total={this.paging.total}
            current={this.paging.current}
            inputChangedHandler={this.inputChangedHandler}
            onBrandKeyPress={this.onBrandKeyPress}
            onInputPage={this.onInputPage}
            onClickReload={this.onClickReload}
            onSelectPage={(index) => this.onSelectPage.bind(this, index)}>
          </Paging>
        </div>

      </div>
    </React.Fragment>

  )
}