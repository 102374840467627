import ProductsJsx from './Products.jsx';
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import { withTranslation } from 'react-i18next';
import Constants from '../../../../../utils/Constants';
import ProductService from '../../../../../services/ProductService';
import './Products.scss';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';

class Products extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = ProductsJsx;
        this.state = {
            curItem: {},
            dataList: [],
            searchParam: {
                limit: Constants.COMMON.LIMIT,
                offset: 0,
                index: 1,
            },
            add: false,
            delete: false,
            import: false,
            formSearch: false,
            allLanguage: Libs.isBlank(this.employee) ? [] : this.employee.languages
        };

        this.paging = {
            total: 0,
            current: 1,
            currentInput: 1
        };
        this.inputChangedHandler = this.inputChangedHandler.bind(this);
        this.inputChangedEnter = this.inputChangedEnter.bind(this);
        this.inputChangedBlue = this.inputChangedBlue.bind(this);

    }
    componentDidMount() {
        this.getList();
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.handleSearch()
        }
    }


    importExcel = (e) => {
        e.preventDefault();
        var self = this;
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        var dataSaveImport = [];
        var employee = this.employee;

        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            // const dataImport = XLSX.utils.sheet_to_csv(ws, {header:1});

            const dataImport = XLSX.utils.sheet_to_json(ws, { header: 1 });
            if (dataImport) {
                for (var i = 1; i < dataImport.length; i++) {
                    let saleoffFrom = null, saleoffTo = null;
                    if (!Libs.isBlank(dataImport[i][7]) && dataImport[i][7] != '#') { saleoffFrom = dataImport[i][7]; }
                    if (!Libs.isBlank(dataImport[i][8]) && dataImport[i][8] != '#') { saleoffTo = dataImport[i][8]; }

                    if (!Libs.isBlank(saleoffFrom)) {
                        saleoffFrom = saleoffFrom.replace("#", '');
                        if (!moment(saleoffFrom).isValid()) { saleoffFrom = null; }
                    }

                    if (!Libs.isBlank(saleoffTo)) {
                        saleoffTo = saleoffTo.replace("#", '');
                        if (!moment(saleoffTo).isValid()) { saleoffTo = null; }
                    }

                    dataSaveImport.push({
                        id: dataImport[i][0],
                        sku: dataImport[i][1],
                        name: dataImport[i][2],
                        id_product_parent: Libs.isBlank(dataImport[i][3]) ? null : dataImport[i][3],
                        category_name: dataImport[i][4],
                        regular_price: Libs.isBlank(dataImport[i][5]) ? 0 : dataImport[i][5],
                        saleoff_price: Libs.isBlank(dataImport[i][6]) ? null : dataImport[i][6],
                        saleoff_from: saleoffFrom,
                        saleoff_to: saleoffTo,
                        quantity: Libs.isBlank(dataImport[i][9]) ? 0 : dataImport[i][9],
                        id_company: employee.id_company,
                        id_language: employee.id_language
                    });
                }
            }

            var params = {
                dataSaveImport: dataSaveImport,
                screen_mode: Constants.SCREEN_MODE.EDIT
            };

            if (Libs.isArrayData(dataSaveImport)) {
                ProductService.instance.updateImport(params, function (status, data, msg) {
                    if (status) {
                        self.toast(msg, "info");
                    } else if (!Libs.isBlank(msg)) {
                        self.toast(msg, "error");
                    }
                }, true);
            }
        };
        reader.readAsBinaryString(f)
    }

    onClickExportExcel() {
        let params = this.state.searchParam;
        params.id_language = this.employee.id_language;
        params.id_company = this.employee.id_company;
        ProductService.instance.exportProductToExcel(params, (data, total_row) => {
            var dataExport = [];
            if (Libs.isArrayData(data)) {
                var dataProducts = data;
                dataProducts.map((item, index) => {
                    dataExport.push({
                        "ID": item.id,
                        "Mã Sản Phẩm": item.sku,
                        "Tên Sản Phẩm": item.name,
                        "Sản Phẩm Cha": item.id_product_parent,
                        "Danh Mục": item.category_name,
                        "Giá gốc": item.regular_price,
                        "Giá Khuyến mãi": item.saleoff_price,
                        "Ngày bắt đầu KM": !Libs.isBlank(item.saleoff_from) ? ("#" + item.saleoff_from) : "#",
                        "Ngày kết thúc KM": !Libs.isBlank(item.saleoff_to) ? ("#" + item.saleoff_to) : "#",
                        "Số Lượng": item.quantity
                    });
                });

                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(dataExport, { skipHeader: false });
                ws['!cols'] = [{ width: 20 }, { width: 30 }, { width: 60 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];
                const wb = {
                    SheetNames: ['Sản phẩm'],
                    Sheets: { 'Sản phẩm': ws }
                };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const dataSave = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(dataSave, "export-product" + fileExtension);

            } else {

            }
        });
    }
    /**
     * get list
     * @author Long.Pham 2019-06-03
     */
    getList() {
        let self = this;
        let params = this.state.searchParam;
        params.id_language = this.employee.id_language;
        params.id_company = this.employee.id_company;
        ProductService.instance.getList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataList: data
                });
                var total = parseInt(total_row / self.state.searchParam.limit);
                if (total_row % self.state.searchParam.limit > 0) {
                    total = total + 1;
                }
                self.paging.total = total;
                self.paging.current = self.state.searchParam.index;
                self.paging.currentInput = self.state.searchParam.index;
                self.state.total_row = total_row;

            } else {
                self.setState({
                    dataList: [],
                    total_row: 0
                });
                self.paging.total = 0;
                self.paging.current = 1;
                self.paging.currentInput = 1;
            }
            self.forceUpdate();
        });
    }


    onClickAdd = () => {
        let curItem = {}, data = [];
        var allLanguage = this.state.allLanguage;
        curItem.screen_mode = Constants.SCREEN_MODE.ADD;
        curItem.tabActive = '';
        allLanguage.map((language, index) => {
            if (language.is_default === 1) { curItem.tabActive = language.is_default === 1 ? language.iso_code : ''; }

            let item = {
                is_default: language.is_default,
                iso_code: language.iso_code,
                id_language: language.id,
                name: "",
                slug: "",
                content: "",
                description: "",
                meta_title: "",
                meta_keyword: "",
                meta_description: "",
                des_size: "",
                privilege: ""
            };
            return data.push(item);
        });

        curItem.data = data;
        this.setState({
            curItem: curItem,
            add: true
        });

    };

    onClickCloseAdd = (status) => {
        if (status) {
            this.getList();
        }
        this.setState({
            add: false
        })
    }

    onClickCloseDelete = (status, data) => {
        if (status && data) {
            this.getList();
        }
        this.setState({
            delete: false
        })
    }

    /**
     * @description Item click event
     * @author Long.Pham 12-05-2021
     * @param index element in the list
     */
    onItemClick = (index) => {
        // if (!Libs.isArrayData(this.state.dataList)) return;
        // var item = this.state.dataList[index];
        // item.screen_mode = Constants.SCREEN_MODE.EDIT;
        // this.setState({
        //     curItem: item,
        //     showAddSitePopup: true
        // });
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index], self = this;

        var params = this.state.curItem;
        params.id_company = item.id_company;
        params.id = item.id;
        params.id_language = item.id_language;


        ProductService.instance.getDetail(params, data => {
            if (data) {
                item.data = data.data;
                data.screen_mode = Constants.SCREEN_MODE.EDIT;

                let curTransItem = Libs.find(data.data, 'is_default', 1);
                if (Libs.isObjectEmpty(curTransItem)) { return false; }
                data["name_" + curTransItem.iso_code] = curTransItem.name;

                if (Libs.isArrayData(data.data)) {
                    var dataLang = data.data;
                    dataLang.map((row, index) => {
                        if (row.is_default === 1) {
                            data.tabActive = row.iso_code;
                        }
                        return 1;
                    });
                }

                var dataVariants = data.dataVariants;
                if (Libs.isArrayData(dataVariants)) {
                    for (var i = 0, len = dataVariants.length; i < len; i++) {
                        dataVariants[i].errors = {
                            quantity: false,
                            regular_price: false,
                            saleoff_price: false,
                            time_range: false
                        };

                    }
                }
                data.dataVariants = dataVariants;

                self.setState({
                    curItem: data,
                    add: true
                });
            }
        }, false);

    }

    /**
   * @description Item click event delete
   * @author Long.Pham 12-05-2021
   * @param index Order element in the list
   */
    onItemClickDelete = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        this.setState({
            curItem: item,
            delete: true
        });
    }

    /**
     * @description Item click event change status
     * @author Long.Pham 12-05-2021
     * @param index element in the list
     */
    onStatusChange = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index], self = this;
        item.screen_mode = Constants.SCREEN_MODE.EDIT;
        item.id_company = this.employee.id_company;
        item.id_language = this.employee.id_language;

        var isActiveUpdate = item.status;
        if (isActiveUpdate * 1 === 1) {
            isActiveUpdate = 0;
        }
        else {
            isActiveUpdate = 1;
        }

        item.status = isActiveUpdate;
        item.updated_by = this.employee.first_name + ' ' + this.employee.last_name;

        ProductService.instance.updateStatus(item, function (status, msg) {
            if (status) {
                self.setState({
                    dataList: self.state.dataList
                });
            }
        });
    }


    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name === 'current') {
            if (!Libs.isBlank(value)) {
                var { t } = this.props;
                if (!Libs.isNumber(value)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            self.paging.currentInput = value;
            self.forceUpdate();
        }

        if (name === 'limit') {
            var { searchParam } = this.state;
            searchParam.limit = value;
            this.setState({
                searchParam: searchParam
            }, () => {
                self.getList();
            })
        }
    }

    inputChangedEnter(event) {
        let self = this;
        if (event.key === 'Enter') {
            var currentInput = this.paging.currentInput;
            if (!Libs.isBlank(currentInput)) {
                var { t } = this.props;
                if (!Libs.isNumber(currentInput)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (Libs.isBlank(currentInput) && !Libs.isNumber(currentInput)) return;
            if (parseInt(currentInput) > this.paging.total) {
                currentInput = self.paging.total;
            }

            if (currentInput <= 0) {
                currentInput = 1;
            }

            self.paging.current = currentInput;
            self.paging.currentInput = currentInput;
            this.onSelectPage(currentInput);
            self.forceUpdate();
        }
    }

    inputChangedBlue(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        if (name === 'current') {
            var currentInput = this.paging.currentInput;
            if (!Libs.isBlank(currentInput)) {
                var { t } = this.props;
                if (!Libs.isNumber(currentInput)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (Libs.isBlank(currentInput) && !Libs.isNumber(currentInput)) return;
            if (parseInt(currentInput) > this.paging.total) {
                currentInput = self.paging.total;
            }

            if (currentInput <= 0) {
                currentInput = 1;
            }

            self.paging.current = currentInput;
            self.paging.currentInput = currentInput;
            this.onSelectPage(currentInput);
            self.forceUpdate();
        }
    }


    /**
     * @description Select page in pagging
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;

        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        self.getList();
    }


    /**
     * @description reload data
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onClickReload() {
        let self = this;
        self.getList();
    }


    /**
    * Func filter table
    * @author Long.Pham 12-05-2021
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sort_column = sortKey;
        this.state.searchParam.order_by = (this.state.searchParam.order_by === '' || this.state.searchParam.order_by === 'asc') ? 'desc' : 'asc';
        this.getList();
    }

    /**
     * @description Call form search
     * @author Long.Pham 12/05/2021
     */
    onSearch() {
        let formSearch = (this.state.formSearch === false) ? true : false;
        this.setState({
            formSearch: formSearch
        });
    }

    onResetSearch() {
        let self = this;
        let searchParam = this.state.searchParam;
        searchParam.name = null;
        searchParam.sku = null;
        searchParam.index = 1;
        searchParam.offset = 0;
        self.paging.current = 1;
        self.paging.currentInput = 1;
        self.paging.total = 1;
        self.setState({
            searchParam: searchParam
        }, () => {
            self.getList();
        });
    }

    /**
     * Func search
     * @author Long.Pham 12/05/2021
     * @param  {Object} e
     */
    handleSearch() {
        this.getList();
    }

    render() {
        return this.jsxTemplate.call(this)
    }

    /**
   * @description Item click event import
   * @author Long.Pham 12-05-2021
   * @param index Order element in the list
   */
    onClickImport = () => {
        this.setState({
            import: true
        });
    }

    onClickCloseImport = (status=false) => {
        if (status) {
            this.getList();
        }
        this.setState({
            import: false
        })
    }
}

const HighOrderComponentTranslated = withTranslation('common')(Products)
export default HighOrderComponentTranslated;