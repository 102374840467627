import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Libs from '../utils/Libs';
import FileUploadService from '../services/FileUploadService';
import Constants from '../utils/Constants';

class CMSEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      readonly: false
    };
    this.tinymceEditor = null;
    this._editor = null;
  }

  render() {
    var { id, content, readonly, name, label, required, height } = this.props;
    return (
      <div className="main-editor">
        <input type="file" id="image-upload-tinymce" name="single-image" style={{ display: "none" }} accept="image/png, image/gif, image/jpeg, image/jpg, image/svg" />
        {!Libs.isBlank(label) ? <label className="control-label">{label}
          {required ? <span className="required">*</span> : ''}
        </label> : ''}

        <Editor
          textareaName={name}
          disabled={readonly}
          initialValue={(content) ? content : ""}
          tinymceScriptSrc='/libs/tinymce/tinymce.min.js'
          onInit={(evt, editor) => this._editor = editor}
          init={{
            selector: '#' + id,
            height: height ? height : 200,
            menubar: true,
            readonly: readonly,
            statusbar: false,
            relative_urls: false,
            remove_script_host: false,
            convert_urls: false,
            paste_data_images: true,
            file_browser_callback_types: 'image',
            file_picker_callback: (callback, value, meta) => {
              if (meta.filetype === 'image') {

                var input = document.getElementById('image-upload-tinymce');
                input.click();

                let info = localStorage.getItem(Constants.COMMON.EMPLOYEE_INFO);
                if (!Libs.isBlank(info)) {
                  try {
                    let userInfo = JSON.parse(Libs.base64Decrypt(info));
                    input.onchange = () => {
                      var file = input.files[0];
                      var reader = new FileReader();

                      reader.onload = (e) => {
                        var img = new Image();
                        var param = {
                          file_name: file.name,
                          file_upload: reader.result,
                          id_company: userInfo.id_company,
                          id_language: userInfo.id_language,
                          iso_code: userInfo.iso_code,
                          iso_code_language: userInfo.lang,
                          config_thumb_folder_new: userInfo.config_thumb_folder_new,
                          config_thumb_folder_pro: userInfo.config_thumb_folder_pro,
                          config_thumb_new_h: userInfo.config_thumb_new_h,
                          config_thumb_pro_h: userInfo.config_thumb_pro_h,
                          config_thumb_pro_w: userInfo.config_thumb_pro_w,
                          config_cdn: userInfo.config_cdn
                        }

                        FileUploadService.instance.saveUploadImage(param, function (status, data, msg) {
                          if (status && data && !Libs.isBlank(data.file_url)) {
                            img.src = data.file_url; // reader.result;
                            callback(data.file_url, { alt: file.name });
                          }
                        }, true);
                      };

                      reader.readAsDataURL(file);
                    };

                  } catch (e) {

                  }
                }
              }
            },
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
              'bold italic forecolor backcolor emoticons | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help | table | link image',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

            setup: editor => {
              this._editor = editor;
              editor.on('keyup change', () => {
                const content = editor.getContent();
                console.log("content: ", content);
                this.props.onEditorChange(content, editor);
              });
              //     if (_this.props.onRegistered) {
              //       _this.props.onRegistered(editor, _this);
              //     }
            }

          }}
          onEditorChange={this.props.onEditorChange}
        />

      </div>
      //       <div className="main-editor">
      //         <input type="file" id="image-upload-tinymce" name="single-image" style={{ display: "none" }} accept="image/png, image/gif, image/jpeg, image/jpg, image/svg" />
      //         {!Libs.isBlank(label) ? <label className="control-label">{label}
      //           {required ? <span className="required">*</span> : ''}
      //         </label> : ''}
      //         <Editor
      //           // apiKey={Constants.TINY_API_KEY}
      //           id={id}
      //           tinymceScriptSrc='/libs/tinymce/tinymce.min.js'
      //           textareaName={name}
      //           disabled={readonly}
      //           initialValue={(content) ? content : ""}
      //           init={this.state.init}
      //           onEditorChange={this.props.handleEditorChange}
      //         />
      //       </div>


    );
  };
}
export default CMSEditor;

// export default function CMSEditor() {
//   const editorRef = useRef(null);
//   const log = () => {
//     if (editorRef.current) {
//       console.log(editorRef.current.getContent());
//     }
//     console.log(this.props);
//   };
//   return (
//     <>
//       {/* <Editor
//         tinymceScriptSrc='/libs/tinymce/tinymce.min.js'
//         onInit={(evt, editor) => editorRef.current = editor}
//         initialValue="<p>This is the initial content of the editor.</p>"
//         init={{
//           height: 200,
//           menubar: true,
//           plugins: [
//             'advlist autolink lists link image charmap print preview anchor',
//             'searchreplace visualblocks code fullscreen',
//             'insertdatetime media table paste code help wordcount'
//           ],
//           toolbar: 'undo redo | formatselect | ' +
//             'bold italic backcolor | alignleft aligncenter ' +
//             'alignright alignjustify | bullist numlist outdent indent | ' +
//             'removeformat | help | table | link image',
//           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
//         }}
//         // onEditorChange
//       /> */}
//       <button onClick={log}>Log editor content</button>
//     </>
//   );
// }


// import React, { Component } from "react";
// import tinymce from "tinymce";
// import "tinymce/themes/modern";
// import "tinymce/plugins/wordcount";
// import "tinymce/plugins/table";

// class CMSEditor extends Component {
//   constructor() {
//     super();
//     this.state = { editor: null };
//   }

//   componentDidMount() {
//     var { id, height, readonly, content, mode } = this.props;
//     tinymce.init({
//       selector: '#'+id,
//       skin_url: `/libs/tinymce/skins/content/dark`,
//       plugins: "wordcount table",
//       setup: (editor) => {
//         this.setState({ editor });
//         editor.on("keyup change", () => {
//           const content = editor.getContent();
//           this.props.onEditorChange(content);
//         });
//       },
//     });
//   }

//   componentWillUnmount() {
//     tinymce.remove(this.state.editor);
//   }

//   render() {
//     return (
//       <textarea
//         id={this.props.id}
//         value={this.props.content}
//         onChange={(e) => console.log(e)}
//       />
//     );
//   }
// }

// export default CMSEditor;


// import { Component } from 'react';
// import Constants from '../utils/Constants';
// import Libs from '../utils/Libs';
// import { Editor } from '@tinymce/tinymce-react';
// class CMSEditor extends Component {
//   //Khởi tạo constructor để nhận các giá trị props từ component
//   constructor(props) {
//     super(props);
//     this.state = {
//       content: null,
//       readonly: false
//     };
//     this.tinymceEditor = null;
//   }
//   /**
//    * @description Được gọi ngay trước khi chuẩn bị render, được dùng để thiết lập hay chuẩn bị trước khi render
//    * @author Long.Pham 12/05/2021
//    */
//   componentDidMount() {
//     var { id, height, readonly, content, mode } = this.props;
//     if (!height) {
//       height = 150;
//     }
//     if (!readonly) {
//       readonly = false;
//     }
//     var _this = this;
//     var _init = {};
//     console.log("mode: ", mode);
//     switch (mode) {
//       case 'full':
//         _init = {
//           selector: "#" + id,
//           theme: "silver",
//           height: height,
//           menubar: true,
//           readonly: readonly,
//           statusbar: false,
//           relative_urls: false,
//           remove_script_host: false,
//           convert_urls: false,
//           paste_data_images: true,
//           file_browser_callback_types: 'image',
//           file_picker_callback: (callback, value, meta) => {
//             if (meta.filetype === 'image') {

//               var input = document.getElementById('image-upload-tinymce');
//               input.click();

//               input.onchange = () => {
//                 // var file = input.files[0];
//                 // var reader = new FileReader();

//                 // reader.onload = (e) => {
//                 //   var img = new Image();
//                 //   var param = {
//                 //     image: file.name,
//                 //     file_upload: reader.result
//                 //   }

//                 //   NewsService.instance.uploadImage(param, function (status, data, msg) {
//                 //       if(status && data && !Libs.isBlank(data.file_url)){
//                 //           img.src = Constants.SERVER_DATA + '/'+ data.file_url; // reader.result;
//                 //           callback(Constants.SERVER_DATA + '/'+ data.file_url, {
//                 //               alt: file.name
//                 //           });
//                 //       }
//                 //   }, true);
//                 // };

//                 // reader.readAsDataURL(file);
//               };
//             }
//           },

//           plugins: [
//             "advlist autolink link image lists charmap print preview hr pagebreak",
//             "wordcount code fullscreen insertdatetime nonbreaking",
//             "save table emoticons paste"
//           ],
//           toolbar: "insertfile undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor",
//           setup: editor => {
//             _this.tinymceEditor = editor;
//             editor.on('keyup change', () => {
//               const content = editor.getContent();
//               _this.props.onEditorChange(content, editor);
//             });
//             if (_this.props.onRegistered) {
//               console.log("editor, _this: ", editor, _this);
//               // _this.props.onRegistered(editor, _this);
//             }
//           }
//         }

//         break;
//       default:
//         // _init = {
//         //   selector: "#" + id,
//         //   theme: "silver",
//         //   height: height,
//         //   menubar: false,
//         //   readonly: readonly,
//         //   statusbar: false,
//         //   relative_urls: false,
//         //   remove_script_host: false,
//         //   convert_urls: false,
//         //   paste_data_images: true,

//         //   plugins: [
//         //     "advlist autolink lists charmap print preview hr pagebreak",
//         //     "wordcount code fullscreen insertdatetime nonbreaking",
//         //     "save table contextmenu emoticons paste"
//         //   ],
//         //   toolbar: "undo redo | forecolor backcolor | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table",
//         //   setup: editor => {
//         //     _this.tinymceEditor = editor;
//         //     editor.on('keyup change', () => {
//         //       const content = editor.getContent();
//         //       console.log("content: ", content);
//         //       _this.props.onEditorChange(content, editor);
//         //     });
//         //     if (_this.props.onRegistered) {
//         //       _this.props.onRegistered(editor, _this);
//         //     }
//         //   }
//         // }
//         break
//     }



//     _this.setState({
//       content,
//       readonly,
//       init: _init
//     });

//   }

//   componentWillUnmount(){
//     tinymce.remove(this.state.editor);
//   }

//   render() {
//     var { id, content, readonly, name, label, required } = this.props;
//     return (


//       <div className="main-editor">
//         <input type="file" id="image-upload-tinymce" name="single-image" style={{ display: "none" }} accept="image/png, image/gif, image/jpeg, image/jpg, image/svg" />
//         {!Libs.isBlank(label) ? <label className="control-label">{label}
//           {required ? <span className="required">*</span> : ''}
//         </label> : ''}
//         <Editor
//           // apiKey={Constants.TINY_API_KEY}
//           id={id}
//           tinymceScriptSrc='/libs/tinymce/tinymce.min.js'
//           textareaName={name}
//           disabled={readonly}
//           initialValue={(content) ? content : ""}
//           init={this.state.init}
//           onEditorChange={this.props.handleEditorChange}
//         />
//       </div>
//     );
//   }
// }

// export default CMSEditor;