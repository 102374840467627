import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RText, RButton, RTextArea } from '../../../../../components/Controls';

import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import Constants from '../../../../../utils/Constants';
import AddSupplierPopup from './AddSupplierPopup';
import AddRow from './AddRow';
import Libs from '../../../../../utils/Libs';
// import AddProductPopup from './AddProductPopup';

export default function () {
    var { t } = this.props;
    var { curItem, allLanguage, allSupplier, dataListAddRow, allStores, allProducts, paymentMethod, manualDiscountType, dataCategories } = this.state;
    var total = 0;
    var AddSupplierPopupLayout = this.state.showAddSupplier
        ? <AddSupplierPopup
            onCloseSupplierPopup={this.onCloseSupplierPopup.bind(this)}
            auth={this.props.auth}
            curItem={curItem}
            allLanguage = {allLanguage}
            t={t}
        /> : '';

    // Add row
    var addRows = null;
    function compareValues(key, order = 'asc') {
        return function (a, b) {
            if (!a.hasOwnProperty(key) ||
                !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order == 'desc') ?
                    (comparison * -1) : comparison
            );
        };
    }

    if (Libs.isArrayData(dataListAddRow)) {
        // console.log(dataListAddRow.sort());
        addRows = dataListAddRow.sort(compareValues('index', 'desc')).map((item, index) => {
            total = total + (item.quantity * item.price);
            return <AddRow
                key={'row_item_' + index}
                index={index}
                dataItem={item}
                curItem={curItem}
                allStores={allStores}
                allProducts={allProducts}
                removeItemRow={this.removeItemRow}
                onRowSelectChange={this.onRowSelectChange}
                handleRowInputChange={this.handleRowInputChange}
                validateOneRow={this.validateOneRow}
                onClickAddProduct={this.onClickAddProduct}
            />
        });
    }

    // var AddProductPopupLayout = this.state.showAddProduct
    //     ? <AddProductPopup
    //         onCloseProductPopup={this.onCloseProductPopup.bind(this)}
    //         auth={this.props.auth}
    //         curItem={curItem}
    //     /> : '';

    return (
        <React.Fragment>
            {AddSupplierPopupLayout}
            {/* {AddProductPopupLayout} */}
            <div className="modal-add-import-stock">
                <Modal visible={true} className="" dialogClassName="modal-dialog-scrollable modal-xl form-add-import-stock full-screen">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('import_stock.label_add')}</h5>
                        <span className="close" onClick={this.props.onClickCloseAdd.bind(this)}><var className="icon-cancel-music"></var></span>
                    </div>

                    <div className="modal-body">


                        <div className="import-stock-info">
                            <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                    <div className="info">
                                        <h2>Thông tin</h2>
                                        <div className="info-content">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <div className="mb-3">
                                                        <div className="box-dropdownlist-add">
                                                            <div className="dropdownlist">
                                                                <FormReactSelect
                                                                    label={t('import_stock.partner_name')}
                                                                    instanceId="id_partner"
                                                                    required="required"
                                                                    className="id_partner"
                                                                    name="id_partner"
                                                                    value={allSupplier.filter(function (item) { return item.id === curItem.id_stores })}
                                                                    onChange={(e) => { this.handleDropdownChange(e, 'id_stores') }}
                                                                    optionList={allSupplier}
                                                                    placeHolder={t('common.choose')}
                                                                />

                                                            </div>
                                                            <div className={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? "dropdownadd disabled" : "dropdownadd"} onClick={curItem.screen_mode != Constants.SCREEN_MODE.VIEW ? this.onClickAddSupplier.bind(this) : {}}  > <i className="icon-plus"></i> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <div className="mb-3">
                                                        <RText label={t('import_stock.id_code')}
                                                            inputClass="form-control"
                                                            required="required"
                                                            inputId="iso_code" inputName="iso_code"
                                                            value={curItem.iso_code}
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            maxLength={100} />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <div className="mb-3">
                                                        <CMSDatePicker
                                                            label={t("import_stock.date")}
                                                            inputId="import_date"
                                                            required="required"
                                                            inputClass="form-control"
                                                            inputName="import_date"
                                                            maxLength={20}
                                                            value={curItem.import_date}
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            handleChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            showIconCalendar={true}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <div className="mb-3">
                                                        <FormReactSelect
                                                            label={t('import_stock.label_id_stores')}
                                                            instanceId="id_stores"
                                                            required="required"
                                                            className="id_stores"
                                                            name="id_stores"
                                                            value={allStores.filter(function (item) { return item.id === curItem.id_stores })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'id_stores') }}
                                                            optionList={allStores}
                                                            placeHolder={t('common.choose')}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                                    <div className="mb-3">
                                                        <RText label={t('import_stock.description')}
                                                            inputClass="form-control"
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            inputId="description" inputName="description"
                                                            value={curItem.description}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                            maxLength={500} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                    <div className="info">
                                        <h2>Thanh toán</h2>
                                        <div className="info-content">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <RText label={t('import_stock.vat')}
                                                            inputClass="form-control"
                                                            inputId="vat" inputName="vat"
                                                            value={curItem.vat}
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            maxLength={100} />
                                                    </div>
                                                </div>


                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <CMSDatePicker
                                                            label={t("import_stock.vat_date")}
                                                            inputId="vat_date"
                                                            inputClass="form-control"
                                                            inputName="vat_date"
                                                            maxLength={20}
                                                            value={curItem.vat_date}
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            handleChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            showIconCalendar={true}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <FormReactSelect
                                                            label={t('import_stock.payment_method')}
                                                            instanceId="payment_method"
                                                            className="payment_method"
                                                            name="payment_method"
                                                            value={paymentMethod.filter(function (item) { return item.id === curItem.payment_method })}
                                                            onChange={(e) => { this.handleDropdownChange(e, 'payment_method') }}
                                                            optionList={paymentMethod}
                                                            placeHolder={t('common.choose')}
                                                        />
                                                    </div>
                                                </div>




                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="mb-3">
                                                        <CMSDatePicker
                                                            label={t("import_stock.appointment_date")}
                                                            inputId="appointment_date"
                                                            required="required"
                                                            inputClass="form-control"
                                                            inputName="appointment_date"
                                                            maxLength={20}
                                                            value={curItem.appointment_date}
                                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                            handleChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                            showIconCalendar={true}
                                                        />
                                                    </div>
                                                </div>



                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <label className="control-label">{t('import_stock.manual_discount')}</label>
                                                    <div className="row">
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                            <FormReactSelect
                                                                instanceId="manual_discount_type"
                                                                required="required"
                                                                className="manual_discount_type"
                                                                name="manual_discount_type"
                                                                value={manualDiscountType.filter(function (item) { return item.id === curItem.manual_discount_type })}
                                                                onChange={(e) => { this.handleDropdownChange(e, 'manual_discount_type') }}
                                                                optionList={manualDiscountType}
                                                                placeHolder={t('common.choose')}
                                                            />
                                                        </div>

                                                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                                            <div className="mb-3">
                                                                <RText
                                                                    inputClass="form-control"
                                                                    disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                                    inputId="manual_discount" inputName="manual_discount"
                                                                    value={curItem.manual_discount}
                                                                    onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                                    maxLength={500} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>

                        <div className="add-stock-product">
                            <div className="import-stock-search-product">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <FormReactSelect
                                                instanceId="manual_discount_type"
                                                required="required"
                                                className="id_categories"
                                                name="id_categories"
                                                value={dataCategories.filter(function (item) { return item.id === curItem.id_categories })}
                                                onChange={(e) => { this.handleDropdownChange(e, 'id_categories') }}
                                                optionList={dataCategories}
                                                placeHolder={t('common.choose')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="mb-3">
                                            <div className="data-search">
                                                <RText
                                                    placeholder={t('import_stock.keyword_search_product')}
                                                    inputClass="form-control"
                                                    required="required"
                                                    inputId="keyword_search_product" inputName="keyword_search_product"
                                                    value={curItem.keyword_search_product}
                                                    disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                    onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                    maxLength={200} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-1">
                                        <div className="add-product" onClick={this.onClickAddRow.bind(this)}>
                                            <i className="icon-plus"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-add-product">
                                <div className="header-col width25">
                                    {t('import_stock.product_name')}
                                </div>

                                <div className="header-col width10 text-center">
                                    {t('import_stock.unit')}
                                </div>

                                <div className="header-col width10 text-end">
                                    {t('import_stock.quantity')}
                                </div>

                                <div className="header-col width10 text-end">
                                    {t('import_stock.price')}
                                </div>

                                <div className="header-col width10 text-center">
                                    {t('import_stock.product_date')}
                                </div>
                                <div className="header-col width10 text-center">
                                    {t('import_stock.expiration_date')}
                                </div>

                                <div className="header-col width20 text-end">
                                    {t('import_stock.total_sub')}
                                </div>

                                <div className="header-col text-right width5">

                                </div>

                            </div>

                            <div className="body">
                                {addRows}

                                <div className="body-row">
                                    <div className="body-col width35 text-end"><p><strong>Tổng</strong></p></div>
                                    <div className="body-col width10 text-end"><p>200</p></div>
                                    <div className="body-col width10 text-end"></div>
                                    <div className="body-col width10"></div>
                                    <div className="body-col width10"></div>
                                    <div className="body-col width20 text-end">{Libs.formatNum(400000000, "#,###.##", 1)}</div>
                                    <div className="body-col text-end width5"></div>
                                </div>
                                <div className="body-row">
                                    <div className="body-col width75 text-end"><p><strong>VAT (%)</strong></p></div>
                                    <div className="body-col width20 text-end">
                                        10
                                </div>
                                    <div className="body-col text-end width5"></div>
                                </div>

                                <div className="body-row">
                                    <div className="body-col width75 text-end"><p><strong>Số tiền cần thanh toán</strong></p></div>
                                    <div className="body-col width20 text-end">
                                        <div className="total">
                                            {Libs.formatNum(total, "#,###.##", 1)} <span>vnđ</span>
                                        </div>
                                    </div>
                                    <div className="body-col text-end width5"></div>
                                </div>




                            </div>

                        </div>
                    </div>

                    <div className="modal-footer">
                        <RButton
                            onClick={this.onClickCloseAdd.bind(this)}
                            className="btn btn-cancel"
                            text={t('common.cancel')}
                            title={t('common.cancel')} />

                        <RButton
                            onClick={this.onSaveAction.bind(this)}
                            className="btn btn-save"
                            text={curItem.screen_mode == Constants.SCREEN_MODE.ADD ? " " + t('common.add') : t('common.update')}
                            iClass={curItem.screen_mode == Constants.SCREEN_MODE.ADD ? "fa fa-plus" : ""}
                            title={curItem.screen_mode == Constants.SCREEN_MODE.ADD ? t('common.add') : t('common.update')} />

                    </div>
                </Modal>
            </div>

        </React.Fragment >
    )
}