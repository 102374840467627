import React from 'react';
import { RText, RButton } from '../../../../../components/Controls';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import Libs from '../../../../../utils/Libs';
import { Paging } from '../../../../../components/Paging';
import RowItem from './RowItem';
import AddRow from './AddRow'
import AddPopup from './AddPopup';
// import DeleteInventoryStockPopup from './DeleteInventoryStockPopup';

export default function () {
  var { t } = this.props;
  var { formSearch, dataList, searchParam, curItem, listSupplier, listIdTypeVoucher, dataListAddRow, allStores, allProducts } = this.state;

  // Add row
  var addRows = null;
  function compareValues(key, order = 'asc') {
    return function (a, b) {
      if (!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ?
          (comparison * -1) : comparison
      );
    };
  }

  if (Libs.isArrayData(dataListAddRow)) {
    // console.log(dataListAddRow.sort());
    addRows = dataListAddRow.sort(compareValues('index', 'desc')).map((item, index) => {
      return <AddRow
        key={'row_item_' + index}
        index={index}
        dataItem={item}
        curItem={curItem}
        allStores={allStores}
        allProducts={allProducts}
        removeItemRow={this.removeItemRow}
        onRowSelectChange={this.onRowSelectChange}
        handleRowInputChange={this.handleRowInputChange}
        validateOneRow={this.validateOneRow}
        onClickAddProduct={this.onClickAddProduct}
      />
    });
  }

  // add list row 
  var RowItems = null;
  if (Libs.isArrayData(dataList)) {
    RowItems = dataList.map((item, index) => {
      return <RowItem
        key={'row_item_' + index}
        index={index}
        dataItem={item}
        onStatusChange={this.onStatusChange}
        setActiveElement={this.setActiveElement}
        activeId={this.state.activeId}
        onItemClick={this.onItemClick}
        onItemClickDelete={this.onItemClickDelete}
        onItemClickDetail={this.onItemClickDetail}
        t={t}
      />
    });
  }

  var AddPopupLayout = this.state.add
    ? <AddPopup
      onCloseAddPopup={this.onCloseAddPopup.bind(this)}
      auth={this.props.auth}
      curItem={curItem}
      dataListAddRow={dataListAddRow}
      listSupplier={listSupplier}
      listIdTypeVoucher={listIdTypeVoucher}
      onClickAddSupplier={this.onClickAddSupplier.bind(this)}
      addRows={addRows}
      t={t}
    /> : '';


  //   var InventoryStockDeletePopupLayout = this.state.showDeleteInventoryStockPopup
  // 	? <DeleteInventoryStockPopup
  // 		onCloseDeleteInventoryStockPopup={this.onCloseDeleteInventoryStockPopup.bind(this)}
  // 		auth={this.props.auth}
  // 		curItem={curItem}
  //   /> : '';





  return (
    <React.Fragment>
      {AddPopupLayout}
      {/* {InventoryStockPopupLayout}
      {InventoryStockDeletePopupLayout} */}
      <div className="inventory" >

        <div className="page-breadcrumb">
          <div className="row">
            <div className="col-md-6"><h2>{t('inventory_stock.title')}</h2>
            </div>
            <div className="col-md-6">
              <ul>
                <li><div className="search" onClick={this.onSearch.bind(this)}><span className="icon icon-search"></span> {t('common.search')}</div></li>
                <li><div className="add" onClick={this.onClickAdd.bind(this)}><span className="icon icon-add"></span> {t('common.add')}</div></li>
              </ul>
            </div>
          </div>
        </div>



        {this.state.formSearch ?
          <div className="form-search">

            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="mb-3">
                  <RText label={t('inventory_stock.id_code')}
                    inputClass="form-control"
                    inputName="id_code"
                    inputId="id_code" inputName="id_code"
                    value={searchParam.id_code}
                    onChange={(e) => { this.handleInputChange(e); }}
                    maxLength={200} />
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="mb-3">
                  <RText label={t('inventory_stock.id_order')}
                    inputClass="form-control"
                    inputName="id_order"
                    inputId="id_order" inputName="id_order"
                    value={searchParam.id_order}
                    onChange={(e) => { this.handleInputChange(e); }}
                    maxLength={200} />
                </div>
              </div>


              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="mb-3">
                  <CMSDatePicker
                    label={t("inventory_stock.from_date")}
                    inputId="from_date"
                    inputClass="form-control"
                    inputName="from_date"
                    maxLength={10}
                    value={searchParam.from_date}
                    handleChange={this.handleSearchDateChange.bind(this)}
                    showIconCalendar={true}
                  />
                </div>

              </div>


              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                <div className="mb-3">
                  <CMSDatePicker
                    label={t("inventory_stock.to_date")}
                    inputId="to_date"
                    inputClass="form-control"
                    inputName="to_date"
                    maxLength={10}
                    value={searchParam.to_date}
                    handleChange={this.handleSearchDateChange.bind(this)}
                    showIconCalendar={true}
                  />
                </div>

              </div>




              <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                <div className="mb-3">
                  <button type="button" className="btn btn-search" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                </div>
              </div>

            </div>




          </div>
          : ""}

        <div className="data-view">
          <div className="main-header">
            <div className="header-row">
              <div onClick={e => this.onSort(e, 'export_date')} className={searchParam.sort_column === "export_date" ? "header-col width10 sorting " + searchParam.order_by : "header-col width10 sorting"}><p>{t('inventory_stock.date')}</p></div>
              <div onClick={e => this.onSort(e, 'id_code')} className={searchParam.sort_column === "iso_code" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('inventory_stock.id_code')}</p></div>
              <div onClick={e => this.onSort(e, 'total')} className={searchParam.sort_column === "total" ? "header-col width20 sorting " + searchParam.order_by : "header-col width20 sorting"}><p>{t('inventory_stock.store_name')}</p></div>
              <div onClick={e => this.onSort(e, 'total')} className={searchParam.sort_column === "partner_name" ? "header-col width20 sorting " + searchParam.order_by : "header-col width20 sorting"}><p>{t('inventory_stock.username')}</p></div>
              <div className="header-col width5"><p>{t('inventory_stock.sp')}</p></div>
              <div className="header-col width5"><p>{t('inventory_stock.sl')}</p></div>
              <div className="header-col width10"><p>{t('inventory_stock.sp_missing')}</p></div>
              <div className="header-col width15 text-end"><p>{t('common.action')}</p></div>
            </div>
          </div>
          <div className="main-body">
            <div className="body">
              {RowItems ? RowItems : <div className="data-empty">{t('common.data_empty')}</div>}
            </div>
          </div>
        </div>

        <div className="main-paging">
          <Paging
            total={this.paging.total}
            current={this.paging.current}
            inputChangedHandler={this.inputChangedHandler}
            onBrandKeyPress={this.onBrandKeyPress}
            onInputPage={this.onInputPage}
            onClickReload={this.onClickReload}
            onSelectPage={(index) => this.onSelectPage.bind(this, index)}>
          </Paging>
        </div>


      </div>
    </React.Fragment>

  )
}