
/********************************************************
* Copyright 2020-2021 NEXT WAVE ENERGY MONITORING INC.
* All rights reserved.
* 
*********************************************************/
import BaseComponent from '../../../../BaseComponent';
import ImportPopupJsx from './ImportPopup.jsx';
import Constants from '../../../../../utils/Constants';
import ProductService from '../../../../../services/ProductService';
import CategoryService from '../../../../../services/CategoryService';
import BrandService from '../../../../../services/BrandService';
import GroupAttributesService from '../../../../../services/GroupAttributesService';
import Libs from '../../../../../utils/Libs';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ObjectGet from "lodash/get"
import ObjectCloneDeep from "lodash/cloneDeep"
import ObjectFind from "lodash/find"
import _findKey from "lodash/findKey"
import _clone from "lodash/clone"
import TypeService from '../../../../../services/TypeService';
import UnitService from '../../../../../services/UnitService';
import ProductTypeService from '../../../../../services/ProductTypeService';
import _isObject from 'lodash/isObject';
import moment from 'moment';

const product_default = {
    "screen_mode": null,
    "tabActive": "vi",
    "data": [
        {
            "id_product": "",
            "is_default": 1,
            "iso_code": "vi",
            "id_language": 1,
            "name": "",
            "slug": "",
            "content": "",
            "description": "",
            "meta_title": "",
            "meta_keyword": "",
            "meta_description": "",
            "des_size": "",
            "privilege": ""
        }
    ],
    "id_type": 3, //Loại sp
    "uplsells": [],
    "crossSelling": [],
    "giftProduct": [],
    "galleries": [],
    "dataTags": [],
    "dataCustomFields": [],
    "quantity": 0,
    "dataVariants": [],
    "dataGroupAttributes": [],
    "sku": "",
    "id_category": 0,
    "id_brand": 0,
    "id_unit": 0,
    "id_product_type": 0,
    "id_type_horizontally": 0,
    "id_type_shape": 0,
    "id_type_material": 0,
    "dataFrameColor": [],
    "dataGender": [],
    "dataFace": [],
    "upload_thumbnail": "",
    "upload_thumbnail_name": "",
    "upload_thumbnail_hover": "",
    "upload_thumbnail_hover_name": "",
    "original_price": 0,
    "wholesale_price": 0,
    "regular_price": 0,
    "name_vi": "",
    "id_company": 0,
    "iso_code_lang": "vi",
    "id_language": 0,
    "iso_code": "",
    "created_by": "",
    "updated_by": "",
    "config_thumb_folder_new": "",
    "config_thumb_folder_pro": "",
    "config_thumb_new_h": 0,
    "config_thumb_pro_h": 0,
    "config_thumb_pro_w": 0,
    "config_cdn": "",
    "dataLens": [],
    "dataTags": []
}

const product_variant_default = {
    "unique_string_id": "",
    "sku": "",
    "id_product": "",
    "id_attribute1": null,
    "id_attribute2": null,
    "id_attribute3": null,
    "quantity": 0,
    "regular_price": 0,
    "saleoff_price": null,
    "saleoff_from": null,
    "saleoff_to": null,
    "show_range_time": 0,
    "is_default": 0,
    "file_upload": "",
    "file_upload_name": "",
    "thumbnail_url": "",
    "errors": {
      "regular_price": false,
      "saleoff_price": false,
      "quantity": false,
      "time_range": false,
      "sku": false
    }
}
const JobClient = require('axios').create({
	baseURL: Constants.JOB_HOST,
	timeout: 10000,
    headers: { 'Authorization': 'Basic '+  (Buffer.from(Constants.JOB_HOST_AUTH).toString('base64')) }
});
class ImportPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            success: [],
            curItem: {
                crossSelling: []
            },
            searchParam: {},
            dataCrossSellingSearchAuto: []
        }
        this.dataImport = null
        this.jsxTemplate = ImportPopupJsx;
        this.getDropdownListCategory()
        this.getDropdownListBrand()
        this.getAttrbutes()
        this.getListFilterType()
        this.getDropdownListUnit()
        this.getDropdownListProductType()
    }

    /**
     * @description close popup
     * @author Long.Pham 12/05/2021
     */
     onClickCloseImport() {
        if (!this.props.onClickCloseImport || typeof this.props.onClickCloseImport !== 'function') return;
        this.props.onClickCloseImport(false, null);
    }

    
    onClearCrossSellingItem = (index) => {
        var curItem = this.state.curItem;
        var crossSelling = curItem.crossSelling;
        if (!Libs.isArrayData(crossSelling)) return;
        var item = crossSelling[index];
        if (Libs.isObjectEmpty(item)) return;
        crossSelling.splice(index, 1);

        curItem.crossSelling = crossSelling;
        this.setState({
            curItem: curItem
        });

    }

    addCrossSelling = (index) => {
        var { curItem, dataCrossSellingSearchAuto } = this.state;
        if (Libs.isArrayData(dataCrossSellingSearchAuto)) {
            var item = dataCrossSellingSearchAuto[index];
            if (Libs.isObjectEmpty(item)) return;

            var crossSelling = curItem.crossSelling;
            var findItemExits = Libs.find(crossSelling, 'id', item.id);
            if (Libs.isObjectEmpty(findItemExits)) {
                crossSelling.push(item);
            }
            dataCrossSellingSearchAuto.splice(index, 1);
            curItem.crossSelling = crossSelling;

        }
        this.setState({
            curItem: curItem
        });
    }

    getCrossSellingSearchAuto(e) {
        let self = this, searchParam = this.state.searchParam;
        searchParam.cross_selling = e.target.value;
        var curItem = this.state.curItem;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            name: searchParam.cross_selling,
            ids: curItem.crossSelling
        };

        this.setState({ searchParam }, () => {
            if (Libs.isBlank(searchParam.cross_selling)) {
                self.setState({
                    dataCrossSellingSearchAuto: []
                });
                return;
            }
    
            ProductService.instance.getSearchAuto(params, (data, total_row) => {
                if (Libs.isArrayData(data)) {
                    self.setState({
                        dataCrossSellingSearchAuto: data
                    });
                } else {
                    self.setState({
                        dataCrossSellingSearchAuto: []
                    });
                }
            })
        });
    }

    getListFilterType() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,

            type_strs: [
                { type: "frame_color" },
                { type: "material" },
                { type: "shape" },
                { type: "horizontally" },
                { type: "gender" },
                { type: "face" },
                { type: "lily_material" },
            ]
        };

        TypeService.instance.getListTypeKeyArr(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataHorizontally: data.filter((v, k) => v.type === 'horizontally'),
                    dataShape: data.filter((v, k) => v.type === 'shape'),
                    dataMaterial: data.filter((v, k) => v.type === 'material' || v.type === 'lily_material'),
                    dataFrameColor: data.filter((v, k) => v.type === 'frame_color'),
                    dataGender: data.filter((v, k) => v.type === 'gender'),
                    dataFace: data.filter((v, k) => v.type === 'face')
                });
            } else {
                self.setState({
                    dataHorizontally: [],
                    dataShape: [],
                    dataMaterial: [],
                    dataFrameColor: [],
                    dataGender: [],
                    dataFace: []
                })
            }
        }, false);
    }

    getDropdownListProductType() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        ProductTypeService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataProductType: data
                });
            }
        })
    }

    getDropdownListUnit() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        UnitService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataUnit: data
                });
            }
        })
    }

    getDropdownListCategory() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        CategoryService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                let rows = {}
                for (let i in data) {
                    rows[data[i].slug] = data[i].id
                }
                self.setState({
                    dataCategory: rows
                });
            } else {
                self.setState({
                    dataCategory: {}
                });
            }
        })
    }

    getDropdownListBrand() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        BrandService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                let rows = {}
                for (let i in data) {
                    rows[data[i].name] = data[i].id
                }
                self.setState({
                    dataBrand: rows
                });
            } else {
                self.setState({
                    dataBrand: []
                });
            }
        })
    }

    getAttrbutes() {
        let self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        GroupAttributesService.instance.getDropdownListAttr(params, (data) => {
            if (Libs.isArrayData(data)) {
                let data_map = {}
                for (let i in data) {
                    for (let i2 in data[i].attrs) {
                        data_map[data[i].attrs[i2].name.trim()] = {
                            group: i,
                            attr: i2
                        }
                    }
                }
                self.setState({
                    attrs: data,
                    attrs_map: data_map,
                });
            }
        });
    }

    /** 
     * @description delete product
     * @author Long.Pham 12/05/2021
    */

    importExcel = (e) => {
        e.preventDefault();
        var self    = this;
        var files   = e.target.files, f = files[0];
        if(!f) return
        var reader  = new FileReader();

        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            self.dataImport = XLSX.utils.sheet_to_json(ws, { header: 1 });
            self.dataImport.splice(0, 1)
            self.setState({errors: []});
        };
        reader.readAsBinaryString(f)
    }

    updateError(row) {
        let errors = this.state.errors
        errors.push(row)
        this.setState({errors});
    }

    updateSuccess(row) {
        let success = this.state.success
        success.push(row)
        this.setState({success});
    }

    exportAllDataUpdate =  async ()=> {
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        }
        ProductService.instance.getAllID(params, (data, err='')=> {
            if (data !== false && data.length > 0) {
                this.state.curItem.crossSelling = data
                this.exportDataUpdate()
            } else {
                alert('Không tìm thấy sản phẩm nào để xuất ra!')
            }
        })
    }

    exportDataUpdate = async ()=> {
        let {crossSelling} = this.state.curItem
        let {dataCategory, dataBrand, dataProductType, dataUnit, dataShape, dataMaterial} = this.state
        let self = this
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        }

        let dataExport = []

        for (let index in crossSelling) {
            params.id = crossSelling[index].id
            await this.sleep()
            await ProductService.instance.getDetailAsync(params, (product_info)=> {
                let attrs_image = ObjectGet(ObjectFind(product_info.dataGroupAttributes, {is_default: 1}), 'dataListAttr', [])
                let _unit = ObjectFind(dataUnit, {id: product_info.id_unit})
                let _product_type = ObjectFind(dataProductType, {id: product_info.id_product_type})
                let _type_shape = ObjectFind(dataShape, {id: product_info.id_type_shape})
                let _type_material = ObjectFind(dataMaterial, {id: product_info.id_type_material})
                let product_export = {
                    "Mã Sản Phẩm *": product_info.sku,
                    "Sản Phẩm Cha *": '',
                    "Tên Sản Phẩm *": ObjectGet(product_info, 'data[0].name', ''),
                    "Mã Danh Mục *": _findKey(dataCategory, _cat_id => (_cat_id == product_info.id_category)),
                    "Thương hiệu *": _findKey(dataBrand, _cat_id => (_cat_id == product_info.id_brand)),
                    "Đơn vị *":  _unit ? _unit.name : '',
                    "Loại sản phẩm *":  _product_type ? _product_type.name : '',
                    "Thuộc tính *": '',
                    "Giá gốc *": product_info.regular_price,
                    "Số Lượng *": product_info.quantity,
                    "Hình ảnh *": [],
                    "Hình dạng": _type_shape ? _type_shape.name : '',
                    "Chất liệu": _type_material ? _type_material.name : '',
                    "Màu gọng kính": [],
                    "Dành cho": [],
                    "Form khuôn mặt": [],
                    "Giá Khuyến mãi": product_info.saleoff_price,
                    "Ngày bắt đầu KM": !Libs.isBlank(product_info.saleoff_from) ? ("#" + product_info.saleoff_from) : "#",
                    "Ngày kết thúc KM": !Libs.isBlank(product_info.saleoff_to) ? ("#" + product_info.saleoff_to) : "#",
                    "Mô tả kích thước": ObjectGet(product_info, 'data[0].des_size', ''),
                    "Chi tiết sản phẩm": ObjectGet(product_info, 'data[0].content', ''),
                    "Mô tả ngắn": ObjectGet(product_info, 'data[0].description', ''),
                    "Ưu đãi riêng khi mua hàng": ObjectGet(product_info, 'data[0].privilege', ''),
                    "Trạng thái *": product_info.status,
                    "Chiều ngang (mm)": product_info.item_length,
                    "Càng kính (mm)": product_info.item_width,
                    "Độ cao tròng (mm)": product_info.item_height,
                    "Độ rộng tròng (mm)": product_info.item_width_2,
                    "Cầu mũi (mm)": product_info.item_width_3,
                    "Từ khóa": ObjectGet(product_info, 'data[0].meta_keyword', ''),
                    "Tag": [],
                    "Thời gian tạo": product_info.created_date,
                    "ID": product_info.id,
                    "Đường dẫn": "",
                }

                if (product_info.id_company == 1) {
                    product_export["Đường dẫn"] = `https://farello.vn/${ObjectGet(product_info, 'data[0].slug', '')}-p${product_info.id}`
                } else if (product_info.id_company == 2) {
                    product_export["Đường dẫn"] = `https://kinhmatlily.com/${ObjectGet(product_info, 'data[0].slug', '')}-p${product_info.id}`
                }

                ObjectGet(product_info, 'dataFrameColor', []).map(_row=> {
                    product_export["Màu gọng kính"].push(_row.name)
                })
                product_export["Màu gọng kính"] = product_export["Màu gọng kính"].join(", ")

                ObjectGet(product_info, 'dataGender', []).map(_row=> {
                    product_export["Dành cho"].push(_row.name)
                })
                product_export["Dành cho"] = product_export["Dành cho"].join(", ")

                ObjectGet(product_info, 'dataFace', []).map(_row=> {
                    product_export["Form khuôn mặt"].push(_row.name)
                })
                product_export["Form khuôn mặt"] = product_export["Form khuôn mặt"].join(", ")

                if (product_info.thumbnail_medium) {
                    product_export["Hình ảnh *"].push(product_info.thumbnail_medium)
                }
                if (product_info.thumbnail_hover_medium) {
                    product_export["Hình ảnh *"].push(product_info.thumbnail_hover_medium)
                }
                ObjectGet(product_info, 'galleries', []).map(img=> {
                    product_export["Hình ảnh *"].push(img.thumbnail_medium)
                })
                product_export["Hình ảnh *"] = product_export["Hình ảnh *"].join(",\n")
                
                ObjectGet(product_info, 'dataTags', []).map(_row=> {
                    product_export["Tag"].push(_row.name)
                })
                product_export["Tag"] = product_export["Tag"].join(", ")

                dataExport.push(product_export)

                ObjectGet(product_info, 'dataVariants', []).map(variant=> {
                    let variant_export = {
                        "Mã Sản Phẩm *": variant.sku,
                        "Sản Phẩm Cha *": product_info.sku,
                        "Tên Sản Phẩm *": product_export["Tên Sản Phẩm *"],
                        "Mã Danh Mục *": product_export["Mã Danh Mục *"],
                        "Thương hiệu *": product_export["Thương hiệu *"],
                        "Đơn vị *": product_export["Đơn vị *"],
                        "Loại sản phẩm *":  product_export["Loại sản phẩm *"],
                        "Thuộc tính *": [],
                        "Giá gốc *": variant.regular_price,
                        "Số Lượng *": variant.quantity,
                        "Hình ảnh *": "",
                        "Hình dạng": product_export["Hình dạng"],
                        "Chất liệu": product_export["Chất liệu"],
                        "Màu gọng kính": product_export["Màu gọng kính"],
                        "Dành cho": product_export["Dành cho"],
                        "Form khuôn mặt": product_export["Form khuôn mặt"],
                        "Giá Khuyến mãi": variant.saleoff_price,
                        "Ngày bắt đầu KM": !Libs.isBlank(variant.saleoff_from) ? ("#" + variant.saleoff_from) : "#",
                        "Ngày kết thúc KM": !Libs.isBlank(variant.saleoff_to) ? ("#" + variant.saleoff_to) : "#",
                        "Mô tả kích thước": '',
                        "Chi tiết sản phẩm": '',
                        "Mô tả ngắn": '',
                        "Ưu đãi riêng khi mua hàng": '',
                        "Trạng thái *": '',
                        "Chiều ngang (mm)": '',
                        "Càng kính (mm)": '',
                        "Độ cao tròng (mm)": '',
                        "Độ rộng tròng (mm)": '',
                        "Cầu mũi (mm)": '',
                        "Từ khóa": '',
                        "Tag": '',
                        "Thời gian tạo": product_info.created_date,
                        "ID": variant.id,
                        "Đường dẫn": product_export["Đường dẫn"]
                    }

                    if (variant.name) {
                        variant_export["Thuộc tính *"].push(variant.name)
                        let attr_img = ObjectFind(attrs_image, {id: variant.id_attribute1})
                        if (attr_img && 'thumbnail_medium' in attr_img)
                            variant_export["Hình ảnh *"] = attr_img.thumbnail_medium
                    }
                    if (variant.name_1) {
                        variant_export["Thuộc tính *"].push(variant.name_1)
                        let attr_img = ObjectFind(attrs_image, {id: variant.id_attribute2})
                        if (attr_img && 'thumbnail_medium' in attr_img)
                            variant_export["Hình ảnh *"] = attr_img.thumbnail_medium
                    }
                    if (variant.name_2) {
                        variant_export["Thuộc tính *"].push(variant.name_2)
                        let attr_img = ObjectFind(attrs_image, {id: variant.id_attribute3})
                        if (attr_img && 'thumbnail_medium' in attr_img)
                            variant_export["Hình ảnh *"] = attr_img.thumbnail_medium
                    }
                    variant_export["Tên Sản Phẩm *"] += '-'+ variant_export["Thuộc tính *"].join('-')
                    variant_export["Thuộc tính *"] = variant_export["Thuộc tính *"].join(', ')
                    dataExport.push(variant_export)
                })
            })
        }

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(dataExport, { skipHeader: false });
        ws['!cols'] = [{ width: 20 }, { width: 30 }, { width: 60 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];
        const wb = {
            SheetNames: ['Sản phẩm'],
            Sheets: { 'Sản phẩm': ws }
        };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataSave = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(dataSave, "export-product" + fileExtension);
    }

    validateData(modeAction=Constants.SCREEN_MODE.ADD) {
        this.setState({
            errors: [],
            dataCrossSellingSearchAuto: []
        })
        if (!this.dataImport || this.dataImport.length < 1) {
            this.toast('Vui lòng nhập file excel có nội dung', "error");
            return null
        }
        // Nhóm sản phẩm thành cấp cha con
        let products = {}
        for (let index in this.dataImport) {
            const row   = this.dataImport[index]
            let errors  = []
            let product = {
                sku_parent: ObjectGet(row, '[1]', ''),
                sku: ObjectGet(row, '[0]', ''),
                price: ObjectGet(row, '[8]', 0),
                qty: ObjectGet(row, '[9]', 0),
                url_images: ObjectGet(row, '[10]', '').replace(/[\n\t\r]/g,"").split(','),
                des_size: ObjectGet(row, '[19]', ''),
                content: ObjectGet(row, '[20]', ''),
                description: ObjectGet(row, '[21]', ''),
                privilege: ObjectGet(row, '[22]', ''),
                status: ObjectGet(row, '[23]', 0),
                item_length: ObjectGet(row, '[24]', 0),
                item_width: ObjectGet(row, '[25]', 0),
                item_height: ObjectGet(row, '[26]', 0),
                item_width_2: ObjectGet(row, '[27]', 0),
                item_width_3: ObjectGet(row, '[28]', 0),
                meta_keyword: ObjectGet(row, '[29]', ''),
                tags: ObjectGet(row, '[30]', '').split(','),
            }

            product.status = Number(product.status) == 1 ? 1 : 0;

            if (modeAction == Constants.SCREEN_MODE.UPDATE_IMPORT) {
                product['saleoff_price'] = ObjectGet(row, '[16]', 0)
                product['saleoff_from'] = ObjectGet(row, '[17]', '').replace("#", '')
                product['saleoff_to'] = ObjectGet(row, '[18]', '').replace("#", '')

                if (!Libs.isBlank(product['saleoff_from']) || !Libs.isBlank(product['saleoff_to']) || product['saleoff_price'] > 0) {
                    if (!moment(product['saleoff_from'], 'DD/MM/YYYY HH:mm').isValid()) errors.push('Ngày bắt đầu KM không hợp lệ')
                    else product['saleoff_from'] = moment(product['saleoff_from'], 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
                    if (!moment(product['saleoff_to'], 'DD/MM/YYYY HH:mm').isValid()) errors.push('Ngày kết thúc KM không hợp lệ')
                    else product['saleoff_to'] = moment(product['saleoff_to'], 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')

                    if (product['saleoff_price'] <= 0) errors.push('Vui lòng nhập giá khuyến mại')
                } else {
                    product['saleoff_price'] = null
                    product['saleoff_from'] = null
                    product['saleoff_to'] = null
                }
                // Chỉ có một chỗ lấy ID thôi
                product['id'] = ObjectGet(row, '[32]', 0)
            }

            if (!product.sku) errors.push('Vui lòng nhập mã sản phẩm')
            if (product.price < 1) errors.push('Vui lòng nhập giá gốc')
            // if (product.qty < 1) errors.push('Vui lòng nhập số lượng')

            // Nếu k có sku sản phẩm cha
            if(!product.sku_parent) {
                product['variants'] = []
                product['name']     = ObjectGet(row, '[2]', '')
                product['category'] = ObjectGet(this.state.dataCategory, ObjectGet(row, '[3]', ''), 0)
                product['brand']    = ObjectGet(this.state.dataBrand, ObjectGet(row, '[4]', ''), 0)
                product['unit']     = ObjectGet(ObjectFind(this.state.dataUnit, {name: ObjectGet(row, '[5]', '').trim()}), 'id', 0)
                product['type']     = ObjectGet(ObjectFind(this.state.dataProductType, {name: ObjectGet(row, '[6]', '').trim()}), 'id', 0)
                product['type_shape'] = ObjectGet(row, '[11]', '').trim()
                product['type_material'] = ObjectGet(row, '[12]', '').trim()
                product['dataFrameColor'] = ObjectGet(row, '[13]', '').split(',')
                product['dataGender'] = ObjectGet(row, '[14]', '').split(',')
                product['dataFace'] = ObjectGet(row, '[15]', '').split(',')

                if (modeAction == Constants.SCREEN_MODE.UPDATE_IMPORT) {
                    if (product.id < 1) errors.push('Vui lòng nhập ID sản phẩm trên hệ thống')
                }
                
                if (!product.name) errors.push('Vui lòng nhập tên sản phẩm')
                if (product.category < 1) errors.push('Vui lòng nhập mã danh mục')
                if (product.brand < 1) errors.push('Vui lòng nhập tên thương hiệu')
                if (product.unit < 1) errors.push('Vui lòng nhập đơn vị')
                if (product.type < 1) errors.push('Vui lòng nhập loại sản phẩm')
                if (product.url_images.length < 1) errors.push('Vui lòng nhập link ảnh sản phẩm')
                if (errors.length) this.updateError({
                    line: Number(index) + 2,
                    content: errors.join(". \n")
                })

                products[product.sku] = product
                continue;
            }
            product['attrs'] = ObjectGet(row, '[7]', '').split(',')
            if (product['attrs'].length < 1) errors.push('Vui lòng nhập thuộc tính sản phẩm')
            for (let i in product['attrs']) {
                let attr_name = product['attrs'][i].trim()
                if (!ObjectGet(this.state.attrs_map, attr_name, null)) errors.push(`Thuộc tính sản phẩm [${attr_name}] không hợp lệ`)
            }

            if (!(product.sku_parent in products)) errors.push('Vui lòng nhập mã sku sản phẩm cha hợp lệ')
            else products[product.sku_parent]['variants'].push(product)
            if (errors.length) this.updateError({
                line: Number(index) + 2,
                content: errors.join(". \n")
            })
        }

        return products
    }

    async renderProductData(products, callBack, modeAction=Constants.SCREEN_MODE.ADD) {
        let payloads = []
        const access_token = localStorage.getItem('cms-access-token')
        let stt = 0
        for (let index in products) {
            let row = products[index]
            let product = ObjectCloneDeep(product_default);
            product.id_company = Number(this.employee.id_company);
            product.iso_code_lang = this.employee.lang;
            product.id_language = Number(this.employee.id_language);
            product.iso_code = this.employee.iso_code;
            product.created_by = this.employee.first_name + " " + this.employee.last_name;
            product.updated_by = this.employee.first_name + " " + this.employee.last_name;
            product.config_thumb_folder_new = this.employee.config_thumb_folder_new;
            product.config_thumb_folder_pro = this.employee.config_thumb_folder_pro;
            product.config_thumb_new_h = this.employee.config_thumb_new_h;
            product.config_thumb_pro_h = this.employee.config_thumb_pro_h;
            product.config_thumb_pro_w = this.employee.config_thumb_pro_w;
            product.config_cdn = this.employee.config_cdn;

            product.screen_mode = Constants.SCREEN_MODE.ADD
            product.data[0].name = product.name_vi = row.name
            product.data[0].des_size = row.des_size
            product.data[0].content = row.content
            product.data[0].description = row.description
            product.data[0].privilege = row.privilege
            product.data[0].meta_keyword = row.meta_keyword
            product.sku    = row.sku
            product.status    = row.status
            product.quantity    = row.qty
            product.id_category     = row.category
            product.id_product_type     = row.type
            product.id_brand     = row.brand
            product.id_unit     = row.unit
            product.regular_price     = row.price
            product.item_length     = row.item_length
            product.item_width     = row.item_width
            product.item_height     = row.item_height
            product.item_width_2     = row.item_width_2
            product.item_width_3     = row.item_width_3
            product.dataTags     = row.tags

            // Lấy thông tin thuộc tính từ name
            if (row.type_shape) {
                let tmp = ObjectFind(this.state.dataShape, {name: row.type_shape})
                if(tmp) product.id_type_shape = tmp.id
            }
            if (row.type_material) {
                let tmp = ObjectFind(this.state.dataMaterial, {name: row.type_material})
                if(tmp) product.id_type_material = tmp.id
            }
            if (row.dataFrameColor.length) {
                row.dataFrameColor.map(value=> {
                    let tmp = ObjectFind(this.state.dataFrameColor, {name: value.trim()})
                    if(tmp) product.dataFrameColor.push(tmp)
                })
            }
            if (row.dataGender.length) {
                row.dataGender.map(value=> {
                    let tmp = ObjectFind(this.state.dataGender, {name: value.trim()})
                    if(tmp) product.dataGender.push(tmp)
                })
            }
            if (row.dataFace.length) {
                row.dataFace.map(value=> {
                    let tmp = ObjectFind(this.state.dataFace, {name: value.trim()})
                    if(tmp) product.dataFace.push(tmp)
                })
            }

            if (modeAction == Constants.SCREEN_MODE.UPDATE_IMPORT) {
                product.screen_mode = Constants.SCREEN_MODE.UPDATE_IMPORT
                product.data[0].id_product = product.id = Number(row.id)

                product.saleoff_price = row.saleoff_price
                product.saleoff_from = row.saleoff_from
                product.saleoff_to = row.saleoff_to
            }
            product.url_images = row.url_images

            for (let i in row['variants']) {
                let variant_raw = row['variants'][i]
                let variant = ObjectCloneDeep(product_variant_default);
                variant.sku = variant_raw.sku
                variant.quantity = variant_raw.qty
                variant.regular_price = variant_raw.price

                if (modeAction == Constants.SCREEN_MODE.UPDATE_IMPORT) {
                    variant.id_product = product.id
                    variant.id         = variant_raw.id
                    variant.saleoff_price = variant_raw.saleoff_price
                    variant.saleoff_from = variant_raw.saleoff_from
                    variant.saleoff_to = variant_raw.saleoff_to
                }

                variant.unique_string_id = []

                for (let i in variant_raw['attrs']) {
                    let position_index = this.state.attrs_map[variant_raw['attrs'][i].trim()]
                    if (!(position_index.group in product.dataGroupAttributes)) {
                        product.dataGroupAttributes[position_index.group] = Object.assign({}, this.state.attrs[position_index.group])
                        delete product.dataGroupAttributes[position_index.group].attrs
                        product.dataGroupAttributes[position_index.group].dataListAttr = []
                    }
                    let attr_item = _clone(this.state.attrs[position_index.group].attrs[position_index.attr])
                    
                    // Nếu là màu sắc thì thêm ảnh vào
                    if (product.dataGroupAttributes[position_index.group].is_default == 1 && variant_raw.url_images.length) {
                        attr_item.url_images = _clone(variant_raw.url_images)
                    }
                    if (!ObjectFind(product.dataGroupAttributes[position_index.group].dataListAttr, {id: attr_item.id}))
                        product.dataGroupAttributes[position_index.group].dataListAttr.push(attr_item)
                        
                    variant.unique_string_id.push(attr_item.id)
                    variant[`id_attribute${Number(i)+1}`] = attr_item.id
                }
                variant.unique_string_id = variant.unique_string_id.join('-')
                product.dataVariants.push(variant)
            }

            let _dataGroupAttributes = []
            for (let i in product.dataGroupAttributes) {
                if (_isObject(product.dataGroupAttributes[i])) _dataGroupAttributes.push(product.dataGroupAttributes[i])
            }
            product.dataGroupAttributes = _dataGroupAttributes

            payloads.push({
                "type": modeAction==Constants.SCREEN_MODE.ADD ? "Thêm mới sản phẩm" : "Cập nhật sản phẩm",
                "data": {
                    "title": product.name_vi,
                    "payload": product,
                    "access_token": access_token
                },
                "options" : {
                    "priority": "high",
                    "delay": 20000 + stt*30000
                }
            })
            if(payloads.length >= 10){
                callBack(payloads)
                payloads = []
                stt++
                await this.sleep()
            }
            
        }
        await this.sleep()
        if(payloads.length <= 10)
            callBack(payloads)
    }

    sleep(s=1) {
        let ms = s*1000;
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async onUpdateAction() {
        let products = this.validateData(Constants.SCREEN_MODE.UPDATE_IMPORT)
        if (!products || this.state.errors.length) return
        this.renderProductData(products, (payloads)=> {
            JobClient({
                method: 'post',
                url: '/job',
                headers: {
                    "Content-Type": `application/json; charset=utf-8`
                },
                data : payloads
            });
        }, Constants.SCREEN_MODE.UPDATE_IMPORT)
        this.toast('Sản phẩm đã được lên lịch cập nhật', "success");
    }

    async onCreateAction() {
        let products = this.validateData()
        if (!products || this.state.errors.length) return
        
        this.renderProductData(products, (payloads)=> {
            JobClient({
                method: 'post',
                url: '/job',
                headers: {
                    "Content-Type": `application/json; charset=utf-8`
                },
                data : payloads
            });
        })
        this.toast('Sản phẩm đã được lên lịch thêm mới', "success");
    }
}
export default ImportPopup;