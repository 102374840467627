
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
import AddRowValidate from './AddRowValidate';
import Constants from '../../../../../utils/Constants';
// import ImportStoresService from '../../../../services/ImportStoresService';
// import SupplierService from '../../../../services/SupplierService';
// import ProductService from '../../../../services/ProductService';
// import StoresService from '../../../../services/StoresService';
// import cloneDeep from 'lodash-es/cloneDeep';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: {},
            allSupplier: [],
            showAddSupplier: false,
            paymentMethod: [
                {id: 1, value: 1, label: "Tiền mặt"},
                {id: 2, value: 2, label: "Chuyển khoản"}
            ],
            manualDiscountType: [
                {id: 1, value: 1, label: "Tiền mặt"},
                {id: 2, value: 2, label: "%"}
            ],

            dataCategories: [
                {id: 1, value: 1, label: "Danh mục 1"},
                {id: 2, value: 2, label: "Danh mục 2"}
            ],

            // dataListAddRow: this.props.dataListAddRow,
            dataListAddRow: [
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                {
                    index: 1,
                    state: false,
                    expiration_dates: "12/12/2021",
                    date_of_manufactures: "12/12/2021",
                    quantity: 1,
                    price: 200000,
                    product_name: "Sản phẩm 1",
                    id_product: 1,
                    unit_name: "Cái",
                    messages: {
                        id_product: "",
                        quantity: "",
                        price: "",
                        date_of_manufactures: "",
                        expiration_dates: ""
                    }
                },
                
            ],
            allStores: [],
            allProducts: [],
            showAddProduct: false,
            indexAddProduct: ''
        }
        this.jsxTemplate = AddPopupJsx;
        // Sự kiện lắng nghe input text thay đổi giá trị
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author long.pham 28/07/2019
     */
    componentDidMount() {
        let self = this;
        let curItem = this.props.curItem;
        // curItem.import_date = Libs.convertDateToStr(new Date(), Constants.COMMON.DATE_FORMAT);
        // let curD = new Date();
        // curItem.iso_code = Date.parse(curD);
        // this.setState({
        //     curItem: curItem
        // });
        // self.listAllSupplier();
        // self.getAllProducts();
        // self.getAllStores();
    }

    /**
     * @description get all store
     * @author long.pham 26/07/2019
     */

    getAllStores() {
        let self = this;
        let params = {};
        // params.id_company = this.staff.id_company;
        // StoresService.instance.getAll(params, function (data, totalRow) {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allStores: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allStores: []
        //         });
        //     }
        // }, false);
    }

    /**
     * @description get all product
     * @author long.pham 26/07/2019
     */

    getAllProducts() {
        let self = this;
        let params = {};
        // params.id_company = this.staff.id_company;
        // params.iso_code = 'vi';
        // ProductService.instance.getAllProduct(params, function (data, totalRow) {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allProducts: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allProducts: []
        //         });
        //     }
        // }, false);
    }

    /**
     * @description get all supplier
     * @author long.pham 26/07/2019
     */

    listAllSupplier() {
        let self = this;
        let params = {};
        // params.id_company = this.staff.id_company;
        // SupplierService.instance.getAllSupplier(params, function (data, totalRow) {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allSupplier: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allSupplier: []
        //         });
        //     }
        // }, false);
    }

    /**
     * @description Close supplier popup
     * @author long.pham 26/07/2019
     */
    onCloseSupplierPopup = (state, item) => {
        let curItem = this.state.curItem, self = this;
        if (item) {
            curItem.id_supplier = item.id;
        }
        self.listAllSupplier();
        this.setState({
            showAddSupplier: false,
            curItem: curItem
        }, () => {
            document.body.classList.remove('modal-open-level');
            // $("body").removeClass('modal-open-level');
            // $(".box-dropdownlist-add").parent('.form-group').find('p.validate-message').remove();
            // $(".box-dropdownlist-add").parent('.form-group').find('.select2').removeClass("input-error");
        });
    }

    /**
     * @description open add supplier popup
     * @author long.pham 28/07/2019
     */
    onClickAddSupplier() {
        // if (this.state.screen_mode != Constants.SCREEN_MODE.VIEW) {
            this.setState({
                showAddSupplier: true,
            }, () => {
                document.body.classList.add('modal-open-level');
            });
        // }
    }

    /**
     * @description add row 
     * @author long.pham 26/07/2019
     */
    onClickAddRow() {
        let params = this.state.dataListAddRow;
        let item = {
            state: true,
            index: params.length + 1,
            id_import_stores: "",
            id_product: "",
            name: "",
            type_name: "",
            quantity: 1,
            price: 0,
            id_type: "",
            date_of_manufactures: "",
            expiration_dates: "",
            messages: {
                id_product: "",
                quantity: "",
                price: "",
                date_of_manufactures: "",
                expiration_dates: ""
            }
        }

        params.push(item);
        this.setState({
            dataListAddRow: params
        });
    }

    /**
     * @description Remove row
     * @author long.pham 26/07/2019
     */
    removeItemRow = (index) => {
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;
        var self = this;
        let dataListAddRow = this.state.dataListAddRow;
        dataListAddRow.splice(index, 1);
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }

    /**
     * @description Select change
     * @author long.pham 26/07/2019
     */
    onRowSelectChange = (event, index) => {
        let self = this;
        var { t } = this.props;
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;


        let dataListAddRow = this.state.dataListAddRow;
        let item = dataListAddRow[index];
        if (name == 'id_product') {
            let currentProductChoose = Libs.find(dataListAddRow, 'id_product', value);
            if (currentProductChoose) {
                value = "";
                item.id_product = "";
                item.obj_product = [];
                item.type_name = "";
                item.quantity = 1;
                item.price = 0;
                item.date_of_manufactures = "";
                item.expiration_dates = "";
                item.messages.id_product = t('WAREHOUSING.exits_product_choose');
                self.toast(t('WAREHOUSING.exits_product_choose'), "error");
                // $('#id_product_' + index).val('').trigger('change');
            } else {
                item.messages.id_product = "";
            }

            if (value != "") {
                let products = this.state.allProducts;
                let productObjAdd = Libs.find(products, 'id', value);
                if (productObjAdd) {
                    item.type_name = productObjAdd.unit_name;
                    item.id_type = productObjAdd.id_type;
                    item.price = productObjAdd.price;
                    item.state = false;
                }
            }
        }

        item[name] = value;
        dataListAddRow[index] = item;
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }

    async validateOneRow(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddRowValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
    * @description Click add new product
    * @author long.pham 27/07/2019
    */
    onClickAddProduct = (index) => {
        // let self = this;
        // if (this.state.curItem.screen_mode != Constants.SCREEN_MODE.VIEW) {
        //     self.setState({
        //         showAddProduct: true,
        //         indexAddProduct: index
        //     }, () => {
        //         // $("body").addClass('modal-open-level');
        //     });
        //     self.forceUpdate();
        // }

    }
    /**
     * @description Close product popup
     * @author long.pham 26/07/2019
     */
    onCloseProductPopup = (state, item) => {
        if (item) {
            this.getAllProducts();
            let itemAddRow = this.state.dataListAddRow[this.state.indexAddProduct];
            itemAddRow.id_product = item.id;
            itemAddRow.price = item.price;
            itemAddRow.unit = item.unit_name;
            itemAddRow.state = false;
        }
        this.setState({
            showAddProduct: false,
            indexAddProduct: ''
        }, () => {
            // $("body").removeClass('modal-open-level');
        });
    }

    /**
     * @description Row input on change
     * @author long.pham 26/07/2019
     */
    handleRowInputChange = (event, index) => {
        var { t } = this.props;

        let self = this;
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (name == 'price' && value != null) {
            if (value != 0) {
                if (!Libs.isNumber(value)) {
                    return;
                }
            }

        }

        if (name == 'quantity' && value != null) {
            if (value != 0) {
                if (!Libs.isNumber(value)) {
                    return;
                }
            }

        }
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;
        let dataListAddRow = this.state.dataListAddRow;
        let item = this.state.dataListAddRow[index];
        item[name] = value;

        // Libs.compareDate = function (from_date, format, to_date = null) {
        if (name == 'date_of_manufactures' || name == 'expiration_dates') {
            if (item.date_of_manufactures != "" && item.expiration_dates != "") {
                var compareDate = Libs.compareDate(item.date_of_manufactures, "DD/MM/YYYY", item.expiration_dates);
                if (compareDate == 1) {
                    item.messages.date_of_manufactures = t('WAREHOUSING.product_date_compare');
                    self.toast(t('WAREHOUSING.product_date_compare'), "error");
                    item.expiration_dates = null;
                } else {
                    item.messages.date_of_manufactures = "";
                }
                // console.log(compareDate);
            }
        }

        dataListAddRow[index] = item;
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }

    /** 
     * @description Save info Warehousing
     * @author long.pham 28/07/2019
    */
    async onSaveAction() {
        // var { t } = this.props;
        // let params = this.state.curItem, self = this, { curItem } = this.state;
        // var screenMode = (!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD;
        // let v = new AddPopupValidate(this.props);
        // let errors = await v.FLValidationAll(params);
        // if (errors) {
        //     this.setValidateMessage(errors);
        //     return;
        // }

        // params.screen_mode = screenMode;
        // params.id_staff = this.staff.id_staff;
        // params.created_by = this.staff.first_name + " " + this.staff.last_name;
        // params.updated_by = this.staff.first_name + " " + this.staff.last_name;
        // params.id_company = this.staff.id_company;
        // params.input_date = Libs.convertStr2DateV01(params.import_date, "dd/mm/yyyy", "/");
        // let dataListAddRow = this.state.dataListAddRow;
        // params.is_delete = 0;
        // params.status = 1;
        // if (Libs.isObjectEmpty(dataListAddRow)) {
        //     self.toast(t('WAREHOUSING.list_product_is_not_null'), "error");
        //     return;
        // }
        // var mess = false;
        // dataListAddRow.map((item, index) => {
        //     let itemRow = dataListAddRow[index];
        //     if (Libs.isBlank(item.id_product)) {
        //         itemRow.messages.id_product = "error";
        //         mess = true;
        //     } else {
        //         itemRow.messages.id_product = "";
        //     }


        //     if (!Libs.isNumber(item.quantity)) {
        //         itemRow.messages.quantity = "error";
        //         mess = true;
        //     } else {
        //         itemRow.messages.quantity = "";
        //     }

        //     if (!Libs.isNumber(item.price)) {
        //         itemRow.messages.price = "error";
        //         mess = true;
        //     } else {
        //         itemRow.messages.price = "";
        //     }

        //     if (!Libs.isBlank(item.date_of_manufactures) && !Libs.isBlank(item.date_of_manufactures)) {
        //         var compareDate = Libs.compareDate(item.date_of_manufactures, "DD/MM/YYYY", item.expiration_dates);
        //         if (compareDate == 1) {
        //             itemRow.messages.date_of_manufactures = t('WAREHOUSING.product_date_compare');
        //             mess = true;
        //         } else {
        //             itemRow.messages.date_of_manufactures = "";
        //         }
        //     } else {
        //         itemRow.messages.date_of_manufactures = "";
        //     }
        //     if (!Libs.isBlank(item.date_of_manufactures)) {
        //         item.date_of_manufacture = Libs.convertStr2DateV01(item.date_of_manufactures, "dd/mm/yyyy", "/");
        //     } else {
        //         item.date_of_manufacture = "";
        //     }

        //     if (!Libs.isBlank(item.expiration_dates)) {
        //         item.expiration_date = Libs.convertStr2DateV01(item.expiration_dates, "dd/mm/yyyy", "/");
        //     } else {
        //         item.expiration_date = "";
        //     }

        //     dataListAddRow[index] = itemRow;

        // });


        // this.setState({
        //     dataListAddRow: dataListAddRow
        // });
        // if (mess == true) {
        //     self.toast(('WAREHOUSING.list_product_is_not_null'), "error");
        //     return;
        // }

        // //remove message validation
        // this.removeAllValidateMessage();
        // params.dataDetail = dataListAddRow;
        // ImportStoresService.instance.save(params, function (status, data, msg) {
        //     // console.log('data: ', data, status, msg);
        //     if (status) {
        //         self.toast(msg, "info");
        //         self.props.onCloseAddWarehousingPopup(true, data);
        //     }
        //     else if (data) {
        //         setValidateMessage(data);
        //     }
        //     else if (!Libs.isBlank(msg)) {
        //         self.toast(msg, "error");
        //     }
        // }, true);
    }

    /**
     * @description close popup
     * @author long.pham 28/07/2019
     */
     onClickCloseAdd() {
        if (!this.props.onClickCloseAdd || typeof this.props.onClickCloseAdd !== 'function') return;
        this.props.onClickCloseAdd(false, null);
    }


    /**
     * @description validate a field input
     * @author long.pham 28/07/2019
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description Get object select change
     * @author long.pham 28/07/2019
     */
    onSelectChange = (event, data) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        var { curItem } = this.state;
        curItem[name] = value;
        this.setState({
            curItem
        });
        this.validateOne(event);
    }


    /**
     * @description select change
     * @author long.pham 28/07/2019
     * @param {*} event 
     */

    handleInputChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            item[name] = (value) ? value : this.state.curItem[name];
            this.setState({ curItem: item });
        }

    }

}
export default AddPopup;