
import BaseComponent from '../../../../BaseComponent';
import Constants from '../../../../../utils/Constants';
import Libs from '../../../../../utils/Libs';
import InventoryStockJsx from './InventoryStock.jsx';
import './InventoryStock.scss';
import { withTranslation } from 'react-i18next';
// import InventoryStockService from '../../../../services/InventoryStockService';
// import StoresService from '../../../../services/StoresService';
// import ProductService from '../../../../services/ProductService';
class InventoryStock extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = InventoryStockJsx;
        this.state = {
            curData: {},
            curItem: {},
            formSearch: false,
            dataList: [
                {
                    date: "12/12/2021",
                    id_code: "D0012",
                    store_name: "Kho 1",
                    sp_count: 2,
                    quantity: 4,
                    sp_missing: 4,
                    username: "Pham Van Long"
                },
                {
                    date: "12/12/2021",
                    id_code: "D0012",
                    store_name: "Kho 1",
                    sp_count: 2,
                    quantity: 4,
                    sp_missing: 4,
                    username: "Pham Van Long"
                },
                {
                    date: "12/12/2021",
                    id_code: "D0012",
                    store_name: "Kho 1",
                    sp_count: 2,
                    quantity: 4,
                    sp_missing: 4,
                    username: "Pham Van Long"
                },
                {
                    date: "12/12/2021",
                    id_code: "D0012",
                    store_name: "Kho 1",
                    sp_count: 2,
                    quantity: 4,
                    sp_missing: 4,
                    username: "Pham Van Long"
                },
                {
                    date: "12/12/2021",
                    id_code: "D0012",
                    store_name: "Kho 1",
                    sp_count: 2,
                    quantity: 4,
                    sp_missing: 4,
                    username: "Pham Van Long"
                }
            ],
            dataListAddRow: [],
            allStores: [],
            allProducts: [],
            searchParam: {
                index: 1,
                offset: 0,
                limit: Constants.COMMON.PER_PAGE,
                keyword: "",
                from_date: Libs.getCurrentDDMMYYYY(),
                to_date: Libs.getCurrentDDMMYYYY(),
                sortColumn: "",
                sortOrder: ""
            },
            total_row: 0,
            activeId: null,
            add: false,
            showDeleteInventoryStockPopup: false,
            showAddSupplier: false,
            showAddProduct: false,
            indexAddProduct: '',
        };
        //pagging info
        this.paging = {
            total: 100,
            current: 1
        };
        //Check the status is in the component or exit the component
        this._isMounted = true;
        this.setActiveElement = this.setActiveElement.bind(this);
        this.inputChangedHandler = this.inputChangedHandler.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearchDateChange = this.handleSearchDateChange.bind(this);
    }



    handleInputChange(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name) {
            let params = self.state.searchParam;
            params[name] = (event.target.validity.valid) ? value : params[name];
            self.setState({
                searchParam: params
            });
        }
    }

    /**
    * Func filter table
    * @author long.pham 28/07/2019
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sortColumn = sortKey;
        this.state.searchParam.sortOrder = (this.state.searchParam.sortOrder == '' || this.state.searchParam.sortOrder == 'asc') ? 'desc' : 'asc';
        this.forceUpdate();
        this.getList();
    }

    /**
     * Func search
     * @author long.pham 28/07/2019
     * @param  {Object} e
     */

    handleSearch() {
        this.getList();
    }

    /**
     * Xử lý set state giá trị search từ ngày tới ngày
     * @author long.pham 28/07/2019
     * @param  {Object} e
     */
    handleSearchDateChange(e) {
        // let self = this;
        // let target = e.target,
        //     value = target.value,
        //     name = target.name,
        //     item = self.state.searchParam,
        // max_message = trans.translate("validate_rule.max"),
        // from_date_label = trans.translate("feeHospital.from_date"),
        // to_date_label = trans.translate("feeHospital.to_date"),
        // message = Libs.stringAssign(max_message, ["", from_date_label, to_date_label]);
        // if (name) {
        //     let date1 = Libs.convertStr2Date(value, Constants.COMMON.DATE_FORMAT, '/');
        //     if (name == 'from_date') {
        //         let date2 = Libs.convertStr2Date(item['to_date'], Constants.COMMON.DATE_FORMAT, '/');
        //         if (date1 > date2) {
        //             this.toast(message, "error");
        //             value = item['from_date'];
        //         }
        //     } else if (name == 'to_date') {
        //         let date2 = Libs.convertStr2Date(item['from_date'], Constants.COMMON.DATE_FORMAT, '/');
        //         if (date1 < date2) {
        //             this.toast(message, "error");
        //             value = item['to_date'];
        //         }
        //     }

        //     item[name] = value;
        //     console.log("date: ", item);
        //     this.setState({ searchParam: item });
        // }
    }


    setActiveElement(id) {
        // this.setState({ activeId: id });
        // var show = true;
        // $(document).mouseup(function(e) 
        // {
        //     var container = $(".function ul");
        //     // if the target of the click isn't the container nor a descendant of the container
        //     if (!container.is(e.target) && container.has(e.target).length === 0) 
        //     {
        //         show = false;
        //     }
        // });
        // if(show == false){
        //     this.setState({ activeId: null });
        // }
    }

    componentDidMount() {
        // super.componentDidMount();
        // var self = this;
        // self.getList();
        // document.addEventListener('click', this.handleClickOutside, true);
    }

    /**
     * @description Called before the component is about to be removed from the DOM
     * @author long.pham 27/07/2019
     */
    componentWillUnmount() {
        // this._isMounted = false;
        // document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        // const domNode = ReactDOM.findDOMNode(this);
        // if (!domNode || !domNode.contains(event.target)) {
        //     // this.setState({
        //     //     visible: false
        //     // });
        //     this.setState({ activeId: null });
        // }
    }


    onClickAdd = () => {
        let curItem = this.state.curItem;
        let data = [];
        // var allLanguage = this.state.allLanguage;
        curItem.screen_mode = Constants.SCREEN_MODE.ADD;
        // curItem.tabActive = '';
        // allLanguage.map((language, index) => {
        //     if (language.default === 1) { curItem.tabActive = language.default === 1 ? language.iso_code : ''; }

        //     let item = {
        //         default: language.default,
        //         iso_code: language.iso_code,
        //         id_language: language.id,
        //         name: "",
        //         description: "",
        //         icon: "",
        //         icon_upload: "",
        //         icon_message: "",
        //         banner_desktop: "",
        //         banner_desktop_upload: "",
        //         banner_desktop_message: "",
        //         banner_mobile: "",
        //         banner_mobile_upload: "",
        //         banner_mobile_message: "",
        //         meta_title: "",
        //         meta_keyword: "",
        //         meta_description: "",
        //         status: 1
        //     };
        //     return data.push(item);
        // });

        curItem.data = data;
        this.setState({
            curItem: curItem,
            add: true
        });

    };


    render() {
        return this.jsxTemplate.call(this)
    }

    addInventoryStockSearch() {
        let formSearch = (this.state.formSearch == false) ? true : false;
        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * @description Select page in pagging
     * @author long.pham 27/07/2019
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;
        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        self.getList();
    }

    /**
     * @description input text change page
     * @author long.pham 27/07/2019
     * @param {int} index
     */

    onInputPage(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name == 'current') {
            if (!Libs.isNumber(value)) {
                self.toast('abc', "info");
            }

        }
    }


    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name == 'current') {
            if (value != '') {
                if (!Libs.isNumber(value)) {
                    // self.toast(trans.translate('message.page_is_number'), "error");
                    return;
                }
            }

            if (value > this.pagging.total) {
                value = self.pagging.total;
            }

            self.pagging.current = value;
            this.onSelectPage(value);
            self.forceUpdate();


        }
    }



    /**
     * get moderation list
     * @author Minh.Pham 2019-06-03
     */
    getList() {
        // let self = this;
        // InventoryStockService.instance.getList(self.state.searchParam, (data, total_row) => {
        //     // console.log("data", data, total_row);
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             dataList: data
        //         });

        //         var total = parseInt(total_row / self.state.searchParam.limit);
        //         if (total_row % self.state.searchParam.limit > 0) {
        //             total = total + 1;
        //         }
        //         self.pagging.total = total;
        //         self.pagging.current = self.state.searchParam.index;
        //         self.state.total_row = total_row;
        //         this.forceUpdate();
        //     }
        // })
    }

    /**
     * @description Status change event
     * @author long.pham 27/07/2019
     */
    onStatusChange = (index) => {
        // if (!Libs.isArrayData(this.state.dataList)) return;
        // var self = this;
        // var item = this.state.dataList[index];
        // var isActiveUpdate = item.status;
        // if (isActiveUpdate * 1 === 1) {
        //     isActiveUpdate = 0;
        // }
        // else {
        //     isActiveUpdate = 1;
        // }
        // var param = {
        //     id: item.id,
        //     status: isActiveUpdate
        // };
        // InventoryStockService.instance.updateStatus(param, function (status, msg) {
        //     console.log('updateStatus: ', status);
        //     // if (!self._isMounted) return;
        //     if (status) {
        //         item.status = isActiveUpdate;
        //         self.setState({
        //             dataList: self.state.dataList
        //         });
        //     }
        // });
    }

    /**
     * @description Item click event
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClick = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var items = this.state.dataList[index];
        items.screen_mode = Constants.SCREEN_MODE.EDIT;

        if (!Libs.isArrayData(items.data)) return;
        items.data.map((item, key) => {
            item.messages = {
                id_product: "",
                quantity_check: ""
            };
            items.data[index][key] = item;
        });

        items.end_date = Libs.dateFormat(items.end_date, "DD/MM/YYYY HH:mm:ss");
        items.date = Libs.dateFormat(items.date, "DD/MM/YYYY HH:mm:ss");

        this.setState({
            curItem: items,
            dataListAddRow: items.data,
            add: true,
        });
        this.getAllStores();
        this.getAllProducts();
    }

    /**
     * @description Item click event detail InventoryStock
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClickDetail = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var items = this.state.dataList[index];
        items.screen_mode = Constants.SCREEN_MODE.VIEW;

        if (!Libs.isArrayData(items.data)) return;
        items.data.map((item, key) => {
            item.messages = {
                id_product: "",
                quantity_check: ""
            };
            items.data[index][key] = item;
        });

        items.end_date = Libs.dateFormat(items.end_date, "DD/MM/YYYY HH:mm:ss");
        items.date = Libs.dateFormat(items.date, "DD/MM/YYYY HH:mm:ss");

        this.setState({
            curItem: items,
            dataListAddRow: items.data,
            add: true,
        });
        this.getAllStores();
        this.getAllProducts();
    }


    /**
     * @description Item click event delete
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClickDelete = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        this.setState({
            curItem: item,
            showDeleteInventoryStockPopup: true,
        });
    }


    /**
    * @description Click add new supplier
    * @author long.pham 27/07/2019
    */
    onClickAddSupplier() {
        if (this.state.screen_mode != Constants.SCREEN_MODE.VIEW) {
            this.setState({
                showAddSupplier: true
            }, () => {
                // $("body").addClass('modal-open-level');
            });
        }
    }

    /**
     * @description Close supplier popup
     * @author long.pham 26/07/2019
     */
    onCloseSupplierPopup = (state, item) => {
        let curItem = this.state.curItem;
        if (item) {
            curItem.id_supplier = item.id;
        }
        this.setState({
            showAddSupplier: false,
            curItem: curItem
        }, () => {
            // $("body").removeClass('modal-open-level');
            // $(".box-dropdownlist-add").parent('.form-group').find('p.validate-message').remove();
            // $(".box-dropdownlist-add").parent('.form-group').find('.select2').removeClass("input-error");
        });
    }


    /**
     * @description callback update state dataListAddRow
     * @author long.pham 26/07/2019
     */
    // validateAllRow = (state, data) => {
    //     console.log('a: ', data);
    //     if(data && state){
    //         this.setState({
    //             dataListAddRow : data
    //         });
    //     }

    // }


    /**
    * @description Click add new product
    * @author long.pham 27/07/2019
    */
    onClickAddProduct = (event, index) => {
        let self = this;
        if (this.state.curItem.screen_mode != Constants.SCREEN_MODE.VIEW) {
            self.setState({
                showAddProduct: true,
                indexAddProduct: index
            }, () => {
                // $("body").addClass('modal-open-level');
            });
            self.forceUpdate();
        }
    }

    /**
         * @description Close product popup
         * @author long.pham 26/07/2019
         */
    onCloseProductPopup = (state, item) => {
        // console.log("data add product: ", item);
        if (item) {
            let itemAddRow = this.state.dataListAddRow[this.state.indexAddProduct];
            itemAddRow.id_product = item.id;
            itemAddRow.price = item.price;
            itemAddRow.unit = item.unit_name;
        }
        this.setState({
            showAddProduct: false,
            indexAddProduct: ''
        }, () => {
            // $("body").removeClass('modal-open-level');
        });
    }

    /**
     * @description Show user popup
     * @author long.pham 28/07/2019
    */
    onAddInventoryStockPopup() {
        let self = this;
        self.getAllStores();
        self.getAllProducts();
        this.setState({
            curItem: { screen_mode: Constants.SCREEN_MODE.ADD, id_store: "" },
            add: true
        });
    }

    /**
     * @description Close InventoryStock popup
     * @author long.pham 26/07/2019
     */
    onCloseAddPopup = (status, item) => {
        this.setState({
            add: false
        });
    }



    /**
     * @description Close InventoryStock popup delete
     * @author long.pham 26/07/2019
     */
    onCloseDeleteInventoryStockPopup = (status, item) => {
        // console.log('item: ', item);
        if (status) {
            if (!item) return;
            var { curItem } = this.state;
            curItem = item;
            this.setState({
                curItem
            });
            this.getList();
        }
        this.setState({
            showDeleteInventoryStockPopup: false
        });
    }



    /**
     * @description get all store
     * @author long.pham 26/07/2019
     */

    getAllProducts() {
        // let self = this;
        // ProductService.instance.getAllProduct(function (data, totalRow) {
        //     if (!self._isMounted) return;
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allProducts: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allStores: []
        //         });
        //     }
        // }, false);
    }


    /**
     * @description get all store
     * @author long.pham 26/07/2019
     */

    getAllStores() {
        // let self = this;
        // StoresService.instance.getAll(function (data, totalRow) {
        //     if (!self._isMounted) return;
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allStores: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allStores: []
        //         });
        //     }
        // }, false);
    }


    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name === 'current') {
            if (value !== '') {
                var { t } = this.props;
                if (!Libs.isNumber(value)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (value > this.paging.total) {
                value = self.paging.total;
            }

            self.paging.current = value;
            this.onSelectPage(value);
            self.forceUpdate();
        }

        if (name === 'limit') {
            var { searchParam } = this.state;
            searchParam.limit = value;
            this.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            })
        }
    }


    /**
     * @description Select page in pagging
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;
        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        // self.getList();
    }


    /**
     * @description reload data
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onClickReload() {
        // let self = this;
        // self.getList();
    }


    /**
    * Func filter table
    * @author Long.Pham 12-05-2021
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sort_column = sortKey;
        this.state.searchParam.order_by = (this.state.searchParam.order_by === '' || this.state.searchParam.order_by === 'asc') ? 'desc' : 'asc';
        this.forceUpdate();
        // this.getList();
    }

    /**
     * @description Call form search
     * @author Long.Pham 12/05/2021
     */
    onSearch() {
        let self = this;
        let formSearch = (this.state.formSearch === false) ? true : false;
        if (this.state.formSearch === true) {
            let searchParam = this.state.searchParam;
            searchParam.name = '';
            searchParam.index = 1;
            searchParam.offset = 0;
            self.paging.current = 1;
            self.paging.total = 1;
            self.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            });
        }

        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * Func search
     * @author Long.Pham 12/05/2021
     * @param  {Object} e
     */
    handleSearch() {
        // this.getList();
    }


}


const HighOrderComponentTranslated = withTranslation('common')(InventoryStock)
export default HighOrderComponentTranslated;