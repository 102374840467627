import Constants from '../../../../../utils/Constants';
import Libs from '../../../../../utils/Libs';
import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate {
    constructor(props) {
        super();
        var { t } = props;
        this.trans = t;
    }
    setRule() {
        
        this.addRuleForField('email', 'trim', true);
        this.addRuleForField('email', 'type', 'email', this.trans('validate_rule.type_email'), this.trans('newsletter.email'));
        this.addRuleForField('email', 'required', true, this.trans('validate_rule.required'), this.trans('newsletter.email'));
        this.addRuleForField('email', 'maxLength', 100, this.trans('validate_rule.maxLength_input'), this.trans('newsletter.email'));

        
    }

    setAlias() {
        this.v.setAlias({
            email: this.trans('newsletter.email')
        });

    }
}
export default AddPopupValidate;