import React, { Component } from 'react';
import { RText } from '../../../../../components/Controls';
class RowItemProductAdd extends Component {

    onItemClickDelete = () => {
        if (!this.props.onItemClickDelete || typeof this.props.onItemClickDelete !== 'function') return;
        this.props.onItemClickDelete(this.props.index);
    }

    render() {
        const { t } = this.props;
        var item = this.props.dataItem;
        return (
            <div className="body-row">
                <div className="body-col width5"><p>{this.props.index}</p></div>
                <div className="body-col width35">
                    <p>{item.id}</p>
                    <p>{item.product_name}</p>
                </div>
                <div className="body-col width20">
                    <p>
                        <RText placeholder={t('sell.price')}
                            inputClass="form-control text-end"
                            inputId={"name"}
                            inputName={"name"}
                            value={item.price}
                            onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                            maxLength={100} />
                    </p>
                </div>
                <div className="body-col width10">
                    <p>
                        <RText placeholder={t('sell.quantity')}
                            inputClass="form-control text-end"
                            inputId={"name"}
                            inputName={"name"}
                            value={item.quantity}
                            onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                            maxLength={10} />
                    </p>
                </div>
                <div className="body-col width20  text-end"><p>{item.sub_total}</p></div>
                <div className="body-col width10  text-end">
                    <p className="delete">
                        <span onClick={this.onItemClickDelete.bind(this)} className="icon-trash-o"></span>
                    </p>
                </div>
            </div>
        );
    }
}
export default RowItemProductAdd;
