
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import SummaryStorePopupJsx from './SummaryStorePopup.jsx';
// import SummaryStoreService from '../../../../services/SummaryStoreService';
// import StoresService from '../../../../services/StoresService';
// import ProductService from '../../../../services/ProductService';
// import cloneDeep from 'lodash-es/cloneDeep';

class SummaryStorePopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: {},
            dataDetail : [
                {
                    id: 1,
                    id_code: 1,
                    type_code: "1",
                    id_type_name: "Xuất kho bán hàng",
                    id_code_date: "2018-03-09 12:12:12",
                    note: "Lễ tân xuất kho bán hàng",
                    quantity_import: 100,
                    price_import: 100000,
                    quantity_export: 100,
                    price_export: 100000,
                    quantity_store: 10,
                    price_total_store: 1000000
                },
                {
                    id: 2,
                    id_code: 2,
                    type_code: "2",
                    id_type_name: "Xuất kho bán hàng",
                    id_code_date: "2018-03-09 12:12:12",
                    note: "Lễ tân xuất kho bán hàng",
                    quantity_import: 100,
                    price_import: 100000,
                    quantity_export: 100,
                    price_export: 100000,
                    quantity_store: 10,
                    price_total_store: 1000000
                },
                {
                    id: 3,
                    id_code: 3,
                    type_code: "3",
                    id_type_name: "Xuất kho bán hàng",
                    id_code_date: "2018-03-09 12:12:12",
                    note: "Lễ tân xuất kho bán hàng",
                    quantity_import: 100,
                    price_import: 100000,
                    quantity_export: 100,
                    price_export: 100000,
                    quantity_store: 10,
                    price_total_store: 1000000
                },
                {
                    id: 4,
                    id_code: 4,
                    type_code: "4",
                    id_type_name: "Xuất kho bán hàng",
                    id_code_date: "2018-03-09 12:12:12",
                    note: "Lễ tân xuất kho bán hàng",
                    quantity_import: 100,
                    price_import: 100000,
                    quantity_export: 100,
                    price_export: 100000,
                    quantity_store: 10,
                    price_total_store: 1000000
                }
            ],
            dataListAddRow: [],
            dataStores: [
                {id: 1, value: 1, label: "kho 1"},
                {id: 1, value: 1, label: "kho 2"},
                {id: 1, value: 1, label: "kho 3"},
            ],
            
            allProducts: [],
            searchParam : []
        }
        this.jsxTemplate = SummaryStorePopupJsx;
        // Sự kiện lắng nghe input text thay đổi giá trị
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author long.pham 28/07/2019
     */
    componentDidMount() {
        // let self = this;
        // let curItem = cloneDeep(this.props.curItem);
        // curItem.date = (!Libs.isBlank(curItem.date)) ? Libs.dateFormat(curItem.date, "DD/MM/YYYY HH:mm:ss") : Libs.getCurrentDDMMYYYYHI();
        // this.setState({
        //     curItem: curItem,
        //     searchParam : cloneDeep(this.props.searchParam)
        // });
        // self.getDetailSummaryStore();
    }




    /**
     * @description close popup
     * @author long.pham 28/07/2019
     */
    onCloseSummaryStorePopup() {
        if (!this.props.onCloseSummaryStorePopup || typeof this.props.onCloseSummaryStorePopup !== 'function') return;
        this.props.onCloseSummaryStorePopup(false, null);
    }

    /** 
     * @description export file excel
     * @author long.pham 28/07/2019
    */
   exportExcel(){
        return true;
    }

    
    /**
     * @description select change
     * @author long.pham 28/07/2019
     * @param {*} event 
     */

    handleInputChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            item[name] = (value) ? value : this.state.curItem[name];
            this.setState({ curItem: item });
        }

    }

    /**
    * @description get all store
    * @author long.pham 26/07/2019
    */

   getDetailSummaryStore() {
    // let self = this, params = this.state.curItem;
    // SummaryStoreService.instance.getDetail(params, function (data, totalRow) {
    //     console.log("abd: ",data);
    //     if (Libs.isArrayData(data)) {
    //         self.setState({
    //             dataDetail: data
    //         });
    //     }
    //     else {
    //         self.setState({
    //             dataDetail: []
    //         });
    //     }
    // }, false);
}

    /**
    * @description get all store
    * @author long.pham 26/07/2019
    */

    getAllStores() {
        let self = this;
        // StoresService.instance.getAll(function (data, totalRow) {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allStores: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allStores: []
        //         });
        //     }
        // }, false);
    }

    /**
     * @description Select change
     * @author long.pham 26/07/2019
     */
    onRowSelectChange = (event, index) => {
        let self = this;
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;


        let dataListAddRow = this.state.dataListAddRow;
        let item = dataListAddRow[index];
        if (name == 'id_product') {
            let currentProductChoose = Libs.find(dataListAddRow, 'id_product', value);
            if (currentProductChoose) {
                value = "";
                item.id_product = "";
                item.obj_product = [];
                item.id_stores = "";
                item.id_unit = "";
                item.unit_name ="";
                item.quantity_system = '';
                item.reason = "";
                // item.messages.id_product = trans.translate('EXPORT_STOCK.exits_product_choose');
                // self.toast(trans.translate('EXPORT_STOCK.exits_product_choose'), "error");
                // $('#id_product_' + index).val('').trigger('change');
            } else {
                item.messages.id_product = "";
            }

            if (value != "") {
                let products = this.state.allProducts;
                let productObjAdd = Libs.find(products, 'id', value);
                if (productObjAdd) {
                    item.obj_product = productObjAdd;
                    item.id_unit = productObjAdd.id;
                    item.unit_name = productObjAdd.unit_name;
                    item.quantity_system = productObjAdd.quantity_total;
                }
            }
        }

        item[name] = value;

        dataListAddRow[index] = item;
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }

    /**
     * @description Row input on change
     * @author long.pham 26/07/2019
     */
    handleRowInputChange = (event, index) => {
        let self = this;
        var target = event.target;
        var name = target.name;
        var value = target.value;

        if (name == 'quantity_check' && value != null) {
            if (value != 0) {
                if (!Libs.isNumber(value)) {
                    return;
                }
            }

        }
        if (!Libs.isArrayData(this.state.dataListAddRow)) return;
        let dataListAddRow = this.state.dataListAddRow;
        let item = this.state.dataListAddRow[index];
        item[name] = value;

        dataListAddRow[index] = item;
        this.setState({
            dataListAddRow: dataListAddRow
        });
    }


}
export default SummaryStorePopup;