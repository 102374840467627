import React from 'react';
import { RText, RTextArea } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';

export default function AddPopup() {

    var { curItem } = this.state;
    const { t } = this.props;

    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('popup.edit_title') : t('poup.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">
                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <RText label={t('popup.name')}
                                        inputClass="form-control"
                                        required="required"
                                        inputId="name"
                                        inputName="name"
                                        value={curItem.name}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                <RTextArea label={t('popup.meta_data')}
                                        inputClass="form-control"
                                        inputId={"meta_data"}
                                        inputName={"meta_data"}
                                        value={curItem.meta_data}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>
    )
}