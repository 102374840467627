import ReactDOM from 'react-dom';
import BaseComponent from '../../../../BaseComponent';
import Constants from '../../../../../utils/Constants';
import Libs from '../../../../../utils/Libs';
import ImportStockJsx from './ImportStock.jsx';
import './ImportStock.scss';
import { withTranslation } from 'react-i18next';
// import ImportStoresService from '../../../../services/ImportStoresService';
class ImportStock extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = ImportStockJsx;
        this.state = {
            curData: {},
            curItem: {},
            formSearch: false,
            dataList: [
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"},
                {id: 1, date: "12/12/2021", iso_code: "MH001", total: '200,000,000 vnđ', partner_name: "Công ty TNHH Minh Hoàng", description: "Mô tả thêm"}
            ],
            dataListAddRow: [],
            // allSupplier: [],
            // allProducts: [],
            searchParam: {
                index: 1,
                offset: 0,
                limit: Constants.COMMON.PER_PAGE,
                iso_code: "",
                from_date: "",
                to_date: Libs.getCurrentDDMMYYYY(),
                sortColumn: "",
                sortOrder: "",
                max_date: Libs.getCurrentDDMMYYYY()
            },
            total_row: 0,
            activeId: null,
            add: false,
            showDeletePopup: false,
            showAddSupplier: false,
            showAddProduct: false,
            indexAddProduct: '',
        };
        //pagging info
        this.paging = {
            total: 100,
            current: 1
        };
        //Check the status is in the component or exit the component
        this._isMounted = true;
        this.setActiveElement = this.setActiveElement.bind(this);
        this.inputChangedHandler = this.inputChangedHandler.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearchDateChange = this.handleSearchDateChange.bind(this);
    }



    handleInputChange(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name) {
            let params = self.state.searchParam;
            params[name] = (event.target.validity.valid) ? value : params[name];
            self.setState({
                searchParam: params
            });
        }
    }

    /**
    * Func filter table
    * @author long.pham 28/07/2019
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sortColumn = sortKey;
        this.state.searchParam.sortOrder = (this.state.searchParam.sortOrder == '' || this.state.searchParam.sortOrder == 'asc') ? 'desc' : 'asc';
        this.forceUpdate();
        this.getList();
    }

    /**
     * Func search
     * @author long.pham 28/07/2019
     * @param  {Object} e
     */

    handleSearch() {
        this.getList();
    }

    /**
     * Xử lý set state giá trị search từ ngày tới ngày
     * @author long.pham 28/07/2019
     * @param  {Object} e
     */
    handleSearchDateChange(e) {
        let self = this;
        var { t } = this.props;
        let target = e.target,
            value = target.value,
            name = target.name,
            item = self.state.searchParam,
            max_message = t("validate_rule.max"),
            from_date_label = t("feeHospital.from_date"),
            to_date_label = t("feeHospital.to_date"),
            message = Libs.stringAssign(max_message, ["", from_date_label, to_date_label]);
        if (name) {
            let date1 = Libs.convertStr2Date(value, Constants.COMMON.DATE_FORMAT, '/');
            if (name == 'from_date') {
                let date2 = Libs.convertStr2Date(item['to_date'], Constants.COMMON.DATE_FORMAT, '/');
                if (date1 > date2) {
                    this.toast(message, "error");
                    value = item['from_date'];
                }
            } else if (name == 'to_date') {
                let date2 = Libs.convertStr2Date(item['from_date'], Constants.COMMON.DATE_FORMAT, '/');
                if (date1 < date2) {
                    this.toast(message, "error");
                    value = item['to_date'];
                }
            }

            item[name] = value;
            // console.log("date: ", item);
            this.setState({ searchParam: item });
        }
    }


    setActiveElement(id) {
        this.setState({ activeId: id });
        var show = true;
        // $(document).mouseup(function (e) {
        //     var container = $(".function ul");
        //     // if the target of the click isn't the container nor a descendant of the container
        //     if (!container.is(e.target) && container.has(e.target).length === 0) {
        //         show = false;
        //     }
        // });
        if (show == false) {
            this.setState({ activeId: null });
        }
    }

    componentDidMount() {
        // super.componentDidMount();
        var self = this;
        self.getList();
        // document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    /**
     * @description Called before the component is about to be removed from the DOM
     * @author long.pham 27/07/2019
     */
    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('click', this.handleClickOutside, true);
    }


    /**
     * @description Handle click outsite
     * @author long.pham 27/07/2019
     */
    handleClickOutside = event => {
        try {
            const domNode = ReactDOM.findDOMNode(this);
            if (!domNode || !domNode.contains(event.target)) {
                if (this.state.activeId != null) {
                    this.setState({
                        activeId: null
                    });
                }
            }
        } catch (err) {
            // expect(err).toBe("Unable to find node on an unmounted component.");
        }
    }

    render() {
        return this.jsxTemplate.call(this)
    }

    addSearch() {
        let self = this;
        if (this.state.formSearch == true) {
            let paramSearch = this.state.searchParam;
            paramSearch.from_date = '';
            paramSearch.to_date = Libs.getCurrentDDMMYYYY();
            paramSearch.iso_code = '';
            self.setState({
                searchParam: paramSearch
            });
            self.getList();

        }

        let formSearch = (this.state.formSearch == false) ? true : false;
        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * @description Select page in pagging
     * @author long.pham 27/07/2019
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;
        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        self.getList();
    }

    /**
     * @description input text change page
     * @author long.pham 27/07/2019
     * @param {int} index
     */

    onInputPage(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name == 'current') {
            if (!Libs.isNumber(value)) {
                self.toast('abc', "info");
            }

        }
    }

    inputChangedHandler(event) {
        let self = this;
        var { t } = this.props;
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name == 'current') {
            if (value != '') {
                if (!Libs.isNumber(value)) {
                    self.toast(t('message.page_is_number'), "error");
                    return;
                }
            }

            if (value > this.pagging.total) {
                value = self.pagging.total;
            }

            self.pagging.current = value;
            this.onSelectPage(value);
            self.forceUpdate();


        }
    }


    /**
     * get moderation list
     * @author Minh.Pham 2019-06-03
     */
    getList() {
        let self = this;
        let params = this.state.searchParam;
        // params.id_company = this.staff.id_company;
        // ImportStoresService.instance.getList(params, (data, total_row) => {
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             dataList: data
        //         });

        //         var total = parseInt(total_row / self.state.searchParam.limit);
        //         if (total_row % self.state.searchParam.limit > 0) {
        //             total = total + 1;
        //         }
        //         self.pagging.total = total;
        //         self.pagging.current = self.state.searchParam.index;
        //         self.state.total_row = total_row;

        //     } else {
        //         self.pagging.total = 0;
        //         self.pagging.current = 1;
        //         self.setState({
        //             dataList: [],
        //             total_row: 0
        //         });
        //     }
        //     this.forceUpdate();

        // })
    }

    /**
     * @description Status change event
     * @author long.pham 27/07/2019
     */
    onStatusChange = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var self = this;
        var item = this.state.dataList[index];
        var isActiveUpdate = item.status;
        if (isActiveUpdate * 1 === 1) {
            isActiveUpdate = 0;
        }
        else {
            isActiveUpdate = 1;
        }
        var param = {
            id: item.id,
            status: isActiveUpdate
        };
        // ImportStoresService.instance.updateStatus(param, function (status, msg) {
        //     console.log('updateStatus: ', status);
        //     // if (!self._isMounted) return;
        //     if (status) {
        //         item.status = isActiveUpdate;
        //         self.setState({
        //             dataList: self.state.dataList
        //         });
        //     }
        // });
    }

    /**
     * @description Item click event
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClick = (index) => {
        let self = this;
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        if (Libs.isObjectEmpty(item)) return;
        item.screen_mode = Constants.SCREEN_MODE.EDIT;

        // get detail import stores
        let params = this.state.curItem;
        params.id_company = this.staff.id_company;
        params.id = item.id;
        let dataDetail = [];

        dataDetail.push({
            index: 1,
            state: false,
            expiration_dates: "12/12/2021",
            date_of_manufactures: "12/12/2021",
            messages: {
                id_product: "",
                quantity: "",
                price: "",
                date_of_manufactures: "",
                expiration_dates: ""
            }
        });

        // ImportStoresService.instance.getDetail(params, data => {
        //     if (data) {
        //         data.screen_mode = Constants.SCREEN_MODE.EDIT;
        //         let dataDetail = [];
        //         if (Libs.isArrayData(data.dataDetail)) {
        //             data.dataDetail.map((item, index) => {
        //                 item.index = index + 1;
        //                 item.state = false;
        //                 item.expiration_dates = Libs.validateDateNull(item.expiration_dates) ? item.expiration_dates : "";
        //                 item.date_of_manufactures = Libs.validateDateNull(item.date_of_manufactures) ? item.date_of_manufactures : "";
        //                 item.messages = {
        //                     id_product: "",
        //                     quantity: "",
        //                     price: "",
        //                     date_of_manufactures: "",
        //                     expiration_dates: ""
        //                 };
        //                 dataDetail.push(item);
        //             });
        //         }
        //         self.setState({
        //             curItem: data,
        //             dataListAddRow: dataDetail,
        //             showAddWarehousingPopup: true
        //         });
        //     }
        // }, false);

        self.setState({
            // curItem: data,
            dataListAddRow: dataDetail,
            add: true
        });
    }

    /**
     * In phiếu phập 
     * @author Long.Pham 2019-09-12
     */
    clickPrintf = (index)  => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];

        let param = {
            id_company: this.staff.id_company,
            id: item.id,
            iso_code: 'vi'
        };
        // ImportStoresService.instance.print(param);
    }

    /**
     * Xuất excel phiếu nhập
     * @author Long.Pham 2019-09-12 15:52
     */
    clickExcel = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];

        let param = {
            id_company: this.staff.id_company,
            id: item.id,
            iso_code: 'vi'
        };
        
        // ImportStoresService.instance.exportExcel(param);
    }


    /**
     * @description Item click event detail Warehousing
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClickDetail = (index) => {
        let self = this;
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        if (Libs.isObjectEmpty(item)) return;
        item.screen_mode = Constants.SCREEN_MODE.EDIT;

        // get detail import stores
        let params = this.state.curItem;
        params.id_company = this.staff.id_company;
        params.id = item.id;
        // ImportStoresService.instance.getDetail(params, data => {
        //     if (data) {
        //         data.screen_mode = Constants.SCREEN_MODE.VIEW;
        //         let dataDetail = [];
        //         if (Libs.isArrayData(data.dataDetail)) {
        //             data.dataDetail.map((item, index) => {
        //                 item.index = index + 1;
        //                 item.state = true;
        //                 item.expiration_dates = Libs.validateDateNull(item.expiration_dates) ? item.expiration_dates : "";
        //                 item.date_of_manufactures = Libs.validateDateNull(item.date_of_manufactures) ? item.date_of_manufactures : "";
        //                 item.messages = {
        //                     id_product: "",
        //                     quantity: "",
        //                     price: "",
        //                     date_of_manufactures: "",
        //                     expiration_dates: ""
        //                 };
        //                 dataDetail.push(item);
        //             });
        //         }
        //         self.setState({
        //             curItem: data,
        //             dataListAddRow: dataDetail,
        //             showAddWarehousingPopup: true
        //         });
        //     }
        // }, false);
    }


    /**
     * @description Item click event delete
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClickDelete = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        this.setState({
            curItem: item,
            showDeletePopup: true,
        });
    }


    /**
    * @description Click add new supplier
    * @author long.pham 27/07/2019
    */
    onClickAddSupplier() {
        if (this.state.screen_mode != Constants.SCREEN_MODE.VIEW) {
            this.setState({
                showAddSupplier: true
            }, () => {
                // $("body").addClass('modal-open-level');
            });
        }
    }
    /**
	 * @description Show user popup
	 * @author long.pham 28/07/2019
	*/
    // onAddPopup() {
    //     let self = this;
    //     this.setState({
    //         curItem: { screen_mode: Constants.SCREEN_MODE.ADD },
    //         showAddPopup: true
    //     });
    // }

    /**
	 * @description Close import stock popup
	 * @author long.pham 26/07/2019
	 */

    onClickCloseAdd = () => {
        this.setState({
            curItem: {},
            dataListAddRow: [],
            add: false
        })
    }

    /**
	 * @description Close Warehousing popup delete
	 * @author long.pham 26/07/2019
	 */
    onCloseDeletePopup = (status, item) => {
        // console.log('item: ', item);
        if (status) {
            if (!item) return;
            var { curItem } = this.state;
            curItem = item;
            this.setState({
                curItem
            });
            this.getList();
        }
        this.setState({
            showDeletePopup: false
        });
    }


    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name === 'current') {
            if (value !== '') {
                var { t } = this.props;
                if (!Libs.isNumber(value)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (value > this.paging.total) {
                value = self.paging.total;
            }

            self.paging.current = value;
            this.onSelectPage(value);
            self.forceUpdate();
        }

        if (name === 'limit') {
            var { searchParam } = this.state;
            searchParam.limit = value;
            this.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            })
        }
    }


    /**
     * @description Select page in pagging
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;
        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        // self.getList();
    }


    /**
     * @description reload data
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onClickReload() {
        // let self = this;
        // self.getList();
    }

    /**
    * Func filter table
    * @author Long.Pham 12-05-2021
    * @param  {Object} e
    */

     onSort(event, sortKey) {
        this.state.searchParam.sort_column = sortKey;
        this.state.searchParam.order_by = (this.state.searchParam.order_by === '' || this.state.searchParam.order_by === 'asc') ? 'desc' : 'asc';
        this.forceUpdate();
        // this.getList();
    }

    /**
     * @description Call form search
     * @author Long.Pham 12/05/2021
     */
    onSearch() {
        let self = this;
        let formSearch = (this.state.formSearch === false) ? true : false;
        if (this.state.formSearch === true) {
            let searchParam = this.state.searchParam;
            searchParam.name = '';
            searchParam.index = 1;
            searchParam.offset = 0;
            self.paging.current = 1;
            self.paging.total = 1;
            self.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            });
        }

        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * Func search
     * @author Long.Pham 12/05/2021
     * @param  {Object} e
     */
     handleSearch() {
        // this.getList();
    }


    onClickAdd = () => {
        let curItem = this.state.curItem;
        let data = [];
        // var allLanguage = this.state.allLanguage;
        curItem.screen_mode = Constants.SCREEN_MODE.ADD;
        // curItem.tabActive = '';
        // allLanguage.map((language, index) => {
        //     if (language.default === 1) { curItem.tabActive = language.default === 1 ? language.iso_code : ''; }

        //     let item = {
        //         default: language.default,
        //         iso_code: language.iso_code,
        //         id_language: language.id,
        //         name: "",
        //         description: "",
        //         icon: "",
        //         icon_upload: "",
        //         icon_message: "",
        //         banner_desktop: "",
        //         banner_desktop_upload: "",
        //         banner_desktop_message: "",
        //         banner_mobile: "",
        //         banner_mobile_upload: "",
        //         banner_mobile_message: "",
        //         meta_title: "",
        //         meta_keyword: "",
        //         meta_description: "",
        //         status: 1
        //     };
        //     return data.push(item);
        // });

        curItem.data = data;
        this.setState({
            curItem: curItem,
            add: true
        });

    };


}

const HighOrderComponentTranslated = withTranslation('common')(ImportStock)
export default HighOrderComponentTranslated;