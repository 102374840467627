
import { Component } from 'react';
import MainMenuJsx from './MainMenu.jsx';
import Constants from '../../../utils/Constants';
import Libs from '../../../utils/Libs';

class MainMenu extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            permissions: this.reRenderPermissions()
        }
        this.jsxTemplate = MainMenuJsx;
    }


    reRenderPermissions = () => {
        let info = localStorage.getItem(Constants.COMMON.ACCESS_PARAM);
        let permission = JSON.parse(Libs.base64Decrypt(info));
        let permissions = Object.values(permission);
        var parents = [];
        if(Libs.isArrayData(permissions)){
            parents = permissions.filter((item) => item.parent === null);
            if(Libs.isArrayData(parents)){
                for(let i = 0 , len = parents.length; i < len; i++){
                    var childs = permissions.filter((item) => item.parent === parents[i].id);
                    parents[i].childs = childs ? childs: [];
                }
            }
        }
        return parents;
    };

    render() {
        return this.jsxTemplate.call(this)
    }
}
export default MainMenu;