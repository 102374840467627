import React from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer';
import SidebarWebsite from '../common/SidebarWebsite/SidebarWebsite';

const MainModuleWebsite = ({ children }) => {
  return <div>
    <Header />
    <SidebarWebsite />
    <div id="main">
      {children}
    </div>
    <Footer />
  </div>
};

export default MainModuleWebsite;