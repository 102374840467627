import Constants from '../../../../../utils/Constants';
import Libs from '../../../../../utils/Libs';
import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate {
    constructor(props, screenMode, password) {
        super();
        var { t } = props;
        this.trans = t;
        this.screenMode = screenMode;
        this.password = password;
    }
    setRule() {
        this.addRuleForField('first_name', 'trim', true);
        this.addRuleForField('first_name', 'required', true, this.trans('validate_rule.required'), this.trans('customer.first_name'));
        this.addRuleForField('first_name', 'maxLength', 100, this.trans('validate_rule.maxLength_input'), this.trans('customer.first_name'));


        this.addRuleForField('last_name', 'trim', true);
        this.addRuleForField('last_name', 'required', true, this.trans('validate_rule.required'), this.trans('customer.last_name'));
        this.addRuleForField('last_name', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('customer.last_name'));

        this.addRuleForField('id_customer_group', 'trim', true);
        this.addRuleForField('id_customer_group', 'required', true, this.trans('validate_rule.required'), this.trans('customer.id_customer_group'));
        this.addRuleForField('id_customer_group', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('customer.id_customer_group'));

        this.addRuleForField('id_customer_type', 'trim', true);
        this.addRuleForField('id_customer_type', 'required', true, this.trans('validate_rule.required'), this.trans('customer.last_name'));
        this.addRuleForField('id_customer_type', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('customer.last_name'));


        this.addRuleForField('email', 'trim', true);
        this.addRuleForField('email', 'type', 'email', this.trans('validate_rule.type_email'), this.trans('customer.email'));
        this.addRuleForField('email', 'required', true, this.trans('validate_rule.required'), this.trans('customer.email'));
        this.addRuleForField('email', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('customer.email'));

        if(this.screenMode === Constants.SCREEN_MODE.ADD || !Libs.isBlank(this.password)){
            this.addRuleForField('password', 'trim', true);
            this.addRuleForField('password', 'required', true, this.trans('validate_rule.required'), this.trans('customer.new_password'));
            this.addRuleForField('password', 'maxLength', 60, this.trans('validate_rule.maxLength_input'), this.trans('customer.new_password'));
            this.addRuleForField('password', 'minLength', 8, this.trans('validate_rule.minLength_input'), this.trans('customer.new_password'));
    
            this.addRuleForField('password_confirm', 'trim', true);
            this.addRuleForField('password_confirm', 'required', true, this.trans('validate_rule.required'), this.trans('customer.password_confirm'));
            this.addRuleForField('password_confirm', 'maxLength', 60, this.trans('validate_rule.maxLength_input'), this.trans('customer.password_confirm'));
            this.addRuleForField('password_confirm', 'minLength', 8, this.trans('validate_rule.minLength_input'), this.trans('customer.password_confirm'));
        }
    }

    setAlias() {
        this.v.setAlias({
            first_name: this.trans('customer.first_name'),
            last_name: this.trans('customer.last_name'),
            id_customer_group: this.trans('customer.id_customer_group'),
            id_customer_type: this.trans('customer.id_customer_type'),
            password: this.trans('customer.password'),
            password_confirm: this.trans('customer.password_confirm')
        });

    }
}
export default AddPopupValidate;