import React from 'react';
import RowItem from './RowItem';
import { Paging } from '../../../../../components/Paging';
import AddPopup from './AddPopup';
import DeletePopup from './DeletePopup';
import { RText } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import './Customer.scss';

export default function Customer() {
    const { t } = this.props;
    var { curItem, dataList, allLanguage, searchParam, dataCustomerType, dataCustomerGroup } = this.state;
    var RowItems = null;
    RowItems = dataList.map((item, index) => {
        return <RowItem
            key={'row_item_' + index}
            index={index}
            dataItem={item}
            onItemClick={this.onItemClick}
            onItemClickDelete={this.onItemClickDelete}
            onStatusChange={this.onStatusChange}
            actions={this.actions}
            t={t}
        />
    });
    var AddPopupLayout = this.state.add
        ? <AddPopup
            t={t}
            allLanguage={allLanguage}
            curItem={curItem}
            onClickCloseAdd={this.onClickCloseAdd}
            auth={this.props.auth}
            actions={this.actions}
            iso_code_language={this.employee.lang}
        /> : '';

    var DeletePopupLayout = this.state.delete
        ? <DeletePopup
            t={t}
            curItem={curItem}
            onClickCloseDelete={this.onClickCloseDelete}
            auth={this.props.auth}
            actions={this.actions}
        /> : '';

    return (
        <div className="customer">
            {AddPopupLayout}
            {DeletePopupLayout}
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-md-6"><h2>{t('customer.title')}</h2>
                    </div>
                    <div className="col-md-6">
                        <ul>
                            <li><div className="search" onClick={this.onSearch.bind(this)}><span className="icon icon-search"></span> {t('common.search')}</div></li>
                            <li><div className="add" onClick={this.onClickAdd.bind(this)}><span className="icon icon-plus"></span> {t('common.add')}</div></li>
                        </ul>
                    </div>
                </div>
            </div>

            {this.state.formSearch ?
                <div className="form-search">
                    <div className="row">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <RText label={t('customer.customer_name')}
                                    inputClass="form-control"
                                    inputId="fullname"
                                    inputName="fullname"
                                    value={searchParam.fullname}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <RText label={t('customer.phone')}
                                    inputClass="form-control"
                                    inputId="phone"
                                    inputName="phone"
                                    value={searchParam.phone}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={20} />
                            </div>
                        </div>

                        

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <CMSDatePicker
                                    label={t("customer.from_date")}
                                    inputId="from_date"
                                    inputClass="form-control"
                                    inputName="from_date"
                                    maxLength={10}
                                    value={searchParam.from_date}
                                    handleChange={this.handleSearchInputChange.bind(this)}
                                    showIconCalendar={true}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <CMSDatePicker
                                    label={t("customer.to_date")}
                                    inputId="to_date"
                                    inputClass="form-control"
                                    inputName="to_date"
                                    maxLength={10}
                                    value={searchParam.to_date}
                                    maxDate={searchParam.max_date}
                                    handleChange={this.handleSearchInputChange.bind(this)}
                                    showIconCalendar={true}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <RText label={t('customer.email')}
                                    inputClass="form-control"
                                    inputId="email"
                                    inputName="email"
                                    value={searchParam.email}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <FormReactSelect
                                    instanceId="id_customer_group"
                                    className="id_customer_group"
                                    name="id_customer_group"
                                    value={dataCustomerGroup.filter(function (item) { return item.id === searchParam.id_customer_group })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'id_customer_group') }}
                                    optionList={dataCustomerGroup}
                                    label={t('customer.id_customer_group')}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <FormReactSelect
                                    instanceId="id_customer_type"
                                    className="id_customer_type"
                                    name="id_customer_type"
                                    value={dataCustomerType.filter(function (item) { return item.id === searchParam.id_customer_type })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'id_customer_type') }}
                                    optionList={dataCustomerType}
                                    label={t('customer.id_customer_type')}
                                />
                            </div>
                        </div>


                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <button type="button" className="btn btn-search" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                                <button type="button" className="btn btn-reset" onClick={this.onResetSearch.bind(this)}> {t('common.reset')} </button>
                            </div>

                            {/* <div className="mb-3">
                                <button type="button" className="btn btn-search inline-row" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                            </div> */}
                        </div>

                    </div>

                    {/* <div className="row">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <RText label={t('common.id')}
                                    inputClass="form-control"
                                    inputId="id"
                                    inputName="id"
                                    value={searchParam.id}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <RText label={t('customer.name')}
                                    inputClass="form-control"
                                    inputId="name"
                                    inputName="name"
                                    value={searchParam.name}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <button type="button" className="btn btn-search" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                                <button type="button" className="btn btn-reset" onClick={this.onResetSearch.bind(this)}> {t('common.reset')} </button>
                            </div>
                        </div>

                    </div> */}
                </div>
                : ""}

            <div className="data-view">
                <div className="main-header">
                    <div className="header-row">
                        <div onClick={e => this.onSort(e, 'id')} className={searchParam.sort_column === "id" ? "header-col width10 sorting " + searchParam.order_by : "header-col width10 sorting"}><p>{t('customer.id')}</p></div>
                        <div onClick={e => this.onSort(e, 'last_name')} className={searchParam.sort_column === "last_name" ? "header-col width20 sorting " + searchParam.order_by : "header-col width20 sorting"}><p>{t('customer.customer_name')}</p></div>
                        <div onClick={e => this.onSort(e, 'id_customer_group')} className={searchParam.sort_column === "id_customer_group" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('customer.id_customer_group')}</p></div>
                        <div onClick={e => this.onSort(e, 'id_customer_type')} className={searchParam.sort_column === "id_customer_type" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('customer.id_customer_type')}</p></div>
                        <div onClick={e => this.onSort(e, 'money_total')} className={searchParam.sort_column === "money_total" ? "header-col width20 sorting " + searchParam.order_by : "header-col width20 sorting"}><p>{t('customer.total_money')}</p></div>
                        <div onClick={e => this.onSort(e, 'status')} className={searchParam.sort_column === "status" ? "header-col width10 sorting text-center " + searchParam.order_by : "header-col width10 sorting text-center "}><p>{t('common.status')}</p></div>
                        <div className="header-col width10 text-end"><strong>{t('common.action')}</strong></div>
                    </div>
                </div>
                <div className="main-body">
                    <div className="body">
                        {!Libs.isBlank(RowItems) ? RowItems : <div className="data-empty">{t('common.data_empty')}</div>}
                    </div>
                </div>
            </div>

            <div className="main-paging">
                <Paging
                    total={parseInt(this.paging.total)}
                    current={(this.paging.current)}
                    currentInput={this.paging.currentInput}
                    inputChangedHandler={this.inputChangedHandler}
                    inputChangedEnter={this.inputChangedEnter}
                    inputChangedBlue={this.inputChangedBlue}
                    onClickReload={(e) => this.onClickReload.bind(this)}
                    onSelectPage={(index) => this.onSelectPage.bind(this, index)}>
                </Paging>
            </div>
        </div>
    );
};