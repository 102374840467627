import StoresJsx from './Stores.jsx';
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import { withTranslation } from 'react-i18next';
import Constants from '../../../../../utils/Constants';

class Stores extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = StoresJsx;
        this.state = {
            curItem: {},
            dataList: [
                { id: 1, name: "Kho tân bình", address: "26 Đồng Xoài, Phường 13, Tân Bình, HCM", phone: "096 666 0127", description: "Description", status: 1 },
                { id: 1, name: "Kho tân bình", address: "26 Đồng Xoài, Phường 13, Tân Bình, HCM", phone: "096 666 0127", description: "Description", status: 1 },
                { id: 1, name: "Kho tân bình", address: "26 Đồng Xoài, Phường 13, Tân Bình, HCM", phone: "096 666 0127", description: "Description", status: 1 },
                { id: 1, name: "Kho tân bình", address: "26 Đồng Xoài, Phường 13, Tân Bình, HCM", phone: "096 666 0127", description: "Description", status: 1 },
                { id: 1, name: "Kho tân bình", address: "26 Đồng Xoài, Phường 13, Tân Bình, HCM", phone: "096 666 0127", description: "Description", status: 1 },
                { id: 1, name: "Kho tân bình", address: "26 Đồng Xoài, Phường 13, Tân Bình, HCM", phone: "096 666 0127", description: "Description", status: 1 },
            ],
            searchParam: {
                limit: Constants.COMMON.LIMIT,
                offset: 0,
                index: 1,
            },
            add: false,
            delete: false,
            formSearch: false,
            allLanguage: [
                { id: 1, name: 'Tiếng Việt', iso_code: 'vi', default: 1 },
                { id: 2, name: 'English', iso_code: 'en', default: 0 }
            ]
        };

        this.paging = {
            total: 100,
            current: 1
        };
        this.inputChangedHandler = this.inputChangedHandler.bind(this);

    }

    onClickAdd = () => {
        let curItem = this.state.curItem;
        let data = [];
        var allLanguage = this.state.allLanguage;
        curItem.screen_mode = Constants.SCREEN_MODE.ADD;
        curItem.tabActive = '';
        allLanguage.map((language, index) => {
            if (language.default === 1) { curItem.tabActive = language.default === 1 ? language.iso_code : ''; }

            let item = {
                default: language.default,
                iso_code: language.iso_code,
                id_language: language.id,
                name: "",
                description: "",
                icon: "",
                icon_upload: "",
                icon_message: "",
                banner_desktop: "",
                banner_desktop_upload: "",
                banner_desktop_message: "",
                banner_mobile: "",
                banner_mobile_upload: "",
                banner_mobile_message: "",
                meta_title: "",
                meta_keyword: "",
                meta_description: "",
                status: 1
            };
            return data.push(item);
        });

        curItem.data = data;
        this.setState({
            curItem: curItem,
            add: true
        });

    };

    onClickCloseAdd = () => {
        this.setState({
            add: false
        })
    }

    onClickCloseDelete = () => {
        this.setState({
            delete: false
        })
    }

    /**
     * @description Item click event
     * @author Long.Pham 12-05-2021
     * @param index element in the list
     */
    onItemClick = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        item.screen_mode = Constants.SCREEN_MODE.EDIT;
        this.setState({
            curItem: item,
            showAddSitePopup: true
        });
    }

    /**
   * @description Item click event delete
   * @author Long.Pham 12-05-2021
   * @param index Order element in the list
   */
    onItemClickDelete = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        this.setState({
            curItem: item,
            delete: true
        });
    }


    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name === 'current') {
            if (value !== '') {
                var { t } = this.props;
                if (!Libs.isNumber(value)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (value > this.paging.total) {
                value = self.paging.total;
            }

            self.paging.current = value;
            this.onSelectPage(value);
            self.forceUpdate();
        }

        if (name === 'limit') {
            var { searchParam } = this.state;
            searchParam.limit = value;
            this.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            })
        }
    }


    /**
     * @description Select page in pagging
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;
        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        // self.getList();
    }


    /**
     * @description reload data
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onClickReload() {
        // let self = this;
        // self.getList();
    }


    /**
    * Func filter table
    * @author Long.Pham 12-05-2021
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sort_column = sortKey;
        this.state.searchParam.order_by = (this.state.searchParam.order_by === '' || this.state.searchParam.order_by === 'asc') ? 'desc' : 'asc';
        this.forceUpdate();
        // this.getList();
    }

    /**
     * @description Call form search
     * @author Long.Pham 12/05/2021
     */
    onSearch() {
        let self = this;
        let formSearch = (this.state.formSearch === false) ? true : false;
        if (this.state.formSearch === true) {
            let searchParam = this.state.searchParam;
            searchParam.name = '';
            searchParam.index = 1;
            searchParam.offset = 0;
            self.paging.current = 1;
            self.paging.total = 1;
            self.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            });
        }

        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * Func search
     * @author Long.Pham 12/05/2021
     * @param  {Object} e
     */
     handleSearch() {
        // this.getList();
    }



    render() {
        return this.jsxTemplate.call(this)
    }
}

const HighOrderComponentTranslated = withTranslation('common')(Stores)
export default HighOrderComponentTranslated;