import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate{
    constructor(props){
        super();
        var {t} = props;
        this.trans = t;
    }
    setRule(){
        this.addRuleForField('transfer_person', 'trim', true);
        this.addRuleForField('transfer_person', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('transfer_person', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('id_code', 'trim', true);
        this.addRuleForField('id_code', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('id_code', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('date', 'trim', true);
        this.addRuleForField('date', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('date', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));
    }
    setAlias(){
        this.v.setAlias({
            transfer_person: this.trans('EXPORT_STOCK.transfer_person'),
            id_code: this.trans('EXPORT_STOCK.id_code'),
            date: this.trans('EXPORT_STOCK.date')
        });
        
    }
}
export default AddPopupValidate;