import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RText, RButton, RTextArea } from '../../../../../components/Controls';
import FormReactSelect from '../../../../../components/FormReactSelect';
import Libs from '../../../../../utils/Libs';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import Constants from '../../../../../utils/Constants';
// import FLSelect from '../../../../component/FLSelect';
import AddRow from './AddRow'
export default function AddPopup() {
    var { t } = this.props;
    var { curItem, dataListAddRow, dataStores, allProducts, dataCategories } = this.state;
    console.log('c: ', curItem);
    // Add row
    var addRows = null;
    function compareValues(key, order = 'asc') {
        return function (a, b) {
            if (!a.hasOwnProperty(key) ||
                !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order == 'desc') ?
                    (comparison * -1) : comparison
            );
        };
    }

    if (Libs.isArrayData(dataListAddRow)) {
        addRows = dataListAddRow.sort(compareValues('index', 'desc')).map((item, index) => {
            return <AddRow
                key={'row_item_' + index}
                index={index}
                dataItem={item}
                curItem={curItem}
                // allStores={allStores}
                // allProducts={allProducts}
                removeItemRow={this.removeItemRow}
                // onRowSelectChange={this.onRowSelectChange}
                handleRowInputChange={this.handleRowInputChange}
                validateOneRow={this.validateOneRow}
            />
        });
    }

    return (
        <React.Fragment>
            <Modal visible={true} className="modal-add-small" dialogClassName="modal-dialog-scrollable modal-xl add-inventory full-screen">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {curItem.screen_mode == Constants.SCREEN_MODE.EDIT ? t('inventory_stock.label_edit')
                            : (curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? t('inventory_stock.label_view') : t('inventory_stock.label_add'))
                        }</h5>
                    <span className="close" onClick={this.props.onCloseAddPopup.bind(this)}><var className="icon-cancel-music"></var></span>
                </div>

                <div className="modal-body">
                    <div className="inventory-stock-info">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <div className="info">
                                    <h2>Thông tin</h2>
                                    <div className="info-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <FormReactSelect
                                                        label={t('inventory_stock.id_store')}
                                                        instanceId="id_store"
                                                        required="required"
                                                        className="id_store"
                                                        name="id_store"
                                                        value={dataStores.filter(function (item) { return item.id === curItem.id_store })}
                                                        onChange={(e) => { this.handleDropdownChange(e, 'id_store') }}
                                                        optionList={dataStores}
                                                        placeHolder={t('common.choose')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <RText label={t('inventory_stock.purpose')}
                                                        inputClass="form-control"
                                                        required="required"
                                                        inputId="purpose" inputName="purpose"
                                                        value={curItem.purpose}
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <RText label={t('inventory_stock.id_code')}
                                                        inputClass="form-control"
                                                        required="required"
                                                        inputId="id_code" inputName="id_code"
                                                        value={curItem.id_code}
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <CMSDatePicker
                                                        label={t("inventory_stock.end_date")}
                                                        inputId="end_date"
                                                        required="required"
                                                        inputClass="form-control"
                                                        inputName="end_date"
                                                        maxLength={20}
                                                        value={curItem.end_date}
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        handleChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        showIconCalendar={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <div className="mb-3">
                                                    <CMSDatePicker
                                                        label={t("inventory_stock.date")}
                                                        inputId="date"
                                                        required="required"
                                                        inputClass="form-control"
                                                        inputName="date"
                                                        maxLength={20}
                                                        value={curItem.date}
                                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                        handleChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                        showIconCalendar={true}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>






                    <div className="add-stock-product">
                        <div className="import-stock-search-product">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <FormReactSelect
                                            instanceId="id_categories"
                                            required="required"
                                            className="id_categories"
                                            name="id_categories"
                                            value={dataCategories.filter(function (item) { return item.id === curItem.id_categories })}
                                            onChange={(e) => { this.handleDropdownChange(e, 'id_categories') }}
                                            optionList={dataCategories}
                                            placeHolder={t('common.choose')}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="mb-3">
                                        <div className="data-search">
                                            <RText
                                                placeholder={t('import_stock.keyword_search_product')}
                                                inputClass="form-control"
                                                required="required"
                                                inputId="keyword_search_product" inputName="keyword_search_product"
                                                value={curItem.keyword_search_product}
                                                disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                                maxLength={200} />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="header-add-product">
                            <div className="header-col width20">
                                {t('inventory_stock.product_name')}
                            </div>

                            <div className="header-col width10">
                                {t('inventory_stock.unit')}
                            </div>

                            <div className="header-col width10">
                                {t('inventory_stock.quantity_system')}
                            </div>

                            <div className="header-col width10">
                                {t('inventory_stock.quantity_check')}
                            </div>

                            <div className="header-col width10">
                                {t('inventory_stock.difference')}
                            </div>

                            <div className="header-col width35">
                                {t('inventory_stock.reason')}
                            </div>

                            <div className="header-col text-right width5"></div>
                        </div>

                        <div className="body">
                            {addRows}

                        </div>
                    </div>

                    <div className="result-form">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RTextArea label={t('inventory_stock.result')}
                                        inputClass="form-control"
                                        disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                        inputId="result" inputName="result"
                                        value={curItem.result}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                        maxLength={500} />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                

                <div className="modal-footer">
                    <RButton
                        onClick={this.onCloseAddPopup.bind(this)}
                        className="btn btn-cancel"
                        text={t('common.cancel')}
                        data-dismiss="modal" aria-hidden="true"
                        title={t('common.cancel')} />

                    {curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true :
                        <RButton
                            onClick={this.onSaveAction.bind(this)}
                            className="btn btn-save"
                            text={t('common.save')}
                            title={t('common.save')} />
                    }

                </div>


                {/* <div className="modal-footer">
                    <RButton
                        onClick={this.onCloseAddInventoryStockPopup.bind(this)}
                        className="btn btn-default btn-cancel"
                        text={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? t('common.label_close') : t('common.label_cancel')}
                        data-dismiss="modal" aria-hidden="true"
                        title={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? t('common.label_close') : t('common.label_cancel')} />

                    {curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true :
                        <RButton
                            onClick={this.onSaveAction.bind(this)}
                            className="btn btn-danger btn-add"
                            text={" " + t('common.label_add')}
                            iClass={"fa fa-plus"}
                            title={t('common.label_add')} />
                    }

                </div> */}
            </Modal>
        </React.Fragment >
    )
}