import React, { Component } from 'react';
class AddRowRight extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var item = this.props.dataItem;
        return (
            <div className="body-row">
                <div className="body-col width20">
                {item.quantity_import}
                </div>
                <div className="body-col width20">
                    {item.price_import}
                </div>
                <div className="body-col width15">
                    {item.quantity_export}
                </div>
                <div className="body-col width15">
                    {item.price_export}
                </div>
                <div className="body-col width15">
                    {item.quantity_store}
                </div>
                <div className="body-col width15">
                    {item.price_total_store}
                </div>
            </div>
        );
    }
}
export default AddRowRight;
