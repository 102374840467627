import React, { Component } from 'react';
import { RText } from '../../../../../components/Controls';
// import CMSSelect from '../../../../../components/CMSSelect';
import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
class AddRow extends Component {
    constructor(props) {
        super(props);
    }

    /**
    * @description Textarea change
    * @author Luyen Nguyen 2019-06-04
    */
    // onCommentChange = (event) => {
    //     if (!this.props.onCommentChange || typeof this.props.onCommentChange !== 'function') return;
    //     this.props.onCommentChange(this.props.dataItem, event.target.value);
    // }

    /**
     * @description Star click
     * @author Luyen Nguyen 2019-06-04
     */
    // onStarClick = (value) => {
    //     if (!this.props.onStarClick || typeof this.props.onStarClick !== 'function') return;
    //     this.props.onStarClick(this.props.dataItem, value);
    // }


    /**
     * @description Select change event
     * @author long.pham 27-07-2019
     */
    // onSelectChange = () => {
    //     if (!this.props.onSelectChange || typeof this.props.onSelectChange !== 'function') return;
    //     this.props.onSelectChange(this.props.index);
    //     return;
    // }

    /**
     * @description Select change event
     * @author long.pham 27-07-2019
     */
    // onRowSelectChange = () => {
    //     if (!this.props.onRowSelectChange || typeof this.props.onRowSelectChange !== 'function') return;
    //     this.props.onSelectChange(this.props.index, item);
    //     return;
    // }



    /**
     * @description remove item row
     * @author long.pham 27-07-2019
     */

    removeItemRow = () => {
        if (!this.props.removeItemRow || typeof this.props.removeItemRow !== 'function') return;
        this.props.removeItemRow(this.props.index);
        return;
    }

    /**
     * @description Item click event
     * @author long.pham 27-07-2019
     */
     onClickAddProduct = () => {
        if (!this.props.onClickAddProduct || typeof this.props.onClickAddProduct !== 'function') return;
        this.props.onClickAddProduct(this.props.index);
    }


    /**
     * @description Item click event
     * @author long.pham 27-07-2019
     */
    // onItemClick = () => {
    //     if (!this.props.onItemClick || typeof this.props.onItemClick !== 'function') return;
    //     this.props.onItemClick(this.props.index);
    // }

    
    /**
     * @description Item click event delete
     * @author long.pham 27-07-2019
     */
    // onItemClickDelete = () => {
    //     if (!this.props.onItemClickDelete || typeof this.props.onItemClickDelete !== 'function') return;
    //     this.props.onItemClickDelete(this.props.index);
    // }


    /**
     * @description Item click event delete
     * @author long.pham 27-07-2019
     */
    // onItemClickDetail = () => {
    //     if (!this.props.onItemClickDetail || typeof this.props.onItemClickDetail !== 'function') return;
    //     this.props.onItemClickDetail(this.props.index);
    // }



    render() {
        var item = this.props.dataItem;
        var curItem = this.props.curItem;
        return (
            <div className="body-row">
                <div className="body-col width25">
                    {item.product_name}
                </div>

                <div className="body-col width10 text-center">
                    {item.unit_name}
                </div>

                <div className="body-col width10">
                    <RText
                        inputClass={(item.messages.quantity != "") ? "form-control input-error text-end" : "form-control text-end"}
                        inputId={"quantity-" + this.props.index} inputName="quantity"
                        value={item.quantity}
                        onChange={(e) => { this.props.handleRowInputChange(e, this.props.index); }}
                        disabled={item.state}
                        pattern="^[0-9]*$"
                        maxLength={100} />
                </div>
                <div className="body-col width10">
                    <RText
                        inputClass={(item.messages.price != "") ? "form-control text-end input-error" : "form-control text-end"}
                        inputId={"price-" + this.props.index} inputName="price"
                        value={item.price}
                        onChange={(e) => { this.props.handleRowInputChange(e, this.props.index) }}
                        disabled={item.state}
                        maxLength={10} />
                </div>




                <div className="body-col width10">
                    <CMSDatePicker
                        inputId={"date_of_manufactures_" + this.props.index}
                        inputClass={(item.messages.date_of_manufactures != "") ? "form-control text-center input-error" : "form-control text-center"}
                        inputName="date_of_manufactures"
                        maxLength={20}
                        value={item.date_of_manufactures}
                        handleChange={(e) => { this.props.handleRowInputChange(e, this.props.index); }}
                        disabled={item.state}
                    />
                </div>

                <div className="body-col width10 text-center">
                    <CMSDatePicker
                        inputId={"expiration_date_" + this.props.index}
                        inputClass={(item.messages.expiration_dates != "") ? "form-control input-error" : "form-control"}
                        inputName="expiration_dates"
                        maxLength={20}
                        value={item.expiration_dates}
                        handleChange={(e) => { this.props.handleRowInputChange(e, this.props.index); }}
                        disabled={item.state}
                    />
                </div>

                <div className="body-col width20 text-end">
                    {Libs.formatNum(item.quantity * item.price, "#,###.##", 1)}
                </div>

                <div className="body-col text-end width5">
                    {curItem.screen_mode != Constants.SCREEN_MODE.VIEW ? <div className="remove-item" onClick={this.removeItemRow.bind(this)}>
                        <i className="icon-trash-o"></i>
                    </div> : ""}
                </div>
            </div>
        );
    }
}
export default AddRow;
