import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Auth from '../../../../../utils/Auth';
import Constants from '../../../../../utils/Constants';

class RowItemField extends Component {
    onItemClick = () => {
        if (!this.props.onItemClick || typeof this.props.onItemClick !== 'function') return;
        this.props.onItemClick(this.props.index);
    }

    onItemClickDelete = () => {
        if (!this.props.onItemClickDelete || typeof this.props.onItemClickDelete !== 'function') return;
        this.props.onItemClickDelete(this.props.index);
    }

    render() {
        var item = this.props.dataItem;
        const { t } = this.props;
        return (
            <div className="field-body-row" key={item.id}>
                <ReactTooltip />
                <div className="field-body-col width10">{item.id}</div>
                <div className="field-body-col width30">{item.field_label}</div>
                <div className="field-body-col width30">{item.field_key}</div>
                <div className="field-body-col width10 text-center">{item.field_type}</div>
                <div className="field-body-col width20 text-end">
                    <div className="functions">
                        <ul>
                            <li className={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.EDIT) ? '' : 'disabled'} onClick={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.EDIT) ? this.onItemClick.bind(this) : ''} data-tip={t('common.edit')} data-type="warning" data-class="tooltip-action"><var className="icon-pencil-square-o" aria-hidden="true"></var></li>
                            <li className={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.DEL) ? '' : 'disabled'} onClick={Auth.getPermisson(this.props.actions, Constants.AUTH_MODE.DEL) ? this.onItemClickDelete.bind(this) : ''} data-tip={t('common.delete')} data-type="warning" data-class="tooltip-action"><var className="icon-trash-o" aria-hidden="true"></var></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default RowItemField;
