import BaseComponent from '../../../BaseComponent';
import DashboardJsx from './Dashboard.jsx';
import { withTranslation } from 'react-i18next';
import './Dashboard.scss';

class Dashboard extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = DashboardJsx;
        this.state = {
            curItem: {}
        };
    }

    render() {
        return this.jsxTemplate.call(this);
    }

}

const HighOrderComponentTranslated = withTranslation('common')(Dashboard)
export default HighOrderComponentTranslated;