import RetailJsx from './Retail.jsx';
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import { withTranslation } from 'react-i18next';
import Constants from '../../../../../utils/Constants';
import OrdersService from '../../../../../services/OrdersService';
import OrderStatusService from '../../../../../services/OrderStatusService';
import PaymentStatusService from '../../../../../services/PaymentStatusService';
import PaymentMethodService from '../../../../../services/PaymentMethodService';
import './Retail.scss';

class Retail extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = RetailJsx;
        this.state = {
            curItem: {},
            dataOrderStatus: [],
            dataPaymentMethod: [],
            dataPaymentStatus: [],
            dataStores: [
                { id: 1, value: 1, label: "kho 1" },
                { id: 1, value: 1, label: "kho 2" },
                { id: 1, value: 1, label: "kho 3" },
            ],

            dataStatus: [
                { id: 1, value: 1, label: "Đã bán" },
                { id: 1, value: 1, label: "Huỷ" },
                { id: 1, value: 1, label: "Trả hàng" },
            ],
            dataPaymentMethod: [
                { id: 1, value: 1, label: "Tiền mặt" },
                { id: 1, value: 1, label: "Chuyển khoản" },
                { id: 1, value: 1, label: "Cà thẻ" },
                { id: 1, value: 1, label: "Trả góp" },
            ],

            dataList: [],
            searchParam: {
                limit: Constants.COMMON.LIMIT,
                offset: 0,
                index: 1,
            },
            add: false,
            delete: false,
            formSearch: false,
        };

        this.paging = {
            total: 0,
            current: 1,
            currentInput: 1
        };

        this.inputChangedHandler = this.inputChangedHandler.bind(this);
        this.inputChangedEnter = this.inputChangedEnter.bind(this);
        this.inputChangedBlue = this.inputChangedBlue.bind(this);

    }
    componentDidMount() {
        this.getList();
        this.getDataOrderStatus();
        this.getDataPaymentMethod();
        this.getDataPaymentStatus();
    }


    handleDropdownChange = (item, name) => {
        var self = this;
        let searchParam = self.state.searchParam;

        if (Libs.isObjectEmpty(item)) {
            if(name == 'id_order_status'){
                searchParam.id_order_status = '';
            }
            if(name == 'id_payment_method'){
                searchParam.id_payment_method = '';
            }
            if(name == 'id_payment_status'){
                searchParam.id_payment_status = '';
            }
            
        } else {
            var value = item.value;
            searchParam[name] = value;
        }

        self.setState({
            searchParam: searchParam
        });
    }


    onResetSearch() {
        let self = this;
        let searchParam = this.state.searchParam;
        searchParam.id = null;
        searchParam.search_first_name = null;
        searchParam.phone = null;
        searchParam.id_order_status = null;
        searchParam.id_payment_method = null;
        searchParam.id_payment_status = null;
        searchParam.from_date = null;
        searchParam.to_date = null;
        searchParam.index = 1;
        searchParam.offset = 0;
        self.paging.current = 1;
        self.paging.currentInput = 1;
        self.paging.total = 1;
        self.setState({
            searchParam: searchParam
        }, () => {
            self.getList();
        });
    }

    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

    getDataPaymentStatus = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        PaymentStatusService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataPaymentStatus: data
                });
            } else {
                self.setState({
                    dataPaymentStatus: []
                });
            }
        })
    }
    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

    getDataPaymentMethod = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        PaymentMethodService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataPaymentMethod: data
                });
            } else {
                self.setState({
                    dataPaymentMethod: []
                });
            }
        })
    }

    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

    getDataOrderStatus = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        OrderStatusService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataOrderStatus: data
                });
            } else {
                self.setState({
                    dataOrderStatus: []
                });
            }
        })
    }

    /**
     * get list
     * @author Long.Pham 2019-06-03
     */
    getList() {
        let self = this;
        let params = this.state.searchParam;
        params.id_language = this.employee.id_language;
        params.id_company = this.employee.id_company;
        OrdersService.instance.getList(params, (data, total_row) => {
            console.log(data);
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataList: data
                });

                var total = parseInt(total_row / self.state.searchParam.limit);
                if (total_row % self.state.searchParam.limit > 0) {
                    total = total + 1;
                }
                self.paging.total = total;
                self.paging.current = self.state.searchParam.index;
                self.paging.currentInput = self.state.searchParam.index;
                self.state.total_row = total_row;

            } else {
                self.setState({
                    dataList: [],
                    total_row: 0
                });
                self.paging.total = 0;
                self.paging.current = 1;
                self.paging.currentInput = 1;
            }
            self.forceUpdate();
        });
    }


    onClickAdd = () => {
        let curItem = {};
        curItem.screen_mode = Constants.SCREEN_MODE.ADD;
        this.setState({
            curItem: curItem,
            add: true
        });

    };

    onClickCloseAdd = (status) => {
        if (status) {
            this.getList();
        }
        this.setState({
            add: false
        })
    }

    onClickCloseDelete = () => {
        this.setState({
            delete: false
        })
    }

    /**
     * @description Item click event
     * @author Long.Pham 12-05-2021
     * @param index element in the list
     */
    onItemClick = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index], self = this;

        var params = this.state.curItem;
        params.id_company = item.id_company;
        params.id = item.id;

        OrdersService.instance.getDetail(params, data => {
            if (data) {
                data.screen_mode = Constants.SCREEN_MODE.EDIT;
                self.setState({
                    curItem: data,
                    add: true
                });
            }
        }, false);
    }

    /**
    * @description Item click event
    * @author Long.Pham 12-05-2021
    * @param index element in the list
    */
    onItemClickView = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index], self = this;

        var params = this.state.curItem;
        params.id_company = item.id_company;
        params.id = item.id;

        OrdersService.instance.getDetail(params, data => {
            if (data) {
                data.screen_mode = Constants.SCREEN_MODE.VIEW;
                self.setState({
                    curItem: data,
                    add: true
                });
            }
        }, false);
    }


    /**
     * @description Item click event
     * @author Long.Pham 12-05-2021
     * @param index element in the list
     */
    //  onItemClickPrint = (index) => {
    //     if (!Libs.isArrayData(this.state.dataList)) return;
    //     var item = this.state.dataList[index], self = this;

    //     var params = this.state.curItem;
    //     params.id_company = item.id_company;
    //     params.id = item.id;

    //     OrdersService.instance.getDetail(params, data => {
    //         if (data) {
    //             data.screen_mode = Constants.SCREEN_MODE.VIEW;
    //             self.setState({
    //                 curItem: data,
    //                 add: true
    //             });
    //         }
    //     }, false);
    // }

    /**
     * @description Item click event
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClickPrint = (index) => {
        var dataList = this.state.dataList;
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = dataList[index];
        if (Libs.isObjectEmpty(item)) return;
        OrdersService.instance.print(item);
    }



    /**
   * @description Item click event delete
   * @author Long.Pham 12-05-2021
   * @param index Order element in the list
   */
    onItemClickDelete = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        this.setState({
            curItem: item,
            delete: true
        });
    }


    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name === 'current') {
            if (!Libs.isBlank(value)) {
                var { t } = this.props;
                if (!Libs.isNumber(value)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            self.paging.currentInput = value;
            self.forceUpdate();
        }

        if (name === 'limit') {
            var { searchParam } = this.state;
            searchParam.limit = value;
            this.setState({
                searchParam: searchParam
            }, () => {
                self.getList();
            })
        }
    }

    inputChangedEnter(event) {
        let self = this;
        if (event.key === 'Enter') {
            var currentInput = this.paging.currentInput;
            if (!Libs.isBlank(currentInput)) {
                var { t } = this.props;
                if (!Libs.isNumber(currentInput)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (Libs.isBlank(currentInput) && !Libs.isNumber(currentInput)) return;
            if (parseInt(currentInput) > this.paging.total) {
                currentInput = self.paging.total;
            }

            if (currentInput <= 0) {
                currentInput = 1;
            }

            self.paging.current = currentInput;
            self.paging.currentInput = currentInput;
            this.onSelectPage(currentInput);
            self.forceUpdate();
        }
    }

    inputChangedBlue(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        if (name === 'current') {
            var currentInput = this.paging.currentInput;
            if (!Libs.isBlank(currentInput)) {
                var { t } = this.props;
                if (!Libs.isNumber(currentInput)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (Libs.isBlank(currentInput) && !Libs.isNumber(currentInput)) return;
            if (parseInt(currentInput) > this.paging.total) {
                currentInput = self.paging.total;
            }

            if (currentInput <= 0) {
                currentInput = 1;
            }

            self.paging.current = currentInput;
            self.paging.currentInput = currentInput;
            this.onSelectPage(currentInput);
            self.forceUpdate();
        }
    }


    /**
     * @description Select page in pagging
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;

        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        self.getList();
    }


    /**
     * @description reload data
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onClickReload() {
        let self = this;
        self.getList();
    }


    /**
    * Func filter table
    * @author Long.Pham 12-05-2021
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sort_column = sortKey;
        this.state.searchParam.order_by = (this.state.searchParam.order_by === '' || this.state.searchParam.order_by === 'asc') ? 'desc' : 'asc';
        this.getList();
    }

    /**
      * @description Call form search
      * @author Long.Pham 12/05/2021
      */
    onSearch() {
        let formSearch = (this.state.formSearch === false) ? true : false;
        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * Func search
     * @author Long.Pham 12/05/2021
     * @param  {Object} e
     */
    handleSearch() {
        this.getList();
    }

    render() {
        return this.jsxTemplate.call(this)
    }
}

const HighOrderComponentTranslated = withTranslation('common')(Retail)
export default HighOrderComponentTranslated;