import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate{
    constructor(props){
        super();
        var {t, iso_code_language} = props;
        this.trans = t;
        this.iso_code_language = iso_code_language;

    }
    setRule(){
        if(this.iso_code_language === 'vi'){
            this.addRuleForField('name_vi', 'trim', true);
            this.addRuleForField('name_vi', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.name'));
            this.addRuleForField('name_vi', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('coupon.name'));
        }
        if(this.iso_code_language === 'en'){
            this.addRuleForField('name_en', 'trim', true);
            this.addRuleForField('name_en', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.name'));
            this.addRuleForField('name_en', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('coupon.name'));
        }

        this.addRuleForField('coupon_code', 'trim', true);
        this.addRuleForField('coupon_code', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.coupon_code'));
        this.addRuleForField('coupon_code', 'maxLength', 60, this.trans('validate_rule.maxLength_input'), this.trans('coupon.coupon_code'));

        this.addRuleForField('id_type', 'trim', true);
        this.addRuleForField('id_type', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.id_type'));
        this.addRuleForField('id_type', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('coupon.id_type'));

        
        this.addRuleForField('min_order', 'trim', true);
        this.addRuleForField('min_order', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.min_order'));
        this.addRuleForField('min_order', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('coupon.min_order'));

        this.addRuleForField('max_value', 'trim', true);
        this.addRuleForField('max_value', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.max_value'));
        this.addRuleForField('max_value', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('coupon.max_value'));


        this.addRuleForField('coupon_value', 'trim', true);
        this.addRuleForField('coupon_value', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.coupon_value'));
        this.addRuleForField('coupon_value', 'maxLength', 10, this.trans('validate_rule.maxLength_input'), this.trans('coupon.coupon_value'));

        this.addRuleForField('from_date', 'trim', true);
        this.addRuleForField('from_date', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.from_date'));
        this.addRuleForField('from_date', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('coupon.from_date'));

        this.addRuleForField('to_date', 'trim', true);
        this.addRuleForField('to_date', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.to_date'));
        this.addRuleForField('to_date', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('coupon.to_date'));

        this.addRuleForField('total', 'trim', true);
        this.addRuleForField('total', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.total'));
        this.addRuleForField('total', 'maxLength', 10, this.trans('validate_rule.maxLength_input'), this.trans('coupon.total'));
    }
    
    setAlias(){
        if(this.iso_code_language ==='vi'){
            this.v.setAlias({
                name_vi: this.trans('coupon.name'),
                coupon_code: this.trans('coupon.coupon_code'),
                id_type: this.trans('coupon.id_type'),
                coupon_value: this.trans('coupon.coupon_value'),
                from_date: this.trans('coupon.from_date'),
                to_date: this.trans('coupon.to_date'),
                total: this.trans('coupon.total'),
                min_order: this.trans('coupon.min_order'),
                max_value: this.trans('coupon.max_value')
            });
        }

        if(this.iso_code_language ==='en'){
            this.v.setAlias({
                name_en: this.trans('coupon.name'),
                coupon_code: this.trans('coupon.coupon_code'),
                id_type: this.trans('coupon.id_type'),
                coupon_value: this.trans('coupon.coupon_value'),
                from_date: this.trans('coupon.from_date'),
                to_date: this.trans('coupon.to_date'),
                min_order: this.trans('coupon.min_order'),
                max_value: this.trans('coupon.max_value')
            });
        }
        
    }
}
export default AddPopupValidate;