import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RText, RTextArea, RButton } from '../../../../../components/Controls';
import Constants from '../../../../../utils/Constants';

export default function () {
    var { curItem } = this.state;
    const { t } = this.props;

    return (
        <Modal visible={true} className="" dialogClassName="modal-dialog-scrollable modal-lg">
            <div className="modal-header">
                <h5 className="modal-title">{t('partner.add_title')}</h5>
                <span className="close" onClick={this.props.onCloseSupplierPopup.bind(this)}><var className="icon-cancel-music"></var></span>
            </div>

            <div className="modal-body">

                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.name')}
                                        inputClass="form-control"
                                        inputId={"name"}
                                        inputName={"name"}
                                        value={curItem.name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.phone')}
                                        inputClass="form-control"
                                        inputId="phone"
                                        inputName="phone"
                                        value={curItem.phone}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.email')}
                                        inputClass="form-control"
                                        inputId="email"
                                        inputName="email"
                                        value={curItem.email}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.tax_code')}
                                        inputClass="form-control"
                                        inputId="tax_code"
                                        inputName="tax_code"
                                        value={curItem.tax_code}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('partner.address')}
                                        inputClass="form-control"
                                        inputId={"address"}
                                        inputName={"address"}
                                        value={curItem.address}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RTextArea label={t('partner.description')}
                                        inputClass="form-control"
                                        inputId={"name"}
                                        inputName={"name"}
                                        value={curItem.name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>

                            </div>



                        </div>
                    </div>
                </div>


                <div className="box-info">
                    <h2>{t('partner.persion_info')}</h2>
                    <div className="box-info-content">
                        <div className="row">

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.contact_fullname')}
                                        inputClass="form-control"
                                        inputId="contact_fullname"
                                        inputName="contact_fullname"
                                        value={curItem.contact_fullname}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.contact_email')}
                                        inputClass="form-control"
                                        inputId="contact_email"
                                        inputName="contact_email"
                                        value={curItem.contact_email}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.contact_phone')}
                                        inputClass="form-control"
                                        inputId="contact_phone"
                                        inputName="contact_phone"
                                        value={curItem.contact_phone}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="box-info">
                    <h2>{t('partner.payment_info')}</h2>
                    <div className="box-info-content">
                        <div className="row">

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.bank')}
                                        inputClass="form-control"
                                        inputId="bank"
                                        inputName="bank"
                                        value={curItem.bank}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.bank_sub')}
                                        inputClass="form-control"
                                        inputId="bank_sub"
                                        inputName="bank_sub"
                                        value={curItem.bank_sub}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.bank_number')}
                                        inputClass="form-control"
                                        inputId="bank_number"
                                        inputName="bank_number"
                                        value={curItem.bank_number}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.bank_own')}
                                        inputClass="form-control"
                                        inputId="bank_own"
                                        inputName="bank_own"
                                        value={curItem.bank_own}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner.bank_note')}
                                        inputClass="form-control"
                                        inputId="bank_note"
                                        inputName="bank_note"
                                        value={curItem.bank_note}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

            <div className="modal-footer">
                <RButton
                    onClick={this.onCloseSupplierPopup.bind(this)}
                    className="btn btn-cancel"
                    text={t('common.cancel')}
                    title={t('common.cancel')} />

                <RButton
                    onClick={this.onSaveSupplierPopupAction.bind(this)}
                    className="btn btn-save"
                    text={curItem.screen_mode == Constants.SCREEN_MODE.ADD ? " " + t('common.add') : t('common.update')}
                    title={curItem.screen_mode == Constants.SCREEN_MODE.ADD ? t('common.add') : t('common.update')} />

            </div>
        </Modal>
    )
}