import React from 'react';
import { RText, RSwitch } from '../../../../../components/Controls';
import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';
import FormReactSelect from '../../../../../components/FormReactSelect';
export default function AddPopup() {

    var { curItem, dataGender } = this.state;
    const { t } = this.props;

    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('newsletter.edit_title') : t('newsletter.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">

                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">

                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <p className="control-label">{t('newsletter.is_follow')}</p>
                                    <div className="checkmark">
                                        <RSwitch
                                            inputId="is_follow"
                                            inputName="is_follow"
                                            checked={curItem.is_follow}
                                            onChange={(e) => { this.handleInputChange(e) }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('newsletter.fullname')}
                                        inputClass="form-control"
                                        inputId={"fullname"}
                                        inputName={"fullname"}
                                        value={curItem.fullname}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        instanceId="gender"
                                        className="gender"
                                        name="gender"
                                        value={dataGender.filter(function (item) { return item.id === curItem.gender })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'gender') }}
                                        optionList={dataGender}
                                        label={t('newsletter.gender')}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('newsletter.email')}
                                        inputClass="form-control"
                                        required="required"
                                        inputId={"email"}
                                        inputName={"email"}
                                        value={curItem.email}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>
                            

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('newsletter.phone')}
                                        inputClass="form-control"
                                        inputId={"phone"}
                                        inputName={"phone"}
                                        value={curItem.phone}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        pattern="^[0-9-+()._ ]*$"
                                        maxLength={20} />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>


            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>

    )
}