
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
import ProvinceService from '../../../../../services/ProvinceService';
import DistrictService from '../../../../../services/DistrictService';
import WardService from '../../../../../services/WardService';
import CustomerGroupService from '../../../../../services/CustomerGroupService';
import CustomerTypeService from '../../../../../services/CustomerTypeService';
import CustomerService from '../../../../../services/CustomerService';
import AddCustomerPopupValidate from './AddCustomerPopupValidate';
import OrdersService from '../../../../../services/OrdersService';
import TypeService from '../../../../../services/TypeService';
import PaymentMethodService from '../../../../../services/PaymentMethodService';
import CouponService from '../../../../../services/CouponService';
import OrderStatusService from '../../../../../services/OrderStatusService';
import PaymentStatusService from '../../../../../services/PaymentStatusService';


class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
            dataBilling: [],
            dataPaymentMethod: [],


            allLanguage: this.props.allLanguage,
            dataCustomerType: [],
            dataCustomerGroup: [],
            searchProductParam: {},
            dataProvince: [],
            dataDistrict: [],
            dataWard: [],
            dataProvinceShip: [],
            dataDistrictShip: [],
            dataWardShip: [],
            dataSearchPhoneAuto: [],
            dataSearchEmailAuto: [],
            dataProductSearchAuto: [],
            dataOrderStatus: [],
            dataPaymentStatus: [],
            dataGender: [
                { id: 1, value: 1, label: "Nam" },
                { id: 2, value: 2, label: "Nữ" },
                { id: 3, value: 3, label: "Khác" }
            ],
            manualTypeDiscount: [],

        }

        this.jsxTemplate = AddPopupJsx;
        this.handleInputSearchAutoChange = this.handleInputSearchAutoChange.bind(this);
        this.handleInputSerachProductAutoChange = this.handleInputSerachProductAutoChange.bind(this);
        this.handleInputManualDiscountChange = this.handleInputManualDiscountChange.bind(this);
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
        var { t } = this.props;
        this.getProvince();
        this.getDropdownCustomerGroup();
        this.getDropdownCustomerType();
        this.getDataTypeDisccount();
        this.getDataPaymentMethod();
        this.getDataOrderStatus();
        this.getDataPaymentStatus();

        var { curItem, dataBilling } = this.state;
        if (Constants.SCREEN_MODE.ADD === curItem.screen_mode) {
            curItem.dataProducts = [];
            curItem.vat = this.employee.vat ? this.employee.vat : 0;
            curItem.coupon = {};
        }
        var itemKey = Libs.generateSKU(this.employee.id_company, 4);
        curItem.order_index = itemKey;
        dataBilling.push({
            id: itemKey,
            index: 1,
            data: curItem,
            name: t('sell.billing') + " 1",
            active: 1
        });
        this.setState({
            curItem: curItem
        })
    }

    handleInputManualDiscountChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value

        if (name) {
            var numericExpression = /^[0-9.\b]+$/;
            if (value == "" || numericExpression.test(value)) {
                let item = this.state.curItem;
                var totalOrder = 0;
                var dataProducts = item.dataProducts;
                if(Libs.isArrayData(dataProducts)){
                    for(var i = 0, len = dataProducts.length; i < len; i++){
                        totalOrder = totalOrder + (dataProducts[i].saleoff_price * dataProducts[i].quantity);
                    }
                }
                if(Libs.convertStringToNumber(value) > totalOrder){
                    value = totalOrder;
                }
                item[name] = value;
                this.setState({ curItem: item });
            }
        }
    }


    deleteCoupon = () => {
        var { curItem } = this.state;
        curItem.coupon = {};
        curItem.coupon_code = '';
        this.setState({
            curItem: curItem
        })
    }

    copyFromCustomer = () => {
        var { curItem } = this.state, self = this;

        curItem.ship_first_name = curItem.first_name;
        curItem.ship_last_name = curItem.last_name;
        curItem.ship_phone = curItem.phone;
        curItem.id_province_ship = curItem.id_province;
        curItem.id_district_ship = curItem.id_district;
        curItem.id_ward_ship = curItem.id_ward;
        curItem.ship_address = curItem.address;
        this.setState({
            curItem: curItem
        }, () => {
            self.getDistrictByIdProvinceShip(curItem.id_province_ship);
        })
    }
    appleCoupon = () => {
        var { t } = this.props;
        var { curItem } = this.state, self = this;
        if (!Libs.isBlank(curItem.coupon_code)) {
            var dataProducts = curItem.dataProducts;
            var subTotal = 0;
            dataProducts.map((item, index) => {
                subTotal = subTotal + (dataProducts[index].saleoff_price * dataProducts[index].quantity);
                return subTotal;
            });

            var params = {
                id_company: this.employee.id_company,
                coupon_code: curItem.coupon_code,
                sub_total: subTotal
            };
            CouponService.instance.getAppleCoupon(params, data => {
                if (!Libs.isObjectEmpty(data)) {
                    curItem.coupon = data;
                    self.setState({
                        curItem: curItem
                    })
                    self.setValidateMessage({ coupon_code: '' }, true);
                    self.toast(t('sell.success_coupon'), 'info');
                } else {
                    self.setValidateMessage({ coupon_code: t('sell.error_coupon') }, true);
                }
            }, false);
        }
    }

    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

    getDataPaymentMethod = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        var curItem = this.state.curItem;

        PaymentMethodService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                if (curItem.screen_mode == Constants.SCREEN_MODE.ADD) {
                    var item = Libs.find(data, 'is_default', 1);
                    if (!Libs.isObjectEmpty(item)) {
                        curItem.id_payment_method = item.id;
                    } else {
                        curItem.id_payment_method = null;
                    }
                }

                self.setState({
                    dataPaymentMethod: data,
                    curItem: curItem
                });
            } else {
                self.setState({
                    dataPaymentMethod: [],
                    curItem: curItem
                });
            }
        })
    }


    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

    getDataOrderStatus = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        var curItem = this.state.curItem;

        OrderStatusService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                if (curItem.screen_mode == Constants.SCREEN_MODE.ADD) {
                    var item = Libs.find(data, 'is_default', 1);
                    if (!Libs.isObjectEmpty(item)) {
                        curItem.id_order_status = item.id;
                    } else {
                        curItem.id_order_status = null;
                    }
                }

                self.setState({
                    dataOrderStatus: data,
                    curItem: curItem
                });
            } else {
                self.setState({
                    dataOrderStatus: [],
                    curItem: curItem
                });
            }
        })
    }


    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

    getDataPaymentStatus = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };
        var curItem = this.state.curItem;

        PaymentStatusService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                if (curItem.screen_mode == Constants.SCREEN_MODE.ADD) {
                    var item = Libs.find(data, 'is_default', 1);
                    if (!Libs.isObjectEmpty(item)) {
                        curItem.id_payment_status = item.id;
                    } else {
                        curItem.id_payment_status = null;
                    }
                }

                self.setState({
                    dataPaymentStatus: data,
                    curItem: curItem
                });
            } else {
                self.setState({
                    dataPaymentStatus: [],
                    curItem: curItem
                });
            }
        })
    }



    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

    getDataTypeDisccount = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            type: 'type_discount'
        };

        TypeService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    manualTypeDiscount: data
                });
            } else {
                self.setState({
                    manualTypeDiscount: []
                });
            }
        })
    }


    onClickSetActiveOrder = (index) => {
        var { dataBilling } = this.state;
        if (!Libs.isArrayData(dataBilling)) return;

        var itemFind = dataBilling[index];
        if (Libs.isObjectEmpty(itemFind)) return;

        dataBilling.map((item, v) => {
            if (index === v) {
                dataBilling[v].active = 1;
            } else {
                dataBilling[v].active = 0;
            }
        });

        this.setState({
            dataBilling: dataBilling,
            curItem: itemFind.data ? itemFind.data : {
                dataProducts: [],
                vat: this.employee.vat ? this.employee.vat : 0,
                coupon: []
            }
        });

    }
    addNewBilling = () => {
        var { t } = this.props;
        var { curItem, dataBilling } = this.state;

        dataBilling.map((item, index) => {
            if (item.active) {
                dataBilling[index].data = curItem;
            }
            dataBilling[index].active = 0;
        });
        var itemKey = Libs.generateSKU(this.employee.id_company, 4);

        var curItemNew = {
            order_index: itemKey,
            dataProducts: [],
            vat: this.employee.vat ? this.employee.vat : 0,
            coupon: {}
        }
        const max = dataBilling.reduce((prev, current) => (prev.index > current.index) ? prev : current);
        dataBilling.push({
            id: itemKey,
            index: (parseInt(max.index) + 1),
            data: curItemNew,
            name: t('sell.billing') + " " + (parseInt(max.index) + 1),
            active: 1
        });

        this.setState({
            dataBilling: dataBilling,
            curItem: curItemNew
        });
    }



    onItemProductClickDelete = (index) => {
        var { curItem } = this.state;
        var dataProducts = curItem.dataProducts;
        if (!Libs.isArrayData(dataProducts)) return;

        var item = dataProducts[index];
        if (Libs.isObjectEmpty(item)) return;
        dataProducts.splice(index, 1);

        curItem.dataProducts = dataProducts;
        this.setState({
            curItem: curItem
        });
    }

    handleInputProductChange = (event, index) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem } = this.state;

            var numericExpression = /^[0-9.\b]+$/;
            if (value == "" || numericExpression.test(value)) {
                var dataProducts = curItem.dataProducts;
                if (!Libs.isArrayData(dataProducts)) return;

                var item = dataProducts[index];
                if (Libs.isObjectEmpty(item)) return;

                if (name === 'saleoff_price') {
                    dataProducts[index].saleoff_price = value;
                }

                if (name === 'quantity') {
                    dataProducts[index].quantity = value;
                }
                curItem.dataProducts = dataProducts;
                this.setState({
                    curItem: curItem
                });
            }
        }
    }


    getChooseProduct = (index) => {
        var { dataProductSearchAuto, curItem } = this.state;
        var itemProduct = dataProductSearchAuto[index];
        if (Libs.isObjectEmpty(itemProduct)) return;

        var dataProducts = curItem.dataProducts;

        if (Libs.isArrayData(dataProducts)) {
            var findItem = Libs.find(dataProducts, 'sku_alias', itemProduct.sku_alias);
            if (Libs.isObjectEmpty(findItem)) {
                dataProducts.push({
                    sku_alias: itemProduct.sku_alias,
                    id_product: itemProduct.id_product,
                    sku: itemProduct.sku,
                    regular_price: itemProduct.regular_price,
                    saleoff_price: itemProduct.saleoff_price,
                    product_name: itemProduct.product_name,
                    quantity: 1
                });
            } else {
                dataProducts.map((item, v) => {
                    if (item.sku_alias === itemProduct.sku_alias) {
                        dataProducts[v].quantity = parseInt(dataProducts[v].quantity) + 1;
                        dataProducts[v].sub_total = parseInt(dataProducts[v].saleoff_price) * parseInt(dataProducts[v].quantity);
                    }
                });
            }

        } else {
            dataProducts.push({
                sku_alias: itemProduct.sku_alias,
                id_product: itemProduct.id_product,
                sku: itemProduct.sku,
                regular_price: itemProduct.regular_price,
                saleoff_price: itemProduct.saleoff_price,
                product_name: itemProduct.product_name,
                quantity: 1,
                sub_total: itemProduct.saleoff_price * 1
            });
        }

        curItem.dataProducts = dataProducts;

        this.setState({
            curItem: curItem
        })


    }

    handleInputSerachProductAutoChange(event) {
        let target = event.target, self = this;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { searchProductParam } = this.state;
            searchProductParam[name] = value;
            if (name == 'name') {
                this.setState({ searchProductParam }, () => {
                    self.getSearchProductAuto();
                });
            }
        }
    }


    onCloseSearchProductAuto = () => {
        this.setState({
            searchProductParam: {},
            dataProductSearchAuto: []
        })
    }

    /**
   * ge list type
   * @author Long.Pham 2019-06-03
   */
    getSearchProductAuto() {
        let self = this, searchProductParam = this.state.searchProductParam;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            name: searchProductParam.name
        };
        if (Libs.isBlank(searchProductParam.name)) {
            self.setState({
                dataProductSearchAuto: []
            });
            return;
        }

        OrdersService.instance.getSearchProductAuto(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataProductSearchAuto: data
                });
            } else {
                self.setState({
                    dataProductSearchAuto: []
                });
            }
        })
    }


    onCloseSearchPhoneAuto = () => {
        this.setState({
            dataSearchPhoneAuto: []
        });
    }
    onCloseSearchEmailAuto = () => {
        this.setState({
            dataSearchEmailAuto: []
        });
    }

    setCurrentCustomerPhone = (index) => {
        var { curItem, dataSearchPhoneAuto } = this.state, self = this;
        var item = dataSearchPhoneAuto[index];
        if (Libs.isObjectEmpty(item)) return;

        if (!Libs.isBlank(item.id_province)) {
            self.getDistrictByIdProvince(item.id_province);
        }
        this.setState({
            curItem: Object.assign(curItem, item),
            dataSearchPhoneAuto: []
        });
    }

    setCurrentCustomerEmail = (index) => {
        var { curItem, dataSearchEmailAuto } = this.state, self = this;
        var item = dataSearchEmailAuto[index];
        if (Libs.isObjectEmpty(item)) return;

        if (!Libs.isBlank(item.id_province)) {
            self.getDistrictByIdProvince(item.id_province);
        }
        this.setState({
            curItem: Object.assign(curItem, item),
            dataSearchEmailAuto: []
        });
    }

    async handleInputSearchAutoChange(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem } = this.state;
            curItem[name] = (event.target.validity.valid) ? value : curItem[name];

            this.setState({ curItem }, async () => {
                if (name === 'phone') {
                    self.getSearchAutoPhone();
                }

                if (name === 'email') {
                    self.getSearchAutoEmail();
                }
                self.removeAllValidateMessage();

            });
        }
    }


    /**
     * get list
     * @author Long.Pham 2019-06-03
     */
    getSearchAutoPhone() {
        let self = this;
        let curItem = this.state.curItem;
        var params = {
            phone: curItem.phone,
            id_language: this.employee.id_language,
            id_company: this.employee.id_company
        };

        CustomerService.instance.getSearchAuto(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataSearchPhoneAuto: data
                });
            } else {
                self.setState({
                    dataSearchPhoneAuto: []
                });
            }
        });
    }


    /**
     * get list
     * @author Long.Pham 2019-06-03
     */
    getSearchAutoEmail() {
        let self = this;
        let curItem = this.state.curItem;
        var params = {
            email: curItem.email,
            id_language: this.employee.id_language,
            id_company: this.employee.id_company
        };

        CustomerService.instance.getSearchAuto(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataSearchEmailAuto: data
                });
            } else {
                self.setState({
                    dataSearchEmailAuto: []
                });
            }
        });
    }

    /**
     * @description get customer type
     * @author long.pham 26/07/2019
     */

    getDropdownCustomerType = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        CustomerTypeService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataCustomerType: data
                });
            }
        })
    }

    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

    getDropdownCustomerGroup = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        CustomerGroupService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataCustomerGroup: data
                });
            }
        })
    }



    /**
   * @description get province
   * @author long.pham 26/07/2019
   */

    getProvince() {
        let self = this;
        ProvinceService.instance.getList({}, function (data, totalRow) {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataProvince: data,
                    dataDistrict: [],
                    dataWard: [],

                    dataProvinceShip: data,
                    dataDistrictShip: [],
                    dataWardShip: [],
                }, () => {
                    if (!Libs.isBlank(self.state.curItem.id_province)) {
                        self.getDistrictByIdProvince(self.state.curItem.id_province);
                        self.getDistrictByIdProvinceShip(self.state.curItem.id_province);
                    }
                });
            }
            else {
                self.setState({
                    dataProvince: [],
                    dataDistrict: [],
                    dataWard: [],
                    dataProvinceShip: [],
                    dataDistrictShip: [],
                    dataWardShip: [],

                });
            }
        }, false);
    }



    /**
     * @description get district by id_province
     * @author long.pham 26/07/2019
     */

    getDistrictByIdProvince(idProvince) {
        let self = this;
        if (Libs.isBlank(idProvince)) { return false; }
        let params = {
            id_province: idProvince
        };
        DistrictService.instance.getByIdProvince(params, function (data, totalRow) {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataDistrict: data,
                    dataWard: [],
                }, () => {
                    if (!Libs.isBlank(self.state.curItem.id_district)) {
                        self.getWardByIdDistrict(self.state.curItem.id_district);
                    }
                });
            }
            else {
                self.setState({
                    dataDistrict: [],
                    dataWard: []
                });
            }
        }, false);
    }


    /**
     * @description get district by id_province
     * @author long.pham 26/07/2019
     */

    getDistrictByIdProvinceShip(idProvince) {
        let self = this;
        if (Libs.isBlank(idProvince)) { return false; }
        let params = {
            id_province: idProvince
        };
        DistrictService.instance.getByIdProvince(params, function (data, totalRow) {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataDistrictShip: data,
                    dataWardShip: [],
                }, () => {
                    if (!Libs.isBlank(self.state.curItem.id_district_ship)) {
                        self.getWardByIdDistrictShip(self.state.curItem.id_district_ship);
                    }
                });
            }
            else {
                self.setState({
                    dataDistrictShip: [],
                    dataWardShip: []
                });
            }
        }, false);
    }




    /**
   * @description get ward by id_district
   * @author long.pham 26/07/2019
   */

    getWardByIdDistrict(idDistrict) {
        let self = this;
        if (Libs.isBlank(idDistrict)) { return false; }
        let params = {
            id_district: idDistrict
        };

        WardService.instance.getByIdDistrict(params, function (data, totalRow) {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataWard: data
                });
            }
            else {
                self.setState({
                    dataWard: []
                });
            }
        }, false);
    }


    /**
   * @description get ward by id_district
   * @author long.pham 26/07/2019
   */

    getWardByIdDistrictShip(idDistrict) {
        let self = this;
        if (Libs.isBlank(idDistrict)) { return false; }
        let params = {
            id_district: idDistrict
        };

        WardService.instance.getByIdDistrict(params, function (data, totalRow) {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataWardShip: data
                });
            }
            else {
                self.setState({
                    dataWardShip: []
                });
            }
        }, false);
    }


    handleDropdownChange = (item, name) => {
        var { t } = this.props;
        if (Libs.isObjectEmpty(item)) return;
        var self = this, value = item.value;
        let curItem = self.state.curItem;


        if (name == 'id_province') {
            self.getDistrictByIdProvince(value);
            curItem.id_district = null;
            curItem.id_ward = null;
        }

        if (name == 'id_district') {
            self.getWardByIdDistrict(value);
            curItem.id_ward = null;
        }

        if (name === 'id_customer_group') {
            if (Libs.isObjectEmpty(item)) {
                curItem.id_customer_group = '';
                self.setValidateMessage({ id_customer_group: t('customer.id_customer_group') }, true);
            } else {
                self.setValidateMessage({ id_customer_group: '' }, true);
            }
        }

        if (name === 'id_customer_type') {
            if (Libs.isObjectEmpty(item)) {
                curItem.id_customer_type = '';
                self.setValidateMessage({ id_customer_type: t('customer.id_customer_type') }, true);
            } else {
                self.setValidateMessage({ id_customer_type: '' }, true);
            }
        }

        if (name === 'id_payment_status') {
            if (Libs.isObjectEmpty(item)) {
                curItem.id_payment_status = '';
                self.setValidateMessage({ id_payment_status: t('customer.id_payment_status') }, true);
            } else {
                self.setValidateMessage({ id_payment_status: '' }, true);
            }
        }

        if (name === 'id_type_discount') {
            curItem.manual_discount = null;
        }


        curItem[name] = value;
        self.setState({
            curItem: curItem
        });
    }


    handleDropdownShipChange = (item, name) => {
        if (Libs.isObjectEmpty(item)) return;
        var self = this, value = item.value;
        let curItem = self.state.curItem;
        if (name == 'id_province_ship') {
            self.getDistrictByIdProvinceShip(value);
            curItem.id_district_ship = null;
            curItem.id_ward_ship = null;
        }

        if (name == 'id_district_ship') {
            self.getWardByIdDistrictShip(value);

            curItem.id_ward_ship = null;
        }

        curItem[name] = value;
        self.setState({
            curItem: curItem
        });
    }














    /**
     * @description validate a field input
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }


    generatePassword() {
        var curItem = this.state.curItem;
        var password = Libs.generateStrRandom(2, 2, 2, 2);
        curItem.password = password;
        curItem.password_confirm = password;
        this.setState({ curItem: curItem });
    }


    resetCustomer = () => {
        var { curItem } = this.state;
        curItem.phone = null;
        curItem.email = null;
        curItem.id_customer = null;
        curItem.first_name = null;
        curItem.last_name = null;
        curItem.card_number = null;
        curItem.id_province = null;
        curItem.id_district = null;
        curItem.id_ward = null;
        curItem.birthday = null;
        curItem.id_customer_type = null;
        curItem.id_customer_group = null;
        curItem.note = null;
        curItem.address = null;
        curItem.facebook = null;
        curItem.gender = null;
        this.setState({
            curItem: curItem
        })
    }
    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSaveCustomer() {
        var curItem = this.state.curItem;
        var params = Object.assign({}, this.state.curItem), self = this;
        var password = Libs.generateStrRandom(2, 2, 2, 2);
        params.password = password;
        params.password_confirm = password;
        let v = new AddCustomerPopupValidate(this.props);
        let errors = await v.FLValidationAll(params);
        if (errors) {
            self.setValidateMessage(errors);
            return;
        }



        //remove message validation
        self.removeAllValidateMessage();
        params.id = null;
        params.id_customer = null;
        params.password = Libs.SHA3(params.password);
        params.screen_mode = Constants.SCREEN_MODE.ADD;
        delete params.password_confirm;
        params.id_company = this.employee.id_company;
        params.iso_code_lang = this.employee.lang;
        params.id_language = this.employee.id_language;
        params.iso_code = this.employee.iso_code;
        params.config_thumb_folder_new = this.employee.config_thumb_folder_new;
        params.config_thumb_folder_pro = this.employee.config_thumb_folder_pro;
        params.config_thumb_new_h = this.employee.config_thumb_new_h;
        params.config_thumb_pro_h = this.employee.config_thumb_pro_h;
        params.config_thumb_pro_w = this.employee.config_thumb_pro_w;
        params.config_cdn = this.employee.config_cdn;

        CustomerService.instance.save(params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                curItem.id_customer = data.id
                self.setState({
                    curItem: curItem
                });
            }
            else if (data) {
                self.setValidateMessage(data);
            }
            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }


    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSave() {
        var curItem = this.state.curItem, self = this;
        var params = Object.assign({}, curItem);
        var screenMode = (!Libs.isBlank(params.id)) ? Constants.SCREEN_MODE.EDIT : ((!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD);
        let v = new AddPopupValidate(this.props);
        let errors = await v.FLValidationAll(params);
        if (errors) {
            self.setValidateMessage(errors);
            return;
        }
        //remove message validation
        this.removeAllValidateMessage();
        params.screen_mode = screenMode;

        if (!Libs.isArrayData(params.dataProducts)) {
            self.toast('Đơn hàng chưa có sản phẩm', 'error'); return;
        }

        var dataProducts = params.dataProducts;

        var subTotal = 0;
        dataProducts.map((item, index) => {
            dataProducts[index].saleoff_price = Libs.convertStringToNumber(dataProducts[index].saleoff_price);
            dataProducts[index].quantity = Libs.convertStringToNumber(dataProducts[index].quantity);
            subTotal = subTotal + (dataProducts[index].saleoff_price * dataProducts[index].quantity);
            return dataProducts;
        });

        params.sub_total = subTotal;
        params.manual_discount = !Libs.isBlank(params.manual_discount) ? Libs.convertStringToNumber(params.manual_discount) : 0;
        params.amount_ship = !Libs.isBlank(params.amount_ship) ? Libs.convertStringToNumber(params.amount_ship) : 0;

        //Chiet khau
        var manualDiscount = 0;
        if (!Libs.isBlank(params.id_type_discount) && params.id_type_discount === 5 && !Libs.isBlank(params.manual_discount)) {
            manualDiscount = subTotal * (parseFloat(params.manual_discount) / 100);
        } else if (!Libs.isBlank(params.id_type_discount) && params.id_type_discount === 4 && !Libs.isBlank(params.manual_discount)) {
            manualDiscount = Libs.convertStringToNumber(params.manual_discount);
        }

        params.manual_discount_value = manualDiscount;

        // Coupon
        var couponTotal = 0;
        var coupon = params.coupon;
        if (!Libs.isObjectEmpty(coupon)) {
            if (subTotal >= coupon.min_order && coupon.id_type === 5) {
                couponTotal = (subTotal * (parseFloat(coupon.coupon_value) / 100)) > coupon.max_value ? coupon.max_value : (subTotal * (parseFloat(coupon.coupon_value) / 100));
            } else if (subTotal >= coupon.min_order && coupon.id_type === 4) {
                couponTotal = coupon.coupon_value;
            }
        };

        if (couponTotal > 0 && !Libs.isObjectEmpty(coupon)) {
            params.id_coupon = coupon.id;
            params.coupon_code = coupon.coupon_code;
            params.coupon_value = couponTotal;
        } else {
            params.id_coupon = null;
            params.coupon_value = null;
            params.coupon_code = null;
        }

        // vat
        var vat_value = (subTotal - couponTotal - manualDiscount) * (parseFloat(params.vat) / 100);
        params.vat_value = vat_value;
        // Total
        var totalOrder = 0;
        totalOrder = subTotal - couponTotal - manualDiscount + vat_value;
        if (!Libs.isBlank(params.amount_ship)) {
            totalOrder = totalOrder + parseInt(params.amount_ship);
        }

        params.total = totalOrder;
        params.id_company = this.employee.id_company;
        params.iso_code_lang = this.employee.lang;
        params.id_language = this.employee.id_language;
        params.iso_code = this.employee.iso_code;
        params.id_employee = this.employee.id_employee;

        var dataPD = [];
        if (!Libs.isBlank(params.pd)) {
            dataPD.push(
                {
                    id_order: "",
                    pd: params.pd,
                    lens_width: !Libs.isBlank(params.lens_width) ? params.lens_width : null,
                    bridge_width: !Libs.isBlank(params.bridge_width) ? params.bridge_width : null,
                    frame_width: !Libs.isBlank(params.frame_width) ? params.frame_width : null
                }
            );
        } else {
            dataPD = Libs.isArrayData(params.dataPD) ? params.dataPD : [];
        }
        params.dataPD = dataPD;

        OrdersService.instance.save(params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onClickCloseAdd(true);
            }
            else if (data) {
                self.setValidateMessage(data);
            }

            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }
}
export default AddPopup;