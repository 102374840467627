import React from 'react';
import RowItem from './RowItem';
import { Paging } from '../../../../../components/Paging';
import { RText } from '../../../../../components/Controls';
import AddPopup from './AddPopup';
import RauCuQuaAddPopup from './RauCuQuaAddPopup';
import DeletePopup from './DeletePopup';
import ImportPopup from './ImportPopup';

export default function Products() {
    const { t } = this.props;
    var { curItem, dataList, allLanguage, searchParam } = this.state;
    var RowItems = null;
    var businessType = this.employee.business_type;
    RowItems = dataList.map((item, index) => {
        return <RowItem
            key={'row_item_' + index}
            index={index}
            dataItem={item}
            onItemClick={this.onItemClick}
            onItemClickDelete={this.onItemClickDelete}
            onStatusChange={this.onStatusChange}
            t={t}
            auth={this.props.auth}
            actions={this.actions}
        />
    });

    var AddPopupLayout = null;
    switch (parseInt(businessType)) {
        case 1:
            AddPopupLayout = this.state.add ? <RauCuQuaAddPopup
                t={t}
                iso_code_language={this.employee.lang}
                allLanguage={allLanguage}
                curItem={curItem}
                onClickCloseAdd={this.onClickCloseAdd}
                auth={this.props.auth}
                actions={this.actions} /> : '';
            break;
        default:
            AddPopupLayout = this.state.add ? <AddPopup
                t={t}
                iso_code_language={this.employee.lang}
                allLanguage={allLanguage}
                curItem={curItem}
                onClickCloseAdd={this.onClickCloseAdd}
                auth={this.props.auth}
                actions={this.actions} /> : '';
            break;
    }


    var DeletePopupLayout = this.state.delete
        ? <DeletePopup
            t={t}
            curItem={curItem}
            onClickCloseDelete={this.onClickCloseDelete}
            auth={this.props.auth}
            actions={this.actions}
        /> : '';

    var ImportPopupLayout = this.state.import
        ? <ImportPopup
            t={t}
            onClickCloseImport={this.onClickCloseImport}
            auth={this.props.auth}
            actions={this.actions}
        /> : '';


    return (
        <div className="products">

            {AddPopupLayout}
            {ImportPopupLayout}
            {DeletePopupLayout}
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-md-4"><h2>{t('products.title')}</h2>
                    </div>
                    <div className="col-md-8">
                        <ul>
                            <li><div className="search" onClick={this.onSearch.bind(this)}><span className="icon icon-search"></span> {t('common.search')}</div></li>
                            <li><div className="add" onClick={this.onClickAdd.bind(this)}><span className="icon-plus"></span> {t('common.add')}</div></li>
                            <li><div className="add" onClick={this.onClickImport.bind(this)}><span className="icon-download"></span> {t('common.import')}</div></li>
                            {/* <li><div className="add import">
                                <label htmlFor="file_import">
                                    <input type="file" onChange={(e) => { this.importExcel(e); }} id="file_import" name="file_import" />
                                    <span className="icon-download"></span> {t('common.import')}
                                </label>
                            </div>
                            </li> */}
                            {/* <li><div className="add" onClick={this.onClickExportExcel.bind(this)}><span className="icon-upload"></span> {t('common.export_excel')}</div></li> */}
                        </ul>
                    </div>
                </div>
            </div>
            {this.state.formSearch ?
                <div className="form-search">
                    <div className="row">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <RText label={t('products.sku')}
                                    inputClass="form-control"
                                    inputId="sku"
                                    inputName="sku"
                                    value={searchParam.sku}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    onKeyDown={this._handleKeyDown}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <RText label={t('products.name')}
                                    inputClass="form-control"
                                    inputId="name"
                                    inputName="name"
                                    value={searchParam.name}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    onKeyDown={this._handleKeyDown}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <button type="button" className="btn btn-search" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                                <button type="button" className="btn btn-reset" onClick={this.onResetSearch.bind(this)}> {t('common.reset')} </button>
                            </div>
                        </div>

                    </div>
                </div>
                : ""}


            <div className="data-view">
                <div className="main-header">
                    <div className="header-row">
                        <div className="header-col width5"><p><var className="icon-picture-o"></var></p></div>
                        {/* <div className="header-col width15"><p>{t('products.sku')}</p></div> */}
                        <div onClick={e => this.onSort(e, 'id')} className={searchParam.sort_column === "id" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('products.sku')}</p></div>

                        <div onClick={e => this.onSort(e, 'name')} className={searchParam.sort_column === "name" ? "header-col width30 sorting " + searchParam.order_by : "header-col width30 sorting"}><p>{t('products.name')}</p></div>
                        <div onClick={e => this.onSort(e, 'regular_price')} className={searchParam.sort_column === "regular_price" ? "header-col width10 sorting " + searchParam.order_by : "header-col width10 sorting"}><p>{t('products.price')}</p></div>
                        <div onClick={e => this.onSort(e, 'category_name')} className={searchParam.sort_column === "category_name" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('products.category_name')}</p></div>
                        <div onClick={e => this.onSort(e, 'status')} className={searchParam.sort_column === "status" ? "header-col width10 sorting text-center " + searchParam.order_by : "header-col width10 sorting text-center "}><p>{t('common.status')}</p></div>
                        <div className="header-col width10 text-end"><p>{t('common.action')}</p></div>
                    </div>
                </div>
                <div className="main-body">
                    <div className="body">
                        {RowItems ? RowItems : <div className="data-empty">{t('common.data_empty')}</div>}
                    </div>
                </div>
            </div>

            <div className="main-paging">
                <Paging
                    total={parseInt(this.paging.total)}
                    current={(this.paging.current)}
                    currentInput={this.paging.currentInput}
                    inputChangedHandler={this.inputChangedHandler}
                    inputChangedEnter={this.inputChangedEnter}
                    inputChangedBlue={this.inputChangedBlue}
                    onClickReload={(e) => this.onClickReload.bind(this)}
                    onSelectPage={(index) => this.onSelectPage.bind(this, index)}>
                </Paging>
            </div>
        </div>
    );
};