import React from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer';
import SidebarStores from '../common/SidebarStores';

const MainModuleStores = ({ children }) => {
  return <div>
    <Header />
    <SidebarStores />
    <div id="main">
      {children}
    </div>
    <Footer />
  </div>
};

export default MainModuleStores;
