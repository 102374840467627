import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import MainLogin from '../containers/layouts/MainLogin';
import MainAdmin from '../containers/layouts/MainAdmin';
import MainModuleProduct from '../containers/layouts/MainModuleProduct';
import MainModuleHr from '../containers/layouts/MainModuleHr';
import MainModuleStores from '../containers/layouts/MainModuleStores';
import MainModuleSell from '../containers/layouts/MainModuleSell';
import MainModuleCRM from '../containers/layouts/MainModuleCRM';
import MainDashboard from '../containers/layouts/MainDashboard';
import MainAcount from '../containers/layouts/MainAcount';
import MainModuleWebsite from '../containers/layouts/MainModuleWebsite';



// views
import Login from '../containers/views/Login/Login';
import ForgotPassword from '../containers/views/ForgotPassword/ForgotPassword';
import ResetPassword from '../containers/views/ResetPassword/ResetPassword';
import Account from '../containers/views/desktop/Account/Account';

// admin Views
import Dashboard from '../containers/views/desktop/Dashboard/Dashboard';

import Brand from '../containers/views/desktop/ModuleProduct/Brand/Brand';
import Category from '../containers/views/desktop/ModuleProduct/Category/Category';
import Attributes from '../containers/views/desktop/ModuleProduct/Attributes/Attributes';
import GroupAttributes from '../containers/views/desktop/ModuleProduct/GroupAttributes/GroupAttributes';
import Products from '../containers/views/desktop/ModuleProduct/Products/Products';
import Tags from '../containers/views/desktop/ModuleProduct/Tags/Tags';
import CustomField from '../containers/views/desktop/ModuleProduct/CustomField/CustomField';
import Unit from '../containers/views/desktop/ModuleProduct/Unit/Unit';
import ProductType from '../containers/views/desktop/ModuleProduct/ProductType/ProductType';
import GlassLensType from '../containers/views/desktop/ModuleProduct/GlassLensType/GlassLensType';


import Role from '../containers/views/desktop/ModuleHumanResources/Role/Role';
import Permissions from '../containers/views/desktop/ModuleHumanResources/Permissions/Permissions';
import Employees from '../containers/views/desktop/ModuleHumanResources/Employees/Employees';
import Regency from '../containers/views/desktop/ModuleHumanResources/Regency/Regency';

import Stores from '../containers/views/desktop/ModuleStores/Stores/Stores';
import Partner from '../containers/views/desktop/ModuleStores/Partner/Partner';
import ImportStock from '../containers/views/desktop/ModuleStores/ImportStock/ImportStock';
import ExportStock from '../containers/views/desktop/ModuleStores/ExportStock/ExportStock';
import TransferStock from '../containers/views/desktop/ModuleStores/TransferStock/TransferStock';
import SummaryStore from '../containers/views/desktop/ModuleStores/SummaryStore/SummaryStore';
import InventoryStock from '../containers/views/desktop/ModuleStores/InventoryStock/InventoryStock';

import Retail from '../containers/views/desktop/ModuleSell/Retail/Retail';
import SearchBill from '../containers/views/desktop/ModuleSell/SearchBill/SearchBill';
import ReturnOrder from '../containers/views/desktop/ModuleSell/ReturnOrder/ReturnOrder';

import CustomerGroup from '../containers/views/desktop/ModuleCRM/CustomerGroup/CustomerGroup';
import CustomerType from '../containers/views/desktop/ModuleCRM/CustomerType/CustomerType';
import Customer from '../containers/views/desktop/ModuleCRM/Customer/Customer';
import Newsletter from '../containers/views/desktop/ModuleCRM/Newsletter/Newsletter';
import Coupon from '../containers/views/desktop/ModuleCRM/Coupon/Coupon';

import BannerPosition from '../containers/views/desktop/ModuleWebsite/BannerPosition/BannerPosition';
import Banner from '../containers/views/desktop/ModuleWebsite/Banner/Banner';
import CustomerReview from '../containers/views/desktop/ModuleWebsite/CustomerReview/CustomerReview';
import NewsCategory from '../containers/views/desktop/ModuleWebsite/NewsCategory/NewsCategory';
import News from '../containers/views/desktop/ModuleWebsite/News/News';
import Collection from '../containers/views/desktop/ModuleWebsite/Collection/Collection'; 
import Pages from '../containers/views/desktop/ModuleWebsite/Pages/Pages'; 
import Promotion from '../containers/views/desktop/ModuleCRM/Promotion/Promotion';
import PartnerCustomer from '../containers/views/desktop/ModuleWebsite/PartnerCustomer/PartnerCustomer';
import Popup from '../containers/views/desktop/ModuleWebsite/Popup/Popup';
import EmailTemplate from '../containers/views/desktop/ModuleWebsite/EmailTemplate/EmailTemplate';


class MainRoute extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Router>
            <Switch>

                <Route path='/' exact>
                    <MainLogin>
                        <Switch>
                            <Route path='/' component={Login} />
                        </Switch>
                    </MainLogin>
                </Route>

                <Route path='/forgot-password'>
                    <MainLogin>
                        <Switch>
                            <Route path='/forgot-password' exact component={ForgotPassword} />
                        </Switch>
                    </MainLogin>
                </Route>

                <Route path='/reset-password'>
                    <MainLogin>
                        <Switch>
                            <Route path='/reset-password' exact component={ResetPassword} />
                        </Switch>
                    </MainLogin>
                </Route>


                <Route path='/dashboard'>
                    <MainDashboard>
                        <Switch>
                            <Route path="/dashboard" exact component={(props) => <Dashboard auth={this.props.auth} baseParam={props} />} />
                        </Switch>
                    </MainDashboard>
                </Route>

                <Route path='/user'>
                    <MainAcount>
                        <Switch>
                            <Route path="/user" exact component={(props) => <Account auth={this.props.auth} baseParam={props} />} />
                        </Switch>
                    </MainAcount>
                </Route>



                <Route path='/prod/:path?'>
                    <MainModuleProduct>
                        <Switch>
                        <Route path="/prod" exact component={(props) => <Dashboard auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/brand" exact component={(props) => <Brand auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/categories" exact component={(props) => <Category auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/group-attributes" exact component={(props) => <GroupAttributes auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/attributes" exact component={(props) => <Attributes auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/product" exact component={(props) => <Products auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/tags" exact component={(props) => <Tags auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/custom-fields" exact component={(props) => <CustomField auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/unit" exact component={(props) => <Unit auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/product-type" exact component={(props) => <ProductType auth={this.props.auth} baseParam={props} />} />
                            <Route path="/prod/glass-lens" exact component={(props) => <GlassLensType auth={this.props.auth} baseParam={props} />} />
                            
                        </Switch>
                    </MainModuleProduct>
                </Route>


                <Route path='/website/:path?'>
                    <MainModuleWebsite>
                        <Switch>
                        <Route path="/website" exact component={(props) => <Dashboard auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/banner-position" exact component={(props) => <BannerPosition auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/banner" exact component={(props) => <Banner auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/customer-review" exact component={(props) => <CustomerReview auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/news-category" exact component={(props) => <NewsCategory auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/news" exact component={(props) => <News auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/collection" exact component={(props) => <Collection auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/pages" exact component={(props) => <Pages auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/partner" exact component={(props) => <PartnerCustomer auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/popup" exact component={(props) => <Popup auth={this.props.auth} baseParam={props} />} />
                            <Route path="/website/email_templates" exact component={(props) => <EmailTemplate auth={this.props.auth} baseParam={props} />} />
                        </Switch>
                    </MainModuleWebsite>
                </Route>


                <Route path='/hr/:path?'>
                    <MainModuleHr>
                        <Switch>
                            <Route path="/hr" exact component={(props) => <Dashboard auth={this.props.auth} baseParam={props} />} />
                            <Route path="/hr/role" exact component={(props) => <Role auth={this.props.auth} baseParam={props} />} />
                            <Route path="/hr/permission" exact component={(props) => <Permissions auth={this.props.auth} baseParam={props} />} />
                            <Route path="/hr/employee" exact component={(props) => <Employees auth={this.props.auth} baseParam={props} />} />
                            <Route path="/hr/regency" exact component={(props) => <Regency auth={this.props.auth} baseParam={props} />} />
                            <Route path="/hr/products" exact component={(props) => <Products auth={this.props.auth} baseParam={props} />} />
                        </Switch>
                    </MainModuleHr>
                </Route>

                <Route path='/stores/:path?'>
                    <MainModuleStores>
                        <Switch>
                            <Route path='/stores' exact component={Dashboard} />
                            <Route path='/stores/partner' exact component={Partner} />
                            <Route path='/stores/stores' exact component={Stores} />
                            <Route path='/stores/import' exact component={ImportStock} />
                            <Route path='/stores/export' exact component={ExportStock} />
                            <Route path='/stores/transfer' exact component={TransferStock} />
                            <Route path='/stores/inventory' exact component={InventoryStock} />
                            <Route path='/stores/summary' exact component={SummaryStore} />

                        </Switch>
                    </MainModuleStores>
                </Route>

                <Route path='/sell/:path?'>
                    <MainModuleSell>
                        <Switch>
                        <Route path="/sell" exact component={(props) => <Dashboard auth={this.props.auth} baseParam={props} />} />
                        <Route path="/sell/retail" exact component={(props) => <Retail auth={this.props.auth} baseParam={props} />} />
                        <Route path="/sell/search-bill" exact component={(props) => <SearchBill auth={this.props.auth} baseParam={props} />} />
                        <Route path="/sell/return-order" exact component={(props) => <ReturnOrder auth={this.props.auth} baseParam={props} />} />
                        <Route path="/sell/export" exact component={(props) => <ExportStock auth={this.props.auth} baseParam={props} />} />
                        </Switch>
                    </MainModuleSell>
                </Route>


                <Route path='/marketing/:path?'>
                    <MainModuleCRM>
                        <Switch>
                        <Route path="/marketing" exact component={(props) => <Dashboard auth={this.props.auth} baseParam={props} />} />
                        <Route path="/marketing/customer-group" exact component={(props) => <CustomerGroup auth={this.props.auth} baseParam={props} />} />
                        <Route path="/marketing/customer-type" exact component={(props) => <CustomerType auth={this.props.auth} baseParam={props} />} />
                        <Route path="/marketing/customer" exact component={(props) => <Customer auth={this.props.auth} baseParam={props} />} />
                        <Route path="/marketing/newsletter" exact component={(props) => <Newsletter auth={this.props.auth} baseParam={props} />} />
                        <Route path="/marketing/coupons" exact component={(props) => <Coupon auth={this.props.auth} baseParam={props} />} />
                        <Route path="/marketing/promotion" exact component={(props) => <Promotion auth={this.props.auth} baseParam={props} />} />
                        </Switch>
                    </MainModuleCRM>
                </Route>


            </Switch>
        </Router>
    }
}

export default MainRoute;