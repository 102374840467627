import Libs from '../utils/Libs';
import CMSHttp from '../utils/CMSHttp';
import Constants from '../utils/Constants';

export default class OrdersService {
    static _instance;
    static get instance() {
        if (!this._instance) {
            this._instance = new OrdersService();
        }
        return this._instance;
    }

    /**
   * Get dropdown list
   * @author Long.Pham
   * @param {function (data,total_row,msg)} callBack 
   * @param {boolean} isShowProgress 
   */
    getSearchProductAuto(objE, callBack) {
        try {
            var http = new CMSHttp(false);
            http.post(Constants.URL.ORDERS.SEARCH_PRODUCT_AUTO, objE, function (status, rs) {
                if (typeof callBack === 'function') {
                    if (!status || !rs) {
                        return;
                    }
                    var data = [];
                    var total_row = 0;
                    if (rs.status && Array.isArray(rs.data)) {
                        data = rs.data;
                        total_row = rs.total_row;
                    }
                    callBack(data, total_row, "");
                }
            });
        } catch (error) {
            var msg = error;
            callBack(false, 0, msg);
        }
    }

    /**
     * API call for save data
     * @author long.pham 2018-07-27
     * @param {Function} callBack
     * @param {boolean} isShowProgress
     */
    save(objE, callBack) {
        try {
            var http = new CMSHttp(true);
            http.post(Constants.URL.ORDERS.SAVE, objE, function (status, rs) {
                if (typeof callBack === 'function') {
                    if (!status || !rs) {
                        return;
                    }
                    var data = null;
                    var msg = rs.mess;
                    if (!Libs.isObjectEmpty(rs.data)) {
                        data = rs.data;
                    }
                    callBack(rs.status, data, msg);
                }
            })
        } catch (error) {
            callBack(false, null, error);
        }
    }




    /**
     * @author long.pham 2018-07-27
     * @param  {function(data,total_row,msg)} callBack
     * @param  {Boolean} isShowProgress
     */
    getList(objE, callBack) {
        try {
            var http = new CMSHttp(true);
            http.post(Constants.URL.ORDERS.LIST, objE, function (status, rs) {
                if (typeof callBack === 'function') {
                    if (!status || !rs) {
                        //lỗi do http nên không làm gì vì đã có http thư viện xử lý
                        return;
                    }
                    var data = [];
                    var total_row = 0;
                    if (rs.status && Array.isArray(rs.data)) {
                        data = rs.data;
                        total_row = rs.total_row;
                    }
                    callBack(data, total_row, "");
                }
            });
        } catch (error) {
            var msg = error;
            callBack(false, 0, msg);
        }
    }


    /**
     * API get detail
     * @author Long.Pham 2019-05-28
     * @param {function(data)} callBack
     * @param {boolean} isShowProgress 
     */

    getDetail(objE, callBack) {
        try {
            var http = new CMSHttp(true);
            http.post(Constants.URL.ORDERS.DETAIL, objE, function (status, rs) {
                if (typeof callBack === 'function') {
                    if (!status || !rs) {
                        //error http => lib http  proccess
                        return;
                    }
                    var data = {};
                    if (rs.status && typeof rs.data === 'object') {
                        data = rs.data;
                    }
                    callBack(data);
                }
            })
        } catch (error) {
            callBack({});
        }
    }


    /**
     * Gọi API in 
     * @author Long.Pham
     * @param  {ImportStoresEntity} objE
     * @param  {boolean} isShowProgress
     */
     print(objE) {
        try {
            var http = new CMSHttp(true);
            http.postPrint(Constants.URL.ORDERS.PRINT, objE);
        } catch (error) {
            console.log('OrdersService.print:', error);
        }
    }

    // /**
    //   * API call for update Role status
    //   * @author long.pham
    //   * @param {function(status,msg)} callBack 
    //   * @param  {boolean} isShowProgress
    //   */
    // updateStatus(objE, callBack) {
    //     try {
    //         var http = new CMSHttp(false);
    //         http.post(Constants.URL.PRODUCT.UPDATE_STATUS, objE, function (status, rs) {
    //             if (typeof callBack === 'function') {
    //                 if (!status || !rs) {
    //                     return;
    //                 }
    //                 var msg = rs.mess;
    //                 callBack(rs.status, msg);
    //             }
    //         })
    //     } catch (error) {
    //         callBack(false, error);
    //     }
    // }








    // /**
    //  * API call for save data
    //  * @author long.pham 2018-07-27
    //  * @param {Function} callBack
    //  * @param {boolean} isShowProgress
    //  */
    // //  updateRoleMapScreen(objE, callBack) {
    // //     try {
    // //         var http = new CMSHttp(true);
    // //         http.post(Constants.URL.ROLE.UPDATE_ROLE_MAP_SCREEN, objE, function (status, rs) {
    // //             if (typeof callBack === 'function') {
    // //                 if (!status || !rs) {
    // //                     return;
    // //                 }
    // //                 var data = null;
    // //                 var msg = rs.mess;
    // //                 if (!Libs.isObjectEmpty(rs.data)) {
    // //                     data = rs.data;
    // //                 }
    // //                 callBack(rs.status, data, msg);
    // //             }
    // //         })
    // //     } catch (error) {
    // //         callBack(false, null, error);
    // //     }
    // // }

    // /**
    //   * API call for delete
    //   * @author long.pham
    //   * @param {function(status,msg)} callBack 
    //   * @param  {boolean} isShowProgress
    //   */
    // delete(objE, callBack) {
    //     try {
    //         var http = new CMSHttp(false);
    //         http.post(Constants.URL.PRODUCT.DELETE, objE, function (status, rs) {
    //             if (typeof callBack === 'function') {
    //                 if (!status || !rs) {
    //                     return;
    //                 }
    //                 var msg = rs.mess;
    //                 callBack(rs.status, rs.data, msg);
    //             }
    //         })
    //     } catch (error) {
    //         callBack(false, error);
    //     }
    // }





    /**
    * Get dropdown list
    * @author Long.Pham
    * @param {function (data,total_row,msg)} callBack 
    * @param {boolean} isShowProgress 
    */
    // getDropdownList(objE, callBack) {
    //     try {
    //         var http = new CMSHttp(false);
    //         http.post(Constants.URL.PRODUCT.DROPDOWN_LIST, objE, function (status, rs) {
    //             if (typeof callBack === 'function') {
    //                 if (!status || !rs) {
    //                     return;
    //                 }
    //                 var data = [];
    //                 var total_row = 0;
    //                 if (rs.status && Array.isArray(rs.data)) {
    //                     data = rs.data;
    //                     total_row = rs.total_row;
    //                 }
    //                 callBack(data, total_row, "");
    //             }
    //         });
    //     } catch (error) {
    //         var msg = error;
    //         callBack(false, 0, msg);
    //     }
    // }



    /**
    * Get dropdown list
    * @author Long.Pham
    * @param {function (data,total_row,msg)} callBack 
    * @param {boolean} isShowProgress 
    */
    //  getDropdownListProduct(objE, callBack) {
    //     try {
    //         var http = new CMSHttp(false);
    //         http.post(Constants.URL.PRODUCT.DROPDOWN_LIST_Product, objE, function (status, rs) {
    //             if (typeof callBack === 'function') {
    //                 if (!status || !rs) {
    //                     return;
    //                 }
    //                 var data = [];
    //                 var total_row = 0;
    //                 if (rs.status && Array.isArray(rs.data)) {
    //                     data = rs.data;
    //                     total_row = rs.total_row;
    //                 }
    //                 callBack(data, total_row, "");
    //             }
    //         });
    //     } catch (error) {
    //         var msg = error;
    //         callBack(false, 0, msg);
    //     }
    // }



}
