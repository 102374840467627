
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
import CouponService from '../../../../../services/CouponService';
import TypeService from '../../../../../services/TypeService';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
            allLanguage: this.props.allLanguage,
            dataType: []
        }

        this.jsxTemplate = AddPopupJsx;
        this.handleInputDateChange = this.handleInputDateChange.bind(this);
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
        var curItem = this.state.curItem;
        this.getDataType();
        if (curItem.screen_mode === Constants.SCREEN_MODE.ADD) {
            curItem.status = 1;
        }
    }


    // handleInputItemDateChange(index, event) {
    //     let target = event.target, self = this;
    //     let name = target.name;
    //     let value = target.value;
    //     if (target.type === 'checkbox') {
    //         value = target.checked ? 1 : 0;
    //     }

    //     if (name) {
    //         let { curItem } = this.state;
    //         var nameFromDate = "saleoff_from_" + index;
    //         var nameToDate = "saleoff_to_" + index;

    //         if (name === nameFromDate) {
    //             if (!Libs.isBlank(curItem.saleoff_to) && Libs.compareDate(value, 'DD/MM/YYYY', curItem.saleoff_to) > 0) {
    //                 curItem.saleoff_from = curItem.saleoff_to;
    //             } else {
    //                 curItem.saleoff_from = value;
    //             }
    //         }


    //         if (name === nameToDate) {
    //             if (!Libs.isBlank(curItem.saleoff_from) && Libs.compareDate(curItem.saleoff_from, 'DD/MM/YYYY', value) > 0) {
    //                 curItem.saleoff_to = curItem.saleoff_from;
    //             } else {
    //                 curItem.saleoff_to = value;
    //             }
    //         }
    //         if(!Libs.isBlank(curItem.saleoff_from) && !Libs.isBlank(curItem.saleoff_to)){
    //             curItem.errors.time_range = false;
    //         }
            
    //         this.setState({ curItem: curItem }, () => {
    //             self.props.callBackCloseTimeRange(curItem, index);
    //         });
    //     }
    // }

    /**
     * setValue method to Input
     * @author Long.Pham 20/05/2021
     */
     handleInputDateChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem } = this.state;

            if (name === 'from_date') {
                if (!Libs.isBlank(curItem.to_date) && Libs.compareDate(value, 'DD/MM/YYYY', curItem.to_date) > 0) {
                    curItem.from_date = curItem.to_date;
                } else {
                    curItem.from_date = value;
                }

                curItem.to_min_date = curItem.from_date;
                curItem.to_date = null;
            }

            if (name === 'to_date') {
                if (!Libs.isBlank(curItem.from_date) && Libs.compareDate(curItem.from_date, 'DD/MM/YYYY', value) > 0) {
                    curItem.to_date = curItem.from_date;
                } else {
                    curItem.to_date = value;
                }
            }

            this.setState({ curItem });
        }
    }


    handleDropdownChange = (item, name) => {
        var { t } = this.props;
        if (Libs.isObjectEmpty(item)) return;
        var self = this, value = item.value;
        let curItem = self.state.curItem;

        if (name === 'id_type') {
            curItem.coupon_value = '';
            if (Libs.isObjectEmpty(item)) {
                curItem.id_type = '';
                self.setValidateMessage({ id_type: t('coupon.id_type') }, true);
            } else {
                self.setValidateMessage({ id_type: '' }, true);
            }
        }

        curItem[name] = value;
        self.setState({
            curItem: curItem
        });
    }

    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

     getDataType = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            type: 'type_discount'
        };

        TypeService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataType: data
                });
            }
        })
    }

    onClickShowTab = (e, tabActive) => {
        var curItem = this.state.curItem;
        if (Libs.isBlank(tabActive)) return;
        curItem.tabActive = tabActive;

        this.setState({
            curItem: curItem
        });
    }


    /**
     * @description validate a field input
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description select change
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */

    handleInputTranslateChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            let allLanguage = this.state.allLanguage;
            let isoCode = name.substr(-2, 2),
                dataName = name.substr(0, 4),
                dataDescription = name.substr(0, 11);

            allLanguage.map((language, index) => {
                if (isoCode === language.iso_code && dataName === 'name') {
                    item.data[index].name = (event.target.validity.valid) ? value : this.state.curItem.data[index].name;
                }

                if (isoCode === language.iso_code && dataDescription === 'description') {
                    item.data[index].description = (event.target.validity.valid) ? value : this.state.curItem.data[index].description;
                }
                return 1;
            });

            item[name] = (event.target.validity.valid) ? value : this.state.curItem[name];

            this.setState({ curItem: item });
        }
    }



    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSave() {
        var curItem = this.state.curItem, self = this;
        var params = Object.assign({}, this.state.curItem);
        var screenMode = (!Libs.isBlank(curItem.id)) ? Constants.SCREEN_MODE.EDIT : ((!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD);
        let v = new AddPopupValidate(this.props);
        let errors = await v.FLValidationAll(curItem);
        if (errors) {
            this.setValidateMessage(errors);
            return;
        }
        //remove message validation
        this.removeAllValidateMessage();
        params.screen_mode = screenMode;

        params.id_company = this.employee.id_company;
        params.iso_code_lang = this.employee.lang;
        params.id_language = this.employee.id_language;
        params.iso_code = this.employee.iso_code;
        params.status = 1;

        if (Libs.isArrayData(params.data)) {
            let itemLanguageDefault = Libs.find(params.data, 'is_default', 1);
            if (!itemLanguageDefault) return;

            params.data.map((item, index) => {
                params.data[index].name = (item.name === '') ? itemLanguageDefault.name : item.name;
                params.data[index].description = (item.description === '') ? itemLanguageDefault.description : item.description;
                return params;

            });
        } else { return false; }

        CouponService.instance.save( params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onClickCloseAdd(true);
            }
            else if (data) {
                self.setValidateMessage(data);
            }

            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }
}
export default AddPopup;