import React from 'react';
import { NavLink } from 'react-router-dom';
import MainMennu from './MainMenu';
import Libs from '../../../utils/Libs';

export default function Header() {
    const { t, i18n } = this.props;
    var { menuProfile, employee } = this.state;

    var languages = employee.languages;
    var RowLanguage = null;
    var LanguageActive = null;

    if (Libs.isArrayData(languages)) {
        var itemLang = Libs.find(languages, 'is_default', 1);
        if (!Libs.isObjectEmpty(itemLang)) {
            LanguageActive = <NavLink to="/" className="text-uppercase" onClick={(e) => { this.setMenuProfile(e, 'language'); e.preventDefault(); }}><img src={"/" + itemLang.icon} alt={itemLang.name} />{itemLang.iso_code}</NavLink>
        }

        RowLanguage = languages.map((item, index) => {
            return <NavLink to="/" key={index} activeClassName={item.is_default === 1 ? "active-lang" : ""} onClick={(e) => { i18n.changeLanguage(item.iso_code); this.setMenuProfile(e, ''); this.setLanguage(e, item.iso_code); e.preventDefault(); }} ><img src={"/" + item.icon} alt={item.name} /> {item.name} </NavLink>
        })
    }


    var layoutMainMenu = this.state.main_menu ?
        <MainMennu
            onClickCloseMainMenu={this.onClickCloseMainMenu}
            t={t}
        /> : "";
    return (
        <div className="header">
            {layoutMainMenu}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2">
                        <div className="logo">
                            <span onClick = {this.menuCollapseSidebar.bind(this)} className= {!this.state.menu_collapse ? "icon-collapse-next" : "icon-collapse-prev" }></span>
                            <img src="/logo.png" alt="Logo" />
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="menu-top">
                            <ul ref={this.wrapperRef}>
                                <li>
                                    <NavLink onClick={(e) => { this.onClickShowMainMenu(); e.preventDefault(); }} className="round" to="/"><span className="icon icon-thumbnails"></span></NavLink>
                                </li>

                                <li>
                                    <NavLink onClick={(e) => { e.preventDefault() }} className="avatar" to="/">
                                        <span>
                                            {employee.avatar !== null && Libs.checkImageExists( employee.avatar ) ? <img src={ employee.avatar} alt="Avatar" /> : <var className="icon-man-people-streamline-user"></var>}
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="username">
                                    <NavLink onClick={(e) => { e.preventDefault() }} className="avatar" to="/">{employee.full_name}</NavLink>
                                </li>

                                {/* <li>
                                    <NavLink className="round" to="/"><span className="icon icon-alert"></span><var className="notification">5</var></NavLink>
                                </li> */}
                                <li>
                                    <NavLink onClick={(e) => { this.setMenuProfile(e, 'setup'); e.preventDefault(); }} className="round" to="#"><span className="icon icon-settings-streamline-1"></span></NavLink>
                                    {menuProfile === 'setup' ?
                                        <ul className="on">
                                            <li>
                                                <div className="item">
                                                    <div className="main-item">
                                                        <div className="header-avatar">
                                                            {employee.avatar !== null && Libs.checkImageExists(employee.avatar) ? <img src={employee.avatar} alt="Avatar" /> : <var className="icon-man-people-streamline-user"></var>}
                                                        </div>

                                                        <div className="channel-container">
                                                            <p><strong>{employee.full_name}</strong></p>
                                                            <p><NavLink to="/user">Quản lý tài khoản</NavLink></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="item-group">
                                                    <NavLink to="/documents"><span className="icon icon-book"></span> Tài liệu hướng dẫn</NavLink>
                                                    <NavLink to="/" onClick={this.logout.bind(this)} ><span className="icon icon-logout"></span> Thoát khỏi tài khoản</NavLink>
                                                </div>
                                            </li>
                                        </ul>
                                        : ""}
                                </li>
                                <li>
                                    {LanguageActive}
                                    {menuProfile === 'language' && Libs.isArrayData(languages) && languages.length > 1 ?
                                        <ul className="on language">
                                            <li>
                                                <div className="item-group">
                                                    {RowLanguage}
                                                </div>
                                            </li>
                                        </ul>
                                        : ''}

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};