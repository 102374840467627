
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
import CustomerService from '../../../../../services/CustomerService';
import ProvinceService from '../../../../../services/ProvinceService';
import DistrictService from '../../../../../services/DistrictService';
import WardService from '../../../../../services/WardService';
import CustomerGroupService from '../../../../../services/CustomerGroupService';
import CustomerTypeService from '../../../../../services/CustomerTypeService';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
            dataProvince: [],
            dataDistrict: [],
            dataWard: [],
            dataGender: [
                { id: 1, value: 1, label: "Nam" },
                { id: 2, value: 2, label: "Nữ" },
                { id: 3, value: 3, label: "Khác" }
            ],
            dataCustomerType: [],
            dataCustomerGroup: []
        }

        this.jsxTemplate = AddPopupJsx;
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
        var curItem = this.state.curItem;
        this.getProvince();
        this.getDropdownCustomerGroup();
        this.getDropdownCustomerType();
        if (curItem.screen_mode === Constants.SCREEN_MODE.ADD) {
            curItem.status = 1;
        }
        this.setState({
            curItem: curItem
        })
    }

    handleInputChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem } = this.state;
            curItem[name] = value;
            this.setState({ curItem });
        }
    }

    /**
     * @description get customer type
     * @author long.pham 26/07/2019
     */

    getDropdownCustomerType = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        CustomerTypeService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataCustomerType: data
                });
            }
        })
    }

    /**
     * @description get customer group
     * @author long.pham 26/07/2019
     */

    getDropdownCustomerGroup = () => {
        var self = this;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language
        };

        CustomerGroupService.instance.getDropdownList(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataCustomerGroup: data
                });
            }
        })
    }

    /**
   * @description get province
   * @author long.pham 26/07/2019
   */

    getProvince() {
        let self = this;
        ProvinceService.instance.getList({}, function (data, totalRow) {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataProvince: data,
                    dataDistrict: [],
                    dataWard: []
                }, () => {
                    if (!Libs.isBlank(self.state.curItem.id_province)) {
                        self.getDistrictByIdProvince(self.state.curItem.id_province);
                    }
                });
            }
            else {
                self.setState({
                    dataProvince: [],
                    dataDistrict: [],
                    dataWard: []
                });
            }
        }, false);
    }



    /**
     * @description get district by id_province
     * @author long.pham 26/07/2019
     */

    getDistrictByIdProvince(idProvince) {
        let self = this;
        if (Libs.isBlank(idProvince)) { return false; }
        let params = {
            id_province: idProvince
        };
        DistrictService.instance.getByIdProvince(params, function (data, totalRow) {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataDistrict: data,
                    dataWard: [],
                }, () => {
                    if (!Libs.isBlank(self.state.curItem.id_district)) {
                        self.getWardByIdDistrict(self.state.curItem.id_district);
                    }
                });
            }
            else {
                self.setState({
                    dataDistrict: [],
                    dataWard: []
                });
            }
        }, false);
    }




    /**
   * @description get ward by id_district
   * @author long.pham 26/07/2019
   */

    getWardByIdDistrict(idDistrict) {
        let self = this;
        if (Libs.isBlank(idDistrict)) { return false; }
        let params = {
            id_district: idDistrict
        };

        WardService.instance.getByIdDistrict(params, function (data, totalRow) {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataWard: data
                });
            }
            else {
                self.setState({
                    dataWard: []
                });
            }
        }, false);
    }

    handleDropdownChange = (item, name) => {
        var { t } = this.props;
        if (Libs.isObjectEmpty(item)) return;
        var self = this, value = item.value;
        let curItem = self.state.curItem;
        if (name === 'id_province') {
            self.getDistrictByIdProvince(value);
            curItem.id_district = null;
            curItem.id_ward = null;
        }

        if (name === 'id_district') {
            self.getWardByIdDistrict(value);

            curItem.id_ward = null;
        }

        if (name === 'id_customer_group') {
            if (Libs.isObjectEmpty(item)) {
                curItem.id_customer_group = '';
                self.setValidateMessage({ id_customer_group: t('customer.id_customer_group') }, true);
            } else {
                self.setValidateMessage({ id_customer_group: '' }, true);
            }
        }

        if (name === 'id_customer_type') {
            if (Libs.isObjectEmpty(item)) {
                curItem.id_customer_type = '';
                self.setValidateMessage({ id_customer_type: t('customer.id_customer_type') }, true);
            } else {
                self.setValidateMessage({ id_customer_type: '' }, true);
            }
        }




        curItem[name] = value;
        self.setState({
            curItem: curItem
        });
    }


    deleteThumbnail() {
        var { curItem } = this.state;
        curItem.upload_thumbnail = '';
        curItem.upload_thumbnail_name = '';
        this.setState({
            curItem: curItem
        });
    }
    /**
     * @description Upload thumbnail
     * @author Long.Pham 12/05/2021
     */
    onUploadThumbnailChange = (event) => {

        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgError = null;
        if (!checkExtFile) {
            msgError = t('message.msg_err_ext_image_file');
        }
        else if (fileSize <= 0) {
            msgError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgError = t('message.msg_err_max_size_image_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgError)) {
            self.toast(msgError, 'error');
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            curItem.upload_thumbnail = e.target.result;
            curItem.upload_thumbnail_name = fileName;
            self.setValidateMessage({ upload_thumbnail: '' }, true);
            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }


    generatePassword() {
        var curItem = this.state.curItem;
        var password = Libs.generateStrRandom(2, 2, 2, 2);
        curItem.password = password;
        curItem.password_confirm = password;
        this.setState({ curItem: curItem });
        this.setValidateMessage({ password: '' }, true);
        this.setValidateMessage({ password_confirm: '' }, true);
    }

    /**
     * @description validate a field input
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */


    async validateOne(event) {
        var { t } = this.props;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        var curItem = this.state.curItem;
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            const regexp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

            if (name === 'password') {
                if (value.length < 8 || regexp.exec(value) === null) {
                    error = { password: t('employee.password_valid_min_8character') };
                } else {
                    error = { password: '' };
                }
            }

            if (name === 'password_confirm' && Libs.isBlank(error.password_confirm)) {
                if (curItem.password !== value) {
                    error = { password_confirm: t('employee.password_incorrect') };
                } else {
                    error = { password_confirm: '' };
                }
            }

            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }




    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSave() {
        var curItem = this.state.curItem, self = this;
        var params = Object.assign({}, this.state.curItem);
        var screenMode = (!Libs.isBlank(curItem.id)) ? Constants.SCREEN_MODE.EDIT : ((!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD);
        let v = new AddPopupValidate(this.props, screenMode, curItem.password);
        let errors = await v.FLValidationAll(curItem);
        if (errors) {
            this.setValidateMessage(errors);
            return;
        }
        //remove message validation
        this.removeAllValidateMessage();
        params.screen_mode = screenMode;
        params.password = Libs.SHA3(curItem.password);
        delete params.password_confirm;

        params.id_company = this.employee.id_company;
        params.iso_code_lang = this.employee.lang;
        params.id_language = this.employee.id_language;
        params.iso_code = this.employee.iso_code;
        params.config_thumb_folder_new = this.employee.config_thumb_folder_new;
        params.config_thumb_folder_pro = this.employee.config_thumb_folder_pro;
        params.config_thumb_new_h = this.employee.config_thumb_new_h;
        params.config_thumb_pro_h = this.employee.config_thumb_pro_h;
        params.config_thumb_pro_w = this.employee.config_thumb_pro_w;
        params.config_cdn = this.employee.config_cdn;

        CustomerService.instance.save(params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onClickCloseAdd(true);
            }
            else if (data) {
                self.setValidateMessage(data);
            }

            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }
}
export default AddPopup;