
// import ReactDOM from 'react-dom';
import BaseComponent from '../../../../BaseComponent';
import Constants from '../../../../../utils/Constants';
import Libs from '../../../../../utils/Libs';
import SummaryStoreJsx from './SummaryStore.jsx';
import { withTranslation } from 'react-i18next';
import './SummaryStore.scss';
// import SummaryStoreService from '../../../../services/SummaryStoreService';
// import StoresService from '../../../../services/StoresService';
// import CategoryService from '../../../../services/CategoryService';
// import ProductService from '../../../../services/ProductService';
class SummaryStore extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = SummaryStoreJsx;
        this.state = {
            curItem: {},
            formSearch: false,
            dataList: [
                {product_name: "Sản phẩm 1", quantity_store_first: 100, quantity_store_import: 100, quantity_store_export: 200, quantity_store_last: 400},
                {product_name: "Sản phẩm 1", quantity_store_first: 100, quantity_store_import: 100, quantity_store_export: 200, quantity_store_last: 400},
                {product_name: "Sản phẩm 1", quantity_store_first: 100, quantity_store_import: 100, quantity_store_export: 200, quantity_store_last: 400},
                {product_name: "Sản phẩm 1", quantity_store_first: 100, quantity_store_import: 100, quantity_store_export: 200, quantity_store_last: 400},
                {product_name: "Sản phẩm 1", quantity_store_first: 100, quantity_store_import: 100, quantity_store_export: 200, quantity_store_last: 400},
                {product_name: "Sản phẩm 1", quantity_store_first: 100, quantity_store_import: 100, quantity_store_export: 200, quantity_store_last: 400},
                {product_name: "Sản phẩm 1", quantity_store_first: 100, quantity_store_import: 100, quantity_store_export: 200, quantity_store_last: 400},
                {product_name: "Sản phẩm 1", quantity_store_first: 100, quantity_store_import: 100, quantity_store_export: 200, quantity_store_last: 400}
            ],
            dataStores: [],
            allCategory: [],
            searchParam: {
                index: 1,
                offset: 0,
                limit: Constants.COMMON.PER_PAGE,
                id_store: "",
                id_category: "",
                from_date: Libs.getCurrentDDMMYYYY(),
                to_date: Libs.getCurrentDDMMYYYY(),
                sortColumn: "",
                sortOrder: ""
            },
            total_row: 0,
            showSummaryStorePopup: false,
            activeId: null
        };
        //pagging info
        this.paging = {
            total: 100,
            current: 1
        };
        //Check the status is in the component or exit the component
        this._isMounted = true;
        this.setActiveElement = this.setActiveElement.bind(this);
        this.inputChangedHandler = this.inputChangedHandler.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearchDateChange = this.handleSearchDateChange.bind(this);
    }



    handleInputChange(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name) {
            let params = self.state.searchParam;
            params[name] = (event.target.validity.valid) ? value : params[name];
            self.setState({
                searchParam: params
            });
        }
    }

    /**
    * Func filter table
    * @author long.pham 28/07/2019
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sortColumn = sortKey;
        this.state.searchParam.sortOrder = (this.state.searchParam.sortOrder == '' || this.state.searchParam.sortOrder == 'asc') ? 'desc' : 'asc';
        this.forceUpdate();
        this.getList();
    }

    /**
     * Func search
     * @author long.pham 28/07/2019
     * @param  {Object} e
     */

    handleSearch() {
        this.getList();
    }

    /**
     * Xử lý set state giá trị search từ ngày tới ngày
     * @author long.pham 28/07/2019
     * @param  {Object} e
     */
    handleSearchDateChange(e) {
        // let self = this;
        // let target = e.target,
        //     value = target.value,
        //     name = target.name,
        //     item = self.state.searchParam,
        //     max_message = trans.translate("validate_rule.max"),
        //     from_date_label = trans.translate("feeHospital.from_date"),
        //     to_date_label = trans.translate("feeHospital.to_date"),
        //     message = Libs.stringAssign(max_message, ["", from_date_label, to_date_label]);
        // if (name) {
        //     let date1 = Libs.convertStr2Date(value, Constants.COMMON.DATE_FORMAT, '/');
        //     if (name == 'from_date') {
        //         let date2 = Libs.convertStr2Date(item['to_date'], Constants.COMMON.DATE_FORMAT, '/');
        //         if (date1 > date2) {
        //             this.toast(message, "error");
        //             value = item['from_date'];
        //         }
        //     } else if (name == 'to_date') {
        //         let date2 = Libs.convertStr2Date(item['from_date'], Constants.COMMON.DATE_FORMAT, '/');
        //         if (date1 < date2) {
        //             this.toast(message, "error");
        //             value = item['to_date'];
        //         }
        //     }

        //     item[name] = value;
        //     console.log("date: ", item);
        //     this.setState({ searchParam: item });
        // }
    }


    setActiveElement(id) {
        this.setState({ activeId: id });
    }


    componentDidMount() {
        // super.componentDidMount();
        // var self = this;
        // self.getList();
        // self.getAllStores();
        // self.getAllCategory();
        // document.addEventListener('click', this.handleClickOutside, true);
    }

    /**
     * @description Called before the component is about to be removed from the DOM
     * @author long.pham 27/07/2019
     */
    componentWillUnmount() {
        // this._isMounted = false;
        // document.removeEventListener('click', this.handleClickOutside, true);
    }

    // handleClickOutside = event => {
    //     const domNode = ReactDOM.findDOMNode(this);
    //     if (!domNode || !domNode.contains(event.target)) {
    //         this.setState({ activeId: null });
    //     }
    // }

    render() {
        return this.jsxTemplate.call(this)
    }

    addInventoryStockSearch() {
        let formSearch = (this.state.formSearch == false) ? true : false;
        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * @description Select page in pagging
     * @author long.pham 27/07/2019
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;
        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        self.getList();
    }

    /**
     * @description input text change page
     * @author long.pham 27/07/2019
     * @param {int} index
     */

    onInputPage(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name == 'current') {
            if (!Libs.isNumber(value)) {
                self.toast('abc', "info");
            }

        }
    }



    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name == 'current') {
            if (value != '') {
                if (!Libs.isNumber(value)) {
                    // self.toast(trans.translate('message.page_is_number'), "error");
                    return;
                }
            }

            if (value > this.pagging.total) {
                value = self.pagging.total;
            }

            self.pagging.current = value;
            this.onSelectPage(value);
            self.forceUpdate();


        }
    }



    /**
     * get moderation list
     * @author Minh.Pham 2019-06-03
     */
    getList() {
        // let self = this;
        // SummaryStoreService.instance.getList(self.state.searchParam, (data, total_row) => {
        //     // console.log("data", data, total_row);
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             dataList: data
        //         });

        //         var total = parseInt(total_row / self.state.searchParam.limit);
        //         if (total_row % self.state.searchParam.limit > 0) {
        //             total = total + 1;
        //         }
        //         self.pagging.total = total;
        //         self.pagging.current = self.state.searchParam.index;
        //         self.state.total_row = total_row;
        //         this.forceUpdate();
        //     }
        // })
    }



    /**
     * @description Item click event detail InventoryStock
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClickDetail = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        let curItem = this.state.curItem;
        curItem.screen_mode = Constants.SCREEN_MODE.VIEW;
        curItem.data = this.state.dataList[index];
        this.setState({
            curItem: curItem,
            showSummaryStorePopup: true,
        });
    }


    /**
     * @description Show user popup
     * @author long.pham 28/07/2019
    */
    onAddInventoryStockPopup() {
        let self = this;
        self.getAllStores();
        self.getAllProducts();
        this.setState({
            curItem: { screen_mode: Constants.SCREEN_MODE.ADD, id_store: "" },
            showAddInventoryStockPopup: true
        });
    }

    /**
     * @description Close InventoryStock popup
     * @author long.pham 26/07/2019
     */
    onCloseSummaryStorePopup = (status, item) => {
        this.setState({
            showSummaryStorePopup: false
        });
    }



    /**
     * @description Close InventoryStock popup delete
     * @author long.pham 26/07/2019
     */
    onCloseDeleteInventoryStockPopup = (status, item) => {
        // console.log('item: ', item);
        if (status) {
            if (!item) return;
            var { curItem } = this.state;
            curItem = item;
            this.setState({
                curItem
            });
            this.getList();
        }
        this.setState({
            showDeleteInventoryStockPopup: false
        });
    }


    /**
     * @description get all store
     * @author long.pham 26/07/2019
     */

    getAllStores() {
        // let self = this;
        // StoresService.instance.getAll(function (data, totalRow) {
        //     if (!self._isMounted) return;
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allStores: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allStores: []
        //         });
        //     }
        // }, false);
    }

    /**
     * @description get all store
     * @author long.pham 26/07/2019
     */

    getAllCategory() {
        // let self = this;
        // CategoryService.instance.getAllCategoryParent(function (data, totalRow) {
        //     if (!self._isMounted) return;
        //     if (Libs.isArrayData(data)) {
        //         self.setState({
        //             allCategory: data
        //         });
        //     }
        //     else {
        //         self.setState({
        //             allCategory: []
        //         });
        //     }
        // }, false);
    }


    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name === 'current') {
            if (value !== '') {
                var { t } = this.props;
                if (!Libs.isNumber(value)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (value > this.paging.total) {
                value = self.paging.total;
            }

            self.paging.current = value;
            this.onSelectPage(value);
            self.forceUpdate();
        }

        if (name === 'limit') {
            var { searchParam } = this.state;
            searchParam.limit = value;
            this.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            })
        }
    }


    /**
     * @description Select page in pagging
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;
        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        // self.getList();
    }


    /**
     * @description reload data
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onClickReload() {
        // let self = this;
        // self.getList();
    }

    /**
    * Func filter table
    * @author Long.Pham 12-05-2021
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sort_column = sortKey;
        this.state.searchParam.order_by = (this.state.searchParam.order_by === '' || this.state.searchParam.order_by === 'asc') ? 'desc' : 'asc';
        this.forceUpdate();
        // this.getList();
    }

    /**
     * @description Call form search
     * @author Long.Pham 12/05/2021
     */
    onSearch() {
        let self = this;
        let formSearch = (this.state.formSearch === false) ? true : false;
        if (this.state.formSearch === true) {
            let searchParam = this.state.searchParam;
            searchParam.name = '';
            searchParam.index = 1;
            searchParam.offset = 0;
            self.paging.current = 1;
            self.paging.total = 1;
            self.setState({
                searchParam: searchParam
            }, () => {
                // self.getList();
            });
        }

        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * Func search
     * @author Long.Pham 12/05/2021
     * @param  {Object} e
     */
    handleSearch() {
        // this.getList();
    }

}

const HighOrderComponentTranslated = withTranslation('common')(SummaryStore)
export default HighOrderComponentTranslated;