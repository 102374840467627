import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate{
    constructor(props){
        super();
        var {t} = props;
        this.trans = t;
    }
    setRule(){
        this.addRuleForField('iso_code', 'trim', true);
        this.addRuleForField('iso_code', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('iso_code', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('id_type', 'trim', true);
        this.addRuleForField('id_type', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('id_type', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('id_stores', 'trim', true);
        this.addRuleForField('id_stores', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('id_stores', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('output_dates', 'trim', true);
        this.addRuleForField('output_dates', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('output_dates', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));
    }
    setAlias(){
        this.v.setAlias({
            iso_code: this.trans('EXPORT_STOCK.id_code'),
            id_type: this.trans('EXPORT_STOCK.id_type_voucher'),
            output_dates: this.trans('EXPORT_STOCK.date'),
            id_stores: this.trans('EXPORT_STOCK.id_stores')
        });
        
    }
}
export default AddPopupValidate;