import React from 'react';
import RowItem from './RowItem';
import { Paging } from '../../../../../components/Paging';
import AddPopup from './AddPopup';
import { RText } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';

export default function PopupJsx() {
    const { t } = this.props;
    var { curItem, dataList, allLanguage, searchParam } = this.state;
    var RowItems = null;
    RowItems = dataList.map((item, index) => {
        return <RowItem
            key={'row_item_' + index}
            index={index}
            dataItem={item}
            onItemClick={this.onItemClick}
            actions={this.actions}
            t={t}
        />
    });

    var AddPopupLayout = this.state.add
        ? <AddPopup
            t={t}
            allLanguage = {allLanguage}
            curItem = {curItem}
            onClickCloseAdd = {this.onClickCloseAdd}
            auth={this.props.auth}
            actions={this.actions}
            iso_code_language = {this.employee.lang}
        /> : '';

    return (
        <div className="position">
            {AddPopupLayout}
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-md-6"><h2>{t('popup.title')}</h2></div>
                </div>
            </div>

            <div className="data-view">
                <div className="main-header">
                    <div className="header-row">
                        <div onClick={e => this.onSort(e, 'id')} className= {searchParam.sort_column === "id" ? "header-col width10 sorting " + searchParam.order_by : "header-col width10 sorting"}><p>{t('common.id')}</p></div>
                        <div onClick={e => this.onSort(e, 'name')} className= {searchParam.sort_column === "name" ? "header-col width40 sorting " + searchParam.order_by : "header-col width40 sorting"}><p>{t('popup.name')}</p></div>
                        <div className="header-col width10 text-end"><strong>{t('common.action')}</strong></div>
                    </div>
                </div>
                <div className="main-body">
                    <div className="body">
                        {!Libs.isBlank(RowItems) ? RowItems : <div className="data-empty">{t('common.data_empty')}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};