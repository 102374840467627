import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
// import Libs from '../../../utils/Libs';
// import { RSwitch } from '../../../../components/Controls';
// import Libs from '../../../../utils/Libs';
class RowItem extends Component {
    constructor(props) {
        super(props);
    }
    /**
     * @description Item click event delete
     * @author long.pham 27-07-2019
     */
    onItemClickDetail = () => {
        if (!this.props.onItemClickDetail || typeof this.props.onItemClickDetail !== 'function') return;
        this.props.onItemClickDetail(this.props.index);
    }



    render() {
        var {t} = this.props;
        var item = this.props.dataItem;
        return (
            <div className="body-row" key = {item.id}>
                <ReactTooltip />
                <div className="body-col width20"><p>{item.product_name} </p></div>
                <div className="body-col width15"><p>{item.quantity_store_first} </p></div>
                <div className="body-col width15"><p>{item.quantity_store_import} </p></div>
                <div className="body-col width15"><p>{item.quantity_store_export}</p></div>
                <div className="body-col width15"><p>{item.quantity_store_last}</p></div>
                <div className="body-col width10">
                    <div className="functions">
                        <ul>
                            {/* <li onClick = {this.onItemClickDetail.bind(this)} data-tip= {t('common.edit')} data-type="warning" data-class="tooltip-action"><var className="icon-pencil-square-o" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDetail.bind(this)} data-tip= {t('common.delete')} data-type="warning" data-class="tooltip-action"><var className="icon-trash-o" aria-hidden="true"></var></li> */}
                            <li onClick = {this.onItemClickDetail.bind(this)} data-tip= {t('common.view')} data-type="warning" data-class="tooltip-action"><var className="icon-eye" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDetail.bind(this)} data-tip= {t('common.excel')} data-type="warning" data-class="tooltip-action"><var className="icon-page-export-csv" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDetail.bind(this)} data-tip= {t('common.pdf')} data-type="warning" data-class="tooltip-action"><var className="icon-file-text-o" aria-hidden="true"></var></li>
                            <li onClick = {this.onItemClickDetail.bind(this)} data-tip= {t('common.print')} data-type="warning" data-class="tooltip-action"><var className="icon-print" aria-hidden="true"></var></li>
                        </ul>
                    </div>
                </div>
            </div>

            // <div className={item.is_delete == 1 ? "body-item is_delete" : "body-item"} key={item.id}>
            //     <div className="body-col width20"><p>{item.product_name}</p></div>
            //     <div className="body-col width10"><p>{item.unit}</p></div>
            //     <div className="body-col width15"><p>{item.quantity_store_first}</p></div>
            //     <div className="body-col width15"><p>{item.quantity_store_import}</p></div>
            //     <div className="body-col width15"><p>{item.quantity_store_export}</p></div>
            //     <div className="body-col width15"><p>{item.quantity_store_last}</p></div>
            //     <div className="body-col width10">
            //         <div className="function">
            //             <p onClick={() => this.props.setActiveElement(this.props.index)}><i className="fa fa-ellipsis-h" aria-hidden="true"></i></p>
            //             <ul className={this.props.index === this.props.activeId ? "on" : ""}>
            //                 <li><a onClick={this.onItemClickDetail.bind(this)}><var className="fa fa-eye" aria-hidden="true"></var> Chi tiết</a></li>
            //                 <li><a onClick={this.onItemClickDetail.bind(this)}><var className="fa fa-file-excel-o" aria-hidden="true"></var> Xuất Excel</a></li>
            //             </ul>
            //         </div>

            //     </div>
            // </div>
        );
    }
}
export default RowItem;
