import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate{
    constructor(props){
        super();
        var {t} = props;
        this.trans = t;
    }
    setRule(){
        this.addRuleForField('id_supplier', 'trim', true);
        this.addRuleForField('id_supplier', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('id_supplier', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('iso_code', 'trim', true);
        this.addRuleForField('iso_code', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('iso_code', 'maxLength', 40, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('id_stores', 'trim', true);
        this.addRuleForField('id_stores', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('id_stores', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));

        this.addRuleForField('import_date', 'trim', true);
        this.addRuleForField('import_date', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('import_date', 'maxLength', 20, this.trans('validate_rule.maxLength_input'));
    }
    setAlias(){
        this.v.setAlias({
            id_supplier: this.trans('Warehousing.id_supplier'),
            iso_code: this.trans('Warehousing.id_code'),
            id_stores: this.trans('Warehousing.id_stores'),
            import_date: this.trans('Warehousing.date')
        });
        
    }
}
export default AddPopupValidate;