
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
            allLanguage: this.props.allLanguage,
            dataEmployees: [
                { id: 1, value: 1, label: "Phạm Văn Long (Nhân viên) - Phòng: Kinh Doanh" },
                { id: 2, value: 2, label: "Lê Văn Tuấn (Nhân viên) - Phòng: Kinh Doanh"},
                { id: 3, value: 3, label: "Nguyễn Thị Loan (Nhân viên) - Phòng: Kinh Doanh"},
                { id: 4, value: 4, label: "Trần Thị Thanh Tâm (Nhân viên) - Phòng: Kinh Doanh" },
                { id: 5, value: 5, label: "Nguyễn Trà My (Nhân viên) - Phòng: Kinh Doanh" },
                { id: 6, value: 6, label: "Hoàng Tuấn Đạt (Nhân viên) - Phòng: Kinh Doanh"},
            ],
        }

        this.jsxTemplate = AddPopupJsx;
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
    }

    onClickShowTab = (e, tabActive) => {
        var curItem = this.state.curItem;
        if (Libs.isBlank(tabActive)) return;
        curItem.tabActive = tabActive;

        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload icon  
     * @author Long.Pham 12/05/2021
     */
    onFileIconChange = (event, language) => {
        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgIconError = null;
        if (!checkExtFile) {
            msgIconError = t('message.msg_err_ext_image_file')
        }
        else if (fileSize <= 0) {
            msgIconError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgIconError = t('message.msg_err_max_size_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgIconError)) {
            curItem.icon_upload = '';
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].icon_message = msgIconError;
                    }
                }
                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });

            return;
        }

        else {
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].icon_message = '';
                    }
                }

                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });
        }

        //Read file upload
        var reader = new FileReader();
        reader.onload = function (e) {
            if (Libs.isArrayData(curItem.data)) {
                var data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].icon_upload = e.target.result;
                    }
                }
                curItem.data = data;
            }

            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }



    /**
     * @description Delete icon upload 
     * @author Long.Pham 12/05/2021
     */
    onClickDeleteIcon = (event, language) => {
        var { curItem } = this.state;
        if (Libs.isArrayData(curItem.data)) {
            var data = curItem.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].iso_code === language) {
                    data[i].icon_message = '';
                    data[i].icon_upload = '';
                }
            }
            curItem.data = data;
        }

        this.setState({
            curItem: curItem
        });
    }


    /**
     * @description Delete banner desktop upload 
     * @author Long.Pham 12/05/2021
     */
    onClickDeleteBannerDesktop = (event, language) => {
        var { curItem } = this.state;
        if (Libs.isArrayData(curItem.data)) {
            var data = curItem.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].iso_code === language) {
                    data[i].banner_desktop_message = '';
                    data[i].banner_desktop_upload = '';
                }
            }
            curItem.data = data;
        }

        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload bannner desktop
     * @author Long.Pham 12/05/2021
     */
    onFileBannerDesktopChange = (event, language) => {
        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgBannerDesktopError = null;
        if (!checkExtFile) {
            msgBannerDesktopError = t('message.msg_err_ext_image_file')
        }
        else if (fileSize <= 0) {
            msgBannerDesktopError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgBannerDesktopError = t('message.msg_err_max_size_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgBannerDesktopError)) {
            curItem.banner_desktop_upload = '';
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_desktop_message = msgBannerDesktopError;
                    }
                }
                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });

            return;
        }

        else {
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_desktop_message = '';
                    }
                }

                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });
        }

        //Read file upload
        var reader = new FileReader();
        reader.onload = function (e) {
            if (Libs.isArrayData(curItem.data)) {
                var data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_desktop_upload = e.target.result;
                    }
                }
                curItem.data = data;
            }

            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }



    /**
     * @description Delete banner mobile upload 
     * @author Long.Pham 12/05/2021
     */
    onClickDeleteBannerMobile = (event, language) => {
        var { curItem } = this.state;
        if (Libs.isArrayData(curItem.data)) {
            var data = curItem.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].iso_code === language) {
                    data[i].banner_mobile_message = '';
                    data[i].banner_mobile_upload = '';
                }
            }
            curItem.data = data;
        }

        this.setState({
            curItem: curItem
        });
    }

    /**
     * @description Upload bannner mobile
     * @author Long.Pham 12/05/2021
     */
    onFileBannerMobileChange = (event, language) => {
        var { t } = this.props;
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var fileName = file.name, fileSize = file.size;
        var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        var msgBannerMobileError = null;
        if (!checkExtFile) {
            msgBannerMobileError = t('message.msg_err_ext_image_file')
        }
        else if (fileSize <= 0) {
            msgBannerMobileError = t('message.msg_err_file_size');
        }
        else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
            msgBannerMobileError = t('message.msg_err_max_size_upload');
        }

        var self = this;
        var { curItem } = this.state;
        if (!Libs.isBlank(msgBannerMobileError)) {
            curItem.banner_mobile_upload = '';
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_mobile_message = msgBannerMobileError;
                    }
                }
                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });

            return;
        }

        else {
            if (Libs.isArrayData(curItem.data)) {
                let data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_mobile_message = '';
                    }
                }

                curItem.data = data;
            }

            this.setState({
                curItem: curItem
            });
        }

        //Read file upload
        var reader = new FileReader();
        reader.onload = function (e) {
            if (Libs.isArrayData(curItem.data)) {
                var data = curItem.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].iso_code === language) {
                        data[i].banner_mobile_upload = e.target.result;
                    }
                }
                curItem.data = data;
            }

            self.setState({
                curItem: curItem
            });
        };
        reader.readAsDataURL(file);
    }

    /**
     * @description validate a field input
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description select change
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */

    handleInputTranslateChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            let allLanguage = this.state.allLanguage;
            let isoCode = name.substr(-2, 2),
                dataName = name.substr(0, 4),
                dataDescription = name.substr(0, 11);

            allLanguage.map((language, index) => {
                if (isoCode === language.iso_code && dataName === 'name') {
                    item.data[index].name = (event.target.validity.valid) ? value : this.state.curItem.data[index].name;
                }

                if (isoCode === language.iso_code && dataDescription === 'description') {
                    item.data[index].description = (event.target.validity.valid) ? value : this.state.curItem.data[index].description;
                }

                return 1;
            });

            item[name] = (event.target.validity.valid) ? value : this.state.curItem[name];

            this.setState({ curItem: item });
        }
    }



    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSave() {
        var curItem = this.state.curItem;
        var screenMode = (!Libs.isBlank(curItem.id)) ? Constants.SCREEN_MODE.EDIT : ((!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD);
        let v = new AddPopupValidate(this.props);
        let errors = await v.FLValidationAll(curItem);
        if (errors) {
            this.setValidateMessage(errors);
            return;
        }
        //remove message validation
        this.removeAllValidateMessage();
        curItem.screen_mode = screenMode;

        // self.toast( "aaaa sss", "info");
        //         params.id_company = this.staff.id_company;
        //         params.iso_code_language = 'vi';
        //         params.status = 1;
        //         params.is_delete = 0;
        //         if (Libs.isArray(params.dataTranslate)) {
        //             let itemLanguageDefault = Libs.find(params.dataTranslate, 'default', '1');
        //             if (!itemLanguageDefault) return;
        //             params.dataTranslate.map((item, index) => {
        //                 params.dataTranslate[index].name = (item.name == '') ? itemLanguageDefault.name : item.name;
        //                 params.dataTranslate[index].content = (item.content == '') ? itemLanguageDefault.content : item.content;
        //                 params.dataTranslate[index].address = (item.address == '') ? itemLanguageDefault.address : item.address;
        //                 params.dataTranslate[index].description = (item.description == '') ? itemLanguageDefault.description : item.description;
        //                 params.dataTranslate[index].policy_cancel = (item.policy_cancel == '') ? itemLanguageDefault.policy_cancel : item.policy_cancel;

        //             });
        //         } else { return false; }
        //         BranchService.instance.save(params, function (status, data, msg) {
        //             if (status) {
        //                 self.toast(msg, "info");
        //                 self.flTinyEditor = null;
        //                 self.contentEditor = null;
        //                 self.setState({
        //                     curItem: data,
        //                     step: 2,
        //                     content: ""
        //                 }, function () {
        //                     self.jsxTemplate = self.getTemplate();
        //                     self.flTinyEditor = null;
        //                     self.contentEditor = null;
        //                     self.getAllBranchGallery();
        //                     self.forceUpdate();

        //                 });
        //             }
        //             else if (data) {
        //                 setValidateMessage(data);
        //             }
        //             else if (!Libs.isBlank(msg)) {
        //                 self.toast(msg, "error");
        //             }
        //         }, true);
    }
}
export default AddPopup;