import React from 'react';



export default function Dashboard() {
    return (
        <div className="dashboard">
            Dashboard
        </div>

    )
}