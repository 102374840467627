import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate{
    constructor(props){
        super();
        var {t, iso_code_language} = props;
        this.trans = t;
        this.iso_code_language = iso_code_language;

    }
    setRule(){
        if(this.iso_code_language === 'vi'){
            this.addRuleForField('name_vi', 'trim', true);
            this.addRuleForField('name_vi', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.name'));
            this.addRuleForField('name_vi', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('coupon.name'));
        }
        if(this.iso_code_language === 'en'){
            this.addRuleForField('name_en', 'trim', true);
            this.addRuleForField('name_en', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.name'));
            this.addRuleForField('name_en', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('coupon.name'));
        }
        
        this.addRuleForField('from_date', 'trim', true);
        this.addRuleForField('from_date', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.from_date'));
        this.addRuleForField('from_date', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('coupon.from_date'));

        this.addRuleForField('to_date', 'trim', true);
        this.addRuleForField('to_date', 'required', true, this.trans('validate_rule.required'), this.trans('coupon.to_date'));
        this.addRuleForField('to_date', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('coupon.to_date'));

    }
    
    setAlias(){
        if(this.iso_code_language ==='vi'){
            this.v.setAlias({
                name_vi: this.trans('coupon.name'),
                from_date: this.trans('coupon.from_date'),
                to_date: this.trans('coupon.to_date')
            });
        }

        if(this.iso_code_language ==='en'){
            this.v.setAlias({
                name_en: this.trans('coupon.name'), 
                from_date: this.trans('coupon.from_date'),
                to_date: this.trans('coupon.to_date')
            });
        }
        
    }
}
export default AddPopupValidate;