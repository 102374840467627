import React from 'react';
import { RText, RTextArea, RSwitch } from '../../../../../components/Controls';
import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';
import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';


export default function AddPopup() {

    var { curItem, allLanguage, dataType } = this.state;
    const { t } = this.props;
    var tabNavLanguage, tabContent = null;

    if (allLanguage) {
        tabNavLanguage = allLanguage.map((language, index) => {
            return (<li key={index} className="nav-item">
                <span onClick={(e) => { this.onClickShowTab(e, language.iso_code) }} className={curItem.tabActive === language.iso_code ? "nav-link active" : "nav-link"} id={"name-tab-" + language.iso_code} >{language.name}</span>
            </li>);
        });

        tabContent = allLanguage.map((language, index) => {
            return (
                <div key={index} className={curItem.tabActive === language.iso_code ? "tab-pane fade show active" : "tab-pane fade"} id={"name-" + language.iso_code} role="tabpanel" aria-labelledby="home-tab">
                    <div className="info">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('coupon.name')}
                                        inputClass="form-control"
                                        required={language.is_default === 1 ? "required" : ""}
                                        inputId={"name_" + language.iso_code}
                                        inputName={"name_" + language.iso_code}
                                        value={curItem.data[index].name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                <div className="mb-3">
                                    <RTextArea label={t('coupon.description')}
                                        inputClass="form-control"
                                        inputId={"description_" + language.iso_code}
                                        inputName={"description_" + language.iso_code}
                                        value={curItem.data[index].description}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={400} />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            );
        });
    }


    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('coupon.edit_title') : t('coupon.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">
                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('coupon.coupon_code')}
                                        inputClass="form-control"
                                        required="required"
                                        inputId={"coupon_code"}
                                        inputName={"coupon_code"}
                                        value={curItem.coupon_code}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        pattern="^[A-Za-z0-9]*$"
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <p className="control-label">{t('coupon.status')}</p>
                                    <div className="checkmark">
                                        <RSwitch
                                            inputId="status"
                                            inputName="status"
                                            checked={curItem.status}
                                            onChange={(e) => { this.handleInputChange(e) }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <FormReactSelect
                                    instanceId="id_type"
                                    className="id_type"
                                    name="id_type"
                                    required="required"
                                    value={dataType.filter(function (item) { return item.id === parseInt(curItem.id_type) })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'id_type') }}
                                    optionList={dataType}
                                    label={t('coupon.id_type')}
                                />

                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('coupon.coupon_value')}
                                        inputClass="form-control"
                                        required="required"
                                        inputId={"coupon_value"}
                                        inputName={"coupon_value"}
                                        value={curItem.coupon_value}
                                        pattern="^[0-9]*$"
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={curItem.id_type === 5 ? 2 : 10} />
                                </div>
                            </div>



                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('coupon.min_order')}
                                        inputClass="form-control"
                                        inputId={"min_order"}
                                        inputName={"min_order"}
                                        required="required"
                                        value={curItem.min_order}
                                        pattern="^[0-9]*$"
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('coupon.max_value')}
                                        inputClass="form-control"
                                        inputId={"max_value"}
                                        required="required"
                                        inputName={"max_value"}
                                        value={curItem.max_value}
                                        pattern="^[0-9]*$"
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <CMSDatePicker
                                        label={t("coupon.from_date")}
                                        inputId="from_date"
                                        inputClass="form-control"
                                        inputName="from_date"
                                        required="required"
                                        maxLength={20}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        format="DD/MM/YYYY HH:mm"
                                        timeFormat="HH:mm"
                                        showTimeSelect={true}
                                        timeIntervals = {60}
                                        value={curItem.from_date}
                                        handleChange={(e) => { this.handleInputDateChange(e); this.validateOne(e); }}
                                        showIconCalendar={true}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <CMSDatePicker
                                        label={t("coupon.to_date")}
                                        inputId="to_date"
                                        inputClass="form-control"
                                        inputName="to_date"
                                        required="required"
                                        maxLength={20}
                                        showTimeSelect={true}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        format="DD/MM/YYYY HH:mm"
                                        timeFormat="HH:mm"
                                        timeIntervals = {60}
                                        value={curItem.to_date}
                                        minDate={curItem.to_min_date}
                                        handleChange={(e) => { this.handleInputDateChange(e); this.validateOne(e); }}
                                        showIconCalendar={true}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('coupon.total')}
                                        inputClass="form-control"
                                        inputId={"total"}
                                        required="required"
                                        inputName={"total"}
                                        value={curItem.total}
                                        pattern="^[0-9]*$"
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={10} />
                                </div>
                            </div>



                        </div>
                    </div>

                </div>

                <div className="box-translate">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-main">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {tabNavLanguage}
                                </ul>
                                <div className="tab-content" id="tabContent">
                                    {tabContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>

    )
}