
import BaseComponent from '../../../../BaseComponent';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
import PromotionService from '../../../../../services/PromotionService';
import OrdersService from '../../../../../services/OrdersService';

class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
            allLanguage: this.props.allLanguage,
            dataProductSearchAuto: [],
            searchProductParam: {}
        }

        this.jsxTemplate = AddPopupJsx;
        this.handleInputDateChange = this.handleInputDateChange.bind(this);
    }
    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author Long.Pham 12/05/2021
     */
    componentDidMount() {
        var curItem = this.state.curItem;
        if (curItem.screen_mode === Constants.SCREEN_MODE.ADD) {
            curItem.status = 1;
            curItem.dataProducts = [];
        }
    }

    onItemProductClickDelete = (index) => {
        var { curItem } = this.state;
        var dataProducts = curItem.dataProducts;
        if (!Libs.isArrayData(dataProducts)) return;

        var item = dataProducts[index];
        if (Libs.isObjectEmpty(item)) return;
        dataProducts.splice(index, 1);

        curItem.dataProducts = dataProducts;
        this.setState({
            curItem: curItem
        });
    }

    handleInputProductChange = (event, index) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem } = this.state;

            var numericExpression = /^[0-9.\b]+$/;
            if (value == "" || numericExpression.test(value)) {
                var dataProducts = curItem.dataProducts;
                if (!Libs.isArrayData(dataProducts)) return;

                var item = dataProducts[index];
                if (Libs.isObjectEmpty(item)) return;

                if (name === 'saleoff_price') {
                    dataProducts[index].saleoff_price = value;
                }

                if (name === 'quantity') {
                    dataProducts[index].quantity = value;
                }
                curItem.dataProducts = dataProducts;
                this.setState({
                    curItem: curItem
                });
            }
        }
    }
    
    getChooseProduct = (index) => {
        var { dataProductSearchAuto, curItem } = this.state;
        var itemProduct = dataProductSearchAuto[index];
        if (Libs.isObjectEmpty(itemProduct)) return;

        var dataProducts = curItem.dataProducts;

        if (Libs.isArrayData(dataProducts)) {
            var findItem = Libs.find(dataProducts, 'sku_alias', itemProduct.sku_alias);
            if (Libs.isObjectEmpty(findItem)) {
                dataProducts.push({
                    sku_alias: itemProduct.sku_alias,
                    id_product: itemProduct.id_product,
                    sku: itemProduct.sku,
                    regular_price: itemProduct.regular_price,
                    saleoff_price: itemProduct.saleoff_price,
                    product_name: itemProduct.product_name,
                    quantity: 1
                });
            } else {
                dataProducts.map((item, v) => {
                    if (item.sku_alias === itemProduct.sku_alias) {
                        dataProducts[v].quantity = parseInt(dataProducts[v].quantity) + 1;
                        dataProducts[v].sub_total = parseInt(dataProducts[v].saleoff_price) * parseInt(dataProducts[v].quantity);
                    }
                });
            }

        } else {
            dataProducts.push({
                sku_alias: itemProduct.sku_alias,
                id_product: itemProduct.id_product,
                sku: itemProduct.sku,
                regular_price: itemProduct.regular_price,
                saleoff_price: itemProduct.saleoff_price,
                product_name: itemProduct.product_name,
                quantity: 1
            });
        }

        curItem.dataProducts = dataProducts;

        this.setState({
            curItem: curItem
        })


    }


    handleInputSerachProductAutoChange(event) {
        let target = event.target, self = this;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { searchProductParam } = this.state;
            searchProductParam[name] = value;
            if (name == 'name') {
                this.setState({ searchProductParam }, () => {
                    self.getSearchProductAuto();
                });
            }
        }
    }


    onCloseSearchProductAuto = () => {
        this.setState({
            searchProductParam: {},
            dataProductSearchAuto: []
        })
    }

    /**
   * ge list type
   * @author Long.Pham 2019-06-03
   */
    getSearchProductAuto() {
        let self = this, searchProductParam = this.state.searchProductParam;
        var params = {
            id_company: this.employee.id_company,
            id_language: this.employee.id_language,
            name: searchProductParam.name
        };
        if (Libs.isBlank(searchProductParam.name)) {
            self.setState({
                dataProductSearchAuto: []
            });
            return;
        }

        OrdersService.instance.getSearchProductAuto(params, (data, total_row) => {
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataProductSearchAuto: data
                });
            } else {
                self.setState({
                    dataProductSearchAuto: []
                });
            }
        })
    }


    /**
     * setValue method to Input
     * @author Long.Pham 20/05/2021
     */
     handleInputDateChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem } = this.state;

            if (name === 'from_date') {
                if (!Libs.isBlank(curItem.to_date) && Libs.compareDate(value, 'DD/MM/YYYY', curItem.to_date) > 0) {
                    curItem.from_date = curItem.to_date;
                } else {
                    curItem.from_date = value;
                }

                curItem.to_min_date = curItem.from_date;
                curItem.to_date = null;
            }

            if (name === 'to_date') {
                if (!Libs.isBlank(curItem.from_date) && Libs.compareDate(curItem.from_date, 'DD/MM/YYYY', value) > 0) {
                    curItem.to_date = curItem.from_date;
                } else {
                    curItem.to_date = value;
                }
            }

            this.setState({ curItem });
        }
    }


    onClickShowTab = (e, tabActive) => {
        var curItem = this.state.curItem;
        if (Libs.isBlank(tabActive)) return;
        curItem.tabActive = tabActive;

        this.setState({
            curItem: curItem
        });
    }


    /**
     * @description validate a field input
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

    /**
     * @description select change
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */

    handleInputTranslateChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        if (name) {
            let item = this.state.curItem;
            let allLanguage = this.state.allLanguage;
            let isoCode = name.substr(-2, 2),
                dataName = name.substr(0, 4),
                dataDescription = name.substr(0, 11);

            allLanguage.map((language, index) => {
                if (isoCode === language.iso_code && dataName === 'name') {
                    item.data[index].name = (event.target.validity.valid) ? value : this.state.curItem.data[index].name;
                }

                if (isoCode === language.iso_code && dataDescription === 'description') {
                    item.data[index].description = (event.target.validity.valid) ? value : this.state.curItem.data[index].description;
                }
                return 1;
            });

            item[name] = (event.target.validity.valid) ? value : this.state.curItem[name];

            this.setState({ curItem: item });
        }
    }



    /**
     * @description save data
     * @author long.pham 12/05/2021
     */
    async onSave() {
        var curItem = this.state.curItem, self = this;
        var {t} = this.props;
        var params = Object.assign({}, this.state.curItem);
        var screenMode = (!Libs.isBlank(curItem.id)) ? Constants.SCREEN_MODE.EDIT : ((!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD);
        let v = new AddPopupValidate(this.props);
        let errors = await v.FLValidationAll(curItem);
        if (errors) {
            this.setValidateMessage(errors);
            return;
        }
        //remove message validation
        this.removeAllValidateMessage();
        params.screen_mode = screenMode;

        var dataProducts = curItem.dataProducts;
        if(!Libs.isArrayData(dataProducts)){
            self.toast( t('promotion.data_production_empty'), "info");
            return;
        }

        dataProducts.map((item, index) => {
            dataProducts[index].saleoff_price = Libs.convertStringToNumber(dataProducts[index].saleoff_price);
            dataProducts[index].quantity = Libs.convertStringToNumber(dataProducts[index].quantity);
            return dataProducts;
        });

        params.dataProducts = dataProducts;
        params.id_company = this.employee.id_company;
        params.iso_code_lang = this.employee.lang;
        params.id_language = this.employee.id_language;
        params.iso_code = this.employee.iso_code;
        params.status = 1;

        if (Libs.isArrayData(params.data)) {
            let itemLanguageDefault = Libs.find(params.data, 'is_default', 1);
            if (!itemLanguageDefault) return;

            params.data.map((item, index) => {
                params.data[index].name = (item.name === '') ? itemLanguageDefault.name : item.name;
                params.data[index].description = (item.description === '') ? itemLanguageDefault.description : item.description;
                return params;

            });
        } else { return false; }

        PromotionService.instance.save( params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onClickCloseAdd(true);
            }
            else if (data) {
                self.setValidateMessage(data);
            }

            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }
}
export default AddPopup;