import React from 'react';
import NavLink from '../../../components/Link';

class SidebarStores extends React.Component {
    render() {
        return (
            <div className="sidebar">
                <h2>Kho</h2>
                <ul>
                    <li><NavLink to="/stores"><var className="icon-tachometer"></var>Dashboard</NavLink></li>
                    <li><NavLink to="/stores/partner"><var className="icon-list-unordered"></var>Nhà cung cấp</NavLink></li>
                    <li><NavLink to="/stores/stores"><var className="icon-flow-tree"></var>Kho hàng</NavLink></li>
                    <li><NavLink to="/stores/import"><var className="icon-equalizer"></var>Nhập kho</NavLink></li>
                    <li><NavLink to="/stores/export"><var className="icon-man-people-streamline-user"></var>Xuất kho</NavLink></li>
                    <li><NavLink to="/stores/transfer"><var className="icon-delay"></var>Chuyển kho</NavLink></li>
                    <li><NavLink to="/stores/inventory"><var className="icon-delay"></var>Kiểm kê kho</NavLink></li>
                    <li><NavLink to="/stores/summary"><var className="icon-delay"></var>Tổng hợp tồn kho</NavLink></li>
                </ul>
            </div>
        )
    }
}

export default SidebarStores;