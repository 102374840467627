import React, { Component } from 'react';
class RowItemProduct extends Component {

    render() {
        var item = this.props.dataItem;
        return (
            <div className="tbody-row" key = {"item-product-"+this.props.index}>
                <div className="tbody-col width60">
                    <p>{item.name}</p>
                </div>
                <div className="tbody-col width20 text-end"><p>{item.price}</p></div>
                <div className="tbody-col width20 text-end"><p>{item.quantity}</p></div>
            </div>
        );
    }
}
export default RowItemProduct;
