import BaseValidate from '../../../../../validator/BaseValidate';
class AddFieldPopupValidate extends BaseValidate{
    constructor(props){
        super();
        var {t, iso_code_language} = props;
        this.trans = t;
        this.iso_code_language = iso_code_language;

    }
    setRule(){
        if(this.iso_code_language === 'vi'){
            this.addRuleForField('field_label_vi', 'trim', true);
            this.addRuleForField('field_label_vi', 'required', true, this.trans('validate_rule.required'), this.trans('custom_field.field_label'));
            this.addRuleForField('field_label_vi', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('custom_field.field_label'));
        }
        if(this.iso_code_language === 'en'){
            this.addRuleForField('field_label_en', 'trim', true);
            this.addRuleForField('field_label_en', 'required', true, this.trans('validate_rule.required'), this.trans('custom_field.field_label'));
            this.addRuleForField('field_label_en', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('custom_field.field_label'));
        }
        
        this.addRuleForField('field_key', 'trim', true);
        this.addRuleForField('field_key', 'required', true, this.trans('validate_rule.required'), this.trans('custom_field.field_key'));
        this.addRuleForField('field_key', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('custom_field.field_key'));

        this.addRuleForField('field_type', 'trim', true);
        this.addRuleForField('field_type', 'required', true, this.trans('validate_rule.required'), this.trans('custom_field.field_type'));
        this.addRuleForField('field_type', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('custom_field.field_type'));
    }
    
    setAlias(){
        if(this.iso_code_language ==='vi'){
            this.v.setAlias({
                field_label_vi: this.trans('custom_field.field_label_vi'),
                field_key: this.trans('custom_field.field_key'),
                field_type: this.trans('custom_field.field_type')
            });
        }

        if(this.iso_code_language ==='en'){
            this.v.setAlias({
                field_label_en: this.trans('custom_field.field_label'),
                field_key: this.trans('custom_field.field_key'),
                field_type: this.trans('custom_field.field_type')
            });
        }
        
    }
}
export default AddFieldPopupValidate;