import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RText, RButton, RTextArea } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import FormReactSelect from '../../../../../components/FormReactSelect';
import Constants from '../../../../../utils/Constants';
import AddRowLeft from './AddRowLeft';
import AddRowRight from './AddRowRight';
export default function SummaryStore() {
    var { t } = this.props;
    var { curItem, dataDetail, searchParam, dataStores } = this.state;
    // Add row left
    var addRowsLeft = null;
    // Add row right
    var addRowsRight = null;

    if (Libs.isArrayData(dataDetail)) {
        addRowsLeft = dataDetail.map((item, index) => {
            return <AddRowLeft
                key={'row_item_' + index}
                index={index}
                dataItem={item}
                curItem={curItem}
            />
        });
        addRowsRight = dataDetail.map((item, index) => {
            return <AddRowRight
                key={'row_item_' + index}
                index={index}
                dataItem={item}
            />
        });
    }

    return (
        <React.Fragment>
            <Modal visible={true} className="modal-add-small" dialogClassName="modal-dialog-scrollable modal-xl summary-store full-screen">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {t('summary_store.label_view')}</h5>
                    <span className="close" onClick={this.props.onCloseSummaryStorePopup.bind(this)}><var className="icon-cancel-music"></var></span>
                </div>

                <div className="modal-body">
                    <div className="filter">
                        <h2>Lọc dữ liệu</h2>
                        <div className="filter-content">
                            <div className="row">
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <FormReactSelect
                                        label={t('summary_store.id_store')}
                                        instanceId="id_store"
                                        className="id_store"
                                        name="id_store"
                                        value={dataStores.filter(function (item) { return item.id === curItem.id_store })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'id_store') }}
                                        optionList={dataStores}
                                        placeHolder={t('common.choose')}
                                    />

                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <RText label={t('summary_store.product_name')}
                                            inputClass="form-control"
                                            inputId="product_name" inputName="product_name"
                                            value={(curItem.data != undefined) ? curItem.data.product_name : ""}
                                            disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                            onChange={this.handleInputChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <CMSDatePicker
                                            label={t("summary_store.from_date")}
                                            inputId="from_date"
                                            inputClass="form-control"
                                            inputName="from_date"
                                            maxLength={20}
                                            value={searchParam.from_date}
                                            handleChange={(e) => { this.handleInputChange(e); }}
                                            showIconCalendar={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <CMSDatePicker
                                            label={t("summary_store.to_date")}
                                            inputId="to_date"
                                            inputClass="form-control"
                                            inputName="to_date"
                                            maxLength={20}
                                            value={searchParam.to_date}
                                            handleChange={(e) => { this.handleInputChange(e); }}
                                            showIconCalendar={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="view-import-export-store">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 col-md-6 col-sx-12">
                                <div className="box-left">
                                    <div className="header-row">
                                        <div className="header-col width10">
                                            {t('summary_store.id_code')}
                                        </div>

                                        <div className="header-col width20">
                                            {t('summary_store.type_code')}
                                        </div>

                                        <div className="header-col width30">
                                            {t('summary_store.id_code_date')}
                                        </div>

                                        <div className="header-col width30">
                                            {t('summary_store.note')}
                                        </div>
                                        <div className="header-col width10">
                                            {t('summary_store.unit')}
                                        </div>
                                    </div>
                                    <div className="store-quantity-first">
                                        {t('summary_store.store_quantity_first')}
                                    </div>
                                    <div className="body">
                                        {addRowsLeft}
                                    </div>

                                    <div className="header-row sum">
                                        <div className="header-col width100">
                                            {t('summary_store.total')}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6 col-sx-12">
                                <div className="box-right">
                                    <div className="box-right-main">
                                        <div className="header-row">
                                            <div className="header-col width20">
                                                {t('summary_store.quantity_import')}
                                            </div>

                                            <div className="header-col width20">
                                                {t('summary_store.price_import')}
                                            </div>

                                            <div className="header-col width15">
                                                {t('summary_store.quantity_export')}
                                            </div>

                                            <div className="header-col width15">
                                                {t('summary_store.price_export')}
                                            </div>
                                            <div className="header-col width15">
                                                {t('summary_store.quantity_store')}
                                            </div>
                                            <div className="header-col width15">
                                                {t('summary_store.price_total_store')}
                                            </div>
                                        </div>
                                        <div className="store-quantity-first">
                                            <div className="header-row">
                                                <div className="header-col width20"></div>
                                                <div className="header-col width20"></div>
                                                <div className="header-col width15"></div>
                                                <div className="header-col width15"></div>
                                                <div className="header-col width15"></div>
                                                <div className="header-col width15">
                                                    100
                                            </div>
                                            </div>
                                        </div>
                                        <div className="body">
                                            {addRowsRight}
                                        </div>

                                        <div className="header-row total">
                                            <div className="header-col width20">
                                                <strong>100</strong>
                                            </div>
                                            <div className="header-col width20">
                                                <strong>100</strong>
                                            </div>
                                            <div className="header-col width15">
                                                <strong>100</strong>
                                            </div>
                                            <div className="header-col width15">
                                                <strong>100</strong>
                                            </div>
                                            <div className="header-col width15">
                                                <strong>100</strong>
                                            </div>
                                            <div className="header-col width15">
                                                <strong>100</strong>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* end modal-body */}

                <div className="modal-footer">
                    <RButton
                        onClick={this.onCloseSummaryStorePopup.bind(this)}
                        className="btn btn-cancel"
                        text={t('common.cancel')}
                        data-dismiss="modal" aria-hidden="true"
                        title={t('common.cancel')} />

                    <RButton
                        onClick={this.exportExcel.bind(this)}
                        className="btn btn-save"
                        text={t('common.excel')}
                        title={t('common.excel')} />

                </div>

                
            </Modal>
        </React.Fragment >
    )
}