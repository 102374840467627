
import BaseComponent from '../../../../BaseComponent';
// import Libs from '../../../../utils/Libs';
import AddSupplierPopupJsx from './AddSupplierPopup.jsx';
import AddSupplierPopupValidate from './AddSupplierPopupValidate';
// import SupplierService from '../../../../services/SupplierService';
// import Constants from '../../../../../utils/Constants';

class AddSupplierPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: {}
        }
        this.jsxTemplate = AddSupplierPopupJsx;
    }

    /**
     * @description Called immediately after the component is mounted in the DOM. Called after the render function
     * @author long.pham 28/07/2019
     */
    componentDidMount() {
        
    }

    /**
     * @description close popup
     * @author long.pham 28/07/2019
     */
    onCloseSupplierPopup() {
        if (!this.props.onCloseSupplierPopup || typeof this.props.onCloseSupplierPopup !== 'function') return;
        this.props.onCloseSupplierPopup(false, null);
    }

    

    /** 
     * @description Save info supplier
     * @author long.pham 28/07/2019
    */
    async onSaveSupplierPopupAction() {
        // let params = this.state.curItem, self = this;
        // var screenMode = Constants.SCREEN_MODE.ADD;
        // let v = new AddSupplierPopupValidate();
        // let errors = await v.FLValidationAll(params);
        // if (errors) {
        //     setValidateMessage(errors);
        //     return;
        // }
        // //remove message validation
        // // removeAllValidateMessage();

        // params.screen_mode = screenMode;
        // params.id_company = this.staff.id_company;
        // params.status = 1;
        // params.is_delete = 0;

        // SupplierService.instance.save(params, function (status, data, msg) {
        //     if (status) {    
        //         self.toast(msg, "info");
        //         self.props.onCloseSupplierPopup(true, data);
        //     }
        //     else if (data) {
        //         setValidateMessage(data);
        //     }
        //     else if (!Libs.isBlank(msg)) {
        //         self.toast(msg, "error");
        //     }
        // }, true);
    }
    /**
     * @description validate a field input
     * @author long.pham 28/07/2019
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddSupplierPopupValidate(null);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }

}
export default AddSupplierPopup;