import BaseValidate from '../../../../../validator/BaseValidate';
class AddSupplierPopupValidate extends BaseValidate{
    constructor(props){
        super();
        var {t} = props;
        this.trans = t;
    }
    setRule(){
        this.addRuleForField('name', 'trim', true);
        this.addRuleForField('name', 'required', true, this.trans('validate_rule.required'));
        this.addRuleForField('name', 'maxLength', 100, this.trans('validate_rule.maxLength_input'));
    }
    setAlias(){
        this.v.setAlias({
            name: this.trans('import_stock.supplier_name')
        });
        
    }
}
export default AddSupplierPopupValidate;