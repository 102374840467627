import React from 'react';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import ReactTooltip from 'react-tooltip';
import CMSOutsideClickHandler from "../../../../../components/CMSOutsideClickHandler";

export default function AddPopup() {

    var { curItem } = this.state;
    const { t, k } = this.props;


    return (
        <CMSOutsideClickHandler
            onOutsideClick={this.onCloseItemFastRangeDate.bind(this, k)}
        >
            <div className={curItem.show_range_time ? "date-range on" : "date-range"}>
                <div className="row">
                    <div className="col-md-4">
                        <CMSDatePicker
                            value={curItem.saleoff_from}
                            inputId={"saleoff_from_" + k}
                            format="DD/MM/YYYY HH:mm"
                            dateFormat="dd/MM/yyyy HH:mm"
                            inputClass="form-control"
                            inputName={"saleoff_from_" + k}
                            minDate={curItem.min_date}
                            handleChange={this.handleInputItemDateChange.bind(this, k)}
                            maxLength={20}
                            placeholder={t('products.placeholder_start_date')}
                            showIconCalendar={false}
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            showTimeSelect={true}
                        />
                    </div>

                    <div className="col-md-2 text-center">-</div>
                    <div className="col-md-4">
                        <CMSDatePicker
                            value={curItem.saleoff_to}
                            inputId={"saleoff_to_" + k}
                            format="DD/MM/YYYY HH:mm"
                            dateFormat="dd/MM/yyyy HH:mm"
                            inputClass="form-control"
                            inputName={"saleoff_to_" + k}
                            minDate={curItem.min_date}
                            handleChange={this.handleInputItemDateChange.bind(this, k)}
                            maxLength={20}
                            placeholder={t('products.placeholder_end_date')}
                            showIconCalendar={false}
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            showTimeSelect={true}
                        />
                    </div>

                    <div className="col-md-2 text-end">
                        <ReactTooltip />
                        <div onClick={this.clearItemSaleOff.bind(this, k)} className="close-calendar" data-tip={t('products.remove_time_sale')} data-type="warning" data-class="tooltip-action" ><var className="icon-cancel-music"></var></div>
                    </div>
                </div>
            </div>

        </CMSOutsideClickHandler>


    )
}