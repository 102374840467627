import React from 'react';
import { RText, RTextArea } from '../../../../../components/Controls';
import Modal from 'react-bootstrap4-modal';
import FormReactSelect from '../../../../../components/FormReactSelect';


export default function AddPopup() {

    var { curItem, allLanguage, dataEmployees } = this.state;
    const { t } = this.props;
    var tabNavLanguage, tabContent = null;

    if (allLanguage) {
        tabNavLanguage = allLanguage.map((language, index) => {
            return (<li key={index} className="nav-item">
                <span onClick={(e) => { this.onClickShowTab(e, language.iso_code) }} className={curItem.tabActive === language.iso_code ? "nav-link active" : "nav-link"} id={"name-tab-" + language.iso_code} >{language.name}</span>
            </li>);
        });

        tabContent = allLanguage.map((language, index) => {
            return (
                <div key={index} className={curItem.tabActive === language.iso_code ? "tab-pane fade show active" : "tab-pane fade"} id={"name-" + language.iso_code} role="tabpanel" aria-labelledby="home-tab">
                    <div className="info">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('stores.name')}
                                        inputClass="form-control"
                                        required={language.default === 1 ? "required" : ""}
                                        inputId={"name_" + language.iso_code}
                                        inputName={"name_" + language.iso_code}
                                        value={curItem.data[index].name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('stores.address')}
                                        inputClass="form-control"
                                        required={language.default === 1 ? "required" : ""}
                                        inputId={"address_" + language.iso_code}
                                        inputName={"address_" + language.iso_code}
                                        value={curItem.data[index].address}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('stores.phone')}
                                        inputClass="form-control"
                                        required={language.default === 1 ? "required" : ""}
                                        inputId={"phone_" + language.iso_code}
                                        inputName={"phone_" + language.iso_code}
                                        value={curItem.data[index].phone}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RTextArea label={t('stores.description')}
                                        inputClass="form-control"
                                        inputId={"name_" + language.iso_code}
                                        inputName={"name_" + language.iso_code}
                                        value={curItem.data[index].name}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={100} />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            );
        });
    }


    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">{t('stores.add_title')}</h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">

                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('stores.id')}
                                        inputClass="form-control"
                                        required="required"
                                        inputId="id"
                                        inputName="id"
                                        value={curItem.id}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="box-translate">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-main">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {tabNavLanguage}
                                </ul>
                                <div className="tab-content" id="tabContent">
                                    {tabContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="box-info">
                    <h2>{t('stores.management_info')}</h2>
                    <div className="box-info-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <FormReactSelect
                                        label={t('stores.choose_employee_manage')}
                                        instanceId="id_employee"
                                        className="id_employee"
                                        name="id_employee"
                                        value={dataEmployees.filter(function (item) { return item.id === curItem.id_employee })}
                                        onChange={(e) => { this.handleDropdownChange(e, 'id_role') }}
                                        optionList={dataEmployees}
                                        placeHolder={t('common.choose')}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('stores.phone')}
                                        inputClass="form-control"
                                        inputId="phone"
                                        inputName="phone"
                                        value={curItem.phone}
                                        disabled={true}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('stores.email')}
                                        inputClass="form-control"
                                        inputId="email"
                                        inputName="email"
                                        value={curItem.email}
                                        disabled={true}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this)}>{t('common.cancel')}</button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}> {t('common.create')} </button>
            </div>
        </Modal>

    )
}