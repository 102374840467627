import React from 'react';
import { RText, RTextArea, RSwitch } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import CMSEditor from '../../../../../components/CMSEditor';
import CMSOutsideClickHandler from "../../../../../components/CMSOutsideClickHandler";
import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';


export default function AddPopup() {

    var { curItem, allLanguage, msgErrorGallery, searchParam, dataCollectionProductSearchAuto } = this.state;
    const { t } = this.props;
    var tabNavLanguage, tabContent = null;

    // --------------- gift product --------------
    var RowCollectionProductSearch = null;
    if (Libs.isArrayData(dataCollectionProductSearchAuto)) {
        RowCollectionProductSearch = dataCollectionProductSearchAuto.map((item, index) => {
            return (
                <li className="col-md-12" key={index} onClick={this.addCollectionProduct.bind(this, index)}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="image">
                                    <img src={item.thumbnail_medium} alt={item.name} />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }

                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <div className="add-collection-product btn-save"> <var className="icon-plus"></var> </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        })
    }


    var collectionProduct = curItem.collectionProduct;
    var RowItemCollectionProduct = null;
    if (Libs.isArrayData(collectionProduct)) {
        RowItemCollectionProduct = collectionProduct.map((item, index) => {
            return (
                <li className="col-md-12" key={index}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="image">
                                    <img src={item.thumbnail_medium} alt={item.name} />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <button type="button" className="btn btn-cancel" onClick={this.onClearCollectionProductItem.bind(this, index)}> <var className="icon-trash-o"></var> </button>
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
    }

    if (allLanguage) {
        tabNavLanguage = allLanguage.map((language, index) => {
            return (<li key={index} className="nav-item">
                <span onClick={(e) => { this.onClickShowTab(e, language.iso_code) }} className={curItem.tabActive === language.iso_code ? "nav-link active" : "nav-link"} id={"name-tab-" + language.iso_code} >{language.name}</span>
            </li>);
        });

        tabContent = allLanguage.map((language, index) => {
            return (
                <div key={index} className={curItem.tabActive === language.iso_code ? "tab-pane fade show active" : "tab-pane fade"} id={"name-" + language.iso_code} role="tabpanel" aria-labelledby="home-tab">
                    <div className="info">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('collection.title')}
                                        inputClass="form-control"
                                        required={language.is_default === 1 ? "required" : ""}
                                        inputId={"title_" + language.iso_code}
                                        inputName={"title_" + language.iso_code}
                                        value={curItem.data[index].title}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-3">
                                    <RText label={t('collection.slug')}
                                        inputClass="form-control"
                                        inputId={"slug_" + language.iso_code}
                                        inputName={"slug_" + language.iso_code}
                                        value={curItem.data[index].slug}
                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                <div className="mb-3">
                                    <div className={"description_" + language.iso_code}>
                                        <CMSEditor
                                            label={t('collection.description')}
                                            id={"description_" + language.iso_code}
                                            name={"description_" + language.iso_code}
                                            height={250}
                                            content={curItem.data[index].description}
                                            readonly={false}
                                            onEditorChange={this.handleEditorChange.bind(this, 'description_' + language.iso_code, language.iso_code)}
                                            mode="basic"
                                        />

                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                    <div className="meta">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="main-meta">
                                    <h2>SEO</h2>
                                    <div className="meta-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_title')}
                                                        inputClass="form-control"
                                                        inputId={"meta_title_" + language.iso_code}
                                                        inputName={"meta_title_" + language.iso_code}
                                                        value={curItem.data[index].meta_title}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RText label={t('common.meta_keyword')}
                                                        inputClass="form-control"
                                                        inputId={"meta_keyword_" + language.iso_code}
                                                        inputName={"meta_keyword_" + language.iso_code}
                                                        value={curItem.data[index].meta_keyword}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); this.validateOne(e); }}
                                                        maxLength={100} />
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-3">
                                                    <RTextArea label={t('common.meta_description')}
                                                        rows="3"
                                                        inputClass="form-control"
                                                        inputId={"meta_description_" + language.iso_code}
                                                        inputName={"meta_description_" + language.iso_code}
                                                        value={curItem.data[index].meta_description}
                                                        onChange={(e) => { this.handleInputTranslateChange(e); }}
                                                        maxLength={500} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        });
    }


    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('collection.edit_title') : t('collection.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">
                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">

                            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                <div className="mb-3">
                                    <p className="control-label">{t('collection.status')}</p>
                                    <div className="checkmark">
                                        <RSwitch
                                            inputId="status"
                                            inputName="status"
                                            checked={curItem.status}
                                            onChange={(e) => { this.handleInputChange(e) }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="box-info">
                    <h2>{t('products.video')}</h2>
                    <div className="box-info-content">
                        <div className="section-video">
                            <ul className="row">
                                <li className="col-md-12">
                                    <input key={curItem.selectedVideoFile} onChange={(event) => this.onUploadVideoChange(event)} type="file" id="upload_video" name="upload_video" />
                                    {Libs.isBlank(curItem.file_video_upload) && Libs.isBlank(curItem.video) ?
                                        <div className="add-video">
                                            <label htmlFor="upload_video" className="upload_video">
                                                <i className="icon-video-camera"></i>
                                            </label>
                                        </div>
                                        : (!Libs.isBlank(curItem.file_video_upload) ?
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_video" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                    <span onClick={this.deleteVideo.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                </div>
                                                <video width="320" height="240" controls>
                                                    <source src={curItem.file_video_upload} type="video/mp4" />
                                                    <source src={curItem.file_video_upload} type="video/avi" />
                                                    <source src={curItem.file_video_upload} type="video/ogg" />
                                                    <source src={curItem.file_video_upload} type="video/mov" />
                                                </video>
                                            </div>
                                            :
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_video" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                    <span onClick = {this.deleteVideo.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                </div>
                                                <video width="320" height="240" controls>
                                                    <source src={curItem.video_full} type="video/mp4" />
                                                    <source src={curItem.video_full} type="video/avi" />
                                                    <source src={curItem.video_full} type="video/ogg" />
                                                    <source src={curItem.video_full} type="video/mov" />
                                                </video>
                                            </div>
                                        )}

                                </li>



                            </ul>
                        </div>
                    </div>

                </div>


                <div className="box-info">
                    <h2>{t('collection.gallery')}</h2>
                    <div className="box-info-content">
                        <div className="list-gallery">
                            {Libs.isArrayData(msgErrorGallery) ?
                                <div className="alert alert-danger">
                                    {msgErrorGallery.map((item, index) => {
                                        return <p key={index}>{item}</p>
                                    })}
                                </div>
                                : ""}

                            <ul className="row">
                                <li className="col-md-3">
                                    <div className="add-gallery">
                                        <input onChange={(event) => this.onUploadGalleryChange(event)} multiple type="file" id="upload_gallery" name="upload_gallery" />
                                        <label htmlFor="upload_gallery" className="upload_gallery">
                                            <i className="icon-picture-o"></i>
                                        </label>
                                    </div>
                                </li>
                                {Libs.isArrayData(curItem.galleries) ?
                                    curItem.galleries.map((item, index) => {
                                        return (
                                            <li className="col-md-3" key={index}>
                                                <div className="item-view">
                                                    <div className="action">
                                                        <span onClick={this.deleteItemGallery.bind(this, index)} className="delete"><var className="icon-trash-o"></var></span>
                                                    </div>
                                                    {!Libs.isBlank(item.thumbnail_medium) ?
                                                        <img src={item.thumbnail_medium} alt={item.file_name} />
                                                        :
                                                        <img src={item.file_upload} alt={item.file_name} />
                                                    }

                                                </div>
                                            </li>
                                        );
                                    })
                                    : ""}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="box-info">
                    <h2>{t('products.thumbnail')}</h2>
                    <div className="box-info-content">
                        <div className="section-thumbnail">
                            <ul className="row">
                                <li className="col-md-6 col-12">
                                    <input key={Libs.generateStrRandom(2, 2, 2, 8)} onChange={(event) => this.onUploadThumbnailChange(event)} type="file" id="upload_thumbnail" name="upload_thumbnail" />
                                    {Libs.isBlank(curItem.upload_thumbnail) && Libs.isBlank(curItem.thumbnail) ?
                                        <div className={!Libs.isBlank(curItem.thumbnail_mess) ? "add-thumbnail error-thumbnail" : "add-thumbnail"}>
                                            <label htmlFor="upload_thumbnail" className="upload_thumbnail">
                                                <i className="icon-picture-o"></i>
                                            </label>
                                        </div>
                                        : (!Libs.isBlank(curItem.upload_thumbnail) ?
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                    <span onClick={this.deleteThumbnail.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                </div>
                                                <img src={curItem.upload_thumbnail} />
                                            </div>
                                            :
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                </div>
                                                <img src={curItem.thumbnail_full} />
                                            </div>
                                        )}

                                </li>

                                <li className="col-md-6 col-12">
                                    <input key={Libs.generateStrRandom(2, 2, 2, 8)} onChange={(event) => this.onUploadThumbnail1Change(event)} type="file" id="upload_thumbnail1" name="upload_thumbnail1" />
                                    {Libs.isBlank(curItem.upload_thumbnail1) && Libs.isBlank(curItem.thumbnail1) ?
                                        <div className={!Libs.isBlank(curItem.thumbnail_mess) ? "add-thumbnail error-thumbnail" : "add-thumbnail"}>
                                            <label htmlFor="upload_thumbnail1" className="upload_thumbnail">
                                                <i className="icon-picture-o"></i>
                                            </label>
                                        </div>
                                        : (!Libs.isBlank(curItem.upload_thumbnail1) ?
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail1" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                    <span onClick={this.deleteThumbnail1.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                </div>
                                                <img src={curItem.upload_thumbnail1} />
                                            </div>
                                            :
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail1" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                </div>
                                                <img src={curItem.thumbnail1_full} />
                                            </div>
                                        )}

                                </li>



                            </ul>
                        </div>
                    </div>

                </div>


                <div className="box-info">
                    <h2>{t('collection.title_product')}</h2>
                    <div className="box-info-content">
                        <div className="collection-products">
                            <CMSOutsideClickHandler
                                onOutsideClick={this.onCloseShowFastRangeDate.bind(this)}
                            >
                                <div className="collection-product-main">
                                    <div className="collection-product-search">
                                        <div className="collection-product-search-auto">
                                            <RText
                                                placeholder={t('collection.search_product')}
                                                inputClass="form-control"
                                                inputId="name_product"
                                                inputName="name_product"
                                                value={searchParam.name_product}
                                                onChange={(e) => { this.handleInputSearchAutoChange(e); }}
                                                maxLength={100} />
                                        </div>

                                        {!Libs.isBlank(RowCollectionProductSearch) ?
                                            <div className="view-search-data">
                                                <ul className="row">
                                                    {RowCollectionProductSearch}
                                                </ul>
                                            </div>
                                            : ""}
                                    </div>

                                    {!Libs.isBlank(RowItemCollectionProduct) ?
                                        <div className="collection-product-data">
                                            <ul className="row">
                                                {RowItemCollectionProduct}
                                            </ul>
                                        </div>
                                        : ""}

                                </div>
                            </CMSOutsideClickHandler>

                        </div>
                    </div>

                </div>



                <div className="box-translate">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-main">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {tabNavLanguage}
                                </ul>
                                <div className="tab-content" id="tabContent">
                                    {tabContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>

    )
}