import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate {
    constructor(props) {
        super();
        var { t } = props;
        this.trans = t;

    }
    setRule() {
        this.addRuleForField('name', 'trim', true);
        this.addRuleForField('name', 'required', true, this.trans('validate_rule.required'), this.trans('glass_lens.name'));
        this.addRuleForField('name', 'maxLength', 200, this.trans('validate_rule.maxLength_input'), this.trans('glass_lens.name'));
    }

    setAlias() {
        this.v.setAlias({
            name: this.trans('glass_lens.name')
        });
    }
}
export default AddPopupValidate;