import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RButton, RText } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import Constants from '../../../../../utils/Constants';

const styles = {
    spanDowload: {
        display: 'block',
        color: '#0dcaf0',
        marginTop: '10px',
        marginBottom: '15px',
        cursor: 'pointer',
    },
    modalBody: {
        background: '#fff'
    },
    table: {
        marginTop: '15px'
    },
    tableFirstTh: {
        maxWith: '100px'
    },
    tableLastTd: {
        whiteSpace: 'pre-wrap'
    }
}

export default function ImportPopup() {
    var { curItem, searchParam, dataCrossSellingSearchAuto, errors, success } = this.state;
    var { t } = this.props;
    var RowCrossSellingSearch = null;
    if (Libs.isArrayData(dataCrossSellingSearchAuto)) {
        RowCrossSellingSearch = dataCrossSellingSearchAuto.map((item, index) => {
            return (
                <li className="col-md-12" key={index} onClick={this.addCrossSelling.bind(this, index)}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-1">
                                <div className="image">
                                    <img src={item.thumbnail_medium} alt={item.name} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }

                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <div className="add-upsell btn-save"> <var className="icon-plus"></var> </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        })
    }

    var crossSelling = curItem.crossSelling;
    var RowItemCrossSelling = null;
    if (Libs.isArrayData(crossSelling)) {
        RowItemCrossSelling = crossSelling.map((item, index) => {
            return (
                <li className="col-md-12" key={index}>
                    <div className="item">
                        <div className="row">
                            <div className="col-md-1">
                                <div className="image">
                                    <img src={item.thumbnail_medium} alt={item.name} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="content">
                                    <p>{item.name}</p>
                                    {!Libs.isBlank(item.regular_price) && !Libs.isBlank(item.saleoff_price) && item.saleoff_price > 0 ?
                                        <p className="price">
                                            <span className="current_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                            <span className="discount_price">{Libs.formatNum(item.saleoff_price, '#.###')} <var>đ</var></span>
                                        </p>
                                        :
                                        <p className="price">
                                            <span className="discount_price">{Libs.formatNum(item.regular_price, '#.###')} <var>đ</var></span>
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-2 text-end">
                                <button type="button" className="btn btn-cancel" onClick={this.onClearCrossSellingItem.bind(this, index)}> <var className="icon-trash-o"></var> </button>
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
    }

    return (
        <Modal visible={true} className="modal-delete-product" dialogClassName="">
            <div className="modal-header">
                <h5 className="modal-title">{t('common.import')} </h5>
                <span className="close" onClick={this.onClickCloseImport.bind(this)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body" style={styles.modalBody}>
                <label htmlFor="file_import" style={{marginBottom: '15px'}}>
                    <input type="file" onChange={(e) => { this.importExcel(e); }} id="file_import" name="file_import" />
                    <span className="icon-download"></span> {t('common.import')}
                </label>
                <a href={ `${Constants.JOB_HOST}/delayed` } target="_blank" style={styles.spanDowload}>Danh sách sản phẩm đang xử lý</a>
                <a href="/data/Template_upload_products_220927.xlsx" target="_blank" download style={styles.spanDowload}>Tải xuống tệp thêm mới dạng excel</a>
                <span onClick={this.exportAllDataUpdate.bind(this)} style={styles.spanDowload}>Tải xuống tệp cập nhật tất cả sản phẩm</span>
                <div className="upsells-main">
                    <div className="upsells-search">
                        <div className="upsells-search-auto">
                            <RText
                                placeholder={t('products.search_product')}
                                inputClass="form-control"
                                inputId="cross_selling"
                                inputName="cross_selling"
                                value={searchParam.cross_selling}
                                onChange={(e) => { this.getCrossSellingSearchAuto(e); }}
                                maxLength={100} />
                            {!Libs.isBlank(RowCrossSellingSearch) ?
                            <div className="view-search-data" style={{marginTop: '15px'}}>
                                <ul className="row">
                                    {RowCrossSellingSearch}
                                </ul>
                            </div>
                            : ""}
                        </div>
                        {!Libs.isBlank(RowItemCrossSelling) ?
                        <div className="upsells-data" style={{marginTop: '15px'}}>
                            <ul className="row">
                                {RowItemCrossSelling}
                            </ul>
                            <span style={styles.spanDowload} onClick={this.exportDataUpdate.bind(this)}>Tải xuống tệp cập nhật dạng excel</span>
                        </div>
                        : ""}
                    </div>

                </div>
                {errors.length > 0 && <table className="table table-bordered" style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.tableFirstTh} >Line Excel</th>
                            <th>Nội dung lỗi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errors.map((row) =>
                            <tr key={row.line}>
                                <td>{row.line}</td>
                                <td style={styles.tableLastTd}>{row.content}</td>
                            </tr>
                        )}
                    </tbody>
                </table>}
                {success.length > 0 && <table className="table table-bordered" style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.tableFirstTh} >Line Excel</th>
                            <th>Thông tin sản phẩm</th>
                        </tr>
                    </thead>
                    <tbody>
                        {success.map((row) =>
                            <tr key={row.line}>
                                <td>{row.line}</td>
                                <td style={styles.tableLastTd}>{row.content}</td>
                            </tr>
                        )}
                    </tbody>
                </table>}
            </div>

            <div className="modal-footer">
                <RButton
                    onClick={this.onClickCloseImport.bind(this)}
                    className="btn btn-cancel"
                    text={" " + t('common.cancel')}
                    data-dismiss="modal" aria-hidden="true"
                    title={t('common.cancel')} />

                <RButton
                    onClick={this.onCreateAction.bind(this)}
                    className="btn btn-save"
                    text={t('common.add')}
                    title={t('common.add')} />

                <RButton
                    onClick={this.onUpdateAction.bind(this)}
                    className="btn btn-save"
                    text={t('common.update')}
                    title={t('common.update')} />
            </div>
        </Modal>
    )
}