import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { NavLink } from 'react-router-dom';
import Libs from '../../../utils/Libs';
import Constants from '../../../utils/Constants';
import Auth from '../../../utils/Auth';

export default function MainMenu() {

    var { permissions } = this.state;

    return (
        <Modal fade={true} visible={true} className="" onClickBackdrop={this.props.onClickCloseMainMenu.bind(this)} dialogClassName="modal-dialog-scrollable modal-xl modal-main-menu full-screen" >
            <div className="modal-header">
                <h5 className="modal-title">Main Menu</h5>
                <span className="close" onClick={this.props.onClickCloseMainMenu.bind(this)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">

                <div className="main-menu-dashboard">
                    <div className="item-dashboard">
                        {Libs.isArrayData(permissions) ?
                            permissions.map((item, index) => {
                                return (
                                    (!Libs.isBlank(item.module_path) && Libs.isArrayData(item.childs)) ?
                                        <div className="main-item" key={index}>
                                            <h2>{item.title}</h2>
                                            <div className="body-item">
                                                <div className="row">
                                                    {Libs.isArrayData(item.childs) ?
                                                        item.childs.slice(0, 4).map((v, k) => {
                                                            return (
                                                                <div className="col-md-6" key={k}>
                                                                    {Auth.getPermisson(v.auth, Constants.AUTH_MODE.VIEW) ?
                                                                        <div className="item-group" onClick={this.props.onClickCloseMainMenu.bind(this)} >
                                                                            <NavLink to={v.module_path}
                                                                                className={Auth.getPermisson(v.auth, Constants.AUTH_MODE.VIEW) ? "aaa" : "bbb"}>
                                                                                <div className="menu-icon"><span className={v.class_icon}></span></div>
                                                                                <div className="menu-title">
                                                                                    {v.title}
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        :
                                                                        <div className="item-group disabled">
                                                                            <a href="#">
                                                                                <div className="menu-icon"><span className={v.class_icon}></span></div>
                                                                                <div className="menu-title">
                                                                                    {v.title}
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            );
                                                        })
                                                        : ""}

                                                </div>
                                            </div>

                                            <div className="view-all">
                                            {Auth.getPermisson(item.auth, Constants.AUTH_MODE.VIEW) ?
                                            <NavLink onClick={this.props.onClickCloseMainMenu.bind(this)} to={item.module_path}>Xem thêm <var className="icon-angle-right"></var></NavLink>
                                             :
                                             <a href="#" className="disabled">Xem thêm <var className="icon-angle-right"></var></a>
                                             }
                                                
                                            </div>
                                        </div>
                                        : ""

                                );
                            })
                            : ""}
                    </div>
                </div>
            </div>

        </Modal>

    )
}