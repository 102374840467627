import React from 'react';
import RowItem from './RowItem';
import { Paging } from '../../../../../components/Paging';
import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import AddPopup from './AddPopup';
import DeletePopup from './DeletePopup';
import { RText } from '../../../../../components/Controls';

export default function Partner() {
    const { t } = this.props;
    var { curItem, dataList, allLanguage, searchParam, dataPaymentStatus, dataOrderStatus, dataPaymentMethod } = this.state;
    var RowItems = null;
    RowItems = dataList.map((item, index) => {
        return <RowItem
            key={'row_item_' + index}
            index={index}
            dataItem={item}
            onItemClick={this.onItemClick}
            onItemClickDelete={this.onItemClickDelete}
            onItemClickView = {this.onItemClickView}
            onItemClickPrint = {this.onItemClickPrint}
            t={t}
            auth={this.props.auth}
            actions={this.actions}
        />
    });

    var AddPopupLayout = this.state.add
        ? <AddPopup
            t={t}
            allLanguage={allLanguage}
            curItem={curItem}
            onClickCloseAdd={this.onClickCloseAdd}
            auth={this.props.auth}
            actions={this.actions}
        /> : '';

    var DeletePopupLayout = this.state.delete
        ? <DeletePopup
            t={t}
            curItem={curItem}
            onClickCloseDelete={this.onClickCloseDelete}
            auth={this.props.auth}
            actions={this.actions}
        /> : '';

    return (
        <div className="orders">
            {AddPopupLayout}
            {DeletePopupLayout}
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-md-6"><h2>{t('sell.title')}</h2>
                    </div>
                    <div className="col-md-6">
                        <ul>
                            <li><div className="search" onClick={this.onSearch.bind(this)}><span className="icon icon-search"></span> {t('common.search')}</div></li>
                            <li><div className="add" onClick={this.onClickAdd.bind(this)}><span className="icon icon-add"></span> {t('common.add')}</div></li>
                        </ul>
                    </div>
                </div>
            </div>

            {this.state.formSearch ?
                <div className="form-search">
                    <div className="row">

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('sell.id')}
                                    inputClass="form-control"
                                    inputId="id"
                                    inputName="id"
                                    value={searchParam.id}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('sell.customer_name')}
                                    inputClass="form-control"
                                    inputId="search_first_name"
                                    inputName="search_first_name"
                                    value={searchParam.search_first_name}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('sell.customer_phone')}
                                    inputClass="form-control"
                                    inputId="phone"
                                    inputName="phone"
                                    value={searchParam.phone}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <FormReactSelect
                                    label={t('sell.status')}
                                    instanceId="id_order_status"
                                    className="id_order_status"
                                    name="id_order_status"
                                    value={dataOrderStatus.filter(function (item) { return item.id === searchParam.id_order_status })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'id_order_status') }}
                                    optionList={dataOrderStatus}
                                    placeHolder={t('common.choose')}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <FormReactSelect
                                    label={t('sell.payment_method')}
                                    instanceId="id_payment_method"
                                    className="id_payment_method"
                                    name="id_payment_method"
                                    value={dataPaymentMethod.filter(function (item) { return item.id === searchParam.id_payment_method })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'id_payment_method') }}
                                    optionList={dataPaymentMethod}
                                    placeHolder={t('common.choose')}
                                />
                            </div>
                        </div>

                        

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <FormReactSelect
                                    label={t('sell.id_payment_status')}
                                    instanceId="id_payment_status"
                                    className="id_payment_status"
                                    name="id_payment_status"
                                    value={dataPaymentStatus.filter(function (item) { return item.id === searchParam.id_payment_status })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'id_payment_status') }}
                                    optionList={dataPaymentStatus}
                                    placeHolder={t('common.choose')}
                                />
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                            <div className="mb-3">
                                <CMSDatePicker
                                    label={t("common.from_date")}
                                    inputId="from_date"
                                    dateFormat = "dd/MM/yyyy"
                                    inputClass="form-control"
                                    inputName="from_date"
                                    maxLength={10}
                                    value={searchParam.from_date}
                                    handleChange={this.handleSearchInputChange.bind(this)}
                                    showIconCalendar={true}
                                />
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                            <div className="mb-3">
                                <CMSDatePicker
                                    label={t("common.to_date")}
                                    inputId="to_date"
                                    inputClass="form-control"
                                    inputName="to_date"
                                    dateFormat = "dd/MM/yyyy"
                                    maxLength={10}
                                    value={searchParam.to_date}
                                    maxDate={searchParam.max_date}
                                    handleChange={this.handleSearchInputChange.bind(this)}
                                    showIconCalendar={true}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div className="mb-3">
                                <button type="button" className="btn btn-search" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                                <button type="button" className="btn btn-reset" onClick={this.onResetSearch.bind(this)}> {t('common.reset')} </button>
                            </div>
                        </div>


                        {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-end">
                            <div className="mb-3">
                                <button type="button" className="btn btn-search inline-row" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                            </div>
                        </div> */}

                    </div>
                </div>
                : ""}

            <div className="data-view">
                <div className="main-header">
                    <div className="header-row">
                        <div onClick={e => this.onSort(e, 'id')} className={searchParam.sort_column === "id" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('sell.id_order')}</p></div>
                        <div onClick={e => this.onSort(e, 'name')} className={searchParam.sort_column === "name" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('sell.username')}</p></div>
                        <div className="header-col width35 text-center"><p>{t('sell.product_name')}</p></div>

                        <div className="header-col width10 text-end"><p>{t('sell.saleoff')}</p></div>
                        <div className="header-col width15 text-end"><p>{t('sell.total')}</p></div>
                        <div className="header-col width10 text-end"><strong>{t('common.action')}</strong></div>
                    </div>
                </div>

                <div className="main-body">
                    <div className="body">
                        {RowItems ? RowItems : <div className="data-empty">{t('common.data_empty')}</div>}
                    </div>
                </div>
            </div>

            <div className="main-paging">
                <Paging
                    total={parseInt(this.paging.total)}
                    current={(this.paging.current)}
                    currentInput={this.paging.currentInput}
                    inputChangedHandler={this.inputChangedHandler}
                    inputChangedEnter={this.inputChangedEnter}
                    inputChangedBlue={this.inputChangedBlue}
                    onClickReload={(e) => this.onClickReload.bind(this)}
                    onSelectPage={(index) => this.onSelectPage.bind(this, index)}>
                </Paging>
            </div>
        </div>
    );
};