import React from 'react';
import { RText } from '../../../../../components/Controls';
import Libs from '../../../../../utils/Libs';
import Modal from 'react-bootstrap4-modal';
import Constants from '../../../../../utils/Constants';


export default function AddPopup() {

    var { curItem } = this.state;
    const { t } = this.props;

    console.log(curItem);


    return (
        <Modal visible={true} className="modal-add" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('partner_customer.edit_title') : t('partner_customer.add_title')}
                </h5>
                <span className="close" onClick={this.props.onClickCloseAdd.bind(this, false)}><var className="icon-cancel-music"></var></span>
            </div>
            <div className="modal-body">
                <div className="box-info">
                    <h2>{t('common.info')}</h2>
                    <div className="box-info-content">
                        <div className="row">
                            

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="mb-3">
                                    <RText label={t('partner_customer.company_name')}
                                        inputClass="form-control"
                                        inputId="company_name"
                                        inputName="company_name"
                                        value={curItem.company_name}
                                        onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                        maxLength={200} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


                <div className="box-info">
                    <h2>{t('products.thumbnail')}</h2>
                    <div className="box-info-content">
                        <div className="section-thumbnail">
                            <ul className="row">
                                <li className="col-md-12">
                                    <input key={Libs.generateStrRandom(2, 2, 2, 8)} onChange={(event) => this.onUploadThumbnailChange(event)} type="file" id="upload_thumbnail" name="upload_thumbnail" />
                                    {Libs.isBlank(curItem.upload_thumbnail) && Libs.isBlank(curItem.thumbnail) ?
                                        <div className={!Libs.isBlank(curItem.thumbnail_mess) ? "add-thumbnail error-thumbnail" : "add-thumbnail"}>
                                            <label htmlFor="upload_thumbnail" className="upload_thumbnail">
                                                <i className="icon-picture-o"></i>
                                            </label>
                                        </div>
                                        : (!Libs.isBlank(curItem.upload_thumbnail) ?
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                    <span onClick={this.deleteThumbnail.bind(this)} className="delete"><var className="icon-trash-o"></var></span>
                                                </div>
                                                <img src={curItem.upload_thumbnail} />
                                            </div>
                                            :
                                            <div className="item-view">
                                                <div className="action">
                                                    <label htmlFor="upload_thumbnail" className="edit"><var className="icon-pencil-square-o"></var></label>
                                                </div>
                                                <img src={curItem.thumbnail_full} />
                                            </div>
                                        )}

                                </li>



                            </ul>
                        </div>
                    </div>

                </div>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-cancel" onClick={this.props.onClickCloseAdd.bind(this, false)}>
                    {t('common.cancel')}
                </button>
                <button type="button" className="btn btn-save" onClick={this.onSave.bind(this)}>
                    {(curItem.screen_mode === Constants.SCREEN_MODE.EDIT) ? t('common.update') : t('common.create')}
                </button>
            </div>
        </Modal>

    )
}