import BaseValidate from '../../../../../validator/BaseValidate';
class AddPopupValidate extends BaseValidate{
    constructor(props){
        super();
        var {t} = props;
        this.trans = t;
    }
    setRule(){
        this.addRuleForField('id_order_status', 'trim', true);
        this.addRuleForField('id_order_status', 'required', true, this.trans('validate_rule.required'), this.trans('sell.id_order_status'));
        this.addRuleForField('id_order_status', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('sell.id_order_status'));

        this.addRuleForField('id_payment_status', 'trim', true);
        this.addRuleForField('id_payment_status', 'required', true, this.trans('validate_rule.required'), this.trans('sell.id_payment_status'));
        this.addRuleForField('id_order_status', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('sell.id_payment_status'));

        this.addRuleForField('id_payment_method', 'trim', true);
        this.addRuleForField('id_payment_method', 'required', true, this.trans('validate_rule.required'), this.trans('sell.id_payment_method'));
        this.addRuleForField('id_payment_method', 'maxLength', 20, this.trans('validate_rule.maxLength_input'), this.trans('sell.id_payment_method'));

    }
    setAlias(){
        this.v.setAlias({
            id_order_status: this.trans('sell.id_order_status'),
            id_payment_status: this.trans('sell.id_payment_status'),
            id_payment_method: this.trans('sell.id_payment_method')
        });
        
    }
}
export default AddPopupValidate;