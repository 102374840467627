import React from 'react';
import RowItem from './RowItem';
import { Paging } from '../../../../../components/Paging';
import FormReactSelect from '../../../../../components/FormReactSelect';
import CMSDatePicker from '../../../../../components/CMSDatePicker/CMSDatePicker';
import AddPopup from './AddPopup';
import DeletePopup from './DeletePopup';
import { RText } from '../../../../../components/Controls';

export default function ReturnOrder() {
    const { t } = this.props;
    var { curItem, dataList, allLanguage, searchParam, dataStores, dataStatus, dataPaymentMethod } = this.state;
    var RowItems = null;
    RowItems = dataList.map((item, index) => {
        return <RowItem
            key={'row_item_' + index}
            index={index}
            dataItem={item}
            onItemClick={this.onItemClick}
            onItemClickDelete={this.onItemClickDelete}
            t={t}
        />
    });

    var AddPopupLayout = this.state.add
        ? <AddPopup
            t={t}
            allLanguage={allLanguage}
            curItem={curItem}
            onClickCloseAdd={this.onClickCloseAdd}
        /> : '';

    var DeletePopupLayout = this.state.delete
        ? <DeletePopup
            t={t}
            curItem={curItem}
            onClickCloseDelete={this.onClickCloseDelete}
        /> : '';

    return (
        <div className="return-order">
            {AddPopupLayout}
            {DeletePopupLayout}
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-md-6"><h2>{t('return_order.title')}</h2>
                    </div>
                    <div className="col-md-6">
                        <ul>
                            <li><div className="search" onClick={this.onSearch.bind(this)}><span className="icon icon-search"></span> {t('common.search')}</div></li>
                            <li><div className="add" onClick={this.onClickAdd.bind(this)}><span className="icon icon-add"></span> {t('common.add')}</div></li>
                        </ul>
                    </div>
                </div>
            </div>

            {this.state.formSearch ?
                <div className="form-search">
                    <div className="row">

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <FormReactSelect
                                    label={t('return_order.id_store')}
                                    instanceId="id_store"
                                    className="id_store"
                                    name="id_store"
                                    value={dataStores.filter(function (item) { return item.id === curItem.id_store })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'id_store') }}
                                    optionList={dataStores}
                                    placeHolder={t('common.choose')}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('return_order.id')}
                                    inputClass="form-control"
                                    inputId="id"
                                    inputName="id"
                                    value={searchParam.id}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('return_order.customer_name')}
                                    inputClass="form-control"
                                    inputId="name"
                                    inputName="name"
                                    value={searchParam.name}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('return_order.customer_phone')}
                                    inputClass="form-control"
                                    inputId="name"
                                    inputName="name"
                                    value={searchParam.name}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('return_order.product_name')}
                                    inputClass="form-control"
                                    inputId="name"
                                    inputName="name"
                                    value={searchParam.name}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <FormReactSelect
                                    label={t('return_order.status')}
                                    instanceId="status"
                                    className="status"
                                    name="status"
                                    value={dataStatus.filter(function (item) { return item.id === curItem.status })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'status') }}
                                    optionList={dataStatus}
                                    placeHolder={t('common.choose')}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <FormReactSelect
                                    label={t('return_order.payment_method')}
                                    instanceId="payment_method"
                                    className="payment_method"
                                    name="payment_method"
                                    value={dataPaymentMethod.filter(function (item) { return item.id === curItem.payment_method })}
                                    onChange={(e) => { this.handleDropdownChange(e, 'payment_method') }}
                                    optionList={dataPaymentMethod}
                                    placeHolder={t('common.choose')}
                                />
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                            <div className="mb-3">
                                <CMSDatePicker
                                    label={t("export_stock.from_date")}
                                    inputId="from_date"
                                    inputClass="form-control"
                                    inputName="from_date"
                                    maxLength={10}
                                    value={searchParam.from_date}
                                    handleChange={this.handleSearchInputChange.bind(this)}
                                    showIconCalendar={true}
                                />
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                            <div className="mb-3">
                                <CMSDatePicker
                                    label={t("export_stock.to_date")}
                                    inputId="to_date"
                                    inputClass="form-control"
                                    inputName="to_date"
                                    maxLength={10}
                                    value={searchParam.to_date}
                                    maxDate={searchParam.max_date}
                                    handleChange={this.handleSearchInputChange.bind(this)}
                                    showIconCalendar={true}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('return_order.employee_sell')}
                                    inputClass="form-control"
                                    inputId="name"
                                    inputName="name"
                                    value={searchParam.name}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('return_order.employee_account')}
                                    inputClass="form-control"
                                    inputId="name"
                                    inputName="name"
                                    value={searchParam.name}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <div className="mb-3">
                                <RText label={t('return_order.employee_technical')}
                                    inputClass="form-control"
                                    inputId="name"
                                    inputName="name"
                                    value={searchParam.name}
                                    onChange={(e) => { this.handleSearchInputChange(e); }}
                                    maxLength={100} />
                            </div>
                        </div>



                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-end">
                            <div className="mb-3">
                                <button type="button" className="btn btn-search inline-row" onClick={this.handleSearch.bind(this)}> {t('common.search')} </button>
                            </div>
                        </div>

                    </div>
                </div>
                : ""}

            <div className="data-view">
                <div className="main-header">
                    <div className="header-row">
                        <div onClick={e => this.onSort(e, 'id')} className={searchParam.sort_column === "id" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('return_order.id_order')}</p></div>
                        <div onClick={e => this.onSort(e, 'name')} className={searchParam.sort_column === "name" ? "header-col width15 sorting " + searchParam.order_by : "header-col width15 sorting"}><p>{t('return_order.username')}</p></div>
                        <div className="header-col width35 text-center"><p>{t('return_order.product_name')}</p></div>
                        
                        <div className="header-col width5 text-end"><p>{t('return_order.vat')}</p></div>
                        <div className="header-col width10 text-end"><p>{t('return_order.total')}</p></div>
                        <div className="header-col width10 text-end"><p>{t('return_order.refund')}</p></div>
                        
                        <div className="header-col width10 text-end">{t('common.action')}</div>
                    </div>
                </div>

                <div className="main-body">
                    <div className="body">
                        {RowItems ? RowItems : <div className="data-empty">{t('common.data_empty')}</div>}
                    </div>
                </div>
            </div>

            <div className="main-paging">
                <Paging
                    total={this.paging.total}
                    current={this.paging.current}
                    inputChangedHandler={this.inputChangedHandler}
                    onBrandKeyPress={this.onBrandKeyPress}
                    onInputPage={this.onInputPage}
                    onClickReload={this.onClickReload}
                    onSelectPage={(index) => this.onSelectPage.bind(this, index)}>
                </Paging>
            </div>
        </div>
    );
};